import React, { FC, useCallback, useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import moment from 'moment/moment';

import { toArray } from '../../../utils/utils';

import GenericColorHeader from '../../../sub-components/GenericColorHeader';
import { HeaderColors } from '../../../shared/headerColors/header-colors';

import ReadyLiveCardList, {
  IReadyLiveCard,
} from '../../../sub-components/LocationLaunchDashboard/ReadyLive/List';
import LiveModal from '../../../sub-components/LocationLaunchDashboard/LiveModal';
import { LauncherLocation } from './dashboard.graphql';
import { getCompletedTask } from './launcher.helpers';
import { LauncherLocationDetail } from '../details/types/location-launch-details.types';

interface ICProps {
  locations?: IReadyLiveCard[];
  onClick?: (locationId: string) => void;
}

const ReadyToLiveContents: FC<ICProps> = ({ locations = [], onClick }) => {
  if (locations.length === 0) {
    return null;
  }

  return (
    <Flex flexDir='column' gap={4}>
      <GenericColorHeader
        color={HeaderColors.Green}
        title={`${locations?.length} Ready to go-live`}
      />

      <ReadyLiveCardList locations={locations} onClick={onClick} />
    </Flex>
  );
};

interface IProps {
  locations: LauncherLocationDetail[];
}

const ReadyToLiveLocations: FC<IProps> = ({ locations }) => {
  const [selected, setSelected] = useState<LauncherLocationDetail>();

  const readyToGoLive = useMemo(() => {
    return toArray(locations).reduce<IReadyLiveCard[]>((acc, currentValue) => {
      const tasks = getCompletedTask(currentValue.tasks);

      if (tasks.length && tasks.length === currentValue.tasks?.length) {
        acc.push({
          isLive: currentValue.isLive,
          eid: currentValue.locationId,
          locationName: currentValue.location?.name,
          completedOn: moment(tasks.at(-1)!.completedAt).format('Do MMMM'),
        });
      }

      return acc;
    }, []);
  }, [locations]);

  const onClick = useCallback(
    (locationId: string) => {
      setSelected(locations?.find((value) => value.locationId === locationId));
    },
    [locations]
  );

  return (
    <React.Fragment>
      <ReadyToLiveContents locations={readyToGoLive} onClick={onClick} />

      {selected?.locationId && (
        <LiveModal
          isOpen={!!selected?.locationId}
          data={selected}
          onClose={() => setSelected(undefined)}
        />
      )}
    </React.Fragment>
  );
};

export default ReadyToLiveLocations;
