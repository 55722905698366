import React, { FC, forwardRef, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface ItemProps {
  color: string;
  isSelected?: boolean;
  onClick?: (color: string) => void;
}

const Item: FC<ItemProps> = ({ color, isSelected, onClick }) => {
  return (
    <Box
      borderRadius='full'
      boxSize={isSelected ? '62px' : '58px'}
      bg={color}
      cursor='pointer'
      border='2px solid #EFEFEF'
      borderColor={isSelected ? '#2A85FF' : '#EFEFEF'}
      _hover={{
        boxShadow: 'md',
      }}
      onClick={() => onClick?.(color)}
    />
  );
};

interface IProps {
  value: string;
  onChange?: (color: string) => void;
}

const BrandColorSelection = forwardRef<never, IProps>(({ value, onChange }) => {
  const colors = useMemo(() => {
    return [
      '#FFFFFF',
      '#7357F6',
      '#FFBA40',
      '#2EC0CA',
      '#DE6179',
      '#EE8131',
      '#4A99E9',
    ];
  }, []);

  return (
    <Flex gap='10px' py='10px' alignItems='center'>
      {colors.map((color) => (
        <Item
          key={color}
          color={color}
          isSelected={color === value}
          onClick={onChange}
        />
      ))}
    </Flex>
  );
});

BrandColorSelection.displayName = 'Brand Color Selection';

export default BrandColorSelection;
