export interface InitialEvents {
  LauncherDetailsPage: {
    fetchCompleteData: any;
  };
  EditLauncherTaskSideDrawer: {
    fetchCompleteData: any;
  };
}

export const InitialEventsJson: InitialEvents = {
  LauncherDetailsPage: {
    fetchCompleteData: undefined,
  },
  EditLauncherTaskSideDrawer: {
    fetchCompleteData: undefined,
  },
};
