import React, { FC, useContext } from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import SetSeeDataContext from '../SetSeeModalStore/set-see-data-context';
import FilterFunnelIcon from '../../../../../assets/images/filterFunnelIcon.svg';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import GenerateConditionsText from '../GenerateConditionsText';
import LocationJobsSection from './LocationJobsSection';
import MembersSection from './MembersSection';
import useCombinedStore from 'zustandStore/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import VisibilityStatusInfo from 'ui-components/VisibilityStatusInfo';
import { parentDataRender } from '../utility';

interface IProps {
  setNewRole: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollapseComponent: FC<IProps> = ({ setNewRole }) => {
  const { t } = useTranslation('deck');
  const setSeeCtx = useContext(SetSeeDataContext);
  const {
    customSectionExpand,
    hierarchyData,
    operationType,
    sameAsParent,
    visibilityScope,
    visibilitySelection,
    updateCustomSectionExpand,
  } = useCombinedStore();

  const opacityHandler = () => {
    if (visibilityScope === 'subFolder' || visibilityScope === 'chapter') {
      if (sameAsParent) {
        return '0.6';
      }
    }
    if (visibilitySelection?.visibility === 'private') {
      return '1';
    }
    return '0.6';
  };

  const cursorHandler = () => {
    if (visibilityScope === 'subFolder' || visibilityScope === 'chapter') {
      if (sameAsParent) {
        return 'no-drop';
      }
    }
    if (visibilitySelection?.visibility === 'private') {
      return 'pointer';
    }
    return 'no-drop';
  };

  const nameRender = () => {
    switch (visibilityScope) {
      case 'folder':
        if (operationType === 'add') {
          return null;
        }
        return (
          <Box>
            <Text fontWeight={700} as='span'>
              {hierarchyData?.parentFolder?.details?.name || 'Folder'}
            </Text>{' '}
            is visible to -
          </Box>
        );
      case 'subFolder':
        return (
          <Box>
            <Text fontWeight={700} as='span'>
              {hierarchyData?.subFolder?.details?.name || 'Subfolder'}
            </Text>{' '}
            is visible to -
          </Box>
        );
      case 'chapter':
        return (
          <Box>
            <Text fontWeight={700} as='span'>
              {hierarchyData?.chapter?.details?.title || 'Chapter'}
            </Text>{' '}
            is visible to -
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Flex
        align='center'
        opacity={opacityHandler()}
        cursor={cursorHandler()}
        justify='space-between'
        w='full'
        onClick={() => {
          if (
            sameAsParent &&
            (visibilityScope === 'subFolder' || visibilityScope === 'chapter')
          )
            return;
          if (visibilitySelection?.visibility === 'private') {
            updateCustomSectionExpand(!customSectionExpand);
          }
        }}
      >
        <Flex align='center' gap={2}>
          <FontAwesomeIcon
            flip='horizontal'
            icon={faFilter as IconProp}
            fontSize='17px'
          />
          <Text as='span' fontSize='20px' fontWeight={600}>
            {t('visibility.custom_heading')}
          </Text>
        </Flex>
        <FontAwesomeIcon
          icon={(customSectionExpand ? faChevronUp : faChevronDown) as IconProp}
          fontSize='18px'
        />
      </Flex>
      {customSectionExpand && (
        <Flex flexDir='column'>
          <LocationJobsSection setNewRole={setNewRole} />

          <MembersSection />

          <GenerateConditionsText
            marginTop='2rem'
            backgroundColor='rgba(177, 229, 252, 0.4)'
            customElement={nameRender() || undefined}
          />

          {parentDataRender(
            visibilityScope,
            hierarchyData,
            visibilitySelection
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default CollapseComponent;
