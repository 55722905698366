import { gql } from '@apollo/client';
import { SelectOption } from '../../../atoms';
import { LocationFieldEntity } from 'sop-commons/src/client';

export type FieldType =
  | 'text'
  | 'date'
  | 'dropDown'
  | 'singleChoice'
  | 'document';

export const FieldTypeString: Record<FieldType, string> = {
  text: 'Text',
  date: 'Date',
  document: 'Upload document',
  dropDown: 'Dropdown',
  singleChoice: 'Single select',
};

export const optionsData: SelectOption<FieldType>[] = [
  {
    label: FieldTypeString['text'],
    value: 'text',
  },
  {
    label: FieldTypeString['date'],
    value: 'date',
  },
  {
    label: FieldTypeString['document'],
    value: 'document',
  },
  {
    label: FieldTypeString['dropDown'],
    value: 'dropDown',
  },
  {
    label: FieldTypeString['singleChoice'],
    value: 'singleChoice',
  },
];

export interface ILocationFieldEntity {
  eid?: string;
  createdBy?: string;
  fieldName: string;
  fieldType: FieldType;
  options: string[];
  validations: Record<string, never>;
}

export interface UpdateFieldVariable {
  input: {
    eid: string;
    locationFields: ILocationFieldEntity[];
  };
}

export const UPDATE_LOCATION_FIELD = gql`
  mutation UpdateLocationField($input: UpdateEntityInput) {
    updateEntityById(input: $input) {
      eid
    }
  }
`;

export const mapToInput = (data: LocationFieldEntity): ILocationFieldEntity => {
  return {
    eid: data.eid,
    fieldType: data.fieldType,
    fieldName: data.fieldName,

    options: data.options,
    validations: data.validations as never,
    createdBy: data.createdBy.eid,
  };
};
