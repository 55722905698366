import { StateCreator } from 'zustand';
import { InitialEvents, InitialEventsJson } from './events';

interface EventState {
  events: InitialEvents;
  emitEvent: <
    Category extends keyof InitialEvents,
    Event extends keyof InitialEvents[Category]
  >(
    category: Category,
    event: Event,
    value: InitialEvents[Category][Event]
  ) => void;
  clearEvent: <
    Category extends keyof InitialEvents,
    Event extends keyof InitialEvents[Category]
  >(
    category: Category,
    event: Event
  ) => void;
}

export const emitterSlice: StateCreator<EventState> = (set) => ({
  events: InitialEventsJson,
  emitEvent: (category, event, value) => {
    set((state) => ({
      events: {
        ...state.events,
        [category]: { ...state.events[category], [event]: value },
      },
    }));
  },
  clearEvent: (category, event) => {
    set((state) => ({
      events: {
        ...state.events,
        [category]: { ...state.events[category], [event]: undefined },
      },
    }));
  },
});
