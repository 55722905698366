import { Stack, Box } from '@chakra-ui/react'
import React from 'react'
import TrainingPathAssignForm from './TrainingPathAssignForm'
import TrainingPathAssignText from './TrainingPathAssignText'

const TrainingPathAssign = () => {
    return (
        <Stack direction={'row'} spacing={4}>
            <Box width={'60%'}>
                <TrainingPathAssignForm />
            </Box>
            <Box width={'40%'}>
                <TrainingPathAssignText />
            </Box>
        </Stack>
    )
}

export default TrainingPathAssign