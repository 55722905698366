import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'atoms';
import Dropdown from 'atoms/Dropdown';
import { BoxHeader } from 'ui-components';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';

import { IFormInput } from '../../AddLocation/add-location.types';
import EmailListField from './EmailListField';
import PhoneListField from './PhonelListField';
import OwnerListField from './OwnerListField';
import { LocationStatusOption } from '../../AddLocation/static-data';

interface IProps {
  partialDisabled?: boolean;
}

const BasicDetails: FC<IProps> = ({ partialDisabled }) => {
  const { t } = useTranslation(['common', 'location']);
  const { control } = useFormContext<IFormInput>();

  const isRoot = useWatch<IFormInput, 'isRoot'>({
    name: 'isRoot',
  });

  const haveLauncher = useWatch<IFormInput, 'haveLauncher'>({
    name: 'haveLauncher',
  });

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='18px'
        color='#CABDFF'
        title={t('location:basicDetails')}
      />

      <Flex flexDir='column' gap='20px'>
        <Controller
          control={control}
          name='locationType'
          rules={{
            required: t('location:validation.locationTypeRequired'),
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={partialDisabled || isRoot}
                isInvalid={!!fieldState.error}
              >
                <TitleHeader title={t('location:locationType')} />
                <RadioGroup
                  mt={2}
                  value={field.value}
                  onChange={field.onChange}
                >
                  <Stack direction='row'>
                    <Radio value='corporate'>{t('location:corporate')}</Radio>
                    <Radio value='franchise'>{t('location:franchise')}</Radio>
                  </Stack>
                </RadioGroup>
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Flex gap='20px'>
          <Controller
            control={control}
            name='locationName'
            rules={{
              required: t('location:validation.locationNameRequired'),
              validate: (value) =>
                value.trim().length !== 0 ||
                t('common:validation.name_invalid'),
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  isDisabled={partialDisabled}
                  isInvalid={!!fieldState.error}
                >
                  <TitleHeader title={t('location:locationName')} />
                  <FormInput
                    mt={2}
                    size='lg'
                    placeholder={t('location:placeholder.name')}
                    {...field}
                  />
                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />

          <Box>
            <TitleHeader title={t('common:status')} />
            <Controller
              control={control}
              name='locationStatus'
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    mt={2}
                    minW='220px'
                    isInvalid={!!fieldState.error}
                  >
                    <Dropdown
                      isDisabled={haveLauncher || partialDisabled || isRoot}
                      size='lg'
                      placeholder={t('location:placeholder.locationStatus')}
                      options={LocationStatusOption}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Flex>

        <EmailListField />

        <PhoneListField />

        <OwnerListField isDisabled={partialDisabled} />
      </Flex>
    </Flex>
  );
};

export default BasicDetails;
