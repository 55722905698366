import { gql } from '@apollo/client';

export const GET_SUPPORTED_LOCALES = gql`
  query Query {
    SupportedLocales
  }
`;

export interface UpdateResponse {
  updateUser: {
    preferredLanguage: string;
  };
}

export const UPDATE_LANGUAGE_PREFERENCE = gql`
  mutation UpdateUser($input: UserUpdateInput) {
    updateUser(input: $input) {
      preferredLanguage
    }
  }
`;
