import { gql } from '@apollo/client';

interface Task {
  eid: string;
  title: string;
  category: string;
  startDate: string;
  completedAt: string;
  dueDate: string;
  isCompleted: boolean;
}

export interface LauncherLocation {
  launchId: string;
  location: Record<string, any>;
  locationDetails: Record<string, any>;
  locationId: string;
  startDate: string;
  isLive: boolean;
  tasks: Task[];

  // Helper Only
  isLauncherDue?: boolean;
}

export const LOCATION_TO_LAUNCH = gql`
  query LocationLaunchList {
    LocationLaunchList {
      launchId
      locationId
      startDate
      isLive
      tasks {
        eid
        title
        startDate
        completedAt
        dueDate
        isCompleted
      }
    }
  }
`;
