import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { ReactComponent as ChatIcon } from '../../../../assets/images/chat.svg';

interface IProps {}

const ItemHelpQuery: FC<IProps> = () => {
  return (
    <Flex flexDir='column'>
      <Text
        color='#6F767E'
        fontWeight={500}
        fontSize='12px'
        lineHeight='unset !important'
      >
        Latest help query
      </Text>

      <Flex align='center' gap='6px'>
        {/*<ChatIcon width='16px' height='16px' />*/}

        {/*<Text color='#2A85FF' fontWeight={500} fontSize='12px'>*/}
        {/*  Getting final document*/}
        {/*</Text>*/}
        <Text color='#111315' fontWeight={500} fontSize='12px'>
          -
        </Text>
      </Flex>
    </Flex>
  );
};

export default ItemHelpQuery;
