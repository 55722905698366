import React, { FC } from 'react';
import {
  Box,
  Flex,
  ModalCloseButton,
  ModalHeader,
  Spacer,
  useModalContext,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IFormInput } from './invite.types';
import BoxHeader from '../BoxHeader';

interface IProps {
  reInviteUser?: boolean;
}

const Header: FC<IProps> = ({ reInviteUser }) => {
  const { t } = useTranslation(['invite']);
  const { onClose } = useModalContext();
  const { setValue, getValues } = useFormContext<IFormInput>();

  const currentStep = useWatch<IFormInput, 'currentStep'>({
    name: 'currentStep',
  });

  const onGoBackClick = () => {
    // switch (getValues('currentStep')) {
    //   case 'QRCode':
    //     setValue('currentStep', undefined);
    //     break;
    //   case 'sendLink':
    //     setValue('currentStep', 'QRCode');
    //     break;
    //   default:
    //     break;
    // }

    if (reInviteUser) {
      onClose();
    } else {
      setValue('currentStep', 'inviteForm');
    }
  };

  if (currentStep) {
    return (
      <ModalHeader>
        <Flex align='center' gap='12px'>
          {currentStep === 'inviteForm' && (
            <BoxHeader
              title={t('invite:inviteMember')}
              color='#CABDFF'
              fontSize='18px'
            />
          )}

          {currentStep === 'shareTabs' && (
            <FontAwesomeIcon
              style={{
                cursor: 'pointer',
              }}
              icon={faArrowLeft as IconProp}
              onClick={onGoBackClick}
            />
          )}

          {currentStep === 'shareTabs' && (
            <Box fontSize='18px' fontWeight='600' color='#1A1D1F'>
              <Trans
                t={t}
                i18nKey='invite:inviteUser'
                values={{
                  name: getValues('name'),
                }}
              />
            </Box>
          )}

          {currentStep === 'chatGroup' && (
            <BoxHeader
              // @ts-ignore
              title={t('invite:assignUserToChatGroups', {
                name: getValues('name'),
              })}
              color='#CABDFF'
              fontSize='18px'
              isTruncated
            />
          )}

          {currentStep === 'trainingPath' && (
            <BoxHeader
              // @ts-ignore
              title={t('invite:assignUserToTrainings', {
                name: getValues('name'),
              })}
              color='#CABDFF'
              fontSize='18px'
              isTruncated
            />
          )}

          <Spacer />
          <ModalCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      </ModalHeader>
    );
  }

  return (
    <ModalHeader>
      <Flex align='center' gap='12px'>
        <BoxHeader
          title={t('invite:inviteMember')}
          fontSize='18px'
          color='#CABDFF'
        />
        <Spacer />
        <ModalCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
    </ModalHeader>
  );
};

export default Header;
