import { Modal } from 'antd';

const VideoModal = ({ open, videoUrl, onClose }) => {
  console.log('Video M odal', videoUrl);
  return (
    <Modal visible={open} onCancel={onClose} footer={null}>
      <div style={{ marginTop: '55px' }}>
        <video controls>
          <source src={videoUrl} />
        </video>
        {/* <video src={videoUrl} /> */}
      </div>
    </Modal>
  );
};

export default VideoModal;
