import { FC, useState } from 'react';
import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLockKeyhole,
  faEye,
  faEyeSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps extends InputProps {
  placeholder?: string;
  id?: string;
  leftIcon?: boolean;
  rightIcon?: boolean;
  error?: boolean;
  formProps: any;
}

const PasswordInput: FC<IProps> = ({
  formProps,
  placeholder,
  error,
  leftIcon,
  rightIcon,
  id,
}) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const size = formProps?.size || 'lg';
  return (
    <InputGroup size={size}>
      {leftIcon && (
        <InputLeftElement pointerEvents='none'>
          <FontAwesomeIcon
            icon={faLockKeyhole as IconProp}
            color={error ? '#ff6a55' : '#6f767e'}
          />
        </InputLeftElement>
      )}
      {/* <FormInput
        id='password'
        type={show ? 'text' : 'password'}
        placeholder='Password'
        formProps={formProps}
      /> */}
      <Input
        id={id || 'password'}
        type={show ? 'text' : 'password'}
        placeholder={placeholder || 'Password'}
        variant='filled'
        style={{
          fontSize: '15px',
          borderRadius: 12,
          background: error ? 'rgba(255, 188, 153, 0.25)' : '',
          border: error ? 'none' : '',
        }}
        autoComplete='new-password'
        {...formProps}
        //   {...register('password', {
        //     required: 'Password is required',
        //   })}
      />

      {rightIcon && (
        <InputRightElement width='3rem'>
          <FontAwesomeIcon
            icon={(show ? faEyeSlash : faEye) as IconProp}
            color={error ? '#ff6a55' : '#6f767e'}
            style={{ cursor: 'pointer', alignSelf: 'center' }}
            onClick={handleClick}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default PasswordInput;
