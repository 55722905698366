import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import moment from 'moment/moment';

import { LocLauncherStatus } from '../launcher.types';

import StatusBadge from '../../../../sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/StatusBadge';
import NameDetails from '../../../../sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/NameDetails';
import InProgressBar from '../../../../sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/ProgressBar/InProgressBar';
import PercentDetails from '../../../../sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/PercentDetails';

interface IProps {
  status: LocLauncherStatus;
  name: string;
  completed: number;
  totalTasks: number;
  startDate?: string;
  overdueTasks?: number;
}

const FORMAT = '[Started on] Do MMMM';

const ItemHeader: FC<IProps> = ({
  name,
  status,
  completed,
  totalTasks,
  startDate,
  overdueTasks,
}) => {
  const isOnTrack = status === LocLauncherStatus.ON_TRACK;

  const startedOnDate = startDate ? moment(startDate).format(FORMAT) : '-';

  return (
    <Flex flexDir='column' gap={2}>
      <Flex gap={4} justify='space-between' align='center'>
        <NameDetails locName={name} startedOnDate={startedOnDate} />
        <StatusBadge
          label={isOnTrack ? 'ON TRACK' : 'LAGGING'}
          labelProps={{
            whiteSpace: 'nowrap',
            color: isOnTrack ? '#83BF6E' : '#FF6A55',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: 'unset !important',
          }}
          boxProps={{
            bg: isOnTrack ? '#B5E4CA4D' : '#FF6A5526',
            p: '5px 8px 4px',
          }}
        />
      </Flex>
      <InProgressBar completed={completed} totalCount={totalTasks} />
      <Flex align='center' justify='space-between'>
        <PercentDetails completed={completed} totalCount={totalTasks} />
        {!isOnTrack && (
          <Text color='rgba(166, 177, 188, 1)' fontWeight={500} fontSize='12px'>
            {overdueTasks} overdue
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default ItemHeader;
