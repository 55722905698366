import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { InfoFormValues } from '../ProfileInfoForm';
import ResendInvite from './ResendInvite';
import UpdateButton from './UpdateButton';

interface IProps {
  userId: string;
  onSubmit: (values: InfoFormValues) => Promise<void>;
  onResendClick?: (...args: any[]) => any | PromiseLike<any>;
}

const UpdateOrResendInvite: FC<IProps> = ({
  userId,
  onResendClick,
  onSubmit,
}) => {
  const { watch } = useFormContext<InfoFormValues>();

  const userStatus = watch('status');

  if (userStatus === 'pending') {
    return <ResendInvite userId={userId} onResendClick={onResendClick} />;
  }

  return <UpdateButton onSubmit={onSubmit} />;
};

export default UpdateOrResendInvite;
