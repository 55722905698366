import React, { FC } from 'react';
import { Flex, IconButton, Spacer } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CloseIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { IoChevronBackOutline } from 'react-icons/all';

import { IFormInput } from './add-location.types';

const AddLocationHeader: FC = () => {
  const history = useHistory();

  const { control, getValues, setValue } = useFormContext<IFormInput>();

  const currentTab = useWatch<IFormInput, 'currentTab'>({
    control: control,
    name: 'currentTab',
  });

  const onBackClick = () => {
    const nextTab = getValues('currentTab') - 1;
    if (nextTab < 0) {
      return history.goBack();
    }
    setValue('currentTab', nextTab);
  };

  return (
    <Flex
      justify={currentTab !== 3 ? 'space-between' : 'flex-end'}
      mt='48px'
      mb='40px'
    >
      <IconButton
        variant='outline'
        aria-label='back'
        fontSize='20px'
        borderRadius='full'
        borderColor='#A4A9AC'
        icon={<IoChevronBackOutline color='#6F767E' />}
        onClick={onBackClick}
      />

      <Spacer />

      <IconButton
        variant='outline'
        aria-label='close'
        borderRadius='full'
        borderColor='#A4A9AC'
        icon={<CloseIcon boxSize='14px' color='#6F767E' />}
        onClick={history.goBack}
      />
    </Flex>
  );
};

export default AddLocationHeader;
