import shortAnswer from '../../../../assets/images/form/Short answer.svg';
import shortAnswerLight from '../../../../assets/images/form/light/Short answer.svg';
import multiLine from '../../../../assets/images/form/Paragraph.svg';
import multiLineLight from '../../../../assets/images/form/light/Paragraph.svg';
import multiChoice from '../../../../assets/images/form/Multiplechoice.svg';
import multiChoiceLight from '../../../../assets/images/form/light/Multiplechoice.svg';
import checkbox from '../../../../assets/images/form/Checkbox.svg';
import checkboxLight from '../../../../assets/images/form/light/Checkbox.svg';
import dropdown from '../../../../assets/images/form/Drodpown.svg';
import dropdownLight from '../../../../assets/images/form/light/Drodpown.svg';
import fileUpload from '../../../../assets/images/form/File Upload.svg';
import fileUploadLight from '../../../../assets/images/form/light/File Upload.svg';
import location from '../../../../assets/images/form/Location.svg';
import locationLight from '../../../../assets/images/form/light/Location.svg';
import member from '../../../../assets/images/form/Member.svg';
import memberLight from '../../../../assets/images/form/light/Member.svg';
import section from '../../../../assets/images/form/Section.svg';
import sectionLight from '../../../../assets/images/form/light/Section.svg';
import record from '../../../../assets/images/form/Video.svg';
import recordLight from '../../../../assets/images/form/light/Video.svg';
import image from '../../../../assets/images/form/Image.svg';
import imageLight from '../../../../assets/images/form/light/Image.svg';

import textSection from '../../../../assets/images/form/light/text-section.svg'; // TODO add dark image
import textSectionLight from '../../../../assets/images/form/light/text-section.svg';

import acknowledgment from '../../../../assets/images/form/signature.svg'; // TODO add dark image
import acknowledgmentLight from '../../../../assets/images/form/light/signature.svg';

import { QuestionType } from '../../../../types';

export interface IQuestionTypeData {
  title: string;
  image: string;
  imageLight: string;
  value: QuestionType;
  description?: string;
}

const QuestionTypeData: IQuestionTypeData[] = [
  {
    title: 'short',
    image: shortAnswer,
    imageLight: shortAnswerLight,
    value: QuestionType.SHORT_TEXT,
  },
  {
    title: 'paragraph',
    image: multiLine,
    imageLight: multiLineLight,
    value: QuestionType.LONG_TEXT,
    description: 'paragraph_desc',
  },
  {
    title: 'multi',
    image: multiChoice,
    imageLight: multiChoiceLight,
    value: QuestionType.MULTI_CHOICE,
  },
  {
    title: 'checkbox',
    image: checkbox,
    imageLight: checkboxLight,
    value: QuestionType.CHECKBOX,
  },
  {
    title: 'dropdown',
    image: dropdown,
    imageLight: dropdownLight,
    value: QuestionType.DROPDOWN,
  },
  {
    title: 'file',
    image: fileUpload,
    imageLight: fileUploadLight,
    value: QuestionType.FILE_UPLOAD,
  },
  {
    title: 'location',
    image: location,
    imageLight: locationLight,
    value: QuestionType.LOCATION,
  },
  {
    title: 'member',
    image: member,
    imageLight: memberLight,
    value: QuestionType.MEMBER,
  },
  {
    title: 'section',
    image: section,
    imageLight: sectionLight,
    value: QuestionType.SECTION,
  },
  {
    title: 'video',
    image: record,
    imageLight: recordLight,
    value: QuestionType.VIDEO_UPLOAD,
  },
  {
    title: 'image',
    image: image,
    imageLight: imageLight,
    value: QuestionType.IMAGE_UPLOAD,
  },
  {
    title: 'textSection',
    image: textSection,
    imageLight: textSectionLight,
    value: QuestionType.TEXT_SECTION,
  },
  {
    title: 'acknowledgment',
    image: acknowledgment,
    imageLight: acknowledgmentLight,
    value: QuestionType.ACKNOWLEDGMENT,
  },
];

export const QuestionImage = QuestionTypeData.reduce((acc, value) => {
  acc[value.value] = value;
  return acc;
}, {} as Record<QuestionType, IQuestionTypeData>);

export default QuestionTypeData;
