import React, { FC, useEffect, useMemo } from 'react';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useUserDataSelector } from 'hooks';

import { ActionButton } from 'ui-components';

import DashboardContainer from 'sub-components/DashboardContainer';
import AddLocationHeader from './AddLocationHeader';
import { IFormInput } from './add-location.types';
import { addLocationFormat } from './formatSubmitData';
import {
  ADD_LOCATION_QUERY,
  AddLocationVariable,
  CREATE_LOCATION,
} from './add-location.graphql';
import { getDefaultFormData } from './default-form-data';
import LocationSidebar from './LocationSidebar';
import BasicInformation from './BasicInformation';
import LocationDetails from './LocationDetails';
import ProjectDetails from './ProjectDetails';
import AddInviteOwner from './AddInviteOwner';
import { UPDATE_LOCATION_OWNERS_FOR_LOCATION } from 'pages/LocationDetails/components/LocationAssetsCard/components/OwnersMembers/components/LocationOwnerListMenu/location-owner-list-menu.graphql';
import { LAUNCHER_DASHBOARD } from 'appRoutes';
import {
  InviteResponse,
  InviteVariable,
  INVITE_USER_QUERY,
  SendInviteResponse,
  SendInviteVariable,
  SEND_INVITE_QUERY,
} from 'ui-components/InviteUserNew/invite.graphql';
import { AuthRole } from 'sop-commons/src/client';
import { toArray } from 'authorization/authorization.utils';

interface IProps {}

const AddLocation: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'location']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const history = useHistory();
  const launcherId = useUserDataSelector(
    (state) => state?.entity?.launcher?.eid
  );

  const methods = useForm<IFormInput>({
    defaultValues: getDefaultFormData(),
  });

  const [createLocation, { loading: creatingLocation }] = useMutation(
    CREATE_LOCATION,
    {
      onCompleted: (data) => {
        methods.setValue('createdLocationId', data.CreateLocationWithLauncher.eid);
        /** Location is created, now move to STEP 2 */
        methods.setValue('loading', true);
        locationOwnerAdditionHandler();
      },
      onError: (error) => {
        methods.setValue('loading', false);
        toast({
          status: 'error',
          title: t('common:error'),
          description: error?.message || t('location:location_error'),
        });
      },
    }
  );

  const [addLocationOwners] = useMutation(UPDATE_LOCATION_OWNERS_FOR_LOCATION, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Finished setting up location',
      });
      methods.setValue('loading', false);
      history.push(LAUNCHER_DASHBOARD);
    },
    onError: () => {
      methods.setValue('loading', false);
      toast({
        status: 'error',
        title: 'Location setup could not be finished',
      });
    },
  });

  const [inviteUser] = useMutation<InviteResponse, InviteVariable>(
    INVITE_USER_QUERY
  );

  useEffect(() => {
    methods.setValue('loading', creatingLocation);
  }, [creatingLocation]);

  // const [addLocation, { loading }] = useMutation<never, AddLocationVariable>(
  //   ADD_LOCATION_QUERY,
  //   {
  //     onCompleted: () => {
  //       toast({
  //         status: 'success',
  //         title: t('common:success'),
  //         description: t('location:location_created'),
  //       });
  //       history.goBack();
  //     },
  //     onError: (error) => {
  //       if (error?.message?.includes('Username')) {
  //         methods.setError(
  //           'username',
  //           {
  //             type: 'custom',
  //             message: error.message,
  //           },
  //           {
  //             shouldFocus: true,
  //           }
  //         );
  //       } else {
  //         toast({
  //           status: 'error',
  //           title: t('common:error'),
  //           description: t('location:location_error'),
  //         });
  //       }
  //     },
  //   }
  // );

  /** STEP 1 */

  const onSubmit = async (data: IFormInput) => {
    if (methods.getValues('currentTab') === 3) {
      console.log('data : ', data);
      const inputData = addLocationFormat(cloneDeep(data), launcherId);
      console.log('input data : ', inputData);
      // locationOwnerAdditionHandler();
      methods.setValue('loading', true);
      createLocation({
        variables: {
          input: inputData,
        },
      });
      // if (methods.getValues('currentTab') === 2) {
      //   console.log(data);
      //   const inputData = addLocationFormat(cloneDeep(data), launcherId);
      //   await createLocation({
      //     variables: {
      //       input: inputData,
      //     },
      //   });
      // }
      // await addLocation({
      //   variables: {
      //     input: inputData,
      //   },
      // });
    }
  };

  const addLocationOwnersHandler = () => {
    let locationOwnerOneData = methods.getValues('locationOwnerOne');
    let locationOwnerTwoData = methods.getValues('locationOwnerTwo');
    let loEids = [] as string[];
    if (locationOwnerOneData?.eid) {
      loEids?.push(locationOwnerOneData?.eid);
    }
    if (locationOwnerTwoData?.eid) {
      loEids?.push(locationOwnerTwoData?.eid);
    }
    if (loEids?.length > 0) {
      let locationOwnerInput = {
        eid: methods.getValues('createdLocationId'),
        locationOwners: loEids,
      };
      console.log('Location Owners input : ', locationOwnerInput);
      methods.setValue('loading', true);
      addLocationOwners({
        variables: {
          input: locationOwnerInput,
        },
      });
    }
  };

  const [sendSmsInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY
  );

  const [sendEmailInvite] = useMutation<SendInviteResponse, SendInviteVariable>(
    SEND_INVITE_QUERY
  );

  const onSendEmailInvite = async (invitedUserId: string, email: string) => {
    await sendEmailInvite({
      variables: {
        input: {
          eid: invitedUserId!,
          contact: email,
          type: 'email',
        },
      },
    });
  };

  const onSendPhoneInvite = async (invitedUserId: string, phone: string) => {
    await sendSmsInvite({
      variables: {
        input: {
          eid: invitedUserId,
          contact: phone?.includes('+') ? phone : `+1${phone}`,
          type: 'sms',
        },
      },
    });
  };

  const inviteUserHandler = async (type: 'lo1' | 'lo2') => {
    methods.setValue('loading', true);
    let res = await inviteUser({
      variables: {
        input: {
          name:
            type === 'lo1'
              ? methods.getValues('locationOwnerOne.name')?.trim()
              : methods.getValues('locationOwnerTwo.name')?.trim(),
          authRole: AuthRole.LOCATION_OWNER,
          role:
            type === 'lo1'
              ? methods.getValues('locationOwnerOne.job.value')
              : methods.getValues('locationOwnerTwo.job.value'),
          branchIds: toArray(methods.getValues('createdLocationId'))
            .map((it) => it)
            .filter(Boolean),
        },
      },
    });
    if (res?.errors) {
      methods.setValue('loading', false);
      toast({
        status: 'error',
        title: `${
          type === 'lo1'
            ? methods.getValues('locationOwnerOne.name')?.trim()
            : methods.getValues('locationOwnerTwo.name')?.trim()
        } could not be added`,
      });
    } else {
      // LO is added, update the form data
      if (type === 'lo1') {
        methods.setValue('locationOwnerOne.eid', res?.data?.inviteUser?.eid!);
      } else {
        methods.setValue('locationOwnerTwo.eid', res?.data?.inviteUser?.eid!);
      }
      // Now, send invite(s) based on the selection of email/phone number
      const invitePromises = [];
      let shouldSendEmailInvite = false;
      let shouldSendSmsInvite = false;
      if (type === 'lo1') {
        if (methods.getValues('locationOwnerOne.email')) {
          shouldSendEmailInvite = true;
        }
        if (methods.getValues('locationOwnerOne.phone')) {
          shouldSendSmsInvite = true;
        }
      } else {
        if (methods.getValues('locationOwnerTwo.email')) {
          shouldSendEmailInvite = true;
        }
        if (methods.getValues('locationOwnerTwo.phone')) {
          shouldSendSmsInvite = true;
        }
      }
      // Prepare promises based on the user selection
      if (shouldSendEmailInvite) {
        let inviteUserId = '';
        let email = '';
        if (type === 'lo1') {
          inviteUserId = methods.getValues('locationOwnerOne.eid');
          email = methods.getValues('locationOwnerOne.email');
        } else {
          inviteUserId = methods.getValues('locationOwnerTwo.eid');
          email = methods.getValues('locationOwnerTwo.email');
        }
        invitePromises.push(onSendEmailInvite(inviteUserId, email));
      }
      if (shouldSendSmsInvite) {
        let inviteUserId = '';
        let phone = '';
        if (type === 'lo1') {
          inviteUserId = methods.getValues('locationOwnerOne.eid');
          phone = methods.getValues('locationOwnerOne.phone');
        } else {
          inviteUserId = methods.getValues('locationOwnerTwo.eid');
          phone = methods.getValues('locationOwnerTwo.phone');
        }
        invitePromises.push(onSendPhoneInvite(inviteUserId, phone));
      }

      // Use Promise.allSettled to wait for all invites to complete
      try {
        methods.setValue('loading', true);
        let result = await Promise.allSettled(invitePromises);
        if (result) {
          setTimeout(() => {
            addLocationOwnersHandler();
          }, 4000);
        }
      } catch (e) {
        toast({
          status: 'error',
          title: 'Invites could not be sent',
        });
        methods.setValue('loading', false);
      }
    }
  };

  const addLO1 = async () => {
    methods.setValue('loading', true);
    let res = await inviteUser({
      variables: {
        input: {
          name: methods.getValues('locationOwnerOne.name')?.trim(),
          authRole: AuthRole.LOCATION_OWNER,
          role: methods.getValues('locationOwnerOne.job.value'),
          branchIds: toArray(methods.getValues('createdLocationId'))
            .map((it) => it)
            .filter(Boolean),
        },
      },
    });
    if (res?.errors) {
      methods.setValue('loading', false);
      toast({
        status: 'error',
        title: `${methods
          .getValues('locationOwnerOne.name')
          ?.trim()} could not be added`,
      });
    } else {
      methods.setValue('locationOwnerOne.eid', res?.data?.inviteUser?.eid!);
      addLO2();
    }
  };

  const addLO2 = async () => {
    methods.setValue('loading', true);
    let res = await inviteUser({
      variables: {
        input: {
          name: methods.getValues('locationOwnerTwo.name')?.trim(),
          authRole: AuthRole.LOCATION_OWNER,
          role: methods.getValues('locationOwnerTwo.job.value'),
          branchIds: toArray(methods.getValues('createdLocationId'))
            .map((it) => it)
            .filter(Boolean),
        },
      },
    });
    if (res?.errors) {
      methods.setValue('loading', false);
      toast({
        status: 'error',
        title: `${methods
          .getValues('locationOwnerTwo.name')
          ?.trim()} could not be added`,
      });
    } else {
      methods.setValue('locationOwnerTwo.eid', res?.data?.inviteUser?.eid!);
      setTimeout(() => {
        inviteLO1();
      }, 1000);
    }
  };

  const inviteLO1 = async () => {
    // Now, send invite(s) based on the selection of email/phone number
    const invitePromises = [];
    let shouldSendEmailInvite = false;
    let shouldSendSmsInvite = false;
    if (methods.getValues('locationOwnerOne.email')) {
      shouldSendEmailInvite = true;
    }
    if (methods.getValues('locationOwnerOne.phone')) {
      shouldSendSmsInvite = true;
    }
    // Prepare promises based on the user selection
    if (shouldSendEmailInvite) {
      let inviteUserId = '';
      let email = '';
      inviteUserId = methods.getValues('locationOwnerOne.eid');
      email = methods.getValues('locationOwnerOne.email');
      invitePromises.push(onSendEmailInvite(inviteUserId, email));
    }
    if (shouldSendSmsInvite) {
      let inviteUserId = '';
      let phone = '';
      inviteUserId = methods.getValues('locationOwnerOne.eid');
      phone = methods.getValues('locationOwnerOne.phone');
      invitePromises.push(onSendPhoneInvite(inviteUserId, phone));
    }

    // Use Promise.allSettled to wait for all invites to complete
    try {
      methods.setValue('loading', true);
      let result = await Promise.allSettled(invitePromises);
      if (result) {
        setTimeout(() => {
          inviteLO2();
        }, 4000);
      }
    } catch (e) {
      toast({
        status: 'error',
        title: 'Invites could not be sent',
      });
      methods.setValue('loading', false);
    }
  };

  const inviteLO2 = async () => {
    // Now, send invite(s) based on the selection of email/phone number
    const invitePromises = [];
    let shouldSendEmailInvite = false;
    let shouldSendSmsInvite = false;
    if (methods.getValues('locationOwnerTwo.email')) {
      shouldSendEmailInvite = true;
    }
    if (methods.getValues('locationOwnerTwo.phone')) {
      shouldSendSmsInvite = true;
    }
    // Prepare promises based on the user selection
    if (shouldSendEmailInvite) {
      let inviteUserId = '';
      let email = '';
      inviteUserId = methods.getValues('locationOwnerTwo.eid');
      email = methods.getValues('locationOwnerTwo.email');
      invitePromises.push(onSendEmailInvite(inviteUserId, email));
    }
    if (shouldSendSmsInvite) {
      let inviteUserId = '';
      let phone = '';
      inviteUserId = methods.getValues('locationOwnerTwo.eid');
      phone = methods.getValues('locationOwnerTwo.phone');
      invitePromises.push(onSendPhoneInvite(inviteUserId, phone));
    }

    // Use Promise.allSettled to wait for all invites to complete
    try {
      methods.setValue('loading', true);
      let result = await Promise.allSettled(invitePromises);
      if (result) {
        addLocationOwnersHandler();
      }
    } catch (e) {
      toast({
        status: 'error',
        title: 'Invites could not be sent',
      });
      methods.setValue('loading', false);
    }
  };

  /** STEP 2 */
  const locationOwnerAdditionHandler = async () => {
    let locOneData = methods.getValues('locationOwnerOne');
    let locTwoData = methods.getValues('locationOwnerTwo');
    console.log({ locationOwner1: locOneData, locationOwner2: locTwoData });
    /** Check how many location owners data is present. Atleast 1 location owner is required, and maximum can be 2 */
    const checkBothSelected = () => {
      console.log('BOTH ARE SELECTED');
      // If both selected are from dropdown
      if (locOneData?.isExistingMember && locTwoData?.isExistingMember) {
        console.log('BOTH ARE SELECTED FROM DROPDOWN');
        addLocationOwnersHandler();
      }
      // If LO1 is selected from dropdown and LO2 is added manually
      if (locOneData?.isExistingMember && !locTwoData?.isExistingMember) {
        console.log('LO1 is selected from dropdown, LO2 is entered manually');
        // First add the LO2, then invite through email/phone and then HIT THE API
        inviteUserHandler('lo2');
        return;
      }
      // If LO1 is added manually and LO2 is selected from dropdown
      if (!locOneData?.isExistingMember && locTwoData?.isExistingMember) {
        console.log('LO1 is entered manually, LO2 is selected from dropdown');
        // First add the LO1, then invite through email/phone and then HIT THE API
        inviteUserHandler('lo1');
        return;
      }
      // If LO1 is added manually and LO2 is added manually
      if (!locOneData?.isExistingMember && !locTwoData?.isExistingMember) {
        console.log('Both LO1 and LO2 are entered manually');
        // Invite both LO1 and LO2, then HIT THE API
        // inviteUserHandler('lo1');
        // inviteUserHandler('lo2');
        // Invite lo1
        addLO1();
        return;
      }
    };

    const checkLO1 = () => {
      console.log('Only LO1 is there');
      if (locOneData?.isExistingMember) {
        console.log('LO1 is selected from dropdown');
        // HIT THE API
        addLocationOwnersHandler();
        return;
      } else {
        // First invite the LO1, then HIT THE API
        console.log('LO1 is entered manually');
        inviteUserHandler('lo1');
        return;
      }
    };

    /** If BOTH are selected */
    if (locOneData?.name && locTwoData?.name) {
      checkBothSelected();
    } else if (locOneData?.name) {
      /** If only LO1 is selected*/
      checkLO1();
    }
  };

  // const locationOwnerAdditionHandler = async () => {
  //   let locOneData = methods.getValues('locationOwnerOne');
  //   let locTwoData = methods.getValues('locationOwnerTwo');

  //   // Handler for adding location owners directly
  //   const handleAddLocationOwnersDirectly = async () => {
  //     try {
  //       const result = await addLocationOwnersHandler();
  //       console.log('Location owners added successfully : ', result);
  //     } catch (error) {
  //       console.error('Error adding location owners:', error);
  //       // Handle the error appropriately
  //     }
  //   };

  //   // Handler for inviting users and then adding them as location owners
  //   const handleInviteAndAddLocationOwners = async () => {
  //     const invitePromises = [];
  //     if (!locOneData.isExistingMember) {
  //       invitePromises.push(inviteUserHandler('lo1'));
  //     }
  //     if (!locTwoData.isExistingMember) {
  //       invitePromises.push(inviteUserHandler('lo2'));
  //     }

  //     try {
  //       // Wait for all invite operations to complete
  //       const inviteResults = await Promise.allSettled(invitePromises);
  //       console.log('All invites sent successfully : ', inviteResults);
  //       // Now add the location owners after the invites
  //       const addOwnersResult = await addLocationOwnersHandler();
  //       console.log('Location owners added successfully after invites : ', addOwnersResult);
  //     } catch (error) {
  //       console.error('Error during invite and add operation:', error);
  //       // Handle the error appropriately
  //     }
  //   };

  //   // Determine the appropriate actions based on the state of location owners
  //   if (locOneData?.isExistingMember && locTwoData?.isExistingMember) {
  //     // Both location owners exist, so we can add them directly
  //     await handleAddLocationOwnersDirectly();
  //   } else {
  //     // At least one of the location owners needs an invite, handle invites first
  //     await handleInviteAndAddLocationOwners();
  //   }
  // };

  const currentTab = useWatch<IFormInput, 'currentTab'>({
    control: methods.control,
    name: 'currentTab',
  });

  const Content = useMemo(() => {
    return [BasicInformation, LocationDetails, ProjectDetails, AddInviteOwner][
      currentTab
    ];
  }, [currentTab]);

  return (
    <FormProvider {...methods}>
      <Box display='none'>
        <DashboardContainer />
      </Box>
      <Flex height='inherit' bg='#F4F4F4'>
        <LocationSidebar />

        <Flex flex={1} flexDir='column' pb={8} px='40px' overflow='auto'>
          <AddLocationHeader />

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Content />
          </form>
        </Flex>
      </Flex>
    </FormProvider>
  );
};

export default AddLocation;
