import React, { FC } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { BoxHeader } from 'ui-components';

import { FormInput } from 'atoms';
import PasswordInput from 'atoms/PasswordInput';

import { IFormInput } from '../AddLocation/add-location.types';

interface IProps {}

const LoginDetails: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'auth', 'location']);

  const { control, getValues } = useFormContext<IFormInput>();

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='18px'
        color='#B1E5FC'
        title={t('location:login_details')}
      />

      <Flex gap='20px'>
        <Controller
          control={control}
          name='username'
          rules={{
            required: t('location:validation.name_required'),
            pattern: {
              value: /^[a-zA-Z_0-9]+$/,
              message: t('location:validation.name_invalid'),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <TitleHeader title={t('location:locationUserName')} />
                <FormInput
                  mt={2}
                  size='lg'
                  placeholder={t('location:placeholder.locUsername')}
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='password'
          rules={{
            required: t('common:validation.password_required'),
            minLength: {
              value: 6,
              message: t('common:validation.password_min_six'),
            },
            validate: (value) =>
              value?.trim().length !== 0 ||
              t('common:validation.password_invalid'),
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <TitleHeader title={t('auth:password')} />
                <Box height={2} />
                <PasswordInput
                  placeholder={t('common:placeholder.create_password')}
                  id='newPassword'
                  rightIcon
                  formProps={{ ...field }}
                  error={!!fieldState?.error}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='confirmPassword'
          rules={{
            required: t('common:validation.conf_pass_required'),
            minLength: {
              value: 6,
              message: t('common:validation.password_min_six'),
            },
            validate: (value) =>
              value === getValues('password') ||
              t('common:validation.password_not_match'),
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <TitleHeader title={t('common:placeholder.confirm_password')} />
                <Box height={2} />

                <PasswordInput
                  placeholder={t('common:placeholder.confirm_password')}
                  id='confirmPassword'
                  rightIcon
                  formProps={{ ...field }}
                  error={!!fieldState?.error}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>
    </Flex>
  );
};

export default LoginDetails;
