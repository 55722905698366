import React, { FC, useEffect, useRef, useState } from 'react';
import { ArrowBackIcon, CheckIcon, LockIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { useFormContext, useWatch, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMobile } from '@fortawesome/pro-regular-svg-icons';
import PrimaryButton from 'atoms/PrimaryButton';
import { IFormInput } from '../chaptor.types';
import { useLocation } from 'react-router-dom';
import { Authorize, AuthRole } from '../../../authorization';
import { ISaveChapterData } from '../hooks/types';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  query: string;
  chapterStatus: string | undefined;
  setQuery: any;
  chapterSaveData: ISaveChapterData;
  chapterSaveLoading: boolean;
  chapterUpdateLoading: boolean;
  initialLoad: boolean;
  isEditMode: boolean;
  showTrainingPathFlowModal: boolean;
  isAnyChangeMade: boolean;
  searchQuery: () => void;
  publishHandler: () => void;
  previewHandler: () => void;
  saveTemporaryHandler: () => void;
  setChapterSaveData: React.Dispatch<React.SetStateAction<ISaveChapterData>>;
}

interface LocationState {
  backToTitle?: string;
}

const Heading: FC<IProps> = ({
  query,
  chapterStatus,
  setQuery,
  chapterSaveData,
  chapterSaveLoading,
  chapterUpdateLoading,
  isEditMode,
  showTrainingPathFlowModal,
  initialLoad,
  isAnyChangeMade,
  searchQuery,
  publishHandler,
  previewHandler,
  saveTemporaryHandler,
  setChapterSaveData,
}) => {
  console.log({
    Heading: {
      isAnyChangeMade,
      chapterSaveData,
      chapterStatus,
    },
  });
  const locState = useLocation<LocationState>().state;
  const history = useHistory();
  const { updateVisibilityScope } = useCombinedStore();
  const { t } = useTranslation(['common']);
  const [showAIField, setShowAIField] = useState(false);
  const windowRef = useRef<any>();

  const { watch, getValues } = useFormContext<IFormInput>();

  const chapterHeading = useWatch<IFormInput>({
    name: 'title',
  });

  useEffect(() => {
    function listener(event: MessageEvent<any>) {
      const message = event?.data?.message;
      switch (message) {
        case 'chapterPreviewLoaded':
          event.source?.postMessage({
            message: 'initChapterPreview',
            data: getValues(),
          });
          break;
        default:
          break;
      }
    }

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (windowRef.current && !windowRef.current?.closed) {
        windowRef.current.postMessage(
          {
            message: 'updateChapterPreview',
            data: value,
          },
          '*'
        );
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const changeQueryHandler = (e: any) => {
    setQuery(e?.target?.value);
  };

  const clearQueryHandler = () => {
    setQuery('');
    setShowAIField(false);
  };

  const onPreviewClick = () => {
    // if (windowRef.current && !windowRef.current?.closed) {
    //   windowRef.current.postMessage(
    //     {
    //       message: 'initChapterPreview',
    //       data: getValues(),
    //     },
    //     '*'
    //   );
    //   windowRef.current?.focus();
    //   return;
    // }
    // window.name = Math.random().toString(36).slice(2);

    // const url = history.createHref({
    //   pathname: '/chapter-preview',
    //   search: `parent=${window.name}`,
    // });

    // windowRef.current = window.open(url, 'chapter-preview');

    // if (windowRef.current) {
    //   const initInt = setInterval(() => {
    //     if (windowRef.current?.closed) {
    //       clearInterval(initInt);
    //     }
    //     windowRef.current.postMessage(
    //       { message: 'isChapterPreviewReady' },
    //       '*'
    //     );
    //   }, 1000);

    //   window.addEventListener('message', (evt) => {
    //     if (evt.data.message === 'chapterPreviewIsReady') {
    //       clearInterval(initInt);
    //       windowRef.current.postMessage(
    //         {
    //           message: 'initChapterPreview',
    //           data: getValues(),
    //         },
    //         '*'
    //       );
    //     }
    //   });
    // }
    previewHandler();
  };

  const redirectHandler = () => {
    locState?.backToTitle ? history.goBack() : history.push('/folders');
  };

  const saveBtnStatusHandler = () => {
    if (
      (chapterSaveData?.savedOnce && !isAnyChangeMade) ||
      chapterSaveLoading ||
      chapterUpdateLoading ||
      (initialLoad && isEditMode && !isAnyChangeMade)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Flex alignItems='baseline' flexDir='row' justifyContent='space-between'>
        <Box display='flex' gap={4}>
          <Box alignItems='baseline'>
            <ArrowBackIcon
              fontSize='4xl'
              cursor='pointer'
              onClick={redirectHandler}
            />
          </Box>
          <Box>
            <Text fontSize='2xl' fontWeight='bold'>
              {chapterHeading || 'Untitled'}
            </Text>
            <Text
              cursor='pointer'
              fontWeight={400}
              fontSize={'15px'}
              color={'rgba(111, 118, 126, 1)'}
              onClick={redirectHandler}
            >
              {locState?.backToTitle || 'Back to Chapters'}
            </Text>
          </Box>
        </Box>
        <Flex gap={4}>
          <PrimaryButton
            variant='solid'
            title={t('common:preview')}
            disabled={!chapterHeading}
            leftIcon={
              <FontAwesomeIcon icon={faMobile as IconProp} color='#000000' />
            }
            style={{
              color: 'black',
              backgroundColor: 'white',
              paddingLeft: 24,
              paddingRight: 24,
            }}
            onClick={onPreviewClick}
          />
          {!showTrainingPathFlowModal &&
            (chapterStatus === 'draft' || !chapterStatus) && (
              <Tooltip
                label='This chapter will only be visible to you unless you publish'
                hasArrow
                placement='bottom-end'
              >
                <Box>
                  <PrimaryButton
                    title={saveBtnStatusHandler() ? 'Saved' : 'Save'}
                    leftIcon={
                      saveBtnStatusHandler() ? <CheckIcon /> : <LockIcon />
                    }
                    disabled={saveBtnStatusHandler()}
                    isLoading={chapterSaveLoading || chapterUpdateLoading}
                    colorScheme='blue'
                    style={{
                      backgroundColor: saveBtnStatusHandler()
                        ? '#c7c7c7'
                        : '#2a85ff',
                    }}
                    variant='solid'
                    onClick={saveTemporaryHandler}
                  />
                </Box>
              </Tooltip>
            )}
          <Authorize
            permittedRoles={[
              AuthRole.SUPER_ADMIN,
              AuthRole.ADMIN,
              AuthRole.MANAGER,
            ]}
            permittedFor='user'
          >
            <PrimaryButton
              variant='solid'
              title={
                isEditMode ? t('common:saveAndPublish') : t('common:publish')
              }
              disabled={!chapterHeading}
              // disabled={
              //   !chapterHeading ||
              //   (!isEditMode &&
              //     !chapterSaveData?.savedOnce &&
              //     !showTrainingPathFlowModal)
              // }
              style={{
                color: 'white',
                backgroundColor: 'rgba(131, 191, 110, 1)',
              }}
              onClick={() => {
                publishHandler();
                updateVisibilityScope('chapter');
              }}
            />
          </Authorize>
        </Flex>
      </Flex>
    </>
  );
};

export default Heading;
