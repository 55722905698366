import React, { FC, useRef } from 'react';
import { cloneDeep } from '@apollo/client/utilities';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AmplitudeEvent, deployEvent } from '../../../shared';

import { IFormInput } from './task.types';
import { dataTransformToSubmit } from './dataTransform';

import { addTaskQuery, AddTaskVariable } from './task.graphql';
import { TaskCreateScheme, validateTaskData } from './validation';
import TaskCreationBase, { TaskCreationRef } from './TaskCreationBase';

interface IProps {}

const TaskCreation: FC<IProps> = () => {
  const history = useHistory();
  const toast = useToast({
    position: 'top-right',
  });
  const { t } = useTranslation(['common', 'task']);

  const taskFormRef = useRef<TaskCreationRef>(null);

  const [submitTask] = useMutation<never, AddTaskVariable>(addTaskQuery, {
    onCompleted: () => {
      toast({
        title: t('common:success'),
        description: t('task:createSuccess'),
        status: 'success',
      });
      history.goBack();
    },
    onError: (error) => {
      // TODO populate error to form field
      console.log(error);
      toast({
        title: t('common:error'),
        description: t('common:something_wrong'),
        status: 'error',
      });
    },
  });

  const handleSubmit = async (values: IFormInput) => {
    try {
      await validateTaskData(
        TaskCreateScheme,
        cloneDeep(values),
        taskFormRef.current!
      );

      const dataToSubmit = dataTransformToSubmit(cloneDeep(values));

      const res = await submitTask({
        variables: {
          input: dataToSubmit,
        },
      });

      if (res.errors) {
        return Promise.reject('Task creation failed');
      }
      deployEvent(AmplitudeEvent.TASK_PUBLISHED);
    } catch (e) {
      console.log(e);
    }
  };

  return <TaskCreationBase ref={taskFormRef} handleSubmit={handleSubmit} />;
};

export default TaskCreation;
