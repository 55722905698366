import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';

import MainHeading from './MainSection';
import PhaseList from './Phase/List';
import StatusSwitch from './StatusSwitch';
import SubSection from './SubSection';
import confettiCelebrate from '../../../assets/lotties/confettieLottie.json';
import { IPhaseListItem } from './Phase/Singleton/PhaseListItem';

export interface IMakeLocLive {
  locationId: string;
  locationName: string;
  isLive?: boolean;
  phaseList: IPhaseListItem[];
  onSwitchClick?: () => void;
}

const MakeLocLive: FC<IMakeLocLive> = ({
  locationName,
  locationId,
  isLive,
  onSwitchClick,
  phaseList,
}) => {
  const history = useHistory();

  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: confettiCelebrate,
  };

  return (
    <Flex
      flexDir='column'
      align='center'
      gap={8}
      pos='relative'
      maxW='800px'
      mx='auto'
      userSelect='none'
    >
      {isLive && (
        <Lottie
          ariaRole='div'
          options={defaultUploadLottieOptions}
          isClickToPauseDisabled
          height='100%'
          width='100%'
          style={{ position: 'absolute', top: '-70px' }}
        />
      )}
      <MainHeading name={locationName} isLive={isLive} />
      <SubSection
        locationName={locationName}
        isLive={isLive}
        goToLocation={() =>
          history.push(`/locations/${locationId}`, {
            backToTitle: 'Back to Location Launcher',
          })
        }
      />
      <PhaseList list={phaseList} />
      <StatusSwitch isLive={isLive} onSwitchClick={onSwitchClick} />
    </Flex>
  );
};

export default MakeLocLive;
