import { ChakraProvider } from '@chakra-ui/react';
import React, { FC } from 'react';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import { FormProvider, useForm } from 'react-hook-form';
import VisibilityModal from 'pages/Handbook/setVisibilityModal';
import { IFolderVisibilityType, IModalStep } from '../FolderVisibility';

interface IProps {
  modalType: IFolderVisibilityType;
  onClose: () => void;
  updateClick: (step: IModalStep) => void;
}

const VisibilityOperation: FC<IProps> = ({
  modalType,
  onClose,
  updateClick,
}) => {
  const methods = useForm<IFormInput>({
    defaultValues: {
      title: 'Set visibility',
    },
  });
  const folderVisibilityUpdateHandler = () => {
    updateClick(3);
  };
  return (
    <FormProvider {...methods}>
      <ChakraProvider portalZIndex={3001}>
        <VisibilityModal
          isLoading={false}
          isOpen={true}
          handleClose={onClose}
          modalType='write'
          folderVisibilityUpdateHandler={folderVisibilityUpdateHandler}
        />
      </ChakraProvider>
    </FormProvider>
  );
};

export default VisibilityOperation;
