import React, { FC } from 'react';
import { InfoBaseComponent } from '../../Profile/TabComponents';
import ProfileActionButtons from './ActionButton';
import PendingUserInvite from './PendingUserInvite';

interface IProps {
  userId: string;
  onResendClick?: (...args: any[]) => any | PromiseLike<any>;
  onDataRefetch?: () => void;
}

const InvitedMemberContent: FC<IProps> = ({
  userId,
  onResendClick,
  onDataRefetch,
}) => {
  return (
    <PendingUserInvite userId={userId}>
      <ProfileActionButtons
        userId={userId}
        refetchData={onDataRefetch}
        onResendClick={onResendClick}
      />
    </PendingUserInvite>
    // <InfoBaseComponent userId={userId}>
    //   <ProfileActionButtons
    //     userId={userId}
    //     refetchData={onDataRefetch}
    //     onResendClick={onResendClick}
    //   />
    // </InfoBaseComponent>
  );
};

export default InvitedMemberContent;
