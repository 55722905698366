import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from 'atoms';

import { IFormInput } from '../../AddLocation/add-location.types';
import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';
import {
  LocationOwnerSelect,
  OwnerOption,
  useLocationOwnerList,
} from '../LocationOwner';
import InviteUserModal from '../../../../ui-components/InviteUserNew/InviteUserModal';
import { AuthRole } from '../../../../authorization';
import LocationOwnerListMenu from 'pages/LocationDetails/components/LocationAssetsCard/components/OwnersMembers/components/LocationOwnerListMenu';
import { CustomLocationOwnerSelect } from 'sub-components/CustomDropdowns';

interface IProps {
  isDisabled?: boolean;
}

const OwnerListField: FC<IProps> = ({ isDisabled }) => {
  const { t } = useTranslation(['common', 'location']);
  const { control, getValues, setValue } = useFormContext<IFormInput>();
  const [openInvite, setOpenInvite] = useState<number>();

  const { fields, append, remove } = useFieldArray<
    IFormInput,
    'locationOwners'
  >({
    name: 'locationOwners',
  });

  const isLocationEdit = useWatch<IFormInput, 'eid'>({
    name: 'eid',
  });

  const { locationOwners, refetch } = useLocationOwnerList();

  const members = useMemo(() => {
    return locationOwners.map((lo): OwnerOption => {
      return {
        ...lo,
        label: lo.name,
        value: lo.eid,
      };
    });
  }, [locationOwners]);

  const onInviteClick = (selectIndex: number) => {
    setOpenInvite(selectIndex);
  };

  const onActionCompleted = () => {
    console.log('onActionCompleted');
  };

  return (
    <Box>
      <TitleHeader title={t('common:owner')} />
      <Grid templateColumns='repeat(3, 1fr)' gap={4} pt={2}>
        {fields?.map((value, index, array) => {
          return (
            <Controller
              key={value.id}
              control={control}
              name={`locationOwners.${index}.owner`}
              // rules={{
              //   required: {
              //     value: !isDisabled,
              //     message: t('location:validation.locationOwnerRequired'),
              //   },
              // }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <Flex pos='relative' gap='4px'>
                      {/* <LocationOwnerSelect
                        isDisabled={isDisabled}
                        members={members}
                        placeholder={t('location:placeholder.owner')}
                        value={field.value as never}
                        onChange={field.onChange}
                        showInviteButton={!!isLocationEdit}
                        onInviteClick={() => onInviteClick?.(index)}
                      /> */}
                      <CustomLocationOwnerSelect
                        options={members}
                        isDisabled={isDisabled}
                        placeholder={t('location:placeholder.owner')}
                        value={field.value as never}
                        searchFieldPlaceholder={t(
                          'location:placeholder.ownerSelect'
                        )}
                        showSearchField
                        onChange={field.onChange}
                        showInviteButton={!!isLocationEdit}
                        onInviteClick={() => onInviteClick?.(index)}
                      />

                      {array.length > 1 && !isDisabled && (
                        <Center pos='absolute' right='0' height='48px' px='6px'>
                          <IconButton
                            alignSelf='center'
                            size='xs'
                            borderRadius='full'
                            aria-label='remove'
                            icon={<CloseIcon boxSize='10px' />}
                            onClick={() => remove(index)}
                          />
                        </Center>
                      )}
                    </Flex>
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          );
        })}

        {!isDisabled && (
          <PrimaryButton
            width='fit-content'
            size='lg'
            variant='outline'
            borderWidth='2px'
            title={t('location:addAnotherOwner')}
            onClick={() => append({ owner: '' as never })}
          />
        )}
      </Grid>
      {openInvite !== undefined && (
        <InviteUserModal
          isOpen={true}
          onClose={() => setOpenInvite(undefined)}
          onActionCompleted={onActionCompleted}
          shouldRefetch={async (newUser) => {
            setValue(`locationOwners.${openInvite}.owner`, {
              label: newUser.name,
              value: newUser.eid,
            });
            await refetch();
          }}
          inviteOnlyFor={AuthRole.LOCATION_OWNER}
          defaultValues={{
            location: [
              {
                id: isLocationEdit,
                value: getValues('locationName'),
                label: getValues('locationName'),
              },
            ],
          }}
        />
      )}
    </Box>
  );
};

export default OwnerListField;
