import React, { FC, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex } from '@chakra-ui/react';
import { Steps } from 'antd';

import { SectionAction, SectionTitleEditor, usePhaseDelete } from '../common';

import { EditorWrapper } from './editor-wrapper';
import EditorHeader from './EditorHeader';
import { IContentItem, IFormInput } from './editor.types';

import EditorSection from './section/EditorSection';
import { StepIconRender } from './section/StepIconRender';

import AddSectionTitle from './AddSectionTitle';
import { EditorEvent, useEditorContext } from './context';
import { EditorSectionLoader, EditorStepLoader } from './EditorLoading';
import { selected } from './helper';
import StepFocus from './StepFocus';

interface IProps {}

const LauncherEditorContainer: FC<IProps> = () => {
  const { emit, initialising } = useEditorContext();
  const phaseDelete = usePhaseDelete();

  const { control, getValues } = useFormContext<IFormInput>();

  const { fields, insert, move, update, remove } = useFieldArray<
    IFormInput,
    'contents'
  >({
    control: control,
    name: 'contents',
  });

  const activeStep = useWatch<IFormInput, 'activeStep'>({
    control: control,
    name: 'activeStep',
  });

  const omMoveClick = async (currentIndex: number, nextIndex: number) => {
    move(currentIndex, nextIndex);
    await emit(EditorEvent.MOVE_PHASE, { currentIndex, nextIndex });
  };

  const onDeleteClick = (index: number) => {
    phaseDelete({
      category: getValues(`contents.${index}.category`),
      onDeletePress: async () => {
        const res = await emit(EditorEvent.DELETE_PHASE, index);
        if (res) {
          remove(index);
        }
      },
    });
  };

  const onTitleUpdate = async (index: number, newTitle: string) => {
    await emit(EditorEvent.UPDATE_PHASE, {
      index: index,
      title: newTitle,
    });

    update(index, {
      ...getValues(`contents.${index}`),
      category: newTitle,
    });
  };

  const maxIndex = useMemo(() => {
    return fields?.filter(selected).length || 0;
  }, [fields]);

  return (
    <EditorWrapper>
      <Flex flexDir='column' gap={4} pb={4} className='launcher-editor-header'>
        <EditorHeader />

        <EditorStepLoader isLoaded={!initialising}>
          <StepFocus maxIndex={maxIndex} />
          <Steps labelPlacement='vertical' current={activeStep}>
            {fields.map((item, index) => {
              if (item.selected) {
                return (
                  <Steps.Step
                    status={
                      activeStep === index
                        ? 'process'
                        : item.updateStatus || 'wait'
                    }
                    key={item.id}
                    title={
                      <Flex gap={4} mb={3} align='center'>
                        <SectionTitleEditor
                          title={item.category}
                          index={index}
                          onUpdate={onTitleUpdate}
                        >
                          <Box
                            fontSize='16px'
                            fontWeight='600'
                            color='#1A1D1F'
                            isTruncated
                          >
                            {item.category}
                          </Box>
                        </SectionTitleEditor>
                        <SectionAction
                          index={index}
                          isLastSection={maxIndex === index + 1}
                          moveSectionTo={omMoveClick}
                          onDeleteClick={onDeleteClick}
                        />
                      </Flex>
                    }
                    stepIcon={StepIconRender}
                  />
                );
              }
              return null;
            })}

            <AddSectionTitle
              emptySection={!fields.length}
              onSubmit={async (value) => {
                const res = await emit<string, [IContentItem, number]>(
                  EditorEvent.ADD_PHASE,
                  value
                );
                if (res?.[0]) {
                  insert(res[1], res[0]);
                }
              }}
            />
          </Steps>
        </EditorStepLoader>
      </Flex>

      <EditorSectionLoader isLoaded={!initialising}>
        {!!fields.length && (
          <EditorSection
            key={`section-${activeStep}`}
            activeStep={activeStep}
            isLastSection={maxIndex === activeStep + 1}
          />
        )}
      </EditorSectionLoader>
    </EditorWrapper>
  );
};

export default LauncherEditorContainer;
