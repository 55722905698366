import React, { useState } from 'react';
import {
  Grid,
  GridItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Loader from 'ui-components/Loader';
import SearchInput from '../../atoms/SearchInput';
import './TrainingPathIconsModal.scss';

export interface IIconList {
  createdAt: string;
  backgroundColor: string;
  description: string;
  eid: string;
  label: string;
  tags: string[];
  type: string;
  updatedAt: string;
  url: string;
}

export interface ITrainingPathIconsModal {
  iconsList: IIconList[];
  isLoading: boolean;
  selectedIcon: (imgObj: IIconList) => void;
  children: React.ReactNode;
  searchedString: (search: string) => void;
}

const TrainingPathIconsModal = ({
  iconsList,
  isLoading,
  selectedIcon,
  children,
  searchedString,
}: ITrainingPathIconsModal) => {
  const { t } = useTranslation('training');
  const [searched, setSearched] = useState('');
  const selectImageHandler = (imgObj: IIconList, onClose: Function) => {
    selectedIcon(imgObj);
    searchedString('');
    setSearched('');
    onClose();
  };

  const searchIconHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearched(e?.target?.value);
    searchedString(e?.target?.value);
  };

  const getIconsList = (onClose: () => void) => {
    return (
      <>
        <div className='training-path-search-input'>
          <SearchInput
            size='lg'
            width='100%'
            hideShortcuts={true}
            placeholder={t('search_icon')}
            disabled={true}
            onChange={searchIconHandler}
            value={searched}
          />
        </div>
        {iconsList?.length > 0 && !isLoading && (
          <Grid templateColumns='repeat(5, 1fr)' justifyItems='center'>
            {iconsList?.map((list) => {
              return (
                <GridItem w='100%' h='auto' key={list.eid} overflowY='scroll'>
                  <img
                    src={list?.url}
                    style={{ padding: '1rem', cursor: 'pointer' }}
                    onClick={() => selectImageHandler(list, onClose)}
                  />
                </GridItem>
              );
            })}
          </Grid>
        )}
        {iconsList?.length === 0 && !isLoading && (
          <div style={{ padding: '1rem' }}>{t('no_icon_found')}</div>
        )}
      </>
    );
  };

  return (
    <>
      <Popover
        placement='bottom-start'
        arrowSize={10}
        closeOnBlur={true}
        direction='ltr'
        preventOverflow={true}
        offset={[-20, 45]}
      >
        {({ onClose }) => (
          <>
            <PopoverTrigger>{children}</PopoverTrigger>
            <PopoverContent borderRadius='10' width='600px' padding={0}>
              <PopoverArrow />
              <PopoverBody borderRadius='10' boxShadow='lg' padding={0}>
                {getIconsList(onClose)}
                {iconsList?.length === 0 && isLoading && <Loader />}
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </>
  );
};

export default TrainingPathIconsModal;
