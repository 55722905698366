import React, { forwardRef } from 'react';
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';

import { UserSignature } from '../../../../../types';
import SignatureValue from './SignatureValue';
import SignatureModalContent from './SignatureModalContent';

interface IProps {
  value?: UserSignature;
  onChange?: (sign?: UserSignature) => void;
  isPreview?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const SignatureInput = forwardRef<never, IProps>(
  ({ value, onChange, isPreview, isReadOnly }) => {
    if (value || isReadOnly) {
      return (
        <SignatureValue value={value!} onClick={() => onChange?.(undefined)} />
      );
    }

    return (
      <Popover
        isLazy
        lazyBehavior='unmount'
        placement='top-start'
        strategy='fixed'
        closeOnBlur={false}
      >
        {/*// @ts-ignore */}
        <PopoverTrigger>
          <Button fontSize='13px' fontWeight='500' size='sm'>
            Add Signature
          </Button>
        </PopoverTrigger>

        <PopoverContent borderRadius='12px' py={2} px={2}>
          <PopoverArrow />

          <SignatureModalContent
            isPreview={isPreview}
            defaultValue={value}
            onChange={onChange}
          />
        </PopoverContent>
      </Popover>
    );
  }
);

SignatureInput.displayName = 'SignatureInput';

export default SignatureInput;
