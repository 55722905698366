import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Center,
  Checkbox,
  CheckboxGroup,
  ColorProps,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserDataSelector } from 'hooks';
import { getImage, shallowEqual } from 'utils';

import { BoxHeader } from '../../../ui-components';

import { IFormInput } from '../AddLocation/add-location.types';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { AuthRole } from 'authorization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  CustomSelectComponent,
  SelectOption,
} from 'sub-components/CustomDropdowns';
import { cloneDeep } from '@apollo/client/utilities';
import styled from '@emotion/styled';

const CheckboxWrapper = styled.div<{ checkboxColor: any }>`
  .phaseCheckboxFlex {
    .chakra-checkbox {
      .chakra-checkbox__control {
        &[data-checked] {
          border-color: #2a85ff !important;
        }
        &[data-invalid] {
          border-color: ${(props) => props?.checkboxColor};
        }
      }
    }
  }
`;

interface ChecklistData {
  category: string;
  eid: string;
  isDisabled: boolean;
}

interface IProps {
  isDisabled?: boolean;
}

const CheckListDetails: FC<IProps> = ({ isDisabled }) => {
  const { t } = useTranslation(['location']);
  const entityData = useReactiveVar(usersEntityObj);

  const filteredUsers = useMemo((): SelectOption[] => {
    let _users = entityData?.filter(
      (en) =>
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(en?.authRole) &&
        en?.type === 'user'
    );
    return _users?.map((user) => {
      return {
        ...user,
        label: user?.name,
        value: user?.eid,
      };
    });
  }, [entityData]);

  const _launcherConfig = useUserDataSelector(
    (state) => state?.entity?.launcher?.contents,
    shallowEqual
  );

  const launcherData = useMemo(() => {
    return _launcherConfig?.map<ChecklistData>((con) => {
      return {
        category: con.category,
        eid: con.eid,
        isDisabled: !con?.tasks?.length,
      };
    });
  }, [_launcherConfig]);

  const { control, getValues, setValue, trigger } =
    useFormContext<IFormInput>();

  const checkListWatch = useWatch<IFormInput, 'checkList'>({
    control: control,
    name: 'checkList',
  });
  const checkListDataWatch = useWatch<IFormInput, 'checkListData'>({
    control: control,
    name: 'checkListData',
  });

  console.log({ checkListWatch });

  useEffect(() => {
    if (
      launcherData &&
      (!checkListWatch ||
        (Array.isArray(checkListWatch) && checkListWatch?.length === 0))
    ) {
      let enabledData = launcherData?.filter((data) => !data?.isDisabled);
      setValue('checkList', enabledData?.map((l) => l?.eid) || []);
      setValue(
        'checkListData',
        enabledData?.map((l) => {
          return {
            id: l?.eid,
            selected: true,
            assignedToUsers: [],
          };
        }) || []
      );
    }
  }, [launcherData]);

  const getCustomSelectField = useCallback(
    (checkListId: string) => {
      let foundCheckListData = checkListDataWatch?.filter(
        (watch) => watch?.id === checkListId
      )?.[0];
      if (foundCheckListData?.assignedToUsers?.length > 0) {
        let userIds = foundCheckListData?.assignedToUsers?.map(
          (user) => user?.eid
        );
        let foundUsers = filteredUsers?.filter((user) =>
          userIds?.some((val) => val === user?.eid)
        );
        const numberOfUsersDisplayed = 2;
        const displayedUsers = foundUsers?.slice(0, numberOfUsersDisplayed);
        const remainingUsers = foundUsers?.slice(numberOfUsersDisplayed);
        let remainingUsersNames = remainingUsers
          ?.map((user) => user?.label || 'Unknown')
          .join(', ');
        console.log('remaining users names : ', remainingUsersNames);
        console.log('displayed users : ', displayedUsers);
        console.log('remaining users : ', remainingUsers);
        return (
          <Flex align='center' gap='5px'>
            {displayedUsers?.slice(0, 2)?.map((user) => {
              return (
                <Box key={user?.eid}>
                  <Tooltip label={user?.label} hasArrow placement='top'>
                    <Image
                      src={getImage(user?.profilePic, user?.label)}
                      boxSize='40px'
                      borderRadius='50%'
                    />
                  </Tooltip>
                </Box>
              );
            })}
            {remainingUsers?.length > 0 && (
              <Tooltip label={remainingUsersNames} hasArrow placement='top'>
                <Center
                  boxSize='40px'
                  color='rgba(105, 105, 116, 1)'
                  borderRadius='50%'
                  border='1px solid rgba(226, 226, 234, 1)'
                >
                  + {remainingUsers?.length}
                </Center>
              </Tooltip>
            )}
          </Flex>
        );
      } else {
        return (
          <Flex>
            <Text fontWeight={600}>Assign to</Text>
            <ChevronDownIcon mt='4px' fontSize='20px' />
          </Flex>
        );
      }
    },
    [checkListDataWatch, filteredUsers]
  );

  const assignRender = (checkListId: string) => {
    return (
      <Flex h='40px' align='center' gap='5px' cursor='pointer'>
        <CustomSelectComponent
          options={filteredUsers}
          authRoleFilterOptions={[
            {
              label: 'Super Admins',
              value: AuthRole.SUPER_ADMIN,
            },
            {
              label: 'Admins',
              value: AuthRole.ADMIN,
            },
          ]}
          placeholder=''
          value={
            checkListDataWatch
              ?.filter((w) => w?.id === checkListId)?.[0]
              ?.assignedToUsers?.map((user) => user?.eid || '') || []
          }
          onChange={async (newValues) => {
            console.log({ newValues });
            let checkListData = checkListDataWatch;
            let checkIds = checkListData?.map((data) => data?.id);
            let _checkListData = cloneDeep(checkListDataWatch);
            _checkListData?.map((data) => {
              if (data?.id === checkListId) {
                data.assignedToUsers = filteredUsers?.filter((user) =>
                  newValues?.some((val) => val === user?.eid)
                );
              }
            });
            setValue('checkListData', _checkListData);
            await trigger('checkList');
          }}
          customWidth='500px'
          headerTitle='Select supervisor(s)'
          headerBarColor='rgba(202, 189, 255, 1)'
          showDivider
          showHeader
          showFilter
          showHeaderCloseIcon
          showSelectAll
          showSearchField
          showChips={false}
          bottomOutsideBtn={{
            show: true,
            title: 'Add selected supervisors',
          }}
          customSelectField={getCustomSelectField(checkListId)}
        />
      </Flex>
    );
  };

  const checkValidation = (checkList: ChecklistData) => {
    const checkListData = checkListDataWatch;
    let color = '#f4f4f4';
    if (checkList?.isDisabled) {
      color = '#f4f4f4';
    } else if (
      checkListData?.some(
        (watch) => watch?.id === checkList?.eid && watch?.selected
      )
    ) {
      color = '#f4f4f4';
    } else {
      checkListData?.map((watch) => {
        if (
          watch?.id === checkList?.eid &&
          watch?.assignedToUsers?.length === 0
        ) {
          color = 'red.200';
        }
      });
    }
    return color;
  };

  return (
    // @ts-ignore
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='16px'
        color='#CABDFF'
        title={t('location:checklistToComplete')}
      />

      <Controller
        control={control}
        name='checkList'
        rules={{
          // required: 'Checklist is required',
          validate: {
            isAssignedProperly: (checkListValues) => {
              const checkListData = getValues('checkListData');
              for (const data of checkListData) {
                // Check if the checkbox for this data item is unchecked
                if (!checkListValues.includes(data.id)) {
                  // If unchecked, ensure it has assigned users
                  if (
                    !data.assignedToUsers ||
                    data.assignedToUsers.length === 0
                  ) {
                    // Return an error message specific to this condition
                    return `Every unselected phase must have at least one supervisor.`;
                  }
                }
              }
              // If all checks pass, return true
              return true;
            },
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isDisabled={isDisabled} isInvalid={!!fieldState.error}>
              <CheckboxGroup value={field.value} onChange={field.onChange}>
                <Stack
                  spacing={[1, 5]}
                  direction='column'
                  divider={<Divider />}
                >
                  {launcherData?.map((checkList) => {
                    return (
                      <CheckboxWrapper
                        checkboxColor={checkValidation(checkList)}
                        key={checkList.eid}
                      >
                        <Flex
                          key={checkList.eid}
                          className='phaseCheckboxFlex'
                          align='center'
                          justify='space-between'
                        >
                          <Checkbox
                            spacing={4}
                            value={checkList.eid}
                            isDisabled={checkList.isDisabled}
                            onChange={(e) => {
                              let _checkListData =
                                cloneDeep(checkListDataWatch);
                              _checkListData?.map((data) => {
                                if (data?.id === checkList?.eid) {
                                  data.assignedToUsers = [];
                                  data.selected = e?.target?.checked;
                                }
                              });
                              setValue('checkListData', _checkListData);
                            }}
                          >
                            <Flex align='center' gap={2}>
                              <Box
                                fontSize='15px'
                                fontWeight='500'
                                color='#272B30'
                              >
                                {checkList.category}
                              </Box>
                              {/* <Box fontSize='12px' color='#6F767E' isTruncated>
                                {checkList.description}
                              </Box> */}
                              {!checkListWatch?.includes(checkList?.eid) &&
                                !checkList?.isDisabled && (
                                  <Box
                                    bg='#B1E5FC4D'
                                    borderRadius='5.6px'
                                    p='2px 10px'
                                  >
                                    <Text
                                      fontWeight={500}
                                      fontSize='12px'
                                      color='#2A85FF'
                                    >
                                      Visible to supervising team only
                                    </Text>
                                  </Box>
                                )}
                            </Flex>
                          </Checkbox>
                          {!checkList.isDisabled && (
                            <Flex>
                              {checkListWatch?.includes(checkList?.eid) ? (
                                <Tooltip
                                  label='Assigned to all Location owners'
                                  placement='bottom-start'
                                  hasArrow
                                >
                                  <Center
                                    bg='rgba(255, 216, 141, 1)'
                                    cursor='pointer'
                                    boxSize='40px'
                                    borderRadius='50%'
                                  >
                                    <FontAwesomeIcon
                                      icon={faLocationDot as IconProp}
                                      color='white'
                                    />
                                  </Center>
                                </Tooltip>
                              ) : (
                                assignRender(checkList?.eid)
                              )}
                            </Flex>
                          )}
                        </Flex>
                      </CheckboxWrapper>
                    );
                  })}
                </Stack>
              </CheckboxGroup>
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Flex>
  );
};

export default CheckListDetails;
