import React, { FC } from 'react';
import { Status } from 'rc-steps/lib/interface';
import { getCharFromNumber } from 'utils';

interface IProps {
  index: number;
  status: Status;
  title: React.ReactNode;
  description: React.ReactNode;
  node: React.ReactNode;
}
export const StepIconRender: FC<IProps> = (props) => {
  return (
    <div className='ant-steps-custom-icon'>
      {getCharFromNumber(props.index + 1)}
    </div>
  );
};

export const VerticalStepIconRender: FC<IProps> = (props) => {
  return <div className='ant-steps-custom-icon'>{props.index + 1}</div>;
};
