import { Box, Flex, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import moment from 'moment-timezone';
import IconText from 'pages/TasksDashboard/Components/sub-components/IconText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarCheck,
  faCalendarDay,
  faRepeat,
} from '@fortawesome/pro-light-svg-icons';
import { faClock, faClockFour } from '@fortawesome/pro-regular-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faArrowLeft,
} from '@fortawesome/pro-solid-svg-icons';
import AssigneeBadgeStack from 'pages/TasksDashboard/Components/sub-components/AssigneeBadgeStack';
import ImageText from 'pages/TasksDashboard/Components/sub-components/ImageText';
import { UserData } from 'sop-commons/src/client';
import { taskRepeat } from 'utils/taskRepeat';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';
import { getImage } from '../../../../../../../utils';
import { ISessionByIdForMyTasks } from 'pages/TasksDashboard/MyTasks/services/my-tasks-types';

const DetailsTableWrapper = styled.div`
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;
  }

  tr {
    height: 30px;
  }

  td {
    &:first-of-type {
      width: 30%;
    }

    &:last-of-type {
      width: 70%;
    }
  }
`;

const statusHandler = (
  item: ISessionByIdForMyTasks['SessionById'] | undefined,
  userData: UserData
) => {
  const startOfWeek = moment().startOf('week');
  const endOfWeek = moment().endOf('week');
  let itemDate = moment.utc(item?.dueDate);
  let userTimezone = userData?.timezone;
  let _now = moment()?.tz(userTimezone);
  let currentDate = moment.utc(
    _now?.format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );
  if (
    itemDate.isBefore(currentDate) &&
    item?.currentUserProgress?.completedTasks?.length !== item?.itemCount
  ) {
    return (
      <Flex
        color='#FF6A55'
        bg='#FF6A5533'
        p='5px 10px'
        fontSize='12px'
        fontWeight={600}
        borderRadius='6px'
        gap='5px'
        align='center'
        w='fit-content'
      >
        <FontAwesomeIcon icon={faClock as IconProp} />
        Overdue
      </Flex>
    );
  } else if (
    itemDate.isSame(currentDate, 'day') &&
    item?.currentUserProgress?.completedTasks?.length !== item?.itemCount
  ) {
    return (
      <Flex
        color='#CABDFF'
        bg='#cabdff4f'
        p='5px 10px'
        fontSize='12px'
        fontWeight={600}
        borderRadius='6px'
        gap='5px'
        align='center'
        w='fit-content'
      >
        <FontAwesomeIcon icon={faClock as IconProp} />
        Today
      </Flex>
    );
  } else if (
    itemDate.isAfter(currentDate) &&
    item?.currentUserProgress?.completedTasks?.length !== item?.itemCount
  ) {
    return (
      <Flex
        color='#8ccbe8'
        bg='#b1e5fc40'
        p='5px 10px'
        fontSize='12px'
        fontWeight={600}
        borderRadius='6px'
        gap='5px'
        align='center'
        w='fit-content'
      >
        <FontAwesomeIcon icon={faClock as IconProp} />
        Upcoming
      </Flex>
    );
  } else {
    return (
      <Box
        color='#83BF6E'
        bg='#B5E4CA66'
        borderRadius='6px'
        fontSize='12px'
        fontWeight={600}
        p='5px 10px'
        w='fit-content'
      >
        Completed
      </Box>
    );
  }
};

const getTimezoneAbbreviation = (date: moment.Moment, timezone: string) => {
  let abbr = moment.tz(date, timezone).format('z');
  console.log('ABBR : ', abbr);
  return abbr;
};

const showTimeText = (dueDate: moment.Moment, timezone: string) => {
  let formattedString = '';
  let formattedDueDate = moment?.utc(dueDate)?.format('lll');
  let abbrevatedTimezone = timezone
    ? getTimezoneAbbreviation(dueDate, timezone)
    : '';
  if (formattedDueDate && abbrevatedTimezone) {
    formattedString = `${formattedDueDate} (${abbrevatedTimezone})`;
  } else {
    formattedString = formattedDueDate;
  }
  return formattedString;
};

const tableDetailsPartOneRender = (
  data: ISessionByIdForMyTasks | undefined,
  userData: UserData
) => {
  console.log('Data : ', data);
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <Text as='b'>Status:</Text>
          </td>
          <td>
            <Box>{statusHandler(data?.SessionById, userData)}</Box>
          </td>
        </tr>
        <tr>
          <td>
            <Text as='b'>Due date: </Text>
          </td>
          <td>
            <IconText
              padding='0px'
              icon={<FontAwesomeIcon icon={faCalendarCheck as IconProp} />}
              text={showTimeText(
                data?.SessionById?.dueDate!,
                data?.SessionById?.task?.timezone!
              )}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Text as='b'>Repeat:</Text>
          </td>
          <td>
            <IconText
              padding='0px'
              icon={<FontAwesomeIcon icon={faRepeat as IconProp} />}
              // text={data?.TaskById?.repeatCycle}
              text={taskRepeat(
                data?.SessionById?.task?.repeatDetails as RepeatDetails<Date>
              )}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const userTimezoneConvertedDateTime = (
  date: string,
  timezone: string
) => {
  const _date = moment(moment.utc(date), 'DD-MMM-YYYY hh:mm a')?.tz(timezone);
  let formattedDate = moment.utc(
    _date?.format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );
  return formattedDate;
};

const tableDetailsPartTwoRender = (
  data: ISessionByIdForMyTasks | undefined,
  userData: UserData
) => {
  const _taskStartDate = moment(
    moment.utc(data?.SessionById?.startDate),
    'DD-MMM-YYYY hh:mm a'
  )?.tz(userData?.timezone);
  let taskStartDate = userTimezoneConvertedDateTime(
    data?.SessionById?.startDate!,
    userData?.timezone
  );
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <Text as='b'>Assignees:</Text>
          </td>
          <td>
            <AssigneeBadgeStack
              assignees={data?.SessionById?.assigneeUsers || []}
              // assignees={Array?.from(
              //   new Map(
              //     [
              //       ...(data?.SessionById?.task?.assignedToLocations || []),
              //       ...(data?.SessionById?.task?.assignedToUsers || []),
              //     ].map((item) => [item.eid, item])
              //   ).values()
              // )}
              status='active'
            />
          </td>
        </tr>
        <tr>
          <td>
            <Text as='b'>Published on: </Text>
          </td>
          <td>
            <IconText
              padding='0px'
              icon={<FontAwesomeIcon icon={faCalendarDay as IconProp} />}
              // text={moment?.utc(data?.TaskById?.startDate)?.format('LLL')}
              text={showTimeText(taskStartDate, userData?.timezone!)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <Text as='b'>Last updated: </Text>
          </td>
          <td>
            <IconText
              padding='0px'
              icon={<FontAwesomeIcon icon={faClockFour as IconProp} />}
              // text={
              //   data?.TaskById?.lastUpdated
              //     ? moment?.utc(data?.TaskById?.lastUpdated)?.format('LLL')
              //     : data?.TaskById?.createdAt
              //     ? moment?.utc(data?.TaskById?.createdAt)?.format('LLL')
              //     : '-'
              // }
              text={
                data?.SessionById?.task?.lastUpdated ||
                data?.SessionById?.createdAt
                  ? showTimeText(
                      data?.SessionById?.task?.lastUpdated
                        ? userTimezoneConvertedDateTime(
                            data?.SessionById?.task?.lastUpdated,
                            data?.SessionById?.task?.timezone
                          )
                        : userTimezoneConvertedDateTime(
                            data?.SessionById?.createdAt,
                            data?.SessionById?.task?.timezone
                          ),
                      data?.SessionById?.task?.timezone
                    )
                  : '-'
              }
            />
          </td>
        </tr>
        <tr>
          <td>
            <Text as='b'>Created by: </Text>
          </td>
          <td>
            <ImageText
              img={
                <Image
                  src={getImage(
                    data?.SessionById?.task?.createdByUser?.profilePic,
                    data?.SessionById?.task?.createdByUser?.name
                  )}
                  borderRadius='full'
                  mr='5px'
                  boxSize='20px'
                />
              }
              text={data?.SessionById?.task?.createdByUser?.name}
              isTruncated
              padding='5px 0px'
              maxW='100px'
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

interface IProps {
  data: ISessionByIdForMyTasks | undefined;
  userData: UserData;
}

const Details: FC<IProps> = ({ data, userData }) => {
  const [more, setMore] = useState(false);
  return (
    <Flex
      bg='white'
      w='40%'
      flexDir='column'
      p={6}
      borderRadius='8px'
      gap={4}
      h='max-content'
    >
      <GenericColorHeader color='#B1E5FC' title='Details' />
      <DetailsTableWrapper>
        {tableDetailsPartOneRender(data, userData)}
      </DetailsTableWrapper>
      <Flex
        onClick={() => setMore((prevValue) => !prevValue)}
        my='-10px'
        cursor='pointer'
        color='#2A85FF'
        justify='space-between'
        align='center'
      >
        <Text fontWeight={600}>View {more ? 'less' : 'more'}</Text>
        {more ? (
          <FontAwesomeIcon icon={faAngleUp as IconProp} />
        ) : (
          <FontAwesomeIcon icon={faAngleDown as IconProp} />
        )}
      </Flex>
      {more && (
        <DetailsTableWrapper>
          {tableDetailsPartTwoRender(data, userData)}
        </DetailsTableWrapper>
      )}
    </Flex>
  );
};

Details.displayName = 'MyTasks/C/MyTaskDetails/SC/Details';

export default Details;
