import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { MoreTrainingCell } from 'pages/Training/TrainingList/Component';
import {
  MemberTrainingList,
  MemberTrainingModal,
} from 'pages/Training/TrainingList/modal';
import {
  getAllTracks,
  TrackResponse,
} from 'pages/Training/TrainingList/training.graphql';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, SortingTable } from 'sub-components/ChakraTable/SortingTable';
import EmptyState from 'sub-components/EmptyState';
import UserTrainings from 'sub-components/TrackTrainingProgress/UserTrainings';
import WorkerPathName from 'sub-components/TrackTrainingProgress/WorkerPathName';
import { SortByName } from 'utils/sorting';
import TrackMembersEmpty from './TrackMembersEmpty';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  table {
    background-color: white;
    thead {
      background-color: #f4f4f4;
      height: 44px !important;
      tr {
        border-radius: 5px;
        th:first-child {
          padding: 0px 20px !important;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        th:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    tbody {
      tr {
        td:first-child {
          padding: 20px 0px 20px 20px !important;
        }
      }
    }
  }
`;

interface IProps {
  setShowViewAllBtn: React.Dispatch<React.SetStateAction<boolean>>;
}

const TrackMembers: FC<IProps> = ({ setShowViewAllBtn }) => {
  const { t, i18n } = useTranslation(['home', 'training', 'role']);
  const [selectedUser, setSelectedUser] = useState<
    MemberTrainingModal | undefined
  >(undefined);
  const { data, loading } = useQuery<TrackResponse>(getAllTracks, {
    fetchPolicy: 'network-only',
  });

  const sliceSize = 3;
  let slicedData = new MemberTrainingList(
    data?.trackWorkersTraining?.slice(0, sliceSize) || []
  )?.data;

  // const slicedData = useCallback(() => {
  //   if (
  //     data &&
  //     data?.trackWorkersTraining &&
  //     data?.trackWorkersTraining?.length > 0
  //   ) {
  //     let threeItems = data?.trackWorkersTraining?.slice(0, 3);
  //     return new MemberTrainingList(threeItems).data;
  //   }
  //   return [];
  // }, [data]);

  useEffect(() => {
    setShowViewAllBtn(slicedData.length > 0);
  }, [slicedData, setShowViewAllBtn]);

  const columns = useMemo((): Column<MemberTrainingModal>[] => {
    return [
      {
        Header: t('home:member'),
        accessor: 'name',
        width: '33.33%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <WorkerPathName
              pathName={value}
              thumbnail={row?.original.profilePic}
              index={row.index}
              address={row?.original.address}
              status='active'
              onClick={() => setSelectedUser(row?.original)}
            />
          );
        },
        sortType: SortByName,
      },
      {
        Header: t('role:job'),
        accessor: 'authRole',
        width: '33.33%',
        disableSortBy: true,
        Cell: ({ cell: { value, row } }) => {
          return (
            <Flex alignItems={'center'}>
              <Box
                mb='0px'
                fontSize='15px'
                fontWeight='600'
                textTransform='capitalize'
                pr={'20px'}
              >
                {value}
                <Box fontSize='13px' color='#2A85FF' pt={1} pr={'20px'}>
                  {row.original.role}
                </Box>
              </Box>
            </Flex>
          );
        },
      },
      {
        Header: t('home:assign_training'),
        accessor: 'assignedTrainings',
        width: '33.33%',
        Cell: ({ cell: { value } }) => {
          return (
            <MoreTrainingCell
              value={value}
              background='rgba(177, 229, 252, 0.4)'
            />
          );
        },
        disableSortBy: true,
      },
    ];
  }, [i18n.language]);
  const tableData = useMemo(() => {
    return new MemberTrainingList(data?.trackWorkersTraining).data;
  }, [data]);
  const filteredData = useMemo(() => {
    return tableData;
  }, [tableData]);
  return (
    <>
      <SortingTable
        colorScheme='blue'
        emptyData={{
          content: <TrackMembersEmpty />,
        }}
        page={1}
        columns={columns}
        data={slicedData}
        isLoading={loading}
        isResponsive
        isHidePagination={true}
      />
      {selectedUser?.eid ? (
        <UserTrainings
          user={selectedUser}
          onClose={() => setSelectedUser(undefined)}
        />
      ) : null}
    </>
  );
};

export default TrackMembers;
