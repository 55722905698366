import React, { FC, Fragment, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext, useFormState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../atoms';
import { AmplitudeEvent, deployEvent } from '../../../shared';
import { useConfirm } from '../../../ui-components';

import FormTemplate from '../FormTemplate';
import FormDataProvider from '../../../pages/forms/FormStore/FormDataProvider';
import { TemplateItem } from '../FormTemplate/form-template.graphql';
import { templateToForm } from '../dataConvert';
import PreviewHeader from '../component/PreviewHeader';
import { IFormInput } from './form.types';

interface IProps {}

const ExistingTemplate: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'form']);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const { control, reset } = useFormContext<IFormInput>();
  const confirm = useConfirm();

  const { isDirty } = useFormState({
    control,
  });

  const onTemplateSelect = (template: TemplateItem) => {
    const newValues = templateToForm(template);

    if (!isDirty) {
      deployEvent(AmplitudeEvent.FORMS_TEMPLATE_CHANGED);
      reset(newValues);
      return;
    }
    confirm({
      title: <PreviewHeader title={t('form:discard_form')} color='#cabdff' />,
      content: (
        <Flex>
          <Box w={8} />
          <Box>{t('form:discard_form_desc')}</Box>
        </Flex>
      ),
      okText: t('discard'),
      isCentered: true,
      contentProps: {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      onOK: () => {
        deployEvent(AmplitudeEvent.FORMS_TEMPLATE_CHANGED);
        return reset(newValues);
      },
    });
  };

  function _onTemplateSelect(item: TemplateItem) {
    onTemplateSelect(item);
    setOpenTemplateModal(false);
  }

  return (
    <Fragment>
      <FormDataProvider>
        <Button
          variant='outline'
          colorScheme='blue'
          leftIcon={
            <FontAwesomeIcon
              icon={faPlus as IconProp}
              style={{ fontSize: 16 }}
              color={'#2a85ff'}
            />
          }
          onClick={() => setOpenTemplateModal(true)}
          event={AmplitudeEvent.FORMS_CHANGE_TEMPLATE}
        >
          {t('form:existing_template')}
        </Button>

        {openTemplateModal && (
          <FormTemplate
            isOpen={openTemplateModal}
            onClose={() => setOpenTemplateModal(false)}
            onTemplateSelect={_onTemplateSelect}
          />
        )}
      </FormDataProvider>
    </Fragment>
  );
};

export default ExistingTemplate;
