import React, { CSSProperties, FC } from 'react';
import { UserAnalyticsOverviewType } from 'sub-components/AnalyticsBoard/AnalyticsBoard';
import { wrapTextWithSpan } from '../NumericStats';
import ParentWrapper from './ParentWrapper';
import { Text } from '@chakra-ui/react';
import { useUserData } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { shouldRender } from 'sub-components/AnalyticsBoard/helper';

const TrainingsFinishedSection: FC<{
  style?: CSSProperties;
  analyticsData: UserAnalyticsOverviewType['userAnalyticsOverview'] | undefined;
}> = ({ style, analyticsData }) => {
  const userData = useUserData();

  const renderText = () => {
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER]?.includes(
        userData?.authRole
      ) &&
      userData?.type === 'user'
    ) {
      return analyticsData?.trainings?.complete! > 0 ? (
        <>
          <Text fontWeight={700} color='#2A85FF'>
            {analyticsData?.trainings?.complete}
          </Text>
          {wrapTextWithSpan(
            `training ${
              analyticsData?.trainings?.complete === 1 ? 'session' : 'sessions'
            } have been completed by active members`
          )}
        </>
      ) : (
        <>
          {wrapTextWithSpan('Click here to')}
          <Text fontWeight={700} color='#2A85FF'>
            create
          </Text>
          <Text>trainings</Text>
        </>
      );
    } else {
      return (
        <>
          {wrapTextWithSpan('You have finished')}
          <Text fontWeight={700} color='#2A85FF'>
            {analyticsData?.trainings?.complete}
          </Text>
          {wrapTextWithSpan(
            `${
              analyticsData?.trainings?.complete === 1
                ? 'training'
                : 'trainings'
            } since you joined.`
          )}
        </>
      );
    }
  };

  const compRender = () => {
    let flag = shouldRender(analyticsData?.trainings?.complete || 0);
    if (flag) {
      return (
        <ParentWrapper
          title='Trainings finished'
          totalCount={analyticsData?.trainings?.complete! + ''}
          style={style}
          redirectPath={
            analyticsData?.trainings?.complete! > 0
              ? '/training'
              : '/create-path'
          }
          backToTitle='Back to Home'
        >
          {renderText()}
        </ParentWrapper>
      );
    } else {
      return null;
    }
  };

  return compRender();
};

export default TrainingsFinishedSection;
