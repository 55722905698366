import React, { FC } from 'react';

import {
  FieldArrayWithId,
  UseFieldArrayInsert,
  UseFieldArrayMove,
  UseFieldArrayUpdate,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import EditorTaskForm from './EditorTaskForm';
import EditorTaskItem from './EditorTaskItem';

import { IFormInput } from '../editor.types';
import { TaskVariable } from '../../common';
import { transformTask } from '../../common/helpers';
import { useCollapseWarning } from './useCollapseWarning';
import { ITaskInput } from './BaseTaskForm';

interface IProps {
  index: number;
  categoryId: string;
  data: FieldArrayWithId<IFormInput, `contents.${number}.tasks`, 'id'>;
  update: UseFieldArrayUpdate<IFormInput, `contents.${number}.tasks`>;
  updateTask: (index: number, values: TaskVariable) => Promise<void>;
  isLast: boolean;
  moveTask: UseFieldArrayMove;
  addNewTask: UseFieldArrayInsert<IFormInput, `contents.${number}.tasks`>;
  actionHandler?: (name: string, value: string) => void;
}

const EditorItemContainer: FC<IProps> = ({
  categoryId,
  index,
  data,
  update,
  updateTask,
  isLast,
  moveTask,
  addNewTask,
  actionHandler,
}) => {
  const { setValue } = useFormContext<IFormInput>();
  const taskEditable = useWatch<IFormInput, 'taskEditable'>({
    name: 'taskEditable',
  });

  const collapseWarning = useCollapseWarning();

  const onCollapse = (values: ITaskInput, valueChanged?: boolean) => {
    if (valueChanged) {
      collapseWarning({
        onOkPress: () => {
          setValue('taskEditable', undefined);
          if (data.isNew) {
            setValue('newTaskAddress', undefined);
          }
        },
      });
    } else {
      setValue('taskEditable', undefined);
      if (data.isNew) {
        setValue('newTaskAddress', undefined);
      }
    }
  };

  if (data.isNew || (taskEditable && taskEditable === data.tempEid)) {
    return (
      <EditorTaskForm
        isFirstTask={index === 0}
        isEdit={!!data.eid}
        initialValue={data}
        onSubmit={async (inputs) => {
          await updateTask(
            index,
            transformTask({ ...inputs, completed: true }, index)
          );

          if (data.isNew) {
            setValue('newTaskAddress', undefined);
          }
        }}
        onCollapse={onCollapse}
        actionHandler={actionHandler}
      />
    );
  }

  return (
    <EditorTaskItem
      index={index}
      data={data}
      isLast={isLast}
      moveTask={moveTask}
      addNewTask={addNewTask}
      actionHandler={actionHandler}
    />
  );
};

export default EditorItemContainer;
