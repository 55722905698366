import React, { FC } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import FileVisibility from 'pages/Chapters/CreateChapterModal/FileVisibility';
import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import { IFolderVisibilityType } from '../FolderVisibility';
import { PrimaryButton } from 'atoms';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useMutation } from '@apollo/client';
import {
  FolderVisibilityArgs,
  FolderVisibilityQuery,
  FolderVisibilityResponse,
} from 'sub-components/Sidebar/category/add-category.graphql';
import useCombinedStore from 'zustandStore/store';
import { getFoldersList } from 'shared/graphql/SharedGraphql';
import {
  UpdateChapterResponse,
  UpdateChapterVariable,
} from 'sub-components/ChapterEditor/ChapterEditorContainer/chapter-editor.graphql';
import { UPDATE_CHAPTER } from 'pages/Chapters/CreateChapterModal/create-chapter.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  categoryId: string;
  locationsList: any[];
  rolesList: any[];
  membersList: any[];
  modalType: IFolderVisibilityType;
  getChaptersList?: () => void;
  onClose: () => void;
  onGoBack: () => void;
}

const FinalRender: FC<IProps> = ({
  categoryId,
  modalType,
  getChaptersList,
  onClose,
  onGoBack,
}) => {
  const {
    hierarchyData,
    visibilityScope,
    visibilitySelection,
    updateVisibilitySelection,
  } = useCombinedStore();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });

  const onFetchFoldersListSuccess = () => {
    getChaptersList?.();
    onClose();
  };

  const { execute: fetchFoldersList, loading: gettingFoldersList } =
    getFoldersList(onFetchFoldersListSuccess);
  const [updateFolderVisibility, { loading: updatingFolderVisibility }] =
    useMutation<FolderVisibilityResponse, FolderVisibilityArgs>(
      FolderVisibilityQuery,
      {
        onCompleted: (data) => {
          toast({
            status: 'success',
            title: 'Folder visibility updated successfully',
          });
          fetchFoldersList();
        },
        onError: () => {
          toast({
            status: 'error',
            title: 'Folder visibility could not be updated',
          });
          onClose();
        },
      }
    );

  const [updateChapter, { loading: updatingChapter }] = useMutation(
    UPDATE_CHAPTER,
    {
      onCompleted: (response) => {
        toast({
          title: 'Chapter updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchFoldersList();
      },
      onError: (error) => {
        toast({
          title: 'Chapter could not be updated',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const updateVisibilityHandler = () => {
    const input: FolderVisibilityArgs = {
      input: {
        categoryId: categoryId,
        copyVisibility: true,
        ...{
          condition: visibilitySelection?.condition
            ? visibilitySelection?.condition
            : undefined,
        },
        locations: visibilitySelection?.locations || [],
        roles: visibilitySelection?.roles || [],
        users: visibilitySelection?.users || [],
        visibility: visibilitySelection?.visibility || 'public',
      },
    };
    console.log('INPUT : ', input);
    deployEvent(AmplitudeEventNames.UPDATE_FOLDER_SUBFOLDER_VISIBILITY);
    updateFolderVisibility({
      variables: input,
    });
  };

  const updateChapterVisibilityHandler = () => {
    deployEvent(AmplitudeEventNames.UPDATE_CHAPTER_VISIBILITY);
    updateChapter({
      variables: {
        input: {
          eid: hierarchyData?.chapter?.details?.eid,
          languageCode: hierarchyData?.chapter?.details?.language,
          visibility: visibilitySelection?.visibility || 'public',
          visibleTo: {
            condition: visibilitySelection?.condition || null,
            locations: visibilitySelection?.locations || [],
            roles: visibilitySelection?.roles || [],
            users: visibilitySelection?.users || [],
          },
        },
      },
    });
  };

  const getModalType = () => {
    switch (visibilityScope) {
      case 'folder':
        return 'folder';
      case 'subFolder':
        return 'subfolder';
      case 'chapter':
        return 'chapter';
      default:
        return '';
    }
  };

  const renderData = () => {
    switch (visibilityScope) {
      case 'folder':
      case 'subFolder':
        return (
          <Flex flexDir='column' gap={4}>
            <FileVisibility
              isEditMode
              hideChangeVisibilityBtn
              viewDirection='top-bottom'
              descTitle={`${
                modalType === 'folder' ? 'Folder' : 'Sub folder'
              } visibility:`}
              customElement={
                <Text>
                  {modalType === 'folder' ? 'Folder' : 'Sub folder'} visibility:
                </Text>
              }
              conditionOperatorColor='black'
              type={modalType}
            />
            {modalType === 'folder' && (
              <FileVisibility
                isEditMode
                hideChangeVisibilityBtn
                viewDirection='top-bottom'
                descTitle='Subfolders & chapters visibility:'
                customElement={<Text>Subfolders & chapters visibility:</Text>}
                bgColor='#B5E4CA99'
                customIcon={
                  <FontAwesomeIcon
                    icon={faCircleCheck as IconProp}
                    color='#83BF6E'
                    style={{ marginRight: '10px' }}
                  />
                }
                type={modalType}
                conditionOperatorColor='black'
              />
            )}
            <Flex justify='flex-end' gap={4} w='full'>
              <PrimaryButton
                title='Go back'
                variant='outline'
                style={{ width: '128px' }}
                onClick={onGoBack}
              />
              <PrimaryButton
                title='Confirm & update'
                variant='solid'
                colorScheme='green'
                style={{ width: '150px' }}
                disabled={updatingFolderVisibility || gettingFoldersList}
                isLoading={updatingFolderVisibility || gettingFoldersList}
                onClick={() => updateVisibilityHandler()}
              />
            </Flex>
          </Flex>
        );
      case 'chapter':
        return (
          <Flex flexDir='column' gap={4}>
            <FileVisibility
              isEditMode
              hideChangeVisibilityBtn
              viewDirection='top-bottom'
              customElement={<Text>Chapter visibility:</Text>}
              bgColor='#B5E4CA99'
              customIcon={
                <FontAwesomeIcon
                  icon={faCircleCheck as IconProp}
                  color='#83BF6E'
                  style={{ marginRight: '10px' }}
                />
              }
              type={modalType}
              conditionOperatorColor='black'
            />
            <Flex justify='flex-end' gap={4} w='full'>
              <PrimaryButton
                title='Go back'
                variant='outline'
                style={{ width: '128px' }}
                onClick={onGoBack}
              />
              <PrimaryButton
                title='Confirm & update'
                variant='solid'
                colorScheme='green'
                style={{ width: '150px' }}
                disabled={
                  updatingFolderVisibility ||
                  gettingFoldersList ||
                  updatingChapter
                }
                isLoading={
                  updatingFolderVisibility ||
                  gettingFoldersList ||
                  updatingChapter
                }
                onClick={() => updateChapterVisibilityHandler()}
              />
            </Flex>
          </Flex>
        );
      default:
        return null;
    }
  };

  return (
    <Modal isOpen onClose={onClose} closeOnEsc={false} size='2xl'>
      <ModalOverlay />
      <ModalContent p={4} borderRadius='16px'>
        <ModalHeader>
          <Flex align='center' justify='space-between'>
            <GenericColorHeader
              color={HeaderColors.Purple}
              title={`Update ${getModalType()} visibility?`}
            />
            <ModalCloseButton m={5} />
          </Flex>
        </ModalHeader>
        <ModalBody>{renderData()}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FinalRender;
