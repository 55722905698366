import React, { forwardRef, useMemo } from 'react';
import {
  Flex,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from '../../authorization';
import { toArray } from '../../utils/utils';

import { loginUserBranchObj } from '../../sub-components/Header';
import { UserBranch } from './InviteLocationSelect';

const PermissionLevelInput = forwardRef<
  never,
  Omit<RadioGroupProps, 'children'>
>((props) => {
  const { t } = useTranslation(['common', 'invite', 'role']);

  const branchList = useReactiveVar<UserBranch[]>(loginUserBranchObj as never);

  const isDisabled = useMemo(() => {
    return !toArray(branchList).filter((it) => !it.isRoot).length;
  }, [branchList]);

  return (
    <RadioGroup {...props}>
      <VStack alignItems='flex-start'>
        <Authorize permittedFor='user' permittedRoles={[AuthRole.SUPER_ADMIN]}>
          <Radio gap={1} value={AuthRole.SUPER_ADMIN}>
            <Flex flexDirection='column'>
              <Text fontWeight='500'>{t('invite:authLevel.super_admin')}</Text>
              <Text as='span' fontSize='14px' color='#6F767E'>
                {t('invite:inviteSuperAdminDesc')}
              </Text>
            </Flex>
          </Radio>
        </Authorize>

        <Authorize
          permittedFor='user'
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          <Radio gap={1} value={AuthRole.ADMIN}>
            <Flex flexDirection='column'>
              <Text fontWeight='500'>{t('invite:authLevel.admin')}</Text>
              <Text as='span' fontSize='14px' color='#6F767E'>
                {t('invite:inviteAdminDesc')}
              </Text>
            </Flex>
          </Radio>
        </Authorize>

        <Radio isDisabled={isDisabled} gap={1} value={AuthRole.LOCATION_OWNER}>
          <Flex flexDirection='column'>
            <Text fontWeight='500'>{t('invite:authLevel.locationOwner')}</Text>
            <Text as='span' fontSize='14px' color='#6F767E'>
              {t('invite:inviteLocationOwnerDesc')}
            </Text>
          </Flex>
        </Radio>

        <Radio isDisabled={isDisabled} gap={1} value={AuthRole.WORKER}>
          <Flex flexDirection='column'>
            <Text fontWeight='500'>{t('invite:authLevel.worker')}</Text>
            <Text as='span' fontSize='14px' color='#6F767E'>
              {t('invite:inviteWorkerDesc')}
            </Text>
          </Flex>
        </Radio>
      </VStack>
    </RadioGroup>
  );
});

PermissionLevelInput.displayName = 'Permission Level Input';
export default PermissionLevelInput;
