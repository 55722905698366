import { useLazyQuery, useReactiveVar } from '@apollo/client';
import React, { FC, useCallback, useContext, useState } from 'react';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { CustomFilterAccordionWrapper } from './CustomFilterAccordion.styles';
import { ADMIN, MANAGER, SUPER_ADMIN, WORKER } from 'utils/userRoles';
import SetSeeDataContext from '../SetSeeModalStore/set-see-data-context';
import FilterFunnelIcon from '../../../../../assets/images/filterFunnelIcon.svg';
import addIcon from '../../../../../assets/images/add.png';
import { Collapse } from 'antd';
import { Box, Container, Flex, Text, Tooltip } from '@chakra-ui/react';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import MultiCustomDropdown from './MultiCustomDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import GenerateConditionsText from '../GenerateConditionsText';
import AddRole from 'sub-components/SettingDataCenter/RolesList/AddRole';
import { GET_USER } from '../../../../Login/login.graphql';
import { useTranslation } from 'react-i18next';
import { AuthRole } from 'sop-commons/src/client';
import { IConditionType, IType } from '../set-see-modal-types';
import CollapseComponent from './CollapseComponent';
import useCombinedStore from 'zustandStore/store';

const CustomFilterAccordionNew: FC = () => {
  const { t } = useTranslation('deck');
  const {
    updateVisibilitySelection,
    visibilitySelection,
    updateCustomSectionExpand,
  } = useCombinedStore();
  const userObject = useReactiveVar(userObj);
  const setSeeCtx = useContext(SetSeeDataContext);

  const [newRole, setNewRole] = useState(false);
  const [newRoleName, setNewRoleName] = useState('');

  const [getUser, { loading }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _data = JSON.parse(JSON.stringify(data));
      userObj(_data?.user || {});
      let foundData: any = null;
      _data?.user?.entity?.roles?.map((role: any) => {
        if (role?.name === newRoleName) {
          foundData = role;
          foundData.checked = true;
        }
      });
      if (foundData) {
        // let allData = [...setSeeCtx?.selectedRolesList, foundData];
        let allData = [...setSeeCtx?.selectedRolesList, foundData];
        setSeeCtx?.updateSelectedRolesList(allData);
        setSeeCtx?.updateRolesList([...setSeeCtx?.rolesList, foundData]);
        updateVisibilitySelection({
          visibility:
            visibilitySelection?.visibility === 'public' ? 'public' : 'private',
          roles: allData?.length > 0 ? allData?.map((role) => role?.name) : [],
          users:
            visibilitySelection && visibilitySelection?.users?.length > 0
              ? visibilitySelection?.users
              : [],
          locations:
            visibilitySelection && visibilitySelection?.locations?.length > 0
              ? visibilitySelection?.locations
              : [],
          condition: (setSeeCtx?.showCondition &&
          visibilitySelection &&
          visibilitySelection?.locations?.length > 0 &&
          visibilitySelection?.roles?.length > 0
            ? setSeeCtx?.conditionType?.toLowerCase()
            : null) as IConditionType,
        });
        // setCreateVisibility &&
        //   setCreateVisibility({
        //     visibility:
        //       setSeeCtx?.selectionType === 'public' ? 'public' : 'private',
        //     roles:
        //       allData?.length > 0 ? allData?.map((role) => role?.name) : [],
        //     users:
        //       setSeeCtx?.selectedMembersList?.length > 0
        //         ? setSeeCtx?.selectedMembersList?.map((user) => user?.eid)
        //         : [],
        //     locations:
        //       setSeeCtx?.selectedLocationsList?.length > 0
        //         ? setSeeCtx?.selectedLocationsList?.map(
        //             (location) => location?.eid
        //           )
        //         : [],
        //     condition: (setSeeCtx?.showCondition &&
        //     setSeeCtx?.selectedLocationsList?.length > 0 &&
        //     setSeeCtx?.selectedRolesList?.length > 0
        //       ? setSeeCtx?.conditionType?.toLowerCase()
        //       : null) as IConditionType,
        //   });
      }
    },
    onError: (error) => {
      setNewRoleName('');
    },
  });

  const render = () => {
    if (setSeeCtx?.modalType === 'write') {
      return <CollapseComponent setNewRole={setNewRole} />;
    }
    if (
      setSeeCtx?.selectionType === 'public' ||
      userObject?.authRole === WORKER ||
      userObject?.authRole === MANAGER ||
      setSeeCtx?.modalType === 'read'
    ) {
      return (
        <GenerateConditionsText
          backgroundColor='rgba(177, 229, 252, 0.4)'
          marginTop='2rem'
        />
      );
    }
  };

  const addRoleHandler = (value: string) => {
    setNewRoleName(value);
  };

  return (
    <CustomFilterAccordionWrapper>
      <div className='custom-filter-accordion-container'>{render()}</div>
      <AddRole
        open={newRole}
        type='add'
        onClose={() => setNewRole(false)}
        zIndex={2002}
        onRoleCreate={(value: string) => {
          let createdRole = {
            color: '#3B82DC',
            createdAt: '2022-06-14T09:26:47.176Z',
            createdBy: 'woml5wq6659n05aajqm4j6j8',
            eid: '',
            label: value,
            name: value,
            status: 'valid',
            updatedAt: '',
            value: value,
            checked: true,
          };
          addRoleHandler(value);
          // setNewRoleName(value);
          // setSelectedRoles([...selectedRoles, createdRole]);
          // setRolesData([...rolesData, createdRole]);
          // getUser({ variables: {} });
        }}
      />
    </CustomFilterAccordionWrapper>
  );
};

export default CustomFilterAccordionNew;
