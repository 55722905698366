import { Pagination } from '../../sub-components/ChakraTable/Pagination';
import { usePagination } from '../../sub-components/ChakraTable/usePagination';

interface IProps<T> {
  page: number;
  data: T[];
  totalRegisters: number;
  onPageChange: (page: number) => void;
  registersPerPage?: number;
}

function ListPagination<I = any>({
  page,
  data,
  totalRegisters,
  onPageChange,
  registersPerPage = 20,
}: IProps<I>) {
  const pagination = usePagination({
    totalRegisters,
    page,
    items: data,
    registersPerPage,
  });

  return (
    <Pagination
      {...pagination}
      colorScheme='blue'
      onPageChange={onPageChange}
    />
  );
}

export default ListPagination;
