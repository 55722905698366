import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Authorize, AuthRole } from 'authorization';
import PrimaryButton from 'atoms/PrimaryButton';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

import DashboardContainer from '../../../sub-components/DashboardContainer';
import TrainingContainer from './TrainingContainer';

const TrainingListHeader = () => {
  const { t } = useTranslation(['common', 'training']);
  const history = useHistory();

  return (
    <Flex align='center' mb='20px'>
      <Box fontSize='28px' fontWeight='700' flex='1'>
        {t('common:training')}
      </Box>
      <Flex>
        <Authorize
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
          permittedFor={'user'}
        >
          <PrimaryButton
            leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
            type='button'
            size='md'
            title={t('training:add_new_path')!}
            colorScheme='blue'
            variant='outline'
            onClick={() => {
              deployEvent(
                AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_CLICK
              );
              history.push('/create-path');
            }}
          />
        </Authorize>
      </Flex>
    </Flex>
  );
};

interface IProps {}

const TrainingList: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <TrainingListHeader />
      <TrainingContainer />
    </DashboardContainer>
  );
};

export default TrainingList;
