import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'atoms';
import Dropdown from 'atoms/Dropdown';
import { BoxHeader } from 'ui-components';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';

import { IFormInput } from '../../AddLocation/add-location.types';
import { LocationStatusOption } from '../../AddLocation/static-data';
import { UploadDocumentInput } from '../UploadDocument';

interface IProps {
  partialDisabled?: boolean;
}

const BasicDetails: FC<IProps> = ({ partialDisabled }) => {
  const { t } = useTranslation(['common', 'location']);
  const { control } = useFormContext<IFormInput>();

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='16px'
        color='#CABDFF'
        title={t('location:basicDetails')}
      />

      <Flex flexDir='column' gap='20px'>
        <Controller
          control={control}
          name='locationType'
          rules={{
            required: t('location:validation.locationTypeRequired'),
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={partialDisabled}
                isInvalid={!!fieldState.error}
              >
                <TitleHeader title={t('location:locationType')} isRequired />
                <RadioGroup
                  mt={2}
                  value={field.value}
                  onChange={field.onChange}
                >
                  <Stack
                    direction='row'
                    spacing={6}
                    sx={{
                      '.chakra-radio__label': {
                        color: '#6F767E',
                        fontSize: 14,
                        fontWeight: 500,
                        _checked: {
                          color: '#33383F',
                        },
                      },
                      '.chakra-radio__control': {
                        _checked: {
                          borderColor: 'blue.500',
                          background: 'white',
                          color: 'blue.500',
                          _hover: {
                            background: 'white',
                          },
                        },
                      },
                    }}
                  >
                    <Radio value='franchise'>{t('location:franchise')}</Radio>
                    <Radio value='corporate'>{t('location:corporate')}</Radio>
                  </Stack>
                </RadioGroup>
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Flex gap='20px'>
          <Box flex={4}>
            <TitleHeader title={t('location:lifecycleStage')} isRequired />
            <Controller
              control={control}
              name='locationStatus'
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={2} isInvalid={!!fieldState.error}>
                    <Dropdown
                      // isDisabled={partialDisabled}
                      isDisabled
                      size='lg'
                      placeholder={t('location:placeholder.locationStatus')}
                      options={LocationStatusOption}
                      {...field}
                      selectStyles={{
                        container: {
                          background: 'transparent',
                        },
                      }}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>

          <Box flex={9}>
            <TitleHeader title={'Upload current license document'} />

            <Controller
              control={control}
              name='licenceDocument'
              // rules={{
              //   required: {
              //     value: true,
              //     message: `Licence document is required`,
              //   },
              // }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    // isDisabled={isDisabled}
                    mt={2}
                    isInvalid={!!fieldState.error}
                  >
                    <UploadDocumentInput
                      // isDisabled={context.isDisabled}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
          <Box flex={6} />
        </Flex>

        <Controller
          control={control}
          name='locationName'
          rules={{
            required: t('location:validation.locationNameRequired'),
            validate: (value) =>
              value.trim().length !== 0 || t('common:validation.name_invalid'),
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={partialDisabled}
                isInvalid={!!fieldState.error}
              >
                <TitleHeader title={t('location:locationName')} isRequired />
                <FormInput
                  mt={2}
                  size='lg'
                  variant='outline'
                  placeholder={t('location:placeholder.name')}
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>
    </Flex>
  );
};

export default BasicDetails;
