import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import DashboardContainer from 'sub-components/DashboardContainer';
import {
  CertificateComponent,
  InfoComponent,
  MyActivityComponent,
  TrainingComponent,
} from './TabComponents';
import { ProfileWrapper } from './profile.styles';
import ProfileTabs from './ProfileTabs';
import { userObj } from 'sop-commons/src/client';
import { AuthRole } from 'authorization';
import { LocationListContainer } from 'pages/Teams/locations';

const Profile: FC = () => {
  const { t, i18n } = useTranslation(['common', 'profile']);

  const userData = useReactiveVar(userObj);
  const authId = userData?.eid;
  const authRole = userData?.authRole;

  const userId = userData?.eid;

  const tabData = useMemo(() => {
    let defaultObj = [
      {
        label: t('profile:info'),
        content: <InfoComponent userId={userId} disableLocation />,
      },
      // {
      //   label: t('common:trainings'),
      //   content: (
      //     <TrainingComponent userId={userId} username={userData?.name} />
      //   ),
      // },
      {
        label: t('profile:certificates'),
        content: <CertificateComponent userId={userId} />,
      },
    ];

    if (authRole === AuthRole.LOCATION_OWNER) {
      defaultObj = [
        ...defaultObj,
        {
          label: t('profile:locations'),
          content: (
            <LocationListContainer
              shouldHideHeader
              shouldHideEdit
              locationOwnerShare
              hiddenColumns={['status']}
            />
          ),
        },
      ];
    }

    let lastElementObj = {
      label: t('profile:myActivity'),
      content: <MyActivityComponent userId={userId} />,
    };

    return [...defaultObj, lastElementObj];
  }, [t, i18n.language, userId, userData?.name]);

  return (
    <DashboardContainer>
      <Flex align='center' mb='20px'>
        <Box fontSize='28px' fontWeight='700' color='#272B30'>
          {t('common:profile')}
        </Box>
      </Flex>
      <ProfileWrapper>
        <ProfileTabs data={tabData} />
      </ProfileWrapper>
    </DashboardContainer>
  );
};

export default Profile;
