import React, { FC, Fragment } from 'react';
import {
  Box,
  Flex,
  Image,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TemplateItem } from './form-template.graphql';
import backIcon from '../../../assets/images/back.svg';
import PreviewHeader from '../component/PreviewHeader';
import { IFormInput } from '../create-form/form.types';
import { QuestionList } from '../create-form/question';
import FormDetails from '../create-form/FormDetails';
import PrimaryButton from '../../../atoms/PrimaryButton';

import TrainingCard from '../../TrainingCard';

interface IProps {
  selectedTemplate?: TemplateItem;
  onBackPress: () => void;
  onTemplateUse: (template: TemplateItem) => void;
}

const TemplatePreviewInternal: FC<Required<IProps>> = ({
  selectedTemplate,
  onBackPress,
  onTemplateUse,
}) => {
  const { t } = useTranslation('form');
  const methods = useForm<IFormInput>({
    defaultValues: selectedTemplate,
  });

  return (
    <Fragment>
      <ModalHeader>
        <Flex align='center' gap={4}>
          <Image
            height='15px'
            width='24px'
            src={backIcon}
            cursor='pointer'
            onClick={() => onBackPress?.()}
          />
          <Box fontSize='20px' fontWeight={600} lineHeight='32px'>
            {selectedTemplate.title}
          </Box>
        </Flex>
      </ModalHeader>
      <ModalCloseButton m={'10'} />
      <ModalBody marginTop={'4'} h='100%' overflowY='auto'>
        <Flex className='form-container' gap={16}>
          <FormProvider {...methods}>
            <Box flex='1'>
              <FormDetails isReadOnly />
              <QuestionList isReadOnly hideAddQuestion />
            </Box>
            <Flex direction='column' pl={4}>
              <PreviewHeader title={t('mobile_view')} />
              <Box
                width='300px'
                mt='30px'
                boxShadow='0px 4px 20px rgba(0, 0, 0, 0.1)'
                borderRadius='16px'
              >
                <TrainingCard
                  content={{
                    cardType: 'formCard',
                    content: [{ ...selectedTemplate }],
                  }}
                  isPreview
                />
              </Box>
            </Flex>
          </FormProvider>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Box position='absolute' bottom='0' left='0' right='0'>
          <PrimaryButton
            variant='solid'
            colorScheme='blue'
            title={t('use_template')}
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            onClick={() => onTemplateUse?.(selectedTemplate)}
          />
        </Box>
      </ModalFooter>
    </Fragment>
  );
};

const TemplatePreview: FC<IProps> = ({ selectedTemplate, ...rest }) => {
  if (!selectedTemplate?.eid) {
    return null;
  }
  return (
    <TemplatePreviewInternal selectedTemplate={selectedTemplate} {...rest} />
  );
};

export default TemplatePreview;
