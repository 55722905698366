import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box } from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';

import { SelectOption } from 'atoms/Dropdown';
import { toArray } from 'utils/utils';
import { useUserData } from 'hooks';

import MemberList from './MemberList';
import {
  MEMBER_LIST_QUERY,
  MemberListResponse,
  MemberListVariable,
  MemberSort,
} from './member.graphql';
import { TeamItem } from './team.types';
import SingleUserChatModal from '../../../ui-components/SingleUserChatModal';
import { SearchFilterHeader } from './component';
import eventBus from '../locations/AddLocationModal/eventBus';
import InviteUserModal from 'ui-components/InviteUserNew/InviteUserModal';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { ReInviteUserModal } from '../../../ui-components/InviteUserNew';
import { useUpdateInvitedMember } from '../InvitedMember';
import { InvitedUser } from '../InvitedMember/update-invite.graphql';
import { AuthRole } from '../../../authorization';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {}

const MemberListContainer: FC<IProps> = () => {
  const history = useHistory();
  const authUser = useUserData();
  const { t } = useTranslation(['common']);

  const [filterBy, setFilterBy] = useState<SelectOption | null>();
  const [authRoleFilterBy, setAuthRoleFilterBy] = useState<SelectOption | null>(
    () => {
      return {
        label: t('common:all'),
        value: 'all',
      };
    }
  );
  const [sortBy, setSortBy] = useState<MemberSort>();
  const [searchQuery, setSearchQuery] = useState('');
  // const [selectedPage, setSelectedPage] = useState(1);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const dashboardCtx = useContext(DashboardDataContext);
  const selectedPage =
    dashboardCtx?.navigationPersistData?.members?.selectedPage;

  const [chatUserId, setChatUserId] = useState<string>();
  const [reInviteUser, setReInviteUser] = useState<InvitedUser>();

  const updateInvitedMember = useUpdateInvitedMember();

  useEffect(() => {
    eventBus.on('openAddMemberModal', locationModalHandler);
    return () => {
      eventBus.removeListener('openAddMemberModal', locationModalHandler);
    };
  }, []);

  const authLocations = useMemo(() => {
    return toArray(authUser.locations).map((it) => it.eid);
  }, [authUser.locations]);

  const locationModalHandler = () => {
    setShowInviteModal(true);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onPageChangeHandler = (page: number) => {
    // setSelectedPage(page);
    let navigationData = dashboardCtx?.navigationPersistData;
    let membersData = dashboardCtx?.navigationPersistData?.members;
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationData,
      members: { ...membersData, selectedPage: page },
    });
  };

  const [fetchMemberList, { loading, data, refetch }] = useLazyQuery<
    MemberListResponse,
    MemberListVariable
  >(MEMBER_LIST_QUERY, {
    fetchPolicy: 'network-only',
  });

  const getVariables = useCallback((): MemberListVariable => {
    return {
      page: selectedPage,
      type: 'user',
      query: searchQuery,
      authRoles:
        authRoleFilterBy?.value === 'all'
          ? []
          : [authRoleFilterBy?.value as AuthRole],
      sort:
        sortBy ||
        match<string, MemberListVariable['sort']>(filterBy?.value as string)
          .with('Alphabetically', () => 'NAME_ASC')
          .with('Last Created', () => '_ID_DESC')
          .otherwise(() => '_ID_DESC'),
    };
  }, [searchQuery, selectedPage, filterBy, sortBy, authRoleFilterBy]);

  useEffect(() => {
    fetchMemberList({
      variables: getVariables(),
    });
  }, [getVariables]);

  const clickedHandler = async (clickedItem: string, member: TeamItem) => {
    switch (clickedItem) {
      case 'message':
        deployEvent(AmplitudeEventNames.MEMBER_CHAT_BUTTON);
        return setChatUserId(member.eid);
      case 'reInvite':
        deployEvent(AmplitudeEventNames.MEMBER_REINVITE_BUTTON);
        return updateInvitedMember({
          data: member,
          onResendClick: setReInviteUser,
          onDataRefetch: () => {
            fetchMemberList({
              variables: getVariables(),
            });
          },
        });
      case 'open':
        deployEvent(AmplitudeEventNames.MEMBER_OPEN);
        return history.push(`/teams/members/${member.eid}`, {
          name: member.name,
        });
      case 'edit':
        deployEvent(AmplitudeEventNames.MEMBER_EDIT);
        return history.push(`/teams/members/${member.eid}`, {
          name: member.name,
        });
      default:
        console.log(clickedItem);
    }
  };

  const items = data?.teams?.items || [];

  const pageInfo = data?.teams;

  const dataList = useMemo(() => {
    return toArray(items);
  }, [items]);

  const onActionCompleted = () => {
    setTimeout(() => {
      refetch && refetch(getVariables());
    }, 4000);
  };

  const shouldRefetch = () => {
    setTimeout(() => {
      refetch && refetch(getVariables());
    }, 4000);
  };

  return (
    <Box flex={1}>
      <SearchFilterHeader
        sortBy={filterBy}
        onSortChange={(value) => {
          setSortBy(undefined);
          setFilterBy(value);
        }}
        filterBy={authRoleFilterBy}
        onFilterChange={setAuthRoleFilterBy}
        searchQuery={searchQuery}
        onSearch={onSearchChange}
      />
      <MemberList
        dataList={dataList}
        loading={loading}
        currentPage={selectedPage}
        itemCount={pageInfo?.count}
        isSearchQuery={!!searchQuery}
        onPageChange={onPageChangeHandler}
        onClickedHandler={clickedHandler}
        shouldRefetch={shouldRefetch}
        onSortByChange={(_value) => {
          setFilterBy(null);
          setSortBy(_value);
        }}
      />
      {chatUserId && (
        <SingleUserChatModal
          selectedUser={chatUserId}
          onClose={() => setChatUserId(undefined)}
        />
      )}
      {showInviteModal && (
        <InviteUserModal
          isOpen={showInviteModal}
          onClose={() => setShowInviteModal(false)}
          onActionCompleted={onActionCompleted}
          shouldRefetch={shouldRefetch}
        />
      )}

      {reInviteUser?.eid && (
        <ReInviteUserModal
          isOpen={!!reInviteUser?.eid}
          data={reInviteUser}
          onClose={() => setReInviteUser(undefined)}
        />
      )}
    </Box>
  );
};

export default MemberListContainer;
