import React, { FC, useMemo, useState } from 'react';
import { Box, Flex, ListItem, Tooltip, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

import { ShareMemberEntity } from './share-member.graphql';
import RoleAndAuthRole from '../role-authrole/RoleAndAuthRole';
import { IconsPlaceholder } from '../Search';
import Image from '../Image';
import { ActionButton } from '../Confirm';
import { useUserEntity } from '../../hooks';
import { useHasChatAccess } from 'hooks/useChatRestriction';

interface IProps {
  data: ShareMemberEntity;
  onSendClick?: (value: ShareMemberEntity) => PromiseLike<void>;
}

const ShareMemItem: FC<IProps> = ({ data, onSendClick }) => {
  const { t } = useTranslation(['common']);
  const hasChatAccess = useHasChatAccess();
  const [sentSuccess, setSendSuccess] = useState(false);

  const isChatEnabled = useUserEntity((entity) => entity?.features?.chat);

  const _hasChatAccess = useMemo(() => {
    return hasChatAccess({
      eid: data?.eid,
      role: data?.role,
      authRole: data?.authRole,
    });
  }, [data]);

  const _onSendClick = async () => {
    if (!onSendClick || sentSuccess) {
      return null;
    }
    try {
      await onSendClick?.(data);
      setSendSuccess(true);
    } catch (err: any) {
      console.log('==', err?.message);
    }
  };

  return (
    <ListItem>
      <Flex gap={3} align='center'>
        <Image
          src={data?.profilePic || IconsPlaceholder['user']}
          width={40}
          height={40}
          style={{
            borderRadius: 40,
            width: 40,
            height: 40,
          }}
        />

        <VStack spacing={0} align='stretch' flex='1' overflow='hidden'>
          <Box w='max-content'>
            <Box
              fontSize='14px'
              fontWeight='600'
              color='#171725'
              whiteSpace='nowrap'
              noOfLines={1}
            >
              {data?.name ? data?.name : 'N/A'}
            </Box>
          </Box>
          <Box overflow='hidden'>
            <RoleAndAuthRole
              authRole={data?.authRole}
              role={data?.role}
              defaultValue='--'
            />
          </Box>
        </VStack>

        <Tooltip
          label={
            !_hasChatAccess
              ? 'This member does not have permission to chat.'
              : ''
          }
          hasArrow
        >
          <Box>
            <ActionButton
              size='sm'
              minW='82px'
              fontSize='12px'
              colorScheme={sentSuccess ? 'green' : 'blue'}
              borderRadius='7px'
              actionFn={_onSendClick}
              isDisabled={!isChatEnabled || !_hasChatAccess}
              rightIcon={
                sentSuccess ? (
                  <FontAwesomeIcon icon={faCheck as IconProp} />
                ) : undefined
              }
            >
              {t(sentSuccess ? 'common:sent' : 'common:send')}
            </ActionButton>
          </Box>
        </Tooltip>
      </Flex>
    </ListItem>
  );
};

export default ShareMemItem;
