import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useQuery, useReactiveVar, gql } from '@apollo/client';

import {
  authTokenVar,
  isJwtValid,
  UserData,
  userObj,
} from 'sop-commons/src/client/clientFactory';
import { useLogout } from './hooks';
import React, { FC } from 'react';
import { GET_USER } from './pages/Login/login.graphql';
import { roleObj } from './ui-components/DashboardMenu';

const GET_TOKEN = gql`
  query getToken($refreshToken: String!) {
    getToken(refreshToken: $refreshToken) {
      accessToken
    }
  }
`;

interface AuthProps {
  rest?: any;
  allow?: '$unauthenticated' | '$authenticated';
  redirectTo: string;
}
const Auth: FC<AuthProps> = ({ children, allow, redirectTo }) => {
  const authToken = useReactiveVar<any>(authTokenVar);
  const userObject = useReactiveVar(userObj);
  const history = useHistory();
  const logoutCurrentUser = useLogout();
  const { data: getTokenObj } = useQuery(GET_TOKEN, {
    variables: {
      refreshToken: localStorage?.getItem?.('refreshAuthToken'),
    },
    skip: !localStorage?.getItem?.('refreshAuthToken'),
  });

  if (getTokenObj?.getToken?.accessToken) {
    localStorage?.setItem('authToken', getTokenObj?.getToken?.accessToken);
  }

  const logoutUser = async () => {
    await logoutCurrentUser();
    history.replace('/');
    window.location.reload();
    return;
  };

  const { loading } = useQuery(GET_USER, {
    skip: !authToken || Object.keys(userObject).length > 0,
    onCompleted: (data: { user: UserData }) => {
      if (data?.user?.status !== 'active' || !data?.user?.entity?.isActive) {
        logoutUser();
      }
      userObj(data?.user);
      const roles = data?.user?.entity?.roles;
      if (roles && roles.length) {
        let roleWithColor: any = {};
        roles.forEach((role: any) => {
          roleWithColor[role.name] = role.color;
        });

        roleObj(roleWithColor);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const { pathname = '' } = useLocation();

  // console.log('Allow', allow);
  // console.log('Is Token Valid', isJwtValid(authToken));

  if (loading) {
    return <></>;
  } else {
    if (allow === '$unauthenticated') {
      if (!isJwtValid(authToken)) return <>{children}</>;
      else {
        return <Redirect to={'/'} />;
      }
    } else if (allow === '$authenticated' && isJwtValid(authToken)) {
      // if (
      //   (pathname === '/dashboard' || pathname === '/') &&
      //   !updatedUserObj?.onboarded
      // ) {
      //   if (
      //     updatedUserObj?.name &&
      //     updatedUserObj?.role &&
      //     updatedUserObj?.authRole === ADMIN
      //   ) {
      //     return <Redirect to={'/onboarding/add-sop'} />;
      //   } else {
      //     return <Redirect to={'/onboarding/company-info'} />;
      //   }
      // } else if (
      //   (pathname === '/onboarding/add-sop' ||
      //     pathname === '/onboarding/company-info' ||
      //     pathname === '/onboarding/sop-list') &&
      //   updatedUserObj?.onboarded
      // )
      // return <Redirect to={'/'} />;
      return <>{children}</>;
    } else {
      return <Redirect to={redirectTo} />;
    }
  }
};

export default Auth;
