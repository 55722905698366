import { CustomLauncherTask, LauncherTask } from './types';
import moment from 'moment-timezone';
import { timeLeftHandler } from 'pages/TasksDashboard/SupervisedTasks/functions/processSupervisedData';
import { cloneDeep } from 'lodash';
import {
  getUserTimezoneConvertedDate,
  getUserTimezoneConvertedNow,
} from 'pages/launcher/details/helper';

export interface ILaunchTaskEntity {
  [key: string]: CustomLauncherTask[];
}

export function calculateTimeDifference(
  now: moment.Moment,
  givenDate: moment.Moment
) {
  let result;

  if (givenDate.isBefore(now)) {
    // Date has passed
    const diffYears = now.diff(givenDate, 'years');
    const diffMonths = now.diff(givenDate, 'months');
    const diffDays = now.diff(givenDate, 'days');
    const diffHours = now.diff(givenDate, 'hours');
    const diffMinutes = now.diff(givenDate, 'minutes');

    if (diffYears >= 1) {
      result = `Overdue by ${diffYears} year${diffYears > 1 ? 's' : ''}`;
    } else if (diffMonths >= 1) {
      result = `Overdue by ${diffMonths} month${diffMonths > 1 ? 's' : ''}`;
    } else if (diffDays >= 1) {
      result = `Overdue by ${diffDays} day${diffDays > 1 ? 's' : ''}`;
    } else if (diffHours >= 1) {
      result = `Overdue by ${diffHours} hour${diffHours > 1 ? 's' : ''}`;
    } else {
      result = `Overdue by ${diffMinutes} minute${diffMinutes > 1 ? 's' : ''}`;
    }
  } else {
    // Date is in the future
    const diffYears = givenDate.diff(now, 'years');
    const diffMonths = givenDate.diff(now, 'months');
    const diffDays = givenDate.diff(now, 'days');
    const diffHours = givenDate.diff(now, 'hours');
    const diffMinutes = givenDate.diff(now, 'minutes');

    if (diffYears >= 1) {
      result = `${diffYears} year${diffYears > 1 ? 's' : ''}`;
    } else if (diffMonths >= 1) {
      result = `${diffMonths} month${diffMonths > 1 ? 's' : ''}`;
    } else if (diffDays >= 1) {
      result = `${diffDays} day${diffDays > 1 ? 's' : ''}`;
    } else if (diffHours >= 1) {
      result = `${diffHours} hour${diffHours > 1 ? 's' : ''}`;
    } else {
      result = `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''}`;
    }
  }

  return result;
}

export const tasksDataFormatter = (
  taskData: ILaunchTaskEntity,
  loggedInUserTimezone: string
) => {
  let clonedTaskData = cloneDeep(taskData);
  Object.keys(clonedTaskData)?.map((_taskData) => {
    clonedTaskData[_taskData]?.map((task, index) => {
      task.categoryName = _taskData;
      let taskDueDate = moment.utc(task?.dueDate);
      let taskStartDate = moment.utc(task?.startDate);
      let taskCompleteDate = moment.utc(task?.completedAt);
      const now = getUserTimezoneConvertedNow(loggedInUserTimezone);
      // let initTaskStartDate = moment.utc(task?.startDate);
      // let bufferApplied = false;
      // if (task.buffer > 0) {
      //   if (!bufferApplied) {
      //     // Apply buffer only to taskDueDate for the first occurrence
      //     taskDueDate = moment(task.dueDate).add(task.buffer, 'days');
      //     bufferApplied = true;
      //   } else {
      //     // Apply buffer to both taskStartDate and taskDueDate for subsequent tasks
      //     initTaskStartDate = moment(task.startDate).add(task.buffer, 'days');
      //     taskDueDate = moment(task.dueDate).add(task.buffer, 'days');
      //   }
      // }
      // let taskStartDate = getUserTimezoneConvertedDate(
      //   loggedInUserTimezone,
      //   initTaskStartDate?.toISOString()
      // );
      // task.dueDate = taskDueDate?.toISOString();
      // task.startDate = taskStartDate?.toISOString();
      // let taskCompleteDate: moment.Moment | undefined = undefined;
      // if (task?.completedAt) {
      //   taskCompleteDate = getUserTimezoneConvertedDate(
      //     loggedInUserTimezone,
      //     task?.completedAt
      //   );
      // }
      let diffInMilliseconds = taskDueDate?.diff(now);
      let duration = moment?.duration(diffInMilliseconds);
      let hours = Math?.floor(duration?.asHours());
      let minutes = Math?.floor(duration?.asMinutes()) - hours * 60;
      // let durationString = timeLeftHandler(hours, minutes);
      let durationString = calculateTimeDifference(now, taskDueDate);
      if (task?.dependency === 'INDEPENDENT') {
        // Non dependent tasks
        if (task?.completedAt) {
          task.formattedDueDate = taskDueDate;
          task.formattedDuration = durationString;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.taskStatus = 'complete';
        } else if (taskDueDate.isBefore(now)) {
          task.formattedDueDate = taskDueDate;
          task.formattedDuration = durationString;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.taskStatus = 'overdue';
        } else {
          task.formattedDueDate = taskDueDate;
          task.formattedDuration = durationString;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.taskStatus = 'inProgress';
        }
      } else {
        // Dependent tasks
        /**
         * 'overdue' | 'inProgress' | 'locked' | 'complete'
         */
        if (clonedTaskData[_taskData][index - 1]?.completedAt) {
          // The dependent task is completed, so need to check if the current locked task is overdue, in progress or still in locked phase
          if (task?.completedAt) {
            task.formattedDueDate = taskDueDate;
            task.formattedDuration = durationString;
            task.formattedStartDate = taskStartDate;
            task.formattedCompleteDate = taskCompleteDate;
            task.taskStatus = 'complete';
          } else if (taskDueDate.isBefore(now)) {
            task.formattedDueDate = taskDueDate;
            task.formattedDuration = durationString;
            task.formattedStartDate = taskStartDate;
            task.formattedCompleteDate = taskCompleteDate;
            task.taskStatus = 'overdue';
          } else {
            task.formattedDueDate = taskDueDate;
            task.formattedDuration = durationString;
            task.formattedStartDate = taskStartDate;
            task.formattedCompleteDate = taskCompleteDate;
            task.taskStatus = 'inProgress';
          }
        } else {
          task.formattedDueDate = taskDueDate;
          task.formattedDuration = durationString;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.taskStatus = 'locked';
        }
      }
    });
  });
  return clonedTaskData;
};
