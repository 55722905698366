import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';
import axios, { CancelTokenSource } from 'axios';
import { fileTOBlobData } from '../utils/blobHelper';
import { GET_SIGNED_URL } from '../ui-components/Profile';

type Progress = (progress: number, eid: string) => void;

export interface FileWithEid {
  file: File;
  eid: string;
  url: string;
}

const extractNameType = (data: Blob, fileName?: string) => {
  // @ts-ignore
  if (typeof data?.name !== 'string') {
    return {
      name: fileName,
      type: data.type,
    };
  }
  return data as File;
};

export const useUploadMultipleFilesWithCancel = () => {
  const [getSignedUrlRequest] = useMutation(GET_SIGNED_URL);
  const cancelTokenSources = useRef<Map<string, CancelTokenSource>>(new Map());

  const getSignedUrl = useCallback(
    async (input) => {
      return await getSignedUrlRequest({
        variables: {
          getSignedUrlInput: input,
        },
      }).then((res) => res?.data?.getSignedUrl);
    },
    [getSignedUrlRequest]
  );

  const cancelUpload = useCallback((eid: string) => {
    const source = cancelTokenSources.current.get(eid);
    if (source) {
      source.cancel('Upload cancelled by the user.');
      cancelTokenSources.current.delete(eid);
    }
  }, []);

  const uploadFiles = useCallback(
    async (files: FileWithEid[], uploadProgress?: Progress) => {
      const urlsData: FileWithEid[] = [];

      for (let i = 0; i < files.length; i++) {
        const { file, eid } = files[i];
        const source = axios.CancelToken.source();
        cancelTokenSources.current.set(eid, source);
        const { name: _filename, type } = extractNameType(file, file?.name);

        const signedUrls = await getSignedUrl([
          { filename: _filename, mimetype: type },
        ]);

        if (Array.isArray(signedUrls) && signedUrls.length) {
          const data = signedUrls[0];
          const signedRequestUrl = data.signedRequestUrl;
          const imageUrl = (data.url || '') as string;

          const blobData = await fileTOBlobData(file);

          try {
            await axios.put(signedRequestUrl, blobData, {
              cancelToken: source.token,
              onUploadProgress: (progressEvent) => {
                const progress = Math.floor(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                uploadProgress?.(progress, eid);
              },
            });

            urlsData.push({ url: imageUrl, file: file, eid: eid });
          } catch (error: any) {
            if (axios.isCancel(error)) {
              console.log('Upload cancelled', error.message);
            } else {
              console.error('Upload error', error);
            }
          }
        }
        cancelTokenSources.current.delete(eid);
      }

      return urlsData;
    },
    [getSignedUrl]
  );

  return { uploadFiles, cancelUpload };
};
