import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useModalContext, useToast } from '@chakra-ui/react';
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { PayloadInput } from 'types';
import {
  ADD_CHAPTER,
  ChapterVariable,
  FileDataEntity,
} from '../create-chapter.graphql';
import { getFileType } from '../helper';
import BaseUploadFile from './BaseUploadFile';
import {
  IConditionType,
  ILocationData,
  IRoleData,
  IType,
  IUserData,
} from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { useUserData } from 'hooks';
import { AuthRole } from 'authorization';
import { CategoryRef } from 'sub-components/ChapterEditor/CategoryModal/CategoryModal';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { CategoryEntity, userObj } from 'sop-commons/src/client';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { loginUserBranchRootObj } from 'sub-components/Header';
import useCombinedStore from 'zustandStore/store';
import {
  ISelectedLocationData,
  ISelectedMemberData,
  ISelectedRoleData,
} from 'zustandStore/slices/useVisibility';
import { getQueryParamValue } from 'utils/queryParams';
import { getAllFolderSubFolderList } from 'pages/Handbook/setVisibilityModal/SetSeeModal/utility';

const GET_USERS = gql`
  query BaseUploadFileUsers {
    userDescendants {
      authRole
      eid
      name
      profilePic
      role
      type
      username
      locations {
        eid
        name
      }
    }
  }
`;

interface IProps {
  defaultCategory: {
    foundParentData?: CategoryEntity;
    foundChildData?: CategoryEntity;
  };
  onChapterCreated?: () => void;
}

export interface IVisibility {
  locations: string[];
  roles: string[];
  users: string[];
  condition?: IConditionType;
  visibility?: IType;
}

const UploadFile: FC<IProps> = ({ onChapterCreated, defaultCategory }) => {
  const { t } = useTranslation(['chapter']);
  const dashboardCtx = useContext(DashboardDataContext);
  const {
    hierarchyData,
    visibilitySelection,
    updateHierarchyData,
    updateVisibilityScope,
    updateOperationType,
    updateVisibilitySelection,
  } = useCombinedStore();
  const categoryModalRef = useRef<CategoryRef>(null);
  const locUserTree: any = useReactiveVar(loginUserBranchRootObj);
  const methods = useForm<IFormInput>();
  const folderWatch = useWatch<IFormInput, 'category'>({
    control: methods.control,
    name: 'category',
  });
  const subFolderWatch = useWatch<IFormInput, 'subFolder'>({
    control: methods.control,
    name: 'subFolder',
  });
  const [chapterTitle, setChapterTitle] = useState<string>('');
  const [file, setFile] = useState<FileDataEntity>();
  const [isUploading, setIsUploading] = useState(false);
  const [thumbnail, setThumbnail] = useState<string>();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const { updateSelectedData } = useCombinedStore();
  const { onClose } = useModalContext();
  const userData = useUserData();
  // const categoryList = useCategoryList();
  const categoryList = useReactiveVar(foldersListVar);

  const { data: _userData } = useQuery(GET_USERS);

  const nameValue = getQueryParamValue('name');
  const typeValue = getQueryParamValue('type');
  const statusValue = getQueryParamValue('status');

  // useEffect(() => {
  //   if (
  //     [AuthRole.LOCATION_OWNER, AuthRole.WORKER]?.includes(userData?.authRole)
  //   ) {
  //     setNewSetVisibility({ ...newSetVisibility, visibility: 'private' });
  //   }
  // }, [userData]);

  useEffect(() => {
    if (statusValue === 'RESTRICT' || (nameValue && typeValue)) {
      if (
        hierarchyData?.parentFolder?.details?.eid &&
        hierarchyData?.subFolder?.details?.eid
      ) {
        let newObj = {
          category: {
            ...hierarchyData?.parentFolder?.details,
            label: hierarchyData?.parentFolder?.details?.name,
            value: hierarchyData?.parentFolder?.details?.eid,
          },
          subFolder: {
            ...hierarchyData?.subFolder?.details,
            label: hierarchyData?.subFolder?.details?.name,
            value: hierarchyData?.subFolder?.details?.eid,
          },
        };
        methods.reset(newObj);
      } else if (hierarchyData?.parentFolder?.details?.eid) {
        let newObj = {
          category: {
            ...hierarchyData?.parentFolder?.details,
            label: hierarchyData?.parentFolder?.details?.name,
            value: hierarchyData?.parentFolder?.details?.eid,
          },
          subFolder: undefined,
        };
        methods.reset(newObj);
      }
    } else if (
      defaultCategory?.foundChildData?.eid &&
      defaultCategory?.foundParentData?.eid
    ) {
      let newObj = {
        category: {
          ...defaultCategory?.foundParentData,
          label: defaultCategory?.foundParentData?.name,
          value: defaultCategory?.foundParentData?.eid,
        },
        subFolder: {
          ...defaultCategory?.foundChildData,
          label: defaultCategory?.foundChildData?.name,
          value: defaultCategory?.foundChildData?.eid,
        },
      };
      methods.reset(newObj);
    } else if (defaultCategory?.foundParentData?.eid) {
      let newObj = {
        category: {
          ...defaultCategory?.foundParentData,
          label: defaultCategory?.foundParentData?.name,
          value: defaultCategory?.foundParentData?.eid,
        },
        subFolder: undefined,
      };
      methods.reset(newObj);
    }
    // if (defaultCategory?.eid) {
    //   console.log('UploadFile : default category : ', defaultCategory);
    //   // if (defaultCategory?.children?.le)
    //   // setNewSetVisibility({ })
    // }
  }, [defaultCategory, nameValue, typeValue, statusValue]);

  // const [category, setCategory] = useState<SelectOption | undefined>(undefined);

  // useEffect(() => {
  //   if (defaultCategory) {
  //     let foundVisibility = categoryList?.filter(
  //       (cat) => cat?.eid === defaultCategory?.eid
  //     )?.[0];
  //     methods.setValue('')
  //     setCategory({
  //       value: defaultCategory,
  //       label: defaultCategory,
  //       visibility: foundVisibility?.visibility,
  //     });
  //   }
  // }, [categoryList, defaultCategory]);

  const [publishChapter] = useMutation<never, PayloadInput<ChapterVariable>>(
    ADD_CHAPTER,
    {
      onCompleted: async () => {
        toast({
          title: 'Chapter created successfully',
          status: 'success',
        });
      },
      onError: () => {
        toast({
          title: 'Chapter could not be created',
          status: 'error',
        });
      },
    }
  );

  /**
   * The function `onCreateChapter` handles the creation and publishing of a new chapter with specified
   * details and file content.
   */
  const onCreateChapter = async () => {
    try {
      setIsUploading(true);

      if (file?.url) {
        const fileType = getFileType(file?.type);
        let input = {
          title: chapterTitle!,
          raw_content: chapterTitle!,
          // category: category?.value!,
          // categoryId: category?.eid!,
          categoryId:
            methods.getValues('subFolder.eid') ||
            methods.getValues('category.eid'),
          sopType: file?.type?.includes('video/') ? 'video' : 'document',
          thumbnail: fileType === 'pdf' ? thumbnail : null,
          files: [
            {
              mimetype: file?.type!,
              name: file?.name!,
              url: file.url,
              type: fileType,
              fileSize: file?.fileSize!,
            },
          ],
          visibility: visibilitySelection?.visibility || 'public',
          visibleTo: {
            condition: visibilitySelection?.condition || null!,
            locations: visibilitySelection?.locations,
            roles: visibilitySelection?.roles,
            users: visibilitySelection?.users,
          },
        };
        const response = await publishChapter({
          variables: {
            input: {
              title: chapterTitle!,
              raw_content: chapterTitle!,
              // category: category?.value!,
              // categoryId: category?.eid!,
              categoryId:
                methods.getValues('subFolder.eid') ||
                methods.getValues('category.eid'),
              sopType: file?.type?.includes('video/') ? 'video' : 'document',
              thumbnail: fileType === 'pdf' ? thumbnail : null,
              files: [
                {
                  mimetype: file?.type!,
                  name: file?.name!,
                  url: file.url,
                  type: fileType,
                  fileSize: file?.fileSize!,
                },
              ],
              visibility: visibilitySelection?.visibility || 'public',
              visibleTo: {
                condition: visibilitySelection?.condition || null!,
                locations: visibilitySelection?.locations || [],
                roles: visibilitySelection?.roles || [],
                users: visibilitySelection?.users || [],
              },
            },
          },
        });
        if (response.errors) {
          throw response.errors;
        } else {
          onChapterCreated?.();
        }
        onClose();
      }
      setIsUploading(false);
    } catch (e) {
      console.log(e);
      setIsUploading(false);
    }
  };

  const allFoldersList = useMemo(() => {
    return getAllFolderSubFolderList(categoryList);
  }, [categoryList]);

  useEffect(() => {
    let _locationsData: ISelectedLocationData[] = [];
    let _usersData: ISelectedMemberData[] = [];
    let updatedRolesData: ISelectedRoleData[] = [];
    const roles = userData?.entity?.roles || [];
    let category = subFolderWatch?.eid ? subFolderWatch : folderWatch;
    if (userData?.authRole === AuthRole.LOCATION_OWNER) {
      let filteredLocationsArray = (
        locUserTree?.descendantBranches || []
      ).filter((location: ILocationData) => {
        return (userData?.locations || []).find((userLocation) => {
          return userLocation.eid === location.eid;
        });
      });
      _locationsData = filteredLocationsArray;
    } else {
      _locationsData = (locUserTree?.descendantBranches || [])?.map(
        (user: ILocationData) => {
          return {
            ...user,
            label: user.name,
            value: user.name,
          };
        }
      );
      _usersData = (_userData?.userDescendants || [])?.map(
        (user: IUserData) => {
          return {
            ...user,
            label: user.name,
            value: user.name,
          };
        }
      );
      updatedRolesData = roles?.map<IRoleData>((role: any) => {
        return {
          ...role,
          label: role?.name,
          value: role?.name,
        };
      });
    }
    let selectedLocations = _locationsData?.filter((loc) => {
      return category?.visibleTo?.locations?.some(
        (_loc: any) => _loc === loc?.eid
      );
    });
    let selectedMembers = _usersData?.filter((user) => {
      return category?.visibleTo?.users?.some(
        (_user: any) => _user === user?.eid
      );
    });
    let selectedJobs = updatedRolesData?.filter((role) => {
      return category?.visibleTo?.roles?.some(
        (_role: any) => _role === role?.label
      );
    });
    updateSelectedData({
      locations: selectedLocations,
      roles: selectedJobs,
      members: selectedMembers,
    });
    updateVisibilitySelection({
      locations: category?.visibleTo?.locations,
      roles: category?.visibleTo?.roles,
      users: category?.visibleTo?.users,
      condition: category?.visibleTo?.condition,
      visibility: category?.visibility,
    });
    let foundFolder = allFoldersList?.find(
      (list) => list?.eid === category?.eid
    );
    let foundParentFolder = allFoldersList?.find(
      (list) => list?.eid === foundFolder?.parentFolderId
    );
    if (foundParentFolder && foundFolder) {
      updateHierarchyData({
        parentFolder: {
          details: foundParentFolder,
          visibilityData: {
            locations: foundParentFolder?.visibleTo?.locations || [],
            roles: foundFolder?.visibleTo?.roles || [],
            users: foundFolder?.visibleTo?.users || [],
            condition: (foundFolder?.visibleTo?.locations?.length > 0 &&
            foundFolder?.visibleTo?.roles?.length > 0
              ? foundFolder?.visibleTo?.condition
              : undefined) as IConditionType,
            visibility: foundFolder?.visibility,
          },
        },
        subFolder: {
          details: foundFolder,
          visibilityData: {
            locations: foundFolder?.visibleTo?.locations || [],
            roles: foundFolder?.visibleTo?.roles || [],
            users: foundFolder?.visibleTo?.users || [],
            condition: (foundFolder?.visibleTo?.locations?.length > 0 &&
            foundFolder?.visibleTo?.roles?.length > 0
              ? foundFolder?.visibleTo?.condition
              : undefined) as IConditionType,
            visibility: foundFolder?.visibility,
          },
        },
      });
    } else if (foundFolder) {
      updateHierarchyData({
        parentFolder: {
          details: foundFolder,
          visibilityData: {
            locations: foundFolder?.visibleTo?.locations || [],
            roles: foundFolder?.visibleTo?.roles || [],
            users: foundFolder?.visibleTo?.users || [],
            condition: (foundFolder?.visibleTo?.locations?.length > 0 &&
            foundFolder?.visibleTo?.roles?.length > 0
              ? foundFolder?.visibleTo?.condition
              : undefined) as IConditionType,
            visibility: foundFolder?.visibility,
          },
        },
      });
    }
  }, [folderWatch, subFolderWatch, _userData]);

  const onAddCategoryModalClose = () => {
    updateVisibilityScope('chapter');
    updateOperationType('add');
  };

  return (
    <FormProvider {...methods}>
      <BaseUploadFile
        file={file}
        onFileChange={setFile}
        title={chapterTitle}
        onTitleChange={setChapterTitle}
        category={subFolderWatch?.eid ? subFolderWatch : folderWatch}
        onCategoryChange={() => {}}
        thumbnail={thumbnail}
        onThumbnailChange={setThumbnail}
        isUploading={isUploading}
        buttonTitle={t('chapter:publishChapter')}
        onSubmitClick={onCreateChapter}
        categoryModalRef={categoryModalRef}
        onAddCategoryModalClose={onAddCategoryModalClose}
      />
    </FormProvider>
  );
};

export default UploadFile;
