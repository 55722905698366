import { FC, useEffect, useState } from 'react';
import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import { Box, Button, Center, Flex, Image } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { authTokenVar } from 'sop-commons/src/client';
import { useRouter } from '../utils';
import { useLogout, useUserDataQuery } from '../hooks';

// @ts-ignore
import icon from '../assets/images/delightreeIcon.svg';

// @ts-ignore
import animationData from '../assets/lotties/magicLinkExpired.json';
// @ts-ignore
import loadingAnimationData from '../assets/lotties/business-loading.json';

// import scss
import './InviteRegistration/InviteRegistration.scss';
import { UserStatus } from '../types';

interface Response {
  signInMagicLink: {
    accessToken: string;
    refreshToken: string;
  };
}

const SIGN_IN_MAGIC_LINK = gql`
  query signInMagicLink($token: String!) {
    signInMagicLink(token: $token) {
      accessToken
      refreshToken
    }
  }
`;

interface UserStatusData {
  eid: string;
  status: UserStatus;
  isInvited: boolean;
  onboarded: boolean;
  inviteDetails: {
    authCode: string;
    status: string;
  };
}

const USER_STATUS_CHECK = gql`
  query UserStatusCheck {
    user {
      eid
      status
      isInvited
      onboarded
      inviteDetails {
        authCode
        status
      }
    }
  }
`;

const MagicLink: FC = () => {
  const { t } = useTranslation('auth');
  const router: any = useRouter();
  const history = useHistory();
  const logoutCurrentUser = useLogout();
  const { query } = router;
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(() => 'magic_expired');

  const client = useApolloClient();

  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const [getUser] = useUserDataQuery({
    onCompleted: () => {
      authTokenVar(localStorage.getItem('authToken'));
      setTimeout(() => {
        history.push('/');
      }, 300);
    },
  });

  const [signInMagicLink] = useLazyQuery<Response>(SIGN_IN_MAGIC_LINK, {
    onCompleted: async (response) => {
      const data = response.signInMagicLink;
      if (data?.accessToken && data?.refreshToken) {
        await logoutCurrentUser();
        try {
          const response = await client
            .query<Record<'user', UserStatusData>>({
              query: USER_STATUS_CHECK,
              fetchPolicy: 'network-only',
              context: {
                headers: {
                  authorization: `Bearer ${data.accessToken}`,
                },
              },
            })
            .then((resp) => resp.data?.user);

          if (
            response.isInvited &&
            (!response.onboarded || response.status === 'pending')
          ) {
            setTimeout(() => {
              history.replace(
                `/invite?code=${response.inviteDetails?.authCode}`
              );
            }, 300);
          } else {
            localStorage.setItem('authToken', data.accessToken);
            localStorage.setItem('refreshAuthToken', data.refreshToken);
            getUser({ variables: {} });
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    onError(error) {
      console.error('Error ', error.graphQLErrors[0]?.message);
      setErrorMessage(error?.graphQLErrors?.[0]?.message);
      setIsError(true);
    },
  });

  useEffect(() => {
    signInMagicLink({
      variables: {
        token: query?.token,
      },
    });
  }, []);

  if (isError) {
    return (
      <Center h='100%'>
        <div style={{ marginBottom: '100px' }}>
          <Lottie
            options={defaultUploadLottieOptions}
            height={112}
            width={112}
          />
          <div className='invite-registration-welcome-text'>
            <Trans t={t} i18nKey={errorMessage}>
              {errorMessage}
            </Trans>
          </div>
          <div className='invite-registration-welcome-desc'>
            {t('contact_magic_link')}
          </div>
          <Flex justify='center'>
            <Button
              size='md'
              colorScheme='blue'
              minW='120px'
              fontWeight='500'
              borderRadius='7px'
            >
              {t('contact_us')}
            </Button>
          </Flex>
        </div>
      </Center>
    );
  }

  return (
    <Center pos='relative' height='full' flexDir='column'>
      <Image pos='absolute' boxSize='54px' src={icon} top='50px' left='50px' />
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loadingAnimationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={120}
        width={120}
      />
      <Center
        flexDir='column'
        fontSize='36px'
        fontWeight='700'
        textAlign='center'
        color='black'
        userSelect='none'
      >
        <Box whiteSpace='pre-line'>{t('plsWaitWhileSettingUpAccount')}</Box>
        <Box
          fontWeight='400'
          fontSize='16px'
          lineHeight='20px'
          color='#555555'
          mt={3}
        >
          {t('take_upto')}
        </Box>
      </Center>
    </Center>
  );
};

export default MagicLink;
