import { Modal, Button, Input } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

import SearchTypeList from './SearchTypeList';

import { ReactComponent as CommandSvg } from '../../assets/images/commandIcon.svg';

interface Props {
  isOpen?: boolean;
  onClose?: any;
}

// import css
import './SearchModal.css';

// import components
import RecentSearchList from 'ui-components/RecentSearchList';
import SearchList from 'ui-components/SearchList';

const GET_SEARCH_RESULTS = gql`
  query Search($input: SearchQueryInput) {
    Search(input: $input) {
      eid
      path
      value
      meta
    }
  }
`;

const SearchModal = ({ isOpen, onClose }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [getSearchResults, { data, error, loading }] =
    useLazyQuery(GET_SEARCH_RESULTS);
  const handleChange: any = (e: any) => {
    setSearchQuery(e?.target?.value);
  };

  const debouncedFetchData = debounce(() => {
    getSearchResults({
      variables: {
        input: {
          query: searchQuery.trim(),
          type: selectedType,
        },
      },
    });
  }, 500);

  useEffect(() => {
    if (searchQuery?.length > 0) {
      debouncedFetchData();
    }
  }, [searchQuery, selectedType]);

  const getControlText = () => {
    if (
      typeof navigator !== 'undefined' &&
      navigator.appVersion.indexOf('Mac')
    ) {
      return <CommandSvg />;
    }
    return 'ctrl';
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      className='search-modal-container'
      closable={false}
    >
      <div className='eventclass-search-modal-container search-modal-search-container '>
        <SearchOutlined style={{ fontSize: '22px', color: '#999' }} />
        <SearchTypeList
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
        <Input
          placeholder='Search by categories, chapters, chapter body, bundle names'
          className='eventclass-search-input search-input-container'
          value={searchQuery}
          onChange={handleChange}
        />
        <div
          className='eventclass-earch-modal-shortcut search-modal-shortcut-container'
          style={{ marginRight: '4px' }}
        >
          {getControlText()}
        </div>
        <div className='search-modal-shortcut-container'>K</div>
      </div>
      {!searchQuery && (
        <RecentSearchList
          setSearchQuery={setSearchQuery}
          setSelectedType={setSelectedType}
          onClose={onClose}
        />
      )}

      {searchQuery && (
        <SearchList
          searchList={data?.Search}
          loading={loading}
          onClose={onClose}
          searchQuery={searchQuery}
        />
      )}
      {searchQuery && (
        <div className='search-list-footer'>
          Doesn’t find what you are looking for,{' '}
          {/* <Link to='/request-sop/new'> */}
          <span
            className='eventclass-search-list-footer-request-new-link'
            style={{
              marginLeft: '3px',
              fontWeight: 600,
              cursor: 'pointer',
              color: '#4d8af0',
            }}
          >
            {' '}
            click here to request
          </span>
          {/* </Link> */}
        </div>
      )}
    </Modal>
  );
};

export default SearchModal;

SearchModal.defaultName = 'SearchModal';
