import React, { FC } from 'react';
import { Login } from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword';
import OtpResetPassword from './pages/OtpResetPassword';
import ResetPassword from './pages/ResetPassword';
import { Switch, Route } from 'react-router-dom';
import Auth from './Auth';
import InviteRegistration from './pages/InviteRegistration';
import MagicLink from './pages/MagicLink';
import OpenAICreation from './pages/OpenAICreation';
import SearchDelightbot from './pages/SearchDelightbot';

import {
  TRAINING_URL,
  REPORT_URL,
  TRAINING_EDIT_URL,
  FORMS_URL,
  INITIALIZE,
  GET_STARTED,
  CHAPTER_URL,
  // CHAPTERS_URL,
  TEAMS_HOME,
  TEAMS_PAGE,
  MEMBER_PAGE,
  TASKS_PAGE,
  TASK_DETAILS,
  ASSIGNEE_PERFORMANCE,
  JOB_PERFORMANCE,
  LOCATION_PERFORMANCE,
  TASKS_CREATE_URL,
  TASKS_EDIT_URL,
  ADD_LOCATION,
  EDIT_LOCATION,
  LOCATIONS_PAGE,
  PROFILE_URL,
  TASK_DETAILS_WITHOUT_SESSSION,
  TASK_PLAY_URL,
  TRAINING_PLAY_URL,
  CHAT_URL,
  MANAGE_CHANNEL,
} from './appRoutes';
import TrainingDraft from './pages/TrainingDraft/TrainingDraft';
import { NewBusinessLoading, NewBusinessStart } from './pages/DemoBusiness';
import ChapterPublicView from './pages/Chapters/ChapterView/ChapterPublicView/ChapterPublicView';
import { TeamLocation } from './pages/deprecated-page';
import CheckboxRoot from './sub-components/CustomDropdowns/CheckboxDropdown/CheckboxRoot';
import {
  AddLocationWrapper,
  AddSopWrapper,
  AssigneeTaskPerformanceWrapper,
  ChapterCreateEditorWrapper,
  ChapterEditEditorWrapper,
  ChapterListWrapper,
  ChapterPreviewWrapper,
  ChapterViewWrapper,
  ChatWrapper,
  CompanyInfoWarpper,
  ContinueSopWrapper,
  CreateCardWrapper,
  CreateDeckWrapper,
  CreateFormsTemplateWrapper,
  CreateFormsWrapper,
  CreateTaskWrapper,
  CreateTrainingPathContainerWrapper,
  DashboardWrapper,
  DeleteUserWrapper,
  EditCardWrapper,
  EditDeckWrapper,
  EditFormsWrapper,
  EditLocationWrapper,
  EditTaskWrapper,
  EditTrainingWrapper,
  FormResponseWrapper,
  FormSubmitWrapper,
  FormsWrapper,
  HandbookDetailWrapper,
  HandbookWrapper,
  HomeWrapper,
  JobTaskPerformanceWrapper,
  LocationDetailsWrapper,
  LocationTaskPerformanceWrapper,
  LocationWrapper,
  ManageChannelWrapper,
  ManagerTrainingViewWrapper,
  MemberDetailWrapper,
  MyTaskDetailsWrapper,
  MyTaskPlayWrapper,
  PerformanceDetailsWrapper,
  PlaygroundWrapper,
  ProfileWrapper,
  ReportsWrapper,
  RequestSopWrapper,
  SettingsWrapper,
  SopDetailWrapper,
  SopListWrapper,
  TaskDetailsWrapper,
  TaskPlayWrapper,
  TasksPageWrapper,
  TeamsPageWrapper,
  TeamsWrapper,
  TrainingPathWrapper,
  TrainingPlayWrapper,
  TrainingWrapper,
  TrainingWrapperNew,
} from './routeComponentDefinitions';
import LauncherRoute from './routes/launcher-route';

const RouteComponentsRender: FC = () => {
  return (
    <Switch>
      <Route path='/' exact component={HomeWrapper} />
      <Route
        path='/login'
        render={(props: any) => (
          <Auth allow='$unauthenticated' redirectTo='/onboarding/company-info'>
            <Login {...props} />
          </Auth>
        )}
      />
      <Route
        path='/forgot-password'
        render={(props: any) => (
          <Auth allow='$unauthenticated' redirectTo='/onboarding/company-info'>
            <ForgotPassword {...props} />
          </Auth>
        )}
      />
      <Route
        path='/otp'
        render={(props: any) => (
          <Auth allow='$unauthenticated' redirectTo='/onboarding/company-info'>
            <OtpResetPassword {...props} />
          </Auth>
        )}
      />
      <Route
        path='/reset-password'
        render={(props: any) => (
          <Auth allow='$unauthenticated' redirectTo='/onboarding/company-info'>
            <ResetPassword {...props} />
          </Auth>
        )}
      />
      <Route path='/onboarding/company-info' component={CompanyInfoWarpper} />
      <Route path='/onboarding/add-sop' component={AddSopWrapper} />
      <Route path='/onboarding/sop-list' component={SopListWrapper} />
      {/* <Route path='/sop-wait' component={SopWaitWrapper} /> */}
      <Route path='/dashboard' component={DashboardWrapper} />
      <Route path='/sop/:id' component={SopDetailWrapper} />
      {/*<Route path='/public/:eid' component={PublicSop} />*/}
      <Route path='/request-sop/new' component={RequestSopWrapper} />
      <Route path='/request-sop/continue' component={ContinueSopWrapper} />
      <Route path='/settings' component={SettingsWrapper} />

      <Route path='/handbook/:category?' component={HandbookWrapper} />
      <Route path='/handbook' component={HandbookWrapper} />
      <Route path='/invite' component={InviteRegistration} />
      <Route path={CHAT_URL} component={ChatWrapper} />
      <Route path='/magiclink' component={MagicLink} />
      <Route path='/:category?/card/create' component={CreateCardWrapper} />
      <Route
        path='/:category?/card/edit/:cardId?'
        component={EditCardWrapper}
      />
      <Route path='/:category?/deck/create' component={CreateDeckWrapper} />
      <Route
        path='/:category?/deck/edit/:deckId?'
        component={EditDeckWrapper}
      />
      <Route
        path='/:category?/deck/:deckId'
        component={HandbookDetailWrapper}
      />
      <Route path={TRAINING_EDIT_URL} component={EditTrainingWrapper} />
      <Route path={TRAINING_PLAY_URL} component={TrainingPlayWrapper} />
      <Route path='/training/:id' component={TrainingPathWrapper} />
      {/* <Route path='/drafts/edit/:id' component={CreateTrainingPath} /> */}
      <Route
        path='/drafts/edit/:id'
        component={CreateTrainingPathContainerWrapper}
      />
      <Route path='/drafts/list' component={TrainingDraft} />
      {/* <Route path='/drafts/create/:id' component={CreateTrainingPath} /> */}
      <Route
        path='/drafts/create/:id'
        component={CreateTrainingPathContainerWrapper}
      />
      <Route path='/view/:id' component={ManagerTrainingViewWrapper} />
      {/* <Route path='/create-path' component={CreateTrainingPath} /> */}
      <Route
        path='/create-path'
        component={CreateTrainingPathContainerWrapper}
      />
      <Route path='/forms/response/:id' component={FormResponseWrapper} />
      <Route path='/forms/create/:id' component={CreateFormsTemplateWrapper} />
      <Route path='/forms/create' component={CreateFormsWrapper} />
      <Route path='/forms/edit/:id' component={EditFormsWrapper} />
      <Route path='/form/submit/:id' component={FormSubmitWrapper} />
      <Route path={FORMS_URL} component={FormsWrapper} />

      <Route path='/training-old' component={TrainingWrapper} />
      <Route path={TRAINING_URL} component={TrainingWrapperNew} />
      <Route path={REPORT_URL} component={ReportsWrapper} />
      <Route path='/create-sop' component={OpenAICreation} />
      <Route path='/search-sop' component={SearchDelightbot} />
      <Route path='/create-chapter' component={ChapterCreateEditorWrapper} />
      <Route path='/chapter-preview' component={ChapterPreviewWrapper} />
      <Route path='/create-chapter' component={ChapterCreateEditorWrapper} />
      <Route path='/edit-chapter/:id' component={ChapterEditEditorWrapper} />
      {/* <Route path='/create-sop' component={OpenAICreateionWrapper} />
        <Route path='/search-sop' component={SearchDelightbotWrapper} /> */}

      <Route path={GET_STARTED} component={NewBusinessStart} />
      <Route path={INITIALIZE} component={NewBusinessLoading} />

      <Route path='/chapters/view/:chapterId' component={ChapterViewWrapper} />
      {/* <Route exact path={SUB_FOLDER} component={ChapterListWrapper} /> */}
      <Route path={CHAPTER_URL} component={ChapterListWrapper} />
      {/* <Route path={CHAPTERS_URL} component={ChapterListWrapper} /> */}
      <Route path='/public-view/:chapterId' component={ChapterPublicView} />
      <Route path={PROFILE_URL} component={ProfileWrapper} />

      <Route exact path='/teams/locations' component={TeamLocation} />
      <Route exact path={TEAMS_HOME} component={TeamsWrapper} />
      <Route exact path={MEMBER_PAGE} component={MemberDetailWrapper} />
      <Route path={TEAMS_PAGE} component={TeamsPageWrapper} />

      <Route exact path={TASKS_CREATE_URL} component={CreateTaskWrapper} />
      <Route exact path={TASKS_EDIT_URL} component={EditTaskWrapper} />
      <Route
        exact
        path={ASSIGNEE_PERFORMANCE}
        component={AssigneeTaskPerformanceWrapper}
      />
      <Route
        exact
        path={JOB_PERFORMANCE}
        component={JobTaskPerformanceWrapper}
      />
      <Route
        exact
        path={LOCATION_PERFORMANCE}
        component={LocationTaskPerformanceWrapper}
      />
      <Route
        exact
        path='/tasks/performance/:userId'
        component={PerformanceDetailsWrapper}
      />
      <Route path='/tasks/view/:taskId' component={TaskPlayWrapper} />
      <Route
        path='/tasks/my-tasks/details/:taskId/:sessionId'
        component={MyTaskDetailsWrapper}
      />
      <Route path={TASK_PLAY_URL} component={MyTaskPlayWrapper} />
      <Route exact path={TASKS_PAGE} component={TasksPageWrapper} />
      <Route exact path={TASK_DETAILS} component={TaskDetailsWrapper} />
      <Route
        exact
        path={TASK_DETAILS_WITHOUT_SESSSION}
        component={TaskDetailsWrapper}
      />
      <Route exact path={LOCATIONS_PAGE} component={LocationWrapper} />
      <Route path={ADD_LOCATION} component={AddLocationWrapper} />
      <Route path={EDIT_LOCATION} component={EditLocationWrapper} />
      <Route path='/locations/:locationId' component={LocationDetailsWrapper} />

      <Route path='/test-components' component={CheckboxRoot} />
      <Route path='/playground' component={PlaygroundWrapper} />
      <Route path='/delete-request' component={DeleteUserWrapper} />
      <Route path={MANAGE_CHANNEL} component={ManageChannelWrapper} />

      <LauncherRoute />
    </Switch>
  );
};

export default RouteComponentsRender;
