import { IMenuType } from 'delightree-editor';

export interface IAmplitudeEvents {
  eventName: IAmplitudeEventNames;
}

export enum AmplitudeEventNames {
  HOME_INVITE_BUTTON_CLICK = 'Home: Clicked on Invite Button', // ✅
  HOME_INVITE_BUTTON_NAME = 'Home: Clicked on Invite modal name field', // ✅
  HOME_INVITE_BUTTON_ACCESS_ROLE_SELECTION = 'Home: Clicked on Invite Modal Access Role Selection', // ✅
  HOME_INVITE_BUTTON_CUSTOM_ROLE_DROPDOWN = 'Home: Clicked on Invite Modal Custom Role Dropdown', // ✅
  HOME_INVITE_BUTTON_LOCATION_DROPDOWN = 'Home: Clicked on Invite Modal Location Dropdown', // ✅
  HOME_INVITE_BUTTON_CANCEL_BUTTON = 'Home: Clicked on Invite Modal Cancel Button', // ✅
  HOME_INVITE_BUTTON_COPY_QR_CODE = 'Home: Clicked on Invite Modal Copy QR Code', // ✅
  HOME_INVITE_BUTTON_SEND_EMAIL_NUMBER = 'Home: Clicked on Invite by email/phone number field', // ✅

  HOME_ADD_NEW_CARD_BUTTON = 'Home: Clicked on Add New Card Button', // ✅
  HOME_ADD_NEW_DECK_BUTTON = 'Home: Clicked on Add New Deck Button', // ✅
  HOME_ADD_FORM_BUTTON = 'Home: Clicked on Add Form or Checklist Button', // ✅
  HOME_ADD_TRAINING_BUTTON = 'Home: Clicked on Add Training Button', // ✅
  HOME_ADD_MEMBER_BUTTON = 'Home: Clicked on Add Member', // ✅
  HOME_ADD_LOCATION_BUTTON = 'Home: Clicked on Add Location', // ✅

  HOME_RECOMMENDED_DECKS_CLICK = 'Home: Clicked on Recommended Decks', // ✅
  HOME_ACTIVITY_FEED_LOCATION_DROPDOWN = 'Home: Clicked on Activity Feed Location Dropdown', // ✅
  HOME_ACTIVITY_FEED_CLICK_ON_ACTIVITY = 'Home: Clicked on Activity Feed Click on Activity', // ✅
  HANDBOOK_ADD_NEW_CARD_BUTTON = 'Handbook: Clicked on Add New Card Button', // ✅
  TRAINING_OPEN_PATH = 'Training: Open training path', // ✅
  TRAINING_EDIT_PATH = 'Training: Edit training path', // ✅

  TRAINING_VIEW_CHANGELOG = 'Training: View Changelog',
  TRAINING_TRACK_PROGRESS = 'Training: Track Progress',
  TRAINING_STATUS_CHANGE = 'Training/Update: Training Status changes',
  TRAINING_DELETE = 'Training/Delete: Training Delete',

  TRAINING_ADD_NEW_PATH_BUTTON_CLICK = 'Training/Add: Clicked on Add a new path button', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_CLICK_ON_PATH_NAME = 'Training/Add: Clicked on Path name in Add new path', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_EACH_ADD_BUTTON_ON_CONTENT_CARDS = 'Training/Add: Clicked on Content card (Add)', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_EACH_REMOVE_BUTTON_ON_CONTENT_CARDS = 'Training/Add: Clicked on Content card (Remove)', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_CREATE_CARD_BUTTON = 'Training/Add: Clicked on Create Card button in Add new path', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_ADD_NEXT_BUTTON = 'Training/Add: Clicked on Next button in Add new path', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_ADD_QUIZ_CARD_BUTTON = 'Training/Add: Clicked on Quiz Card button in Add new path', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_ADD_MILESTONE_BUTTON = 'Training/Add: Clicked on Add Milestone button in Add new path', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_ASSIGN_ROLES_DROPDOWN_ADD = 'Training/Add: Clicked on Assign Roles dropdown in Add new path & added role', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_ASSIGN_ROLES_DROPDOWN_REMOVE = 'Training/Add: Clicked on Assign Roles dropdown in Add new path & removed role', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_SUPERVISORS_DROPDOWN_ADD = 'Training/Add: Clicked on Supervisors dropdown in Add new path & added supervisor', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_SUPERVISORS_DROPDOWN_REMOVE = 'Training/Add: Clicked on Supervisors dropdown in Add new path & removed supervisor', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_LOCATION_DROPDOWN_ADD = 'Training/Add: Clicked on Location dropdown in Add new path & added location', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_LOCATION_DROPDOWN_REMOVE = 'Training/Add: Clicked on Location dropdown in Add new path & removed location', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_MEMBER_DROPDOWN_ADD = 'Training/Add: Clicked on Member dropdown in Add new path & added member', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_MEMBER_DROPDOWN_REMOVE = 'Training/Add: Clicked on Member dropdown in Add new path & removed member', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_SAVE_AS_DRAFT = 'Training/Add: Clicked on Save as Draft in Add new path', // ✅
  TRAINING_ADD_NEW_PATH_BUTTON_PUBLISH = 'Training/Add: Clicked on Publish in Add new path',

  TRAINING_PATH_TAB_TRACKING = 'Training/Tab: Clicked on Tracking tab',
  TRAINING_PATH_TAB_PATHS = 'Training/Tab: Clicked on Tracking -> Paths tab',
  TRAINING_PATH_TAB_MEMBERS = 'Training/Tab: Clicked on Tracking -> Members tab',

  TRAINING_PATH_SEARCH = 'Training/Tab: Search Training Path',
  TRAINING_PATH_MEMBER_SEARCH = 'Training/Tab: Search Member',

  TRAINING_PATH_FILTER = 'Training/Tab: Filter Training Path',
  TRAINING_PATH_MEMBER_FILTER = 'Training/Tab: Filter Members',

  TRAINING_PATH_MEMBER_SELECTED = 'Training/Tab: Tracking Member selected',

  TRAINING_PATH_TAB_MY_TRAINING = 'Training/Tab: Clicked on My Training tab',
  TRAINING_PATH_TAB_PUBLIC = 'Training/Tab: Clicked on My Training-> Public tab',
  TRAINING_PATH_TAB_ASSIGNED_TO_ME = 'Training/Tab: Clicked on My Training -> Assigned to me tab',

  TRAINING_PATH_MY_TRAINING_SEARCH = 'Training/Tab: Search My Training',

  SETTINGS_EDIT_PROFILE_TAB = 'Settings: Clicked on Edit Tab', // ✅
  SETTINGS_LOCATIONS_TAB = 'Settings: Clicked on Locations Tab', // ✅
  SETTINGS_TEAM_TAB = 'Settings: Clicked on Team Tab', // ✅
  SETTINGS_BILLING_TAB = 'Settings: Clicked on Billing Tab', // ✅
  SETTINGS_COMPANY_SETTING_TAB = 'Settings: Clicked on Company Setting Tab', // ✅
  SETTINGS_DELETE_ACCOUNT_TAB = 'Settings: Clicked on Delete Account Tab', // ✅
  SETTINGS_EDIT_PROFILE_FULL_NAME = 'Settings/Edit Profile: Clicked on Full Name field', // ✅
  SETTINGS_EDIT_PROFILE_ACCESS = 'Settings/Edit Profile: Clicked on Access field', // ✅
  SETTINGS_EDIT_PROFILE_ROLE = 'Settings/Edit Profile: Clicked on Role dropdown', // ✅
  SETTINGS_EDIT_PROFILE_EMAIL = 'Settings/Edit Profile: Clicked on Email', // ✅
  SETTINGS_EDIT_PROFILE_PHONE_NUMBER = 'Settings/Edit Profile: Clicked on Phone number', // ✅
  SETTINGS_EDIT_PROFILE_LOCATION = 'Settings/Edit Profile: Clicked on Location dropdown', // ✅
  SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD = 'Settings/Edit Profile: Clicked on Update Password button', // ✅
  SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_CURRENT_PASSWORD_FIELD = 'Settings/Edit Profile/Change Password: Clicked on Current Password field', // ✅
  SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_NEW_PASSWORD_FIELD = 'Settings/Edit Profile/Change Password: Clicked on New Password field', // ✅
  SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_CONFIRM_NEW_PASSWORD_FIELD = 'Settings/Edit Profile/Change Password: Clicked on Confirm New Password field', // ✅
  SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_BUTTON_SAVE = 'Settings/Edit Profile/Change Password: Clicked on Save button', // ✅
  SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_BUTTON_CLOSE = 'Settings/Edit Profile/Change Password: Clicked on Close button', // ✅
  SETTINGS_EDIT_PROFILE_SAVE = 'Settings/Edit Profile: Clicked on Save button', // ✅
  SETTINGS_LOCATIONS_ADD_LOCATION_BUTTON = 'Settings/Locations: Clicked on Add Location', // ✅
  SETTINGS_LOCATIONS_ADD_LOCATION_MODAL_NAME = 'Settings/Locations/Add Location: Clicked on Name field', // ✅
  SETTINGS_LOCATIONS_ADD_LOCATION_MODAL_PHONE_NUMBER = 'Settings/Locations/Add Location: Clicked on Phone Number', // ✅
  SETTINGS_LOCATIONS_ADD_LOCATION_MODAL_USER_NAME = 'Settings/Locations/Add Location: Clicked on Username', // ✅
  SETTINGS_LOCATIONS_ADD_LOCATION_MODAL_CREATE_PASSWORD = 'Settings/Locations/Add Location: Clicked on Create Password', // ✅
  SETTINGS_LOCATIONS_ADD_LOCATION_MODAL_CONFIRM_PASSWORD = 'Settings/Locations/Add Location: Clicked on Confirm Password', // ✅
  SETTINGS_LOCATIONS_ADD_LOCATION_MODAL_CLOSE_MODAL = 'Settings/Locations/Add Location: Clicked on Close button', // ✅
  SETTINGS_LOCATIONS_ADD_LOCATION_MODAL_ADD_MODAL = 'Settings/Locations/Add Location: Clicked on Add button', // ✅
  SETTINGS_LOCATIONS_EDIT_LOCATION_BUTTON = 'Settings/Locations: Clicked on Edit Location button', // ✅
  SETTINGS_LOCATIONS_EDIT_SHARE_BUTTON = 'Settings/Locations: Clicked on Share button', // ✅
  SETTINGS_TEAM_SEARCH_FIELD = 'Settings/Team: Clicked on Search Field', // ✅
  SETTINGS_TEAM_LOCATION_DROPDOWN = 'Settings/Team: Clicked on Location dropdown', // ✅
  SETTINGS_TEAM_CHAT_BUTTON = 'Settings/Team: Clicked on Chat button', // ✅
  SETTINGS_TEAM_EDIT_BUTTON = 'Settings/Team: Clicked on Edit button', // ✅

  // Form
  FORM_TAB_ALL_FORMS = 'Forms: Clicked on All forms tab',
  FORM_TAB_FORM_RESPONSE = 'Forms: Clicked on Form responses tab',
  FORMS_FILL_FROM_FORMS_LIST = 'Forms: Clicked on Fill form',
  FORMS_SEND = 'Forms: Clicked on Send form action',
  FORMS_MARK_INACTIVE = 'Forms: Clicked on Mark as inactive',
  FORMS_APPEAR_IN = 'Forms: Clicked on Appears in...',
  FORMS_DELETE_CLICK = 'Forms: Clicked on Delete',
  FORMS_DELETE_CONFIRM = 'Forms/Delete: Clicked on Form deletion confirmation',
  FORMS_DELETE_EXPORT = 'Forms/Delete: Clicked on Export form response',

  FORMS_RESPONSE_VIEW_TAB_2 = 'Forms: Clicked on View Form Response from Form response tab',
  FORMS_ADD_WHEN_NO_FORM = 'Forms: Clicked on Add form',
  FORMS_CHANGE_TEMPLATE = 'Forms/Create: Clicked on Use existing templates',
  FORMS_TEMPLATE_CHANGED = 'Forms/Create: Form template changed',
  FORMS_RESPONSE_SUBMITTED = 'Forms: Clicked on Submit form response',

  // Form Response
  FORMS_RESPONSE_SHARE = 'Forms/Response: Clicked on Share this form',
  FORMS_RESPONSE_EDIT_FORM = 'Forms/Response: Clicked on Edit form',
  FORM_RESPONSE_TAB_USER_WISE = 'Forms/Response: Clicked on User wise tab',
  FORM_RESPONSE_TAB_QUESTION_WISE = 'Forms/Response: Clicked on Question wise tab',
  FORM_RESPONSE_TAB_SUMMARY = 'Forms/Response: Clicked on Summary tab',

  FORM_RESPONSE_DATE_FILTER = 'Forms/Response: Clicked on form response date filter',
  FORM_RESPONSE_DATE_FILTER_APPLIED = 'Forms/Response: Clicked on Apply date filter',
  FORM_RESPONSE_USER_SPECIF_RESPONSE = 'Forms/Response: Clicked on View specific user response',

  FORMS_ADD_NEW_FORM_BUTTON_TEMPLATE_CLICK = 'Forms: Clicked on Create from template', // ✅
  FORMS_ADD_NEW_FORM_BUTTON_FROM_SCRATCH_CLICK = 'Forms: Clicked on Create from scratch', // ✅
  FORMS_ADD_NEW_FORM_BUTTON_ADD_CONTRIBUTOR_CLICK = 'Forms/Create Form: Clicked on Add Contributor',
  FORMS_ADD_NEW_FORM_BUTTON_PUBLISH_CLICK = 'Forms/Create Form: Clicked on Publish', // ✅
  FORMS_EXISTING_FORM_SEE_RESPONSE_BUTTON_CLICK = 'Forms: Clicked on View Form Response', // ✅
  FORMS_EXISTING_FORM_MODIFY_FORM_BUTTON = 'Forms: Clicked on Edit Form', // ✅
  FORM_RESPONSE_EXPORT = 'Forms: Clicked on Export Form Responses',

  // KNOWLEDGE BASE
  SEARCH_QUERY = 'Knowledge Base: Entered text in Knowledge base search',
  TURN_ON_BULK_GRID_VIEW_ALL_SELECT = 'Knowledge Base: Checked Select All checkbox for Grid View subfolders and chapters',
  TURN_OFF_BULK_GRID_VIEW_ALL_SELECT = 'Knowledge Base: Unchecked Select All checkbox for Grid View subfolders and chapters',
  TURN_ON_BULK_LIST_VIEW_ALL_SELECT = 'Knowledge Base: Checked Select All checkbox for List View subfolders and chapters',
  TURN_OFF_BULK_LIST_VIEW_ALL_SELECT = 'Knowledge Base: Unchecked Select All checkbox for List View subfolders and chapters',
  TURN_ON_SINGLE_GRID_VIEW_SELECT = 'Knowledge Base: Checked checkbox for Grid View item',
  TURN_OFF_SINGLE_GRID_VIEW_SELECT = 'Knowledge Base: Unchecked checkbox for Grid View item',
  TURN_ON_SINGLE_LIST_VIEW_SELECT = 'Knowledge Base: Checked checkbox for List View item',
  TURN_OFF_SINGLE_LIST_VIEW_SELECT = 'Knowledge Base: Unchecked checkbox for List View item',
  BULK_DELETE_CLICK = 'Knowledge Base: Clicked on Bulk Delete button',
  BULK_MOVE_CLICK = 'Knowledge Base: Clicked on Bulk Move button',
  SOP_TYPE_DROPDOWN_CLICK = 'Knowledge Base: Clicked on type dropdown',
  SOP_FILTER_DROPDOWN_CLICK = 'Knowledge Base: Clicked on filter dropdown',
  LIST_VIEW_TYPE = 'Knowledge Base: Clicked on List Type View',
  GRID_VIEW_TYPE = 'Knowledge Base: Clicked on Grid Type View',
  FOLDER_SORT_ASC = 'Knowledge Base: Sort Folders List in ascending order',
  FOLDER_SORT_DESC = 'Knowledge Base: Sort Folders List in descending order',
  CREATE_FOLDER_TOP_BUTTON = 'Knowledge Base: Create folder from button next to All',
  CREATE_FOLDER_BOTTOM_BUTTON = 'Knowledge Base: Create folder from button at the bottom',
  NAVIGATE_ALL_FOLDER_FROM_FOLDER_LIST = 'Knowledge Base: Clicked on All to navigate to initial knowledge base list',
  NAVIGATE_FOLDER_FROM_FOLDER_LIST = 'Knowledge Base: Clicked on folder from folder list to navigate to it',
  OPEN_EDIT_POPOVER_FOLDER = 'Knowledge Base: Clicked on ellipsis to open options for folder in sidebar',
  COPY_LINK_FOLDER = 'Knowledge Base: Clicked on Copy Folder link from options',
  DELETE_FOLDER = 'Knowledge Base: Clicked on Delete Folder from options',
  EDIT_FOLDER = 'Knowledge Base: Clicked on Edit Folder from options',
  VISIBILITY_FOLDER = 'Knowledge Base: Clicked on Visibility for a folder from options',
  NAVIGATE_SUB_FOLDER_FROM_FOLDER_LIST = 'Knowledge Base: Clicked on subfolder from folder list to navigate to it',
  ADD_NEW_CHAPTER_FROM_HEADER = 'Knowledge Base: Clicked on Add new Chapter from Knowledge Base Header',
  ADD_NEW_CHAPTER_FROM_EMPTY_STATE = 'Knowledge Base: Clicked on Add new Chapter from Knowledge Base Empty State',
  CREATE_SUB_FOLDER_FROM_HEADER = 'Knowledge Base: Clicked on Add sub folder from Knowledge Base Header',
  CREATE_SUB_FOLDER_FROM_FOLDER_LIST = 'Knowledge Base: Clicked on Add sub folder from folder list',
  LIST_VIEW_SINGLE_ITEM_ACTION_MENU_CLICK = 'Knowledge Base: Clicked on List view single item action menu',
  GRID_VIEW_SINGLE_ITEM_ACTION_MENU_CLICK = 'Knowledge Base: Clicked on Grid view single item action menu',
  ADD_SUB_FOLDER_FROM_MODAL = 'Knowledge Base: Clicked on Add subfolder from modal to be saved at backend',
  RENAME_SUB_FOLDER_FROM_MODAL = 'Knowledge Base: Clicked on Rename subfolder from modal to be saved at backend',
  EDIT_FOLDER_FROM_MODAL = 'Knowledge Base: Clicked on Edit Folder from modal to be saved at backend',
  UPDATE_FILE_CHAPTER = 'Knowledge Base: Clicked on update chapter (file type chapters) from modal to be saved at backend',
  CREATE_CHAPTER_FROM_EDITOR = 'Knowledge Base: Clicked on Create chapter from chapter editor to be saved at backend',
  UPDATE_CHAPTER_FROM_EDITOR = 'Knowledge Base: Clicked on Save & Publish button from chapter editor to update chapter and save at backend',
  SAVE_PRIVATE_CHAPTER_FROM_EDITOR = 'Knowledge Base: Clicked on Save button to save chapter as private from chapter editor to be saved at backend',
  UPDATE_PRIVATE_CHAPTER_FROM_EDITOR = 'Knowledge Base: Clicked on Save button to update chapter as private from chapter editor to be saved at backend',
  ADD_FOLDER = 'Knowledge Base: Clicked on Add folder from modal to be saved at backend',
  EDIT_FOLDER_BACKEND = 'Knowledge Base: Clicked on Edit folder from modal to be saved at backend',
  MOVE_SINGLE_CHAPTER = 'Knowledge Base: Clicked on Move button to move single chapter to another folder/subfolder to be saved at backend',
  MAKE_SUB_FOLDER_MAIN = 'Knowledge Base: Clicked on Make <SUBFOLDER NAME> a main folder to be saved at backend',
  MOVE_SUBFOLDER = 'Knowledge Base: Clicked on Move button to move subfolder to another subfolder to be saved at backend',
  UPDATE_FOLDER_SUBFOLDER_VISIBILITY = 'Knowledge Base: Clicked on update folder/subfolder visibility to be saved at backend',
  UPDATE_CHAPTER_VISIBILITY = 'Knowledge Base: Clicked on update chapter visibility to be saved at backend',
  OPEN_SUBFOLDER_ON_CLICK = 'Knowledge Base: Clicked on open subfolder',
  OPEN_VIDEO_FILE_ON_CLICK = 'Knowledge Base: Clicked on video file',
  OPEN_CHAPTER_ON_CLICK = 'Knowledge Base: Clicked on chapter view',

  CREATE_CHAPTER = 'Knowledge Base: Clicked on Create Chapter',
  EDIT_CHAPTER = 'Knowledge Base: Clicked on Edit Chapter',
  EDIT_SUB_FOLDER = 'Knowledge Base: Clicked on Edit Sub Folder',
  DELETE_SUB_FOLDER = 'Knowledge Base: Clicked on Delete Sub Folder',
  DELETE_CHAPTER = 'Knowledge Base: Clicked on Delete Chapter',
  ITEM_VISIBILITY = 'Knowledge Base: Clicked on Visibility of item',
  ITEM_LINK_COPY = 'Knowledge Base: Clicked on copy link of item',
  SUB_FOLDER_MOVE = 'Knowledge Base: Clicked on move sub folder',
  CHAPTER_MOVE = 'Knowledge Base: Clicked on move chapter',
  CHAPTER_VIEW_FROM_LIST = 'Knowledge Base: Opened Chapter View from Chapter List',
  CHAPTER_VIEW_FROM_SEARCH = 'Knowledge Base: Opened Chapter View from Search',
  CHAPTER_PREVIEW_BUTTON = 'Knowledge Base: Clicked on Preview button',
  SMART_PAGES_TOGGLE = 'Knowledge Base: Clicked on Smart Pages toggle',

  SLASH_COMMAND = 'Chapter Editor: Opened Slash Command',
  MENU_CLICKED = 'Chapter Editor: Menu clicked',
  WRITE_WITH_AI_MENU_ICON = 'Chapter Editor: Clicked on Write with AI menu icon',
  WRITE_WITH_AI_SAMPLE_PROMPT = "Chapter Editor: Clicked on Write with AI sample prompt's option",
  WRITE_WITH_AI_USE_THIS = "Chapter Editor: Clicked on Write with AI 'Use this' button",
  WRITE_WITH_AI_DISMISS_MODAL = "Chapter Editor: Clicked on Write with AI 'Close' button",
  TRAINING_ADD_KNOWLEDGE_BASE = "Training: Clicked on 'Add button' from Knowledge Base",
  TRAINING_CREATE_NEW_CHAPTER = "Training: Clicked on 'Create New Chapter' button",
  TRAINING_ADD_POWER_UP = "Training: Clicked on 'Add Power Up' button",
  TRAINING_PREVIEW_BUTTON = "Training: Clicked on 'Preview' button",
  TRAINING_PLAY_BUTTON = "Training: Clicked on 'Play' button",

  NAVIGATE_ROUTE = 'Sidebar: Navigate from sidebar',

  LOCATION_ADD_OWNER_MENU = "Location: Clicked on 'Add new owner' menu button",
  LOCATION_ADD_OWNER_INIT = 'Location: Adding owner to location initiated',
  LOCATION_ADD_OWNER_SUCCESS = 'Location: Owner added to location',
  LOCATION_INVITE_NEW_OWNER = "Location: Clicked on 'Invite new owner'",

  // Tasks
  TASK_CREATE = 'Task: Clicked on Create Task',
  TASK_EDIT = 'Task: Clicked on Edit task',
  TASK_PUBLISH = 'Task: Clicked on Publish task',
  TASK_PUBLISHED = 'Task/Create: Task publish confirmed',
  // TASK_UPDATE = 'Task: Clicked on Update Task',
  TASK_UPDATED = 'Task/Edit: Task update confirmed',
  TASK_TERMINATE = 'Task/Edit: Clicked on Task Terminate',
  TASK_DELETE = 'Task/Edit: Clicked on Task Delete',
  TASK_RESTART = 'Task/Edit: Clicked on Task Restart',

  TASK_SUPERVISED_DETAILS = 'Task: Clicked on Task Supervised details',

  // Launcher
  LAUNCHER_ADD_LOCATION_HOVER = "Launcher: Hovered on 'Add new location' button",
  LAUNCHER_ADD_LOCATION = "Launcher: Clicked on 'Location with launcher' button",
  LAUNCHER_ADD_LOCATION_WITHOUT_CONFIG = "Launcher: Clicked on 'Location without launcher' button",

  LAUNCHER_LOCATION_DETAILS = "Launcher: Clicked on 'Location in-progress' item",

  // Members
  ADD_NEW_MEMBER = 'Member: Clicked on Add new member button',
  MEMBER_CHAT_BUTTON = 'Member: Clicked on Send Message from table action item',
  MEMBER_REINVITE_BUTTON = 'Member: Clicked on Re Invite from table action item',
  MEMBER_OPEN = 'Member: Clicked on open member details page',
  MEMBER_EDIT = 'Member: Clicked on edit member',
  RESEND_INVITE_BUTTON = 'Member: Clicked on Resend invite button from resend invite side drawer',
  DELETE_MEMBER = 'Member: Clicked on Delete button from resend invite side drawer',
  MEMBER_UPDATE = 'Member: Update member details from members details page',
  RESEND_INVITE_BUTTON_FROM_DETAILS_PAGE = 'Member: Clicked on Resend invite button from members details page for pending user',

  // Invite
  INVITE_AND_SET_UP_NOW = 'Invite: Clicked on Invite and set up now button',
  INVITE_AND_SET_UP_LATER = 'Invite: Clicked on Invite and set up later button',
  INVITE_ADD_ANOTHER_MEMBER = 'Invite: Clicked on Add another member button',
  INVITE_ADD_TO_TRAININGS = 'Invite: Clicked on Add user to trainings button',
  INVITE_FINISH = 'Invite: Clicked on Finish button',
  INVITE_FINISH_INVITE_ANOTHER = 'Invite: Clicked on Finish & invite another member',

  // Job
  JOB_CREATE_BUTTON = 'Jobs: Clicked on Add new job from header',
  JOB_ADD_BUTTON = 'Jobs: Clicked on Add new job from modal to be saved to backend',
  JOB_EDIT_BUTTON = 'Jobs: Clicked on Edit new job from modal to be saved to backend',

  // Locations
  LOCATION_ADD_NEW_LOCATION = 'Location: Clicked on Add new location from locations listing page header',
  LOCATION_ADD_BUTTON = 'Location: Clicked on Add location from Add new location page to be saved at backend',
  LOCATION_EDIT_ACTION_BUTTON = "Location: Clicked on edit location from location listing page's action menu edit button",
  LOCATION_UPDATE_BUTTON = 'Location: Clicked on update button from location edit page to be updated at backend',
  LOCATION_DELETE_ANYWAY_BUTTON = 'Location: Clicked on delete anyway location from modal',

  LOCATION_CONV_LOG_ADD_BUTTON = 'Location: Clicked on Add Conversation log button',
  LOCATION_CONV_CREATED = 'Location: New Conversation log is added',
  LOCATION_CONV_VIEW = 'Location: Clicked on View Conversation log',
  LOCATION_CONV_EDIT_BUTTON = 'Location: Clicked on Edit Conversation log button',
  LOCATION_CONV_EDITED = 'Location: Conversation log is edited',
  LOCATION_CONV_DELETE_BUTTON = 'Location: Clicked on Delete Conversation log button',
  LOCATION_CONV_DELETED = 'Location: Conversation log is deleted',
  LOCATION_CONV_PIN_BUTTON = 'Location: Clicked on Pin/Unpin Conversation log button',
}

export type IAmplitudeEventNamesEntity =
  | AmplitudeEventNames
  | `${AmplitudeEventNames.MENU_CLICKED} ${IMenuType}`
  | `${AmplitudeEventNames.NAVIGATE_ROUTE}: ${string}`;

export type IAmplitudeEventNames = [];
