import React from 'react';
import { forwardRef, Text, TextProps } from '@chakra-ui/react';

type ICommonLabel = TextProps & {
  label: string;
};

/** Custom Text wrapper based on Chakra UI Text Props
 * Default values are
 * - fontWeight: 400
 * - fontSize: 15px
 * - color: #6F767E
 */
const CommonLabel = forwardRef<ICommonLabel, 'p'>((props, ref) => {
  const {
    label,
    fontWeight = 400,
    fontSize = '15px',
    color = '#6F767E',
    ...rest
  } = props;
  return (
    <Text
      ref={ref}
      fontWeight={fontWeight}
      fontSize={fontSize}
      color={color}
      {...rest}
    >
      {label}
    </Text>
  );
});

export default CommonLabel;
