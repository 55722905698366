import React, { FC, useState } from 'react';
import { Flex, Spacer, useToast } from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '../../atoms';
import { ActionButton } from '../Confirm';
import { IFormInput } from './invite.types';

const INVITE_USER_REQUEST_UPDATE = gql`
  mutation InviteUserRequestUpdate($input: InviteUserUpdateInputInput) {
    InviteUserRequestUpdate(input: $input) {
      succeed
    }
  }
`;

interface IProps {
  reInviteUser?: boolean;
  closeHandler: () => void;
  onBackClick: () => void;
}

const InviteFinishButton: FC<IProps> = ({
  reInviteUser,
  closeHandler,
  onBackClick,
}) => {
  const { t } = useTranslation(['common', 'invite']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const [addAnother, setAddAnother] = useState(false);

  const { getValues, reset } = useFormContext<IFormInput>();

  const [updateUserSelections, { loading: updatingSelections }] = useMutation(
    INVITE_USER_REQUEST_UPDATE,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('common:success'),
          // @ts-ignore
          description: t('invite:inviteDetailSuccess', {
            value: getValues('name'),
          }),
        });
        if (addAnother) {
          reset();
        } else {
          closeHandler();
        }
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('common:error'),
          description: t('invite:inviteDetailError'),
        });
      },
    }
  );

  const onFinish = async () => {
    let trainings = getValues('selectedTrainingPaths');
    let userId = getValues('invitedUserId');
    let input = { trainings, userId };
    await updateUserSelections({
      variables: {
        input,
      },
    });
  };

  return (
    <Flex w='full'>
      {!reInviteUser && (
        <ActionButton
          variant='outline'
          width='fit-content'
          actionFn={() => {
            setAddAnother(true);
            return onFinish();
          }}
        >
          {t('invite:finishAndInviteAnotherMember')}
        </ActionButton>
      )}
      <Spacer />
      <Flex gap='10px'>
        <PrimaryButton
          title={t('invite:goBack')}
          variant='outline'
          width='fit-content'
          disabled={updatingSelections}
          onClick={onBackClick}
        />
        <ActionButton
          variant='solid'
          colorScheme='blue'
          width='fit-content'
          minW='100px'
          actionFn={onFinish}
        >
          {t('common:finish')}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default InviteFinishButton;
