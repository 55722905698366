import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { FormInput } from 'atoms';
import React, { ChangeEvent, FC } from 'react';
import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TrainingPathIconsModal from 'sub-components/TrainingPathIconsModal';
import { ISelectedIcon } from '.';
import TrainingPathIcon from '../../assets/images/training-path-icon.png';

interface IProps {
  trainingIcons: any;
  trainingIconsLoading: boolean;
  selectedIcon: ISelectedIcon;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  trainingData: any;
  checkChangesHandler: () => void;
  register: UseFormRegister<FieldValues>;
  selectedIconHandler: (icon: any) => void;
  searchedStringHandler: (search: string) => void;
  setTrainingData: React.Dispatch<any>;
}

const EditTrainingDetails: FC<IProps> = ({
  trainingIcons,
  trainingIconsLoading,
  selectedIcon,
  errors,
  trainingData,
  checkChangesHandler,
  register,
  selectedIconHandler,
  searchedStringHandler,
  setTrainingData,
}) => {
  const { t } = useTranslation(['training', 'common']);
  return (
    <div id='edit-training-details' className='cards-list-main-div'>
      <div className='cards-list-details'>
        <div className='cards-icon-modal-main-div'>
          <TrainingPathIconsModal
            iconsList={trainingIcons}
            isLoading={trainingIconsLoading}
            selectedIcon={selectedIconHandler}
            searchedString={(search) => searchedStringHandler(search)}
          >
            <img
              src={TrainingPathIcon}
              style={{
                position: 'absolute',
                zIndex: '1',
                left: '35px',
                top: '-12px',
                cursor: 'pointer',
                width: 'unset',
              }}
            />
          </TrainingPathIconsModal>
          {selectedIcon && <img src={selectedIcon?.thumbnail} />}
        </div>
      </div>
      <Flex pos='relative' width='100%'>
        <FormControl isInvalid={!!errors?.name}>
          <FormInput
            id='name'
            placeholder=''
            size='lg'
            {...register('name', {
              required: t('common:validation.name_required'),
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                setTrainingData({
                  ...trainingData,
                  title: e.target.value?.trimStart(),
                });
                checkChangesHandler();
              },
            })}
          />
          <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
        </FormControl>
      </Flex>
    </div>
  );
};

EditTrainingDetails.displayName = 'EditTraining/EditTrainingDetails';

export default EditTrainingDetails;
