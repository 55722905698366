import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { usePhaseDelete } from '../common';
import { IContentItem, IFormInput } from '../editor-form/editor.types';
import { EditorEvent, useEditorContext } from '../editor-form';

import AddNewList from '../board-view/AddNewList';

import { LauncherConfigLoading } from './ConfigLoading';
import TaskSection from './TaskSection';
import PublishButton from './PublishButton';
import Header from './Header';

interface IProps {}

const LauncherTaskContainer: FC<IProps> = () => {
  const history = useHistory();

  const { emit, initialising } = useEditorContext();
  const phaseDelete = usePhaseDelete();

  const { control, getValues } = useFormContext<IFormInput>();

  const { fields, append, move, remove } = useFieldArray<
    IFormInput,
    'contents'
  >({
    control: control,
    name: 'contents',
  });

  const addNewSection = async (title: string) => {
    const res = await emit<string, [IContentItem, number]>(
      EditorEvent.ADD_PHASE,
      title
    );
    if (res?.[0]) {
      append(res[0]);
    }
  };

  const omMoveClick = async (currentIndex: number, nextIndex: number) => {
    try {
      move(currentIndex, nextIndex);
      await emit(EditorEvent.SAVE_CHECKLIST);
    } catch (e) {
      move(nextIndex, currentIndex);
    }
  };

  const onDeleteClick = (index: number) => {
    phaseDelete({
      category: getValues(`contents.${index}.category`),
      onDeletePress: async () => {
        const res = await emit(EditorEvent.DELETE_PHASE, index);
        if (res) {
          remove(index);
        }
      },
    });
  };

  const onViewModeChange = async () => {
    if (!getValues('eid')) {
      await emit(EditorEvent.SAVE_CHECKLIST);
    }
    history.replace('?viewMode=editor');
  };

  return (
    <React.Fragment>
      <Header onViewModeChange={onViewModeChange} />

      <Flex flex={1} align='start' gap={8} overflow='auto' pb={4}>
        <LauncherConfigLoading isLoaded={!initialising}>
          {fields.map((item, index, arr) => {
            return (
              <TaskSection
                key={item.id}
                sectionIndex={index}
                isLastSection={arr.length === index + 1}
                moveSectionTo={omMoveClick}
                onPhaseDeleteClick={onDeleteClick}
              />
            );
          })}

          <AddNewList addNewSection={addNewSection} />
        </LauncherConfigLoading>
      </Flex>

      <PublishButton />
    </React.Fragment>
  );
};

export default LauncherTaskContainer;
