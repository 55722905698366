import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  OpenStepHeader,
  SaveNextButton,
  useStepperIsOpen,
} from '../common';
import SchedulerStepper from './SchedulerStepper';
import SchedulerForm from './SchedulerForm';
import { useFormContext } from 'react-hook-form';
import { IFormInput } from '../task.types';

interface IProps {
  isEdit?: boolean;
}

const Scheduler: FC<IProps> = ({ isEdit }) => {
  const { t } = useTranslation(['task']);
  const isOpen = useStepperIsOpen('scheduler');
  const { handleSubmit, setValue } = useFormContext<IFormInput>();

  const saveAndNext = () => {
    setValue('completed.scheduler', true);
    setValue('filled.scheduler', true);
    setValue('currentStep', 'taskSteps');
  };

  if (!isOpen) {
    return <SchedulerStepper />;
  }

  return (
    <Container flexDir='column' p='20px'>
      <OpenStepHeader title={t('task:schedule')} />

      <SchedulerForm isEdit={isEdit} />

      <SaveNextButton onClick={handleSubmit(saveAndNext)} />
    </Container>
  );
};

export default Scheduler;
