import React, { FC, useMemo } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LocationFieldEntity } from 'sop-commons/src/client';

import { PrimaryButton } from 'atoms';
import { useUserData } from 'hooks';
import { BoxHeader } from 'ui-components';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';

import { IFormInput, OtherFieldInput } from '../AddLocation/add-location.types';
import OtherInputField from './OtherInputField';
import { useAddCustomField } from './AddCustomField';
import DeletedField from './DeletedField';

const ResponseKey: Record<
  LocationFieldEntity['fieldType'],
  keyof OtherFieldInput
> = {
  text: 'value',
  date: 'value',
  document: 'files',
  dropDown: 'options',
  singleChoice: 'value',
};

interface IProps {
  isDisabled?: boolean;
}

const OtherDetails: FC<IProps> = ({ isDisabled }) => {
  const { t } = useTranslation(['location']);
  const { control, getValues } = useFormContext<IFormInput>();

  const addCustomField = useAddCustomField();

  const entities = useUserData()?.entity?.locationFields || [];

  const { fields, append } = useFieldArray<IFormInput, 'otherFields'>({
    name: 'otherFields',
  });

  const fieldEntities = useMemo(() => {
    return entities.reduce<Record<string, LocationFieldEntity>>(
      (acc, entity) => {
        acc[entity.eid] = entity;
        return acc;
      },
      {}
    );
  }, [entities]);

  const onAddClick = () => {
    addCustomField({
      availableField: getValues('otherFields').map((val) => val.fieldId),
      onFieldAdded: (newField) => {
        append(newField);
      },
    });
  };

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <Flex align='center' justify='space-between'>
        <BoxHeader
          fontSize='18px'
          color='#FFD88D'
          title={t('location:otherDetails')}
        />
        {!isDisabled && (
          <PrimaryButton
            width='auto'
            variant='ghost'
            size='sm'
            colorScheme='blue'
            leftIcon={<AddIcon />}
            title={t('location:addMoreFields')}
            style={{
              fontSize: '12px',
            }}
            onClick={onAddClick}
          />
        )}
      </Flex>

      <Grid templateColumns='repeat(2, 1fr)' gap='20px'>
        {fields.map((value, index) => {
          const entity = fieldEntities[value.fieldId];

          if (!entity) {
            return <DeletedField key={value.id} {...value} />;
          }

          return (
            <Box key={value.id}>
              <TitleHeader
                title={entity.fieldName}
                isRequired={entity.validations?.isRequired}
              />

              <Controller
                control={control}
                name={`otherFields.${index}.${ResponseKey[entity.fieldType]}`}
                rules={{
                  required: {
                    value: !!entity.validations?.isRequired && !isDisabled,
                    message: `${entity.fieldName} is required`,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <FormControl
                      isDisabled={isDisabled}
                      mt={2}
                      isInvalid={!!fieldState.error}
                    >
                      <OtherInputField
                        fieldType={entity.fieldType}
                        options={entity.options}
                        {...field}
                      />
                      <FormErrorMessage>
                        <span>{fieldState.error?.message}</span>
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              />
            </Box>
          );
        })}
      </Grid>
    </Flex>
  );
};

export default OtherDetails;
