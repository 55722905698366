import React, {
  BaseSyntheticEvent,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { SelectOption } from '../../atoms';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Spacer,
  Switch,
  useToast,
} from '@chakra-ui/react';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';

// import scss
import './CardEditor.scss';
import TextCardEditor from './TextCardEditor';
import ImageCardEditor from './ImageCardEditor/ImageCardEditor';
import TitleHeader from './TitleHeader';
import ActionCardEditor from './ActionCardEditor';
import QuizCardEditor from './quiz-card/QuizCardEditor';
import VideoCardEditor from './video-card/VideoCardEditor';
import FormCardEditor from './FormCardEditor/FormCardEditor';
import MixedCardEditor from './MixedCardEditor';
import { userObj } from 'sop-commons/src/client';
import CardEditorFooter from './CardEditorFooter';
import {
  CREATE_CARD_MUTATION,
  UPDATE_CARD_MUTATION,
  UPDATE_TRANS_CARD_MUTATION,
  UPDATED_CARD_DATA,
} from './card-editor.graphql';
import {
  CardDefaultValues,
  CardFormInput,
  CardInputData,
  CardTypeOption,
} from './card-editor.types';
import CardTypeSelect from './CardTypeSelect';
import IsHandbookCheckbox from './IsHandbookCheckbox';
import { contentDataConvert } from './jsonDataFormatter';
import {
  useCardResetInitial,
  useLayoutDisabled,
  useTranslatedCardUpdate,
} from './hook';
import { CardType } from '../../types';
import CustomFormEditor from './CustomFormEditor/CustomFormEditor';
import CategorySelect from './CategorySelect';
import { isContentChanged } from './isContentChanged';
import { localizationEnabled } from '../../utils/constants';
import { IPowerUpModalTypes } from 'pages/Training/PowerUpModal/power-up.types';
import MilestoneEditor from './MilestoneEditor';
import {
  CreateFormComponent,
  FormCreationRef,
} from 'sub-components/forms/create-form';
import {
  FormEditResponse,
  getFormEditData,
} from 'sub-components/forms/form.graphql';
import { toEditableForm } from 'sub-components/forms/dataConvert';

interface IProps {
  entityId: string;

  isEdit?: boolean;
  cardId?: string;
  onClose?: () => void;
  onSubmit?: (...args: any) => void;

  isDeckView?: boolean;
  stableCardData?: any;
  isHide?: boolean;

  returnedCardData?: (...args: any[]) => void;
  otherDefaultCardInitialData?: object;

  disabledLayout?: CardType[];
  onCreatePress?: () => void;
  fromTraining?: boolean;
  passedCardType?: IPowerUpModalTypes['types'];
  isReadOnly?: boolean;
}

const EditFormContainer: FC<{ formId: string }> = ({ formId }) => {
  const { t } = useTranslation('form');
  const formRef = useRef<FormCreationRef>(null);

  useEffect(() => {
    getFormEditDataFn();
  }, [formId]);

  const [getFormEditDataHandler, { loading }] = useLazyQuery<FormEditResponse>(
    getFormEditData,
    {
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        const editableForm = toEditableForm(response.FormById);
        formRef.current?.initForm(editableForm, true, true);
      },
    }
  );

  const getFormEditDataFn = () => {
    getFormEditDataHandler({
      variables: {
        eid: formId,
      },
    });
  };

  return (
    <CreateFormComponent
      ref={formRef}
      showInitialize={loading}
      isEdit
      title={t('update_form')}
    />
  );
};

const CardEditor: FC<IProps> = ({
  entityId,
  isEdit,
  cardId,
  onClose,
  onSubmit,
  isDeckView,
  stableCardData,
  returnedCardData,
  otherDefaultCardInitialData,
  disabledLayout,
  isHide,
  onCreatePress,
  fromTraining,
  passedCardType,
  isReadOnly,
}) => {
  const apolloClient = useApolloClient();
  const { t, i18n } = useTranslation(['common', 'card']);
  const creatingRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const categoryEntities = useReactiveVar(userObj)?.entity?.category || [];
  const { handleSubmit, reset, control, getValues, setValue, watch } =
    useFormContext<CardFormInput>();

  const getInitialData = useCardResetInitial(getValues);
  const translatedCardUpdate = useTranslatedCardUpdate();

  const shouldShowFooter = useWatch({
    name: 'shouldShowFooter',
    defaultValue: true,
  });
  const formLayout: CardTypeOption | undefined = useWatch<CardFormInput>({
    control: control,
    name: 'cardType',
  });
  const isDisabled = useLayoutDisabled();

  useEffect(() => {
    if (isReadOnly) {
      setValue('shouldShowFooter', false);
      setValue('isReadOnly', isReadOnly);
    }
  }, [isReadOnly]);

  const [createCard] = useMutation(CREATE_CARD_MUTATION);
  const [updateCard] = useMutation(UPDATE_CARD_MUTATION);
  const [updateTranslatedCard] = useMutation(UPDATE_TRANS_CARD_MUTATION);

  useEffect(() => {
    console.log('PASSED CARD TYPE : ', passedCardType);
    if (passedCardType === 'action') {
      setValue('cardType', {
        label: 'Action',
        transKey: 'card:cardType.action',
        value: CardType.Action,
      });
    }
    if (passedCardType === 'quiz') {
      setValue('cardType', {
        label: 'Quiz',
        transKey: 'card:cardType.quiz',
        value: CardType.Quiz,
      });
    }
    if (passedCardType === 'video') {
      setValue('cardType', {
        label: 'Video',
        transKey: 'card:cardType.video',
        value: CardType.Video,
      });
    }
    if (passedCardType === 'form') {
      setValue('cardType', {
        label: 'Form',
        transKey: 'card:cardType.form',
        value: CardType.CustomForm,
      });
    }
    if (passedCardType === 'milestone') {
      setValue('cardType', {
        label: 'Milestone',
        transKey: 'card:cardType.milestone',
        value: CardType.Milestone,
      });
    }
  }, []);

  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });

  const updateLoadingState = (isSave: boolean, status: boolean) => {
    isSave ? setIsSaving(status) : setIsLoading(status);
  };

  const _updateCard = async (
    inputData: CardInputData,
    values: CardFormInput
  ) => {
    const language = values.language;
    const defaultLanguage = values.defaultLanguage;

    if (language && language !== defaultLanguage && localizationEnabled) {
      await updateTranslatedCard({
        variables: {
          input: {
            content: inputData.content,
            eid: inputData.eid,
            languageCode: language,
            subTitle: inputData.subTitle,
            raw_content: inputData.raw_content,
            title: inputData.title,
          },
        },
      });
      return await apolloClient
        .query({
          fetchPolicy: 'network-only',
          query: UPDATED_CARD_DATA,
          variables: {
            eid: inputData.eid,
          },
        })
        .then((response) => response?.data?.findCardById);
    }
    const updateCardData = await updateCard({
      variables: {
        input: {
          ...inputData,
        },
      },
    }).then((response) => response?.data?.updateCard);
    if (isContentChanged(values, inputData.content)) {
      return await translatedCardUpdate(updateCardData, inputData);
    } else {
      return updateCardData;
    }
  };

  const onCreateOrEditCard = (values: CardFormInput, isAddAnother: boolean) => {
    if (creatingRef.current) {
      return;
    }

    creatingRef.current = true;

    values?.cardType?.value !== 'milestone' &&
      updateLoadingState(isAddAnother, true);

    const contentData = contentDataConvert(values.cardType.value, values);

    const readyForProcess =
      values?.cardType?.value === 'video'
        ? values?.cardType?.value && contentData?.raw_content
        : values?.cardType?.value &&
          entityId &&
          values?.layout &&
          contentData?.raw_content;

    if (readyForProcess) {
      const inputData: CardInputData = {
        cardType: values.cardType?.value,
        entityId: entityId || null,
        backgroundColor: values.backgroundColor || '#ffffff',
        layout: values.layout,
        content: contentData?.content,
        raw_content: contentData?.raw_content,
        subTitle: values?.subTitle || '',
        thumbnail: contentData?.thumbnail || '',
        title: values?.title,
        handbookView: values.handbookView,
      };
      if (!isDeckView) {
        inputData.handbookView = true;
      }
      if (values?.category?.value) {
        inputData.category = values?.category?.value;
      }
      if (values?.cardType?.value === CardType.CustomForm) {
        inputData.formId = values.form?.eid;
        inputData.title = values.form?.title;
      }
      if (isEdit) {
        // @ts-ignore
        delete inputData.entityId;
        inputData.eid = cardId;

        inputData.status = values?.status;
      }
      console.log('INPUT DATA : ', inputData);
      returnedCardData && returnedCardData(inputData);
      if (values?.cardType?.value === 'milestone') {
        creatingRef.current = false;
        if (!isAddAnother) {
          onClose?.();
          return;
        } else {
          const newData = getInitialData({
            cardType: {
              label: 'Milestone',
              transKey: 'card:cardType.milestone',
              value: 'milestone',
            },
            status: undefined,
            category: undefined,
          });
          reset(newData);
          return;
        }
      }
      if (isEdit) {
        _updateCard(inputData, values)
          .then((updateCard) => {
            reset({ ...CardDefaultValues });
            toast({
              status: 'success',
              title: t('card:card_success_update'),
            });
            updateLoadingState(isAddAnother, false);
            creatingRef.current = false;
            if (onSubmit) {
              onSubmit(updateCard);
              onClose?.();
            }
          })
          .catch((error) => {
            creatingRef.current = false;
            updateLoadingState(isAddAnother, false);
            console.log('Error in updating card', error);
            toast({
              status: 'error',
              title: t('card:card_error_update'),
            });
          });
      } else {
        createCard({
          variables: {
            input: {
              ...inputData,
              defaultLanguage: i18n.language,
            },
          },
        })
          .then((response) => {
            if (returnedCardData) {
              returnedCardData(response);
            }

            const newData = getInitialData({
              cardType: getValues('cardType'),
              status: undefined,
              category: getValues('category'),
            });
            setTimeout(() => reset(newData));
            toast({
              status: 'success',
              title: t('card:card_success_create'),
            });

            updateLoadingState(isAddAnother, false);
            creatingRef.current = false;
            if (onSubmit) {
              onSubmit(response?.data?.createCard);
            }
            if (!isAddAnother) {
              onClose?.();
            }
          })
          .catch((error) => {
            console.log('Error in creating card', error);
            creatingRef.current = false;
            updateLoadingState(isAddAnother, false);
            toast({
              status: 'error',
              title: t('card:card_error_create'),
            });
          });
      }
    } else {
      updateLoadingState(isAddAnother, false);
      creatingRef.current = false;
    }
  };

  const hideCategory =
    isHide || (fromTraining && formLayout?.value === CardType.Quiz);

  const onFinish = (values: CardFormInput, event?: BaseSyntheticEvent) => {
    const submitName = (event?.nativeEvent as any)?.submitter?.name;
    onCreateOrEditCard(values as CardFormInput, submitName === 'addAnother');
  };

  const categoryList = useMemo(() => {
    const categoryList: SelectOption[] = [];
    categoryEntities?.forEach((item) => {
      categoryList.push({
        label: item?.name,
        value: item?.name,
      });
    });
    return categoryList;
  }, [categoryEntities]);

  return (
    <form
      className='card-edit-form-container'
      onSubmit={handleSubmit(onFinish)}
    >
      <div>
        <Flex align='center'>
          {/* <div style={{ flex: '1 1 auto' }}>
            <TitleHeader
              title={t('card:cart_type')}
              desc={t('card:cart_type_desc')}
              isRequired
            />
          </div> */}

          {/* <Controller
            name={'status'}
            render={({ field }) => {
              if (!isEdit) {
                return <span />;
              }
              return (
                <div className='switch-cover'>
                  <p>{t('active')}</p>
                  <Switch
                    size='lg'
                    isChecked={field.value === 'active'}
                    onChange={({ target }) => {
                      field.onChange(target.checked ? 'active' : 'inactive');
                    }}
                  />
                </div>
              );
            }}
          /> */}
        </Flex>

        {/* <Controller
          name={'cardType'}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                mt={3}
                isInvalid={!!fieldState.error}
                isDisabled={
                  isDisabled ||
                  !!otherDefaultCardInitialData ||
                  disabledLayout?.includes(formLayout?.value as CardType)
                }
              >
                <CardTypeSelect
                  isEdit={isEdit}
                  selectedCardType={field.value}
                  isDisabled={
                    isDisabled ||
                    !!otherDefaultCardInitialData ||
                    disabledLayout?.includes(formLayout?.value as CardType)
                  }
                  stableCardType={stableCardData?.cardType}
                  onChangeCardType={(value) => {
                    console.log('ON CHANGE DROPDOWN VALUE : ', value)
                    field.onChange(value);
                  }}
                  // setStableDataToCard={() => setCardData(stableCardData)}
                />
              </FormControl>
            );
          }}
        /> */}

        {formLayout?.value === CardType.Text && <TextCardEditor />}

        {formLayout?.value === CardType.Image && <ImageCardEditor />}

        {formLayout?.value === CardType.Action && <ActionCardEditor />}

        {formLayout?.value === CardType.Quiz && <QuizCardEditor />}

        {formLayout?.value === CardType.Mixed && <MixedCardEditor />}

        {formLayout?.value === CardType.Video && <VideoCardEditor />}

        {formLayout?.value === CardType.Form && <FormCardEditor />}

        {formLayout?.value === CardType.CustomForm && !isReadOnly && (
          <CustomFormEditor />
        )}

        {formLayout?.value === CardType.CustomForm && isReadOnly && (
          <EditFormContainer formId={stableCardData?.form?.eid} />
        )}

        {formLayout?.value === CardType.Milestone && <MilestoneEditor />}

        {!hideCategory && (
          <Box mt={9}>
            <TitleHeader
              title={t('card:folder')} //
              desc={t('card:folder_desc')}
              isRequired
            />

            <Controller
              name={'category'}
              control={control}
              rules={{
                required: t('card:validation.category_required'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={3} isInvalid={!!fieldState.error}>
                    <CategorySelect
                      options={categoryList}
                      placeholder=''
                      value={field.value}
                      onChange={(newValue) => field.onChange(newValue)}
                      isDisabled={isDeckView}
                      onCreatePress={onCreatePress}
                    />
                    <FormErrorMessage>
                      <span>{fieldState?.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        )}
        <IsHandbookCheckbox isDeckView={isDeckView} control={control} />
      </div>
      <Spacer />
      {shouldShowFooter && (
        <CardEditorFooter
          isEdit={isEdit}
          isLoading={isLoading}
          isSaving={isSaving}
          onCancel={onClose}
        />
      )}
    </form>
  );
};

export default CardEditor;

CardEditor.displayName = 'SubComponents/CardEditor';
