import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, Center, Flex } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { useUserData } from '../../../hooks';

import DashboardContainer from '../../../sub-components/DashboardContainer';
import TeamHeader from '../TeamHeader';
import { ProfileWrapper } from '../../Profile/profile.styles';
import ProfileTabs from '../../Profile/ProfileTabs';
import {
  CertificateComponent,
  InfoComponent,
  MyActivityComponent,
  TrainingComponent,
} from '../../Profile/TabComponents';
import UpdateMember from './UpdateMember';
import eventBus from '../locations/AddLocationModal/eventBus';
import InviteUserModal from 'ui-components/InviteUserNew/InviteUserModal';
import MemberStatus from '../members/component/MemberStatus';
import { PendingUserChatGroups, PendingUserTrainings } from './components';
import { UserDataResponse } from '../../Profile/TabComponents/InfoComponent/info.graphql';
import { useTabPermission } from './useTabPermission';

interface IProps {}

const MemberDetails: FC<IProps> = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation(['common', 'profile', 'team']);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const authUser = useUserData();
  const userId = useParams<{ memberId: string }>()?.memberId;
  const locationState = useLocation<{ name: string }>()?.state;
  // return <Profile memberId={params.memberId} />;

  useEffect(() => {
    eventBus.on('openAddMemberModal', locationModalHandler);
    return () => {
      eventBus.removeListener('openAddMemberModal', locationModalHandler);
    };
  }, []);

  const locationModalHandler = () => {
    setShowInviteModal(true);
  };

  const [data, setData] = useState<UserDataResponse['userById']>();

  const username = data?.name || locationState?.name || '- - -';
  const userStatus = data?.status;

  const infoTabOnly = useTabPermission(data);

  const tabData = useMemo(() => {
    const list: Array<{ label: string; content: React.ReactElement }> = [];

    if (authUser.eid === userId) {
      list.push({
        label: t('profile:info'),
        content: (
          <InfoComponent
            userId={userId}
            onUserDataFetched={setData}
            disableLocation
          />
        ),
      });
    } else {
      list.push({
        label: t('profile:info'),
        content: <UpdateMember userId={userId} onUserDataFetched={setData} />,
      });
    }

    if (infoTabOnly) {
      return list;
    }

    if (userStatus) {
      switch (userStatus) {
        case 'pending':
          return list.concat([
            {
              label: t('common:trainings'),
              content: (
                <PendingUserTrainings userId={userId} username={username} />
              ),
            },
            {
              label: t('team:chatGroups'),
              content: (
                <PendingUserChatGroups userId={userId} username={username} />
              ),
            },
          ]);
        default:
          return list.concat([
            {
              label: t('common:trainings'),
              content: (
                <TrainingComponent userId={userId} username={username} />
              ),
            },
            {
              label: t('profile:certificates'),
              content: <CertificateComponent userId={userId} />,
            },
            {
              label: t('profile:activity'),
              content: <MyActivityComponent userId={userId} />,
            },
          ]);
      }
    }

    return list;
  }, [
    t,
    i18n.language,
    userId,
    username,
    authUser.eid,
    authUser.type,
    authUser.authRole,
    userStatus,
    infoTabOnly,
  ]);

  const goBack = () => history.replace('/teams/members');

  const onActionCompleted = (pathName: string, tabSelected: string) => {
    setTimeout(() => {
      history.push({ pathname: pathName, state: { tabSelected } });
    }, 5000);
  };

  return (
    <DashboardContainer>
      <TeamHeader />
      <ProfileWrapper>
        <Flex gap={2} mb='16px'>
          <Center height='32px' onClick={history.goBack} cursor='pointer'>
            <ArrowBackIcon color='#272B30' boxSize='22px' />
          </Center>
          <Box>
            <Flex align='center' gap={2} h='32px'>
              <Box fontSize='18px' fontWeight='600' color='#1A1D1F'>
                {username}
              </Box>
              {userStatus === 'pending' && (
                <MemberStatus status={userStatus} mb='unset' />
              )}
            </Flex>

            <Box
              fontSize='15px'
              fontWeight='400'
              color='#6F767E'
              cursor='pointer'
              onClick={goBack}
            >
              {t('team:backToMember')}
            </Box>
          </Box>
        </Flex>
        <ProfileTabs data={tabData} />
        <InviteUserModal
          isOpen={showInviteModal}
          onClose={() => setShowInviteModal(false)}
          onActionCompleted={onActionCompleted}
        />
      </ProfileWrapper>
    </DashboardContainer>
  );
};

export default MemberDetails;
