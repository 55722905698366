import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  makeVar,
  split,
  from,
  defaultDataIdFromObject,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwt_decode from "jwt-decode";
import { UserData } from "./user-data.types";
import { match, P } from "ts-pattern";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

export const isJwtValid = (token: string) => {
  if (token) {
    const decodedToken: any = jwt_decode(token);
    if (decodedToken?.exp < (new Date().getTime() + 1) / 1000) {
      return false;
    }
    return true;
  }
  return false;
};
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("authToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    },
  };
});
const token: any = localStorage.getItem("authToken");
const refreshToken: any = localStorage.getItem("refreshAuthToken");
let wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_SOCKET_ENDPOINT}`,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: token ? `Bearer ${token}` : "",
    },
    lazy: true,
  },
});

export const userObj = makeVar<UserData>({} as UserData);
export const chatStatus = makeVar<boolean>(false);
export type { UserData };

export const authTokenVar = makeVar(localStorage.getItem("authToken"));
export const refreshTokenVar = makeVar(localStorage.getItem("authToken"));

const isTokenExpired = (token: string): Boolean => {
  try {
    if (token) {
      var decodedToken: any = jwt_decode(token);
      var dateNow = new Date();
      if (decodedToken?.exp < (new Date().getTime() + 1) / 1000) {
        // console.log(
        //   "Inside token expired",
        //   decodedToken?.exp,
        //   (new Date().getTime() + 1) / 1000
        // );
        return true;
      }
    }
  } catch (err) {
    return true;
  }
  // console.log("Token not expired");
  return false;
};

const refreshLink = new TokenRefreshLink({
  isTokenValidOrUndefined: () =>
    !isTokenExpired(token) || typeof token !== "string",
  fetchAccessToken: async () => {
    const refreshTokenQuery = `
                query GetToken($refreshToken: String!) {
                      getToken(refreshToken: $refreshToken) {
                      accessToken
                  }
                }`;

    const graphqlUrl: any = process.env.REACT_APP_API_ENDPOINT;
    const response = await fetch(graphqlUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: refreshTokenQuery,
        variables: {
          refreshToken: refreshToken,
        },
      }),
    });
    return response.json();
  },
  handleFetch: (accessToken) => {
    // console.log("accestokenaferfetch", accessToken)
    // const accessTokenDecrypted = jwt_decode(accessToken);
    // console.log("Hanlde Fetch", accessToken);
    authTokenVar(accessToken);
    localStorage.setItem("authToken", accessToken);
  },
  handleResponse: (operation, accessTokenField) => (response: any) => {
    // here you can parse response, handle errors, prepare returned token to
    // further operations

    return { access_token: response?.data?.getToken?.accessToken };
  },
  handleError: (err) => {
    userObj({} as UserData);
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshAuthToken");
    // showErrorMessage("Session expired. Please login again.");
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

/** Error Interceptor to handle various errors coming from graphql backend */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log(
    JSON.stringify({ errors: { GE: graphQLErrors, NE: networkError } })
  );
  if (graphQLErrors)
    graphQLErrors?.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      Sentry.captureMessage(message);
      // Add your error handling logic here
      // For example, redirect on a specific error
      // if (message.includes('some specific error')) {
      //   window.location.href = '/error-page';
      // }
    });

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError);
    // Redirect on network errors
    // window.location.href = '/network-error';
  }
});

export const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  /** Uncomment to add `errorLink` to the array */
  // link: from([errorLink, refreshLink, authLink, splitLink]),
  link: from([errorLink, refreshLink, authLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        merge: true,
      },
      ChatGroup: {
        merge: true,
      },
    },
    dataIdFromObject: (responseObject, context) => {
      return match(responseObject)
        .with(
          {
            __typename: "User",
            eid: P.string,
          },
          () => {
            return `${responseObject.__typename}:${responseObject.eid}`;
          }
        )
        .with(
          {
            __typename: "ChatGroup",
            guid: P.string,
          },
          () => {
            return `${responseObject.__typename}:${responseObject.guid}`;
          }
        )
        .with(
          {
            __typename: "FormsCategory",
            eid: P.string,
          },
          () => {
            return `${responseObject.__typename}:${responseObject.eid}`;
          }
        )
        .otherwise(() => defaultDataIdFromObject(responseObject, context));
    },
    // dataIdFromObject: (responseObject) => {
    //   switch (responseObject.__typename) {
    //     case "LocationLaunchContentsTask":
    //       if (responseObject.eid && responseObject.launchId) {
    //         return `${responseObject.__typename}:${responseObject.launchId}:${responseObject.eid}`;
    //       }
    //       return defaultDataIdFromObject(responseObject);
    //     case "LauncherLocations":
    //       return `${responseObject.__typename}:${responseObject.launchId}`;
    //   }
    //
    //   if (responseObject.__typename && responseObject.eid) {
    //     return `${responseObject.__typename}:${responseObject.eid}`;
    //   }
    //   return defaultDataIdFromObject(responseObject);
    // },
  }),
});
