import React, { FC, useEffect, useState } from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthRole } from '../../../../authorization';
import { useUserData } from '../../../../hooks';

import {
  Container,
  OpenStepHeader,
  SaveNextButton,
  SelectOption,
  useStepperIsOpen,
} from '../common';
import SupervisorStepper from './SupervisorStepper';
import SupervisorForm from './SupervisorForm';
import { IFormInput } from '../task.types';
import { useMembersData } from './useMembersData';
import { handleValidationError } from '../validation';

interface IProps {
  onPublishClick?: () => void;
}

const TaskSupervisors: FC<IProps> = ({ onPublishClick }) => {
  const { t } = useTranslation(['task']);
  const isOpen = useStepperIsOpen('supervisors');

  const entityId = useUserData()?.entityId;

  const { handleSubmit, setValue } = useFormContext<IFormInput>();

  const [members, setMembers] = useState<SelectOption[]>([]);

  const { getUsersData, loading } = useMembersData(setMembers);

  useEffect(() => {
    getUsersData({
      variables: {
        entityId: entityId,
        status: ['active', 'inactive'],
        type: ['user'],
        authRole: [
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ],
      },
    });
  }, [entityId]);

  const saveAndNext = () => {
    setValue('completed.supervisors', true);
    setValue('filled.supervisors', true);
    onPublishClick?.();
  };

  const onError = (error: FieldErrors<IFormInput>) => {
    const nextStep = handleValidationError(error);
    if (nextStep) setValue('currentStep', nextStep);
  };

  if (!isOpen) {
    return <SupervisorStepper members={members} />;
  }

  return (
    <Container flexDir='column' p='20px'>
      <OpenStepHeader title={t('task:whoWillSupervise')} />

      <SupervisorForm members={members} loading={loading} />

      <SaveNextButton
        title={t('task:savePublish')}
        onClick={handleSubmit(saveAndNext, onError)}
      />
    </Container>
  );
};

export default TaskSupervisors;
