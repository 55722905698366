import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react';
import moment from 'moment';
import { LauncherLocation } from 'pages/launcher/dashboard/dashboard.graphql';
import {} from 'pages/launcher/details/LocationLaunchDetails';
import React, { FC, useMemo } from 'react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { userObj } from 'sop-commons/src/client';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';
import {
  ILauncherTaskData,
  ITaskListSectionProps,
} from 'sub-components/LocationLaunchDetail/types/types';
import { DatePickerComponent } from 'sub-components/tasks/create-task/Scheduler/CustomDatePicker/CustomDatePicker';
import TaskListItem from '../../Singleton/TaskListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisStroke } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { useSupervisorModal } from '../../SupervisorModal/useSupervisorModal';
import { usersEntityObj } from 'sub-components/Header';
import { AuthRole } from 'sop-commons/src/client';
import {
  LauncherLocationByIdDetail,
  LauncherLocationDetail,
  LauncherLocationDetailTask,
} from 'pages/launcher/details/types/location-launch-details.types';

interface IPhaseSectionProps {
  foundLocationData: LauncherLocationByIdDetail | undefined;
  launchId: string;
  phaseSectionData: IGroupedData[];
  taskClickHandler: (task: LauncherLocationDetailTask) => void;
}
export interface IGroupedData {
  category: string;
  phaseId: string;
  data: LauncherLocationDetail['tasks'];
  phaseSelected: boolean;
}
const PhaseHeader: FC<{ phaseName: string; phaseDueDate: string }> = ({
  phaseName,
  phaseDueDate,
}) => (
  <Flex justify='space-between'>
    <CommonLabel
      label={phaseName}
      fontWeight={600}
      fontSize='20px'
      color='#111315'
    />
    {/* <Flex gap={2}>
      <CommonLabel
        label={moment(phaseDueDate)?.format('DD MMM')}
        fontWeight={500}
        color='#111315'
      />
      <DatePickerComponent
        onChange={(date) => console.log(date)}
        placeholderText='Edit'
        selectedValue={null}
        dateFormat='d MMMM yyyy'
        isSelected={false}
        customInput={
          <CommonLabel
            label='Edit'
            color={HeaderColors.DarkBlue}
            cursor='pointer'
          />
        }
      />
    </Flex> */}
  </Flex>
);

/** Phase Section is a sub-section of Task List Section. Used to show tasks based on the selected phase filter. */
const PhaseSection: FC<IPhaseSectionProps> = ({
  foundLocationData,
  launchId,
  phaseSectionData,
  taskClickHandler,
}) => {
  const supervisorModal = useSupervisorModal();
  const entityData = useReactiveVar(usersEntityObj);
  return (
    <Flex flexDir='column' gap={4}>
      {phaseSectionData?.map((phaseItem, index) => {
        return (
          <Flex key={index} flexDir='column' gap={4} w='full'>
            <Flex align='center' gap={2} justify='space-between'>
              <Flex align='center' gap={2}>
                <PhaseHeader phaseName={phaseItem.category} phaseDueDate={''} />
                {!phaseItem?.phaseSelected && (
                  <Flex
                    bg='#2A85FF1A'
                    p='7px 11px'
                    borderRadius='7px'
                    h='31px'
                    align='center'
                    justify='center'
                  >
                    <Text color='#2A85FF'>Only for supervising team</Text>
                  </Flex>
                )}
              </Flex>
              {!phaseItem?.phaseSelected && (
                <Popover placement='bottom-start'>
                  {/* @ts-ignore */}
                  <PopoverTrigger>
                    <Box>
                      <FontAwesomeIcon
                        icon={faEllipsisStroke as IconProp}
                        fontSize='20px'
                        cursor='pointer'
                        style={{ marginRight: '10px' }}
                      />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent borderRadius='16px' p={4} w='200px'>
                    <PopoverArrow />
                    <PopoverBody>
                      <Flex>
                        <Flex
                          gap='10px'
                          align='center'
                          cursor='pointer'
                          onClick={() => {
                            let _users = entityData?.filter(
                              (en) =>
                                [
                                  AuthRole.SUPER_ADMIN,
                                  AuthRole.ADMIN,
                                ]?.includes(en?.authRole) && en?.type === 'user'
                            );
                            let userIds = phaseItem?.data?.[0]?.assignedUsers;
                            let filteredUsers = _users?.map((user) => {
                              return {
                                ...user,
                                label: user?.name,
                                value: user?.eid,
                              };
                            });
                            let foundUsers = filteredUsers?.filter((user) =>
                              userIds?.some((val) => val === user?.eid)
                            );
                            supervisorModal({
                              title: phaseItem?.category,
                              foundUsers: foundUsers,
                              phaseEid: phaseItem?.phaseId,
                              locationId: launchId,
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleUser as IconProp}
                            fontSize='16px'
                          />
                          <Text fontSize='15px' fontWeight={500}>
                            Edit supervisors
                          </Text>
                        </Flex>
                      </Flex>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </Flex>
            {phaseItem?.data?.map((task, index) => (
              <TaskListItem
                key={index}
                selectedFilter='phase'
                task={task}
                taskClickHandler={taskClickHandler}
                phaseAssignedToInternalTeam={phaseItem?.phaseId}
                phaseName={phaseItem?.category}
                launchId={launchId}
                isFirstTask={index === 0}
              />
            ))}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default PhaseSection;
