import React, { FC } from 'react';
import styled from '@emotion/styled';
import DashboardContainer from '../../sub-components/DashboardContainer';

import TeamHeader from './TeamHeader';
import TeamTabs from './TeamTabs';
import TeamPgeContainer from './TeamPgeContainer';

const Wrapper = styled.div`
  background: white;
  border-radius: 12px;
  padding-block: 32px;
  padding-inline: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .sop-list-loader {
    flex: 1;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;
    border-radius: 5px;
    display: table;
    max-width: unset;
    // max-width: -moz-fit-content;
    // /* max-width: fit-content; */
    overflow-x: auto;
    white-space: nowrap;
  }

  table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`;

interface IProps {}

const TeamsPage: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <TeamHeader />
      <Wrapper>
        <TeamTabs />
        <TeamPgeContainer />
      </Wrapper>
    </DashboardContainer>
  );
};

export default TeamsPage;
