import React, { FC } from 'react';
import { Box, CloseButton } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import CustomFormType from 'sub-components/forms/from-preview/CustomFormType';

interface IProps {
  formData: any;
  sessionId: string;
  taskId?: string;
  updateProgressHandler: () => void;
}

const FormRender: FC<IProps> = ({
  formData,
  sessionId,
  taskId,
  updateProgressHandler,
}) => {
  const history = useHistory();

  console.log(formData);

  return (
    <Box
      pos='relative'
      maxW='800px'
      width='full'
      mx='auto'
      borderRadius='10px'
      backgroundColor='white'
      my='16px'
    >
      <CloseButton
        pos='absolute'
        top='10px'
        right='10px'
        zIndex={1}
        bg='#F4F4F4 !important'
        color='#5B5A64'
        borderRadius='full'
        onClick={() =>
          history.push(`/tasks/my-tasks/details/${taskId}/${sessionId}`)
        }
      />
      <Box borderRadius='10px' overflow='hidden'>
        <CustomFormType
          content={formData}
          title={formData?.title}
          shouldMoveToNextCard={updateProgressHandler}
          sessionId={sessionId}
        />
      </Box>
    </Box>
  );
};

export default FormRender;
