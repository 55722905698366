import { ApolloError, MutationTuple, useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { userObj } from 'sop-commons/src/client';

import { PayloadInput } from '../../../../types';
import { updateObject } from '../../../../utils/objectHelper';

import { LauncherConfig } from './fragment.graphql';
import { ADD_CONFIG_QUERY, NewLauncherInput } from './add-launcher.graphql';

interface MutationOptions {
  onCompleted?: (data: LauncherConfig) => void;
  onError?: (error: ApolloError) => void;
}

interface CreateResponse {
  AddLauncher: LauncherConfig;
}

export const useAddLauncherConfig = (
  options: MutationOptions = {}
): MutationTuple<CreateResponse, PayloadInput<NewLauncherInput>> => {
  return useMutation<CreateResponse, PayloadInput<NewLauncherInput>>(
    ADD_CONFIG_QUERY,
    {
      onCompleted: (data) => {
        const launcher = data?.AddLauncher;
        const userData = cloneDeep(userObj());
        if (launcher && userData?.entity?.eid) {
          updateObject(userData, ['entity', 'launcher'], launcher);
          userObj(userData);
        }
        options?.onCompleted?.(launcher);
      },
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.log(err);

        options?.onError?.(err);
      },
    }
  );
};
