import React, { FC, useMemo, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import MakeLocLive from '../MakeLocLive';
import { IMakeLocLive } from '../MakeLocLive/MakeLocLive';
import { IReadyLiveCard } from '../ReadyLive/Singleton/ReadyLiveCard';
import {
  LauncherLocation,
  LOCATION_TO_LAUNCH,
} from '../../../pages/launcher/dashboard/dashboard.graphql';
import { LocationLiveResp, MAKE_LOCATION_LIVE } from './live-modal.graphql';
import { toArray } from '../../../utils/utils';
import { getLoggedInUserDataHandler } from 'shared/graphql/SharedGraphql';

/**
 * Type definition for the modal's data, extracting 'locName' from IReadyLiveCard.
 */
export type ILiveModalData = Pick<IReadyLiveCard, 'locationName'>;

/**
 * Interface for the props of LiveModal component.
 * @param isOpen Boolean to control the visibility of the modal.
 * @param data Data object containing information like location name.
 * @param onClose Function to call when the modal is requested to be closed.
 */
interface ILiveModalProps {
  isOpen: boolean;
  data?: LauncherLocation;
  onClose: () => void;
}

/**
 * LiveModal is a modal component used to display location live status and manage it.
 * It includes a MakeLocLive component that handles the status and actions related to the location's live state.
 *
 * @param props The props for the LiveModal component, including isOpen, data, and onClose.
 * @returns A modal component with custom content and functionality.
 */
const LiveModal: FC<ILiveModalProps> = (props) => {
  const { isOpen, data, onClose } = props;
  const [status, setStatus] = useState<IMakeLocLive['isLive']>(
    typeof data?.isLive === 'boolean' ? data.isLive : false
  );

  const [makeLive] = useMutation<LocationLiveResp>(MAKE_LOCATION_LIVE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LOCATION_TO_LAUNCH,
      },
    ],
  });

  const { execute } = getLoggedInUserDataHandler();

  const onSwitchClick = async () => {
    const res = await makeLive({
      variables: {
        locationId: data?.locationId,
      },
    });

    if (res.errors) {
      throw res.errors;
    }

    if (res.data?.MakeLauncherLocationLive?.succeed) {
      setStatus(true);
      execute?.();
    }
  };

  const phaseList = useMemo(() => {
    return toArray(data?.tasks)
      .reduce<string[]>((acc, value) => {
        if (!acc.includes(value.category)) {
          acc.push(value.category);
        }
        return acc;
      }, [])
      .map((it) => ({ title: it }));
  }, [data?.tasks]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='5xl' isCentered>
      <ModalOverlay />
      <ModalContent p='60px' borderRadius='20px'>
        <ModalCloseButton m={'50px'} />
        <ModalBody>
          <MakeLocLive
            locationId={data?.locationId!}
            locationName={data?.location?.name}
            isLive={status}
            onSwitchClick={onSwitchClick}
            phaseList={phaseList}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LiveModal;
