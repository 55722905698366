import React, { FC, ReactNode, useContext } from 'react';
import { FlexProps } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { useHistory } from 'react-router-dom';

import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';

import { ReactComponent as ChevronIcon } from '../../assets/images/cheveronIcon.svg';
import {
  AmplitudeEventNames,
  IAmplitudeEventNamesEntity,
} from 'shared/amplitudeEvents/amplitude-events-types';
import { deployEvent } from 'shared/amplitudeEvents';

interface IProps {
  icon: typeof ChevronIcon;
  isSelected?: boolean;
  title: ReactNode;
  link: string;
  onClick?: FlexProps['onClick'];
  id?: string;
}

const SidebarBaseItem: FC<IProps> = ({
  isSelected,
  icon,
  title,
  link,
  onClick,
  children,
  id,
}) => {
  const history = useHistory();
  const dashboardCtx = useContext(DashboardDataContext);

  const IconComp = icon;

  let className = 'sidebar-item-container';
  if (isSelected) {
    className = `sidebar-item-container selected`;
  }

  const _onClickHandler = () => {
    let eventName: IAmplitudeEventNamesEntity = `${AmplitudeEventNames.NAVIGATE_ROUTE}: ${link}`;
    deployEvent(eventName);
    history.push(link);
    dashboardCtx?.resetNavigationPersistDataHandler();
  };

  return (
    <div
      className={className}
      onClick={callAll(_onClickHandler, onClick)}
      id={id}
    >
      <IconComp />

      <div className='sidebar-item-title-container'>{title}</div>
      {children}
    </div>
  );
};

export default SidebarBaseItem;
