import { useReactiveVar } from '@apollo/client';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthRole, userObj } from 'sop-commons/src/client';
import useCombinedStore from 'zustandStore/store';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import SetSeeDataContext from '../SetSeeModalStore/set-see-data-context';

const ConditionalButtons: FC = () => {
  const { t } = useTranslation('deck');
  const userObject = useReactiveVar(userObj);
  const setSeeCtx = useContext(SetSeeDataContext);
  const { visibilitySelection, updateVisibilitySelection } = useCombinedStore();
  return (
    <Flex justifyContent={'space-between'} alignItems={'center'}>
      <Flex>
        <Flex
          p='12px 18px 11px'
          justify='center'
          align='center'
          fontWeight={600}
          fontSize='15px'
          cursor='pointer'
          bg={`${
            visibilitySelection?.condition === 'or' ? '#2a85ff' : '#ffffff'
          }`}
          color={`${
            visibilitySelection?.condition === 'or' ? '#ffffff' : '#000000'
          }`}
          borderBottomLeftRadius='12px'
          borderTopLeftRadius='12px'
          onClick={() => {
            setSeeCtx?.updateConditionType('or');
            if (visibilitySelection) {
              updateVisibilitySelection({
                ...visibilitySelection,
                condition: 'or',
              });
            }
          }}
        >
          {t('visibility.or_button')}
        </Flex>
        <Flex
          p='12px 18px 11px'
          justify='center'
          align='center'
          fontWeight={600}
          fontSize='15px'
          cursor='pointer'
          borderBottomRightRadius='12px'
          borderTopRightRadius='12px'
          bg={`${
            visibilitySelection?.condition === 'and' ? '#2a85ff' : '#ffffff'
          }`}
          color={`${
            visibilitySelection?.condition === 'and' ? '#ffffff' : '#000000'
          }`}
          onClick={() => {
            setSeeCtx?.updateConditionType('and');
            if (visibilitySelection) {
              updateVisibilitySelection({
                ...visibilitySelection,
                condition: 'and',
              });
            }
          }}
        >
          {t('visibility.and_button')}
        </Flex>
      </Flex>
      <Tooltip
        label='This will delete your additional condition'
        placement='bottom-end'
      >
        <Box>
          <FontAwesomeIcon
            icon={faTrashCan as IconProp}
            color='#6f767e'
            size='lg'
            cursor={'pointer'}
            onClick={() => {
              // setSeeCtx?.updateShowCondition(false);
              // setSeeCtx?.updateConditionType('or');
              // setSeeCtx?.selectedFirstDropdown?.label === 'Location'
              //   ? setSeeCtx?.updateSelectedRolesList([])
              //   : userObject?.authRole === AuthRole.LOCATION_OWNER &&
              //     setSeeCtx?.locationsList?.length === 1
              //   ? setSeeCtx?.updateSelectedLocationsList(
              //       setSeeCtx?.selectedLocationsList
              //     )
              //   : setSeeCtx?.updateSelectedLocationsList([]);
              if (visibilitySelection) {
                setSeeCtx?.selectedFirstDropdown?.label === 'Location'
                  ? updateVisibilitySelection({
                      ...visibilitySelection,
                      roles: [],
                      condition: undefined,
                    })
                  : userObject?.authRole === AuthRole.LOCATION_OWNER &&
                    setSeeCtx?.locationsList?.length === 1
                  ? updateVisibilitySelection({
                      ...visibilitySelection,
                      locations: visibilitySelection?.locations,
                      condition: undefined,
                    })
                  : updateVisibilitySelection({
                      ...visibilitySelection,
                      locations: [],
                      condition: undefined,
                    });
              }
            }}
          />
        </Box>
      </Tooltip>
    </Flex>
  );
};

export default ConditionalButtons;
