import LauncherPublishAlert from './LauncherPublishAlert';
export { AsyncAlertEvent, PromptAction } from './async-alert-event';

const _RouteAlerts = {
  LauncherPublishAlert: LauncherPublishAlert,
} as const;

export type AlertType = keyof typeof _RouteAlerts;

export const RouteAlerts = new Map(Object.entries(_RouteAlerts));
