import React, { FC, useEffect, useMemo, useState } from 'react';
import { Flex, useOutsideClick } from '@chakra-ui/react';
import { HeaderColors } from '../../../shared/headerColors/header-colors';
import { MenuContainer, SelectField } from './components';
import { IProps } from './custom-select-component.types';

import { AuthRole } from 'sop-commons/src/client';
import CustomSelectChips from './CustomSelectChips';

const defaultOptions = [
  {
    label: 'Super Admins',
    value: AuthRole.SUPER_ADMIN,
  },
  {
    label: 'Admins',
    value: AuthRole.ADMIN,
  },
  {
    label: 'Location Owners',
    value: AuthRole.LOCATION_OWNER,
  },
  {
    label: 'Workers',
    value: AuthRole.WORKER,
  },
];

/**
 * CustomSelectComponent is a functional component that renders a custom dropdown select mainly used for selecting Supervisors across application.
 *
 * @param props - IProps interface elements to configure the component.
 * @returns React Functional Component.
 */
const CustomSelectComponent: FC<IProps> = ({
  placeholder = 'Select...',
  placeholderColor,
  options = [],
  value = [],
  showHeader = false,
  headerTitle = 'Search',
  headerBarColor = HeaderColors.Blue,
  showHeaderCloseIcon = false,
  showSearchField = false,
  searchFieldPlaceholder = 'Search...',
  showDivider = false,
  topOutsideBtn = {
    show: false,
    title: 'Click',
  },
  bottomOutsideBtn = {
    show: false,
    title: 'Click',
  },
  showFilter = false,
  chipsColor,
  hideChipsImage,
  showSelectAll,
  showChips = true,
  customWidth,
  selectInputBg,
  loading,
  authRoleFilterOptions = defaultOptions,
  customSelectField,
  onChange,
  customStyles,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(value || []);
  const [storedFieldPlaceholder, setFieldPlaceholder] =
    useState<string>(placeholder);

  useEffect(() => {
    if (showList) {
      setSelectedValues(value);
    }
  }, [showList]);

  const handleOptionClick = (eid: string) => {
    let newSelectedValues;

    if (selectedValues.includes(eid)) {
      newSelectedValues = selectedValues.filter((val) => val !== eid);
    } else {
      newSelectedValues = [...selectedValues, eid];
    }
    setSelectedValues(newSelectedValues);
  };

  const reset = () => {
    setSelectedValues([]);
    setShowList(false);
  };

  useOutsideClick({
    ref: ref,
    handler: () => setShowList(false),
  });

  const handleRemove = (eidToRemove: string, selectedValues: string[]) => {
    setSelectedValues((prevValues) =>
      prevValues.filter((eid) => eid !== eidToRemove)
    );
    if (onChange) {
      onChange(selectedValues.filter((eid) => eid !== eidToRemove));
    }
  };

  const fieldPlaceholder = useMemo(() => {
    if (!showChips) {
      let names: string[] = [];
      options?.forEach((option) => {
        if (value?.includes(option?.value)) {
          names.push(option?.label);
        }
      });
      if (names?.length === 0) {
        return placeholder;
      } else {
        return names.join(', ');
      }
    }
    return placeholder;
  }, [value, options, placeholder, showChips]);

  useEffect(() => {
    setFieldPlaceholder(fieldPlaceholder);
  }, [fieldPlaceholder]);

  const onApplyHandler = () => {
    onChange(selectedValues);
    reset();
  };

  return (
    // @ts-ignore
    <Flex pos='relative' flexDir='column' ref={ref}>
      <SelectField
        showChips={showChips}
        setShowList={setShowList}
        showList={showList}
        placeholder={storedFieldPlaceholder}
        placeholderColor={placeholderColor}
        selectInputBg={selectInputBg}
        loading={loading}
        customSelectField={customSelectField}
        customStyles={customStyles}
      />
      {showList && (
        <MenuContainer
          showHeader={showHeader}
          showHeaderCloseIcon={showHeaderCloseIcon}
          headerBarColor={headerBarColor}
          headerTitle={headerTitle}
          setShowList={setShowList}
          showSearchField={showSearchField}
          searchFieldPlaceholder={searchFieldPlaceholder}
          options={options}
          reset={reset}
          handleOptionClick={handleOptionClick}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          showDivider={showDivider}
          bottomOutsideBtn={bottomOutsideBtn}
          showFilter={showFilter}
          showList={showList}
          applyHandler={onApplyHandler}
          showSelectAll={showSelectAll}
          customWidth={customWidth}
          loading={loading}
          authRoleFilterOptions={authRoleFilterOptions}
        />
      )}

      {showChips && (
        <CustomSelectChips
          options={options}
          value={value}
          handleRemove={handleRemove}
          chipsColor={chipsColor}
          hideChipsImage={hideChipsImage}
        />
      )}
    </Flex>
  );
};

export default CustomSelectComponent;
