import React, { FC, useMemo, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import './LocationShareModal.scss';

import { userObj } from 'sop-commons/src/client';

import { Authorize, AuthRole } from 'authorization';
import { PrimaryButton, SearchInput } from 'atoms';
import { searchRegExp } from 'utils';
import { toArray } from 'utils/utils';

import ShareLoginLink from './ShareLoginLink';
import { ILocation, ILocationMember } from './locationTypes/location-types';

import ShareMemberList from '../../ui-components/ShareToMember/ShareMemberList';
import { ShareMemberEntity } from '../../ui-components/ShareToMember';
import {
  FormToUserResponse,
  FormToUserVariable,
  SEND_FORM_QUERY,
} from '../../pages/forms/modules/SendForm/send-form.graphql';

import { ReactComponent as CloseIconDark } from '../../assets/images/closeIconDark.svg';

const magicLinkQuery = gql`
  query Query($eid: String!) {
    getMagicLink(eid: $eid)
  }
`;

interface IProps {
  onCloseClick: () => void;
  data?: ILocation;
  locationOwnerSearchType?: boolean;
}

const LocationShare: FC<IProps> = ({
  onCloseClick,
  data,
  locationOwnerSearchType,
}) => {
  const { t } = useTranslation(['location', 'header']);
  const [locationOwnerSearchQuery, setLocationOwnerSearchQuery] = useState('');
  const [showLocationWorkers, setShowLocationWorkers] = useState(false);

  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });

  const { refetch, data: response } = useQuery(magicLinkQuery, {
    variables: {
      eid: data?.eid,
    },
  });

  const magikLink = response?.getMagicLink;

  const [submitFormData] = useMutation<FormToUserResponse, FormToUserVariable>(
    SEND_FORM_QUERY
  );

  const onSendClick = async (value: ShareMemberEntity) => {
    const response = await refetch({
      eid: data?.eid,
    });
    if (response.error) {
      return Promise.reject(response.error);
    }
    await submitFormData({
      variables: {
        senderId: userObj().eid,
        receiverIds: [value.eid],
        message: `Please use the link to login : ${response.data?.getMagicLink}`,
      },
    }).then((response) => {
      if (response.data?.sendDirectMessage?.succeed) {
        toast({
          title: t('success'),
          status: 'success',
          description: t('location:loginDetailSuccessSentUser', {
            name: value.name,
          }),
        });
      }
    });
  };

  const members = useMemo(() => {
    let _membersList: ILocationMember[] = toArray(data?.members)?.filter(
      (member) => member?.authRole !== AuthRole.LOCATION_OWNER
    );
    const _members = toArray(_membersList);
    return _members as unknown as ShareMemberEntity[];
  }, [data?.members]);

  const locationMembers = useMemo(() => {
    let _locationMembersList: ILocationMember[] = toArray(
      data?.members
    )?.filter((member) => member?.authRole === AuthRole.LOCATION_OWNER);
    const _locationMembers = toArray(_locationMembersList);
    if (locationOwnerSearchQuery) {
      const reg = searchRegExp(locationOwnerSearchQuery, 'gi');
      return _locationMembers.filter((value) => {
        return (
          value.name?.match(reg) ||
          value.role?.match(reg) ||
          value.authRole?.match(reg) ||
          value.email?.match(reg) ||
          value.username?.match(reg)
        );
      }) as unknown as ShareMemberEntity[];
    }
    return _locationMembers as unknown as ShareMemberEntity[];
  }, [data?.members, locationOwnerSearchQuery]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        maxWidth: 580,
        width: '100%',
        backgroundColor: 'rgba(255,255,255,1)',
        height: '100%',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        padding: 32,
        paddingTop: 32,
        // boxShadow: '-2px -3px 5px -4px rgba(0,0,0,0.75)',
        boxShadow: '-10px 4px 10px 0 rgba(153, 153, 153, 0.2)',
        //overflowY: 'auto',
        zIndex: 201,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      }}
    >
      <Flex flexDir='column' h='100%'>
        <Flex
          align='center'
          mb='24px'
          fontSize='18px'
          fontWeight='900'
          gap='16px'
        >
          <Box h='32px' w='16px' minW='16px' borderRadius='4px' bg='#b5e4ca' />
          <Box className='shareHeading' flex='1 1 auto' paddingRight='10px'>
            <Trans
              t={t}
              i18nKey='location:shareLoginDetailsOf'
              values={{
                value: data?.name,
              }}
            />
          </Box>

          <div onClick={onCloseClick} style={{ cursor: 'pointer' }}>
            <div className='Comment'>
              <CloseIconDark />
            </div>
          </div>
        </Flex>

        <Authorize permittedFor='user' permittedRoles={AuthRole.SUPER_ADMIN}>
          <ShareLoginLink link={magikLink} name={data?.name} />
        </Authorize>
        <Box mb={4}>
          <SearchInput
            value={locationOwnerSearchQuery}
            hideShortcuts
            placeholder={
              locationOwnerSearchType
                ? t('header:searchLocationOwner')
                : t('header:search_member')
            }
            onChange={(e) => setLocationOwnerSearchQuery(e?.target?.value)}
          />
        </Box>
        <Box overflow='scroll'>
          <Box id='location-owner-list-share'>
            <ShareMemberList
              search={locationOwnerSearchQuery}
              members={locationMembers}
              onSendClick={onSendClick}
              i18NotFoundKey='location:nobody_share'
              i18NotFoundDescKey='location:invite_manager_for_access'
            />
          </Box>
          {!showLocationWorkers && members?.length > 0 && (
            <PrimaryButton
              title='Show location workers'
              variant='outline'
              onClick={() => setShowLocationWorkers(true)}
              style={{
                marginTop: '12px',
              }}
            />
          )}

          {showLocationWorkers && (
            <Box mt={3}>
              <Text fontWeight={600} fontSize='12px' color='#6F767E'>
                MEMBERS
              </Text>
              <Box>
                <ShareMemberList
                  members={members}
                  onSendClick={onSendClick}
                  i18NotFoundKey='location:nobody_share'
                  i18NotFoundDescKey='location:invite_manager_for_access'
                />
              </Box>
            </Box>
          )}
        </Box>
      </Flex>
    </div>
  );
};

export default LocationShare;
