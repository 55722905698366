import { match } from 'ts-pattern';
import { userObj } from 'sop-commons/src/client';

import { AuthRole } from 'authorization';
import { toArray } from 'utils/utils';
import { FileEntity } from 'types';

import { LocationResponse } from './edit-location.graphql';
import { IFormInput } from '../AddLocation/add-location.types';

import { EntityLabel, LocationStatusOption } from '../AddLocation/static-data';

const mapFile = (file: FileEntity): FileEntity => {
  return {
    url: file.url,
    name: file.name,
    fileSize: file.fileSize,
    type: file.type,
    mimetype: file.mimetype,
  };
};

const withDefaultValue = <T>(data: T | T[], defaultValue: T): T[] => {
  const _value = toArray(data);

  if (_value.length) {
    return _value;
  }

  return [defaultValue];
};

export function convertToFormData(
  data: LocationResponse['userById']
): IFormInput {
  const address = data.address;

  const otherFieldsData = toArray(data.otherFields).map<
    IFormInput['otherFields'][number]
  >((currentValue) => ({
    fieldId: currentValue.fieldId,
    value: currentValue.value,
    files: toArray(currentValue.files).map(mapFile),
    options: toArray(currentValue.options).map((value) => ({
      value: value,
      label: value,
    })),
  }));

  const otherFieldKeys = toArray(data.otherFields).map((it) => it.fieldId);

  const locationOwners = data.members.reduce<IFormInput['locationOwners']>(
    (acc, value) => {
      if (value.authRole === AuthRole.LOCATION_OWNER) {
        acc.push({
          owner: {
            ...value,
            label: value.name,
            value: value.eid,
          },
        });
      }
      return acc;
    },
    []
  );

  return {
    eid: data.eid,
    isRoot: data.isRoot,
    haveLauncher: !!data.launchId,

    // Basic details
    locationType: data.locationType,
    locationName: data.name,
    locationStatus: LocationStatusOption.find(
      (value) => value.value === data.locationStatus
    )!,
    locationEmail: withDefaultValue(data.locationEmail, '').map((value) => ({
      email: value,
    })),
    locationPhone: withDefaultValue(data.locationPhone, '').map((value) => ({
      phone: value,
    })),

    locationOwners: withDefaultValue(locationOwners, {
      owner: '' as never,
    }),

    // // Login Details ==
    username: data.username,

    // Address
    address: address?.address,
    city: address?.city,
    state: address?.state,
    zipCode: address?.zipCode,
    timezone: data.timezone,

    // Entity details
    entityName: data.entityName,
    entityType: data.entityType
      ? {
          value: data.entityType?.toUpperCase() as never,
          label: EntityLabel[data.entityType?.toUpperCase() as never],
        }
      : undefined!,
    countryOfFormation: data.countryOfFormation
      ? {
          value: data.countryOfFormation,
          label: data.countryOfFormation,
        }
      : undefined!,
    stateOfFormation: data.stateOfFormation
      ? {
          value: data.stateOfFormation,
          label: data.stateOfFormation,
        }
      : undefined!,
    taxPayerId: data.taxPayerId,

    // Other details
    otherFields: toArray(userObj()?.entity?.locationFields).reduce<
      IFormInput['otherFields']
    >((previousValue, currentValue) => {
      if (currentValue?.validations?.isRequired) {
        if (!otherFieldKeys.includes(currentValue.eid)) {
          previousValue.push({
            fieldId: currentValue.eid,
            options: [],
            value: '',
            files: [],
          });
        }
      }
      return previousValue;
    }, otherFieldsData),

    // TODO NOT delete this, this one is used to pass data for some modal
    rawLocation: data,
  };
}

export const checkLocationCanEdit = (
  data: LocationResponse['userById']
): boolean => {
  const userData = userObj();

  if (userData.type === 'branch') {
    return false;
  }

  return match(userData.authRole)
    .with(AuthRole.SUPER_ADMIN, () => true)
    .with(AuthRole.ADMIN, () => true)
    .with(AuthRole.LOCATION_OWNER, () =>
      toArray(userData.locations).some((loc) => data.eid === loc.eid)
    )
    .with(AuthRole.WORKER, () => false)
    .otherwise(() => false);
};
