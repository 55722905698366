import React, { FC } from 'react';
import { Divider, Flex, ListItem, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';

import { IconImage } from '../../../../ui-components';

import { SelectOption } from '../custom-select-component.types';

interface IProps {
  option: SelectOption;
  isSelected?: boolean;
  onClick?: (value: string) => void;
  showDivider?: boolean;
}

const MenuItem: FC<IProps> = ({ option, isSelected, onClick, showDivider }) => {
  return (
    <ListItem
      key={option?.value}
      cursor='pointer'
      aria-selected={isSelected}
      _hover={{ bg: '#EEEEEE', '.chakra-divider': { opacity: 0 } }}
      _selected={{ bg: '#2A85FF1F', '.chakra-divider': { opacity: 0 } }}
      onClick={() => onClick?.(option?.value!)}
    >
      <Flex flexDir='column' px={6}>
        <Flex justify='space-between' align='center' py={2}>
          <Flex gap='10px' align='center' userSelect='none'>
            <IconImage
              name={option?.label}
              thumbnail={option?.profilePic}
              boxSize={40}
              borderRadius='50%'
            />
            <Flex flexDir='column'>
              <Text m={0} p={0}>
                {option?.label}
              </Text>
              <Flex gap='5px' fontWeight={500} fontSize='12px' color='#92929D'>
                <Text m={0} p={0}>
                  {option?.role}
                </Text>
                <Text m={0} p={0}>
                  &bull;
                </Text>
                <Text m={0} p={0}>
                  {option?.locations?.[0]?.name || '-'}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {isSelected ? (
            <FontAwesomeIcon
              icon={faSquareCheck as IconProp}
              color='#2A85FF'
              size='lg'
            />
          ) : (
            <FontAwesomeIcon
              icon={faSquare as IconProp}
              color='#999999'
              size='lg'
            />
          )}
        </Flex>
        {showDivider && <Divider />}
      </Flex>
    </ListItem>
  );
};

export default MenuItem;
