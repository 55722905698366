// @ts-ignore
import SALTY_DAWG_FULL from 'assets/images/custom-login-page/saltyDawgFull.png';
// @ts-ignore
import SALTY_DAWG_SHORT from 'assets/images/custom-login-page/saltyDawgShort.png';
// @ts-ignore
import SALTY_DAWG_DELIGHTREE_SHORT from 'assets/images/custom-login-page/saltyDawgDelightreeShort.png';
// @ts-ignore
import SALTY_DAWG_DELIGHTREE_FULL from 'assets/images/custom-login-page/saltyDawgDelightreeFull.svg';
// @ts-ignore
import DEFAULT_LOGO from 'assets/images/logo.webp';

export interface LogoMapEntity {
  maxAspectLogo: string;
  midAspectLogo: string;
  smallAspectLogo: string;
}

export interface LogoMap {
  [domain: string]: LogoMapEntity;
}

export interface LabelMap {
  [domain: string]: {
    signInTitle: string;
    signInDescription: string;
    maxAspectLeftCompanyTitle: string;
    maxAspectLeftCompanyDescription: string;
    midAspectSignInTitle: string;
    midAspectSignInDescription: string;
    smallAspectSignInTitle: string;
    smallAspectSignInDescription: string;
  };
}

export const LABEL_MAP: LabelMap = {
  'saltyhub.delightree': {
    signInTitle: 'Sign in',
    signInDescription: '',
    maxAspectLeftCompanyTitle: 'Salty Hub',
    maxAspectLeftCompanyDescription: '',
    midAspectSignInTitle: 'Salty Hub',
    midAspectSignInDescription: 'Login to your portal',
    smallAspectSignInTitle: 'Login',
    smallAspectSignInDescription: 'Enter your credentials',
  },
};

export const DEFAULT_LOGO_JSON = {
  maxAspectLogo: DEFAULT_LOGO,
  midAspectLogo: DEFAULT_LOGO,
  smallAspectLogo: DEFAULT_LOGO,
};

export const DEFAULT_LABEL_JSON = {
  signInTitle: 'Sign in',
  signInDescription: 'Login to Delightree',
  maxAspectLeftCompanyTitle: 'Delightree',
  maxAspectLeftCompanyDescription: 'Operations and training on auto-pilot',
  midAspectSignInTitle: 'Sign in',
  midAspectSignInDescription: 'Login to Delightree',
  smallAspectSignInTitle: 'Sign in',
  smallAspectSignInDescription: 'Login to Delightree',
};

export const LOGO_MAP: LogoMap = {
  'saltyhub.delightree': {
    maxAspectLogo: SALTY_DAWG_DELIGHTREE_FULL,
    midAspectLogo: SALTY_DAWG_DELIGHTREE_SHORT,
    smallAspectLogo: SALTY_DAWG_DELIGHTREE_SHORT,
  },
};
