import { createRef, FC, Fragment } from 'react';
import { ChakraProvider, useTheme } from '@chakra-ui/react';
import { IKContext } from 'imagekitio-react';
import { IMAGEKIT_URL_ENDPOINT } from '../../utils/constants';

import { customRender, customUnmount } from './react-render';
import ConfirmComponent, { ComponentProps } from './ConfirmComponent';
import destroyFns from './destroyFns';

export interface ConfirmConfig extends Omit<ComponentProps, 'passedRef'> {
  Wrapper?: FC;
}

interface DefaultConfig extends ConfirmConfig {
  theme: ReturnType<typeof useTheme>;
}

type ConfigUpdate =
  | Partial<ConfirmConfig>
  | ((prevConfig: Partial<ConfirmConfig>) => Partial<ConfirmConfig>);

interface Config {
  isOpen: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function confirmManager({ theme, ...config }: Partial<DefaultConfig>) {
  const ref = createRef<never>();
  const container = document.createDocumentFragment();

  let currentConfig = { isOpen: true, close, ...config } as any;

  function destroy(...args: any[]) {
    for (let i = 0; i < destroyFns.length; i++) {
      const fn = destroyFns[i];
      if (fn === close) {
        destroyFns.splice(i, 1);
        break;
      }
    }

    customUnmount(container);
  }

  function render(props: Config & DefaultConfig) {
    const Wrapper = props?.Wrapper || Fragment;
    setTimeout(() => {
      customRender(
        <ChakraProvider theme={theme}>
          <IKContext urlEndpoint={IMAGEKIT_URL_ENDPOINT}>
            <Wrapper>
              <ConfirmComponent passedRef={ref} {...props} />
            </Wrapper>
          </IKContext>
        </ChakraProvider>,
        container
      );
    });
  }

  function close(...args: any[]) {
    currentConfig = {
      ...currentConfig,
      isOpen: false,
      onCloseComplete: () => {
        if (typeof config.afterClose === 'function') {
          config.afterClose();
        }
        // @ts-ignore
        destroy.apply(this, args);
      },
    };

    render(currentConfig);
  }

  function update(configUpdate: ConfigUpdate) {
    if (typeof configUpdate === 'function') {
      currentConfig = configUpdate(currentConfig);
    } else {
      currentConfig = {
        ...currentConfig,
        ...configUpdate,
      };
    }
    render(currentConfig);
  }

  render(currentConfig);

  destroyFns.push(close);

  return {
    destroy: close,
    update,
  };
}
