import React, { FC, useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { Center, Flex, List, ListItem, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useUserDataSelector } from 'hooks';
import { shallowEqual } from 'utils';
import { usersEntityObj } from 'sub-components/Header';
import { ITasksForToday } from 'pages/Home/Home';
import SupervisedTaskItem from 'pages/TasksDashboard/SupervisedTasks/component/SupervisedTaskItem';
import { processSupervisedData } from 'pages/TasksDashboard/SupervisedTasks/functions/processSupervisedData';
import { getSupervisedByMeTasks } from 'pages/TasksDashboard/SupervisedTasks/services/SupervisedTasks.service';
import { ISupervisedAPIArgs } from 'pages/TasksDashboard/tasks.types';
import { IEntityUser } from 'shared/global-types';
import { defaultCSTTimezone } from 'sub-components/tasks/create-task/validation/helper';
import Loader from 'ui-components/Loader';
import MyTasksEmpty from '../MyTasksTab/MyTasksEmpty';

interface IProps {
  tasksForTodayData: ITasksForToday;
  setTasksForTodayData: React.Dispatch<React.SetStateAction<ITasksForToday>>;
}

const SupervisedTab: FC<IProps> = ({
  tasksForTodayData,
  setTasksForTodayData,
}) => {
  const userData = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      locations: state.locations,
      timezone: state.timezone,
    }),
    shallowEqual
  );

  const history = useHistory();
  const usersEntityData: IEntityUser[] = useReactiveVar(usersEntityObj);

  const { execute, loading, error, data } = getSupervisedByMeTasks();
  const getSupervisorQuery = () => {
    const _fromDate = moment
      ?.utc()
      .subtract(1, 'days')
      .startOf('day')
      .toISOString();
    let filter: {
      supervisor: boolean;
      activeOnly: boolean;
      fromDate: string;
    } = {
      supervisor: true,
      activeOnly: true,
      fromDate: _fromDate,
    };
    return {
      filter,
      sort: 'NEXTREPEAT_ASC',
      page: 1,
      perPage: 3,
    } as ISupervisedAPIArgs;
  };
  useEffect(() => {
    execute({
      variables: getSupervisorQuery(),
    });
  }, []);
  const getDueTodayCount = () => {
    if (data?.TaskPagination && data?.TaskPagination?.count > 0) {
      let { taskDetails } = processSupervisedData(
        data?.TaskPagination?.items,
        userData?.timezone || defaultCSTTimezone,
        usersEntityData,
        userData
      );
      const dueTodayArray = taskDetails.filter(
        (item) => item.type === 'dueToday'
      );
      return dueTodayArray.reduce((acc, curr: any) => acc.concat(curr.data), [])
        .length;
    }
    return 0;
  };
  useEffect(() => {
    const count = getDueTodayCount();
    setTasksForTodayData((prevState) => ({
      ...prevState,
      total: count,
    }));
  }, [data]);
  const supervisedItems = useCallback(() => {
    if (
      data?.TaskPagination &&
      data?.TaskPagination?.count > 0 &&
      usersEntityData &&
      usersEntityData?.length > 0
    ) {
      let { taskDetails, taskOverview } = processSupervisedData(
        data?.TaskPagination?.items,
        userData?.timezone || defaultCSTTimezone,
        usersEntityData,
        userData
      );
      const requiredTypes = [
        'overdue',
        'dueToday',
        'thisWeek',
        'partiallyDone',
        'upcoming',
      ];
      const filteredArray = taskDetails.filter((item) =>
        requiredTypes.includes(item.type)
      );
      const combinedData = filteredArray.reduce((acc, curr: any) => {
        return acc.concat(curr.data);
      }, []);
      let topThreeData = combinedData?.slice(0, 3);
      return topThreeData;
    } else {
      return [];
    }
  }, [data, usersEntityData]);

  return loading ? (
    <Center h='30vh' bg='white'>
      <Loader />
    </Center>
  ) : supervisedItems()?.length > 0 ? (
    <Flex flexDir='column' gap={1}>
      <List spacing={1}>
        {supervisedItems()?.map((item: any) => (
          <ListItem
            key={item?.eid}
            padding='12px 24px'
            bg='#FFFFFF'
            borderRadius='8px'
            _hover={{ bg: '#F9F9F9' }}
          >
            <SupervisedTaskItem
              task={item}
              backToTitle='Back to Home'
              truncRepeat
            />
          </ListItem>
        ))}
      </List>

      <Center
        bg='white'
        gap='5px'
        color='#2A85FF'
        cursor='pointer'
        onClick={() => history.push('/tasks/supervised')}
        py='8px'
        borderBottomRadius='8px'
      >
        <Text fontWeight={600} fontSize='12.41px'>
          View all
        </Text>
        <ChevronRightIcon />
      </Center>
    </Flex>
  ) : (
    <Flex bg='white' justify='center' py={4}>
      <MyTasksEmpty emptyDesc='Tasks will appear here once you create them' />
    </Flex>
  );
};

export default SupervisedTab;
