import React, { FC, ReactNode, RefObject } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  ButtonProps,
  ModalBodyProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  useTheme,
} from '@chakra-ui/react';
import ActionButton from './ActionButton';

export interface ComponentProps
  extends Pick<
    AlertDialogProps,
    'scrollBehavior' | 'isCentered' | 'size' | 'styleConfig'
  > {
  isOpen: boolean;
  passedRef: RefObject<any>;

  theme: ReturnType<typeof useTheme>;
  cancelText: string | null;
  okText: string | null;
  zIndex: number;
  onCancel: (...args: any[]) => any | PromiseLike<any>;
  onOK: (...args: any[]) => any | PromiseLike<any>;
  footer?: ReactNode;
  okButtonProps?: Omit<ButtonProps, 'onClick'>;
  cancelButtonProps?: Omit<ButtonProps, 'onClick'>;
  afterClose?: () => void;

  title?: ReactNode;
  content?: ReactNode;

  contentProps?: ModalContentProps;
  headerProps?: ModalHeaderProps;
  bodyProps?: ModalBodyProps;
  footerProps?: ModalFooterProps;

  close?: Function;

  hideOverlay?: boolean;
}

const ConfirmComponent: FC<ComponentProps> = (allProps) => {
  const {
    isOpen,
    close,
    passedRef,
    zIndex,
    footer,
    okText,
    cancelText,
    okButtonProps,
    cancelButtonProps,
    contentProps,
    headerProps,
    bodyProps,
    footerProps,
    onOK,
    onCancel,
    title,
    content,
    hideOverlay,
    ...props
  } = allProps;

  return (
    <AlertDialog
      id={'confirm'}
      isOpen={isOpen}
      onClose={() => close?.()}
      leastDestructiveRef={passedRef}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      {...props}
    >
      {!hideOverlay && <AlertDialogOverlay zIndex={zIndex} />}
      <AlertDialogContent
        {...contentProps}
        containerProps={{ zIndex, ...contentProps?.containerProps }}
      >
        {title ? (
          <AlertDialogHeader {...headerProps}>{title}</AlertDialogHeader>
        ) : null}
        {content ? (
          <AlertDialogBody {...bodyProps}>{content}</AlertDialogBody>
        ) : null}
        {footer !== null && (
          <AlertDialogFooter {...footerProps}>
            {footer || (
              <>
                {cancelText && (
                  <ActionButton
                    close={close}
                    actionFn={onCancel}
                    {...cancelButtonProps}
                  >
                    {cancelText}
                  </ActionButton>
                )}

                {okText && (
                  <ActionButton
                    close={close}
                    actionFn={onOK}
                    ml={3}
                    variant='solid'
                    colorScheme='blue'
                    {...okButtonProps}
                  >
                    {okText}
                  </ActionButton>
                )}
              </>
            )}
          </AlertDialogFooter>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmComponent;
