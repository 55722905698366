import React from 'react';
import {
  forwardRef,
  Textarea as NTextarea,
  TextareaProps as IProps,
} from '@chakra-ui/react';

interface TextAreaProps extends IProps {}

const Textarea = forwardRef<TextAreaProps, 'textarea'>(
  ({ _focus, sx, ...rest }, ref) => {
    return (
      <NTextarea
        ref={ref}
        bg='gray.100'
        borderRadius='12px'
        _focus={{
          background: 'var(--chakra-colors-transparent)',
          ..._focus,
        }}
        sx={{
          '&[aria-invalid=true]': {
            borderWidth: 0,
            borderColor: 'rgba(42, 133, 255, 0.35) !important',
            background: 'rgba(255, 188, 153, 0.3)',
            color: 'var(--chakra-colors-red-500)',
            boxShadow: 'none',
          },
          '&:focus': {
            border: '2px solid',
            borderColor: 'rgba(42, 133, 255, 0.35) !important',
            color: 'inherit',
            boxShadow: 'none',
          },
          ...sx,
        }}
        {...rest}
      />
    );
  }
);

Textarea.displayName = 'Textarea Input';

export default Textarea;
