import React, { FC, useEffect, useMemo } from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Grid,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthRole } from '../../../../authorization';
import { useUserData, useUserDataSelector } from '../../../../hooks';
import { EMAIL_REGEX } from '../../../../utils/constants';
import { UserStatus, UserType } from '../../../../types';
import { SelectOption } from '../../../../atoms';

import InputAuthorize from './InputAuthorize';
import { DisablePermission } from './useDisabledField';
import LocationSelect from './LocationSelect';
import { useIsReadonly } from './useIsReadonly';

export type InfoFormValues = {
  eid: string;
  firstName: string;
  lastName: string;
  name?: string;
  email: string;
  phoneNumber: string;
  location: string[];
  initialLocation?: string[];
  computedLocations?: SelectOption | SelectOption[];
  profilePic: string;
  role: string;
  job?: SelectOption;
  accessDetails: AuthRole;
  initialAccessDetails?: AuthRole;
  permissionLevel?: AuthRole;
  username: string;
  type: UserType;
  status: UserStatus;
  isDeleted: boolean;
};

const accessDetails: SelectOption<AuthRole>[] = [
  { value: AuthRole.SUPER_ADMIN, label: 'Super Admin' },
  { value: AuthRole.ADMIN, label: 'Admin' },
  { value: AuthRole.LOCATION_OWNER, label: 'Location Owner' },
  { value: AuthRole.WORKER, label: 'Worker' },
];

const DisabledAccess: Record<AuthRole, AuthRole[]> = {
  [AuthRole.SUPER_ADMIN]: [],
  [AuthRole.ADMIN]: [AuthRole.SUPER_ADMIN],
  [AuthRole.LOCATION_OWNER]: [AuthRole.SUPER_ADMIN, AuthRole.ADMIN],
  [AuthRole.WORKER]: [
    AuthRole.SUPER_ADMIN,
    AuthRole.ADMIN,
    AuthRole.LOCATION_OWNER,
    AuthRole.WORKER,
  ],
  [AuthRole.LOCATION_USER]: [
    AuthRole.SUPER_ADMIN,
    AuthRole.ADMIN,
    AuthRole.LOCATION_OWNER,
    AuthRole.WORKER,
  ],
};

interface IProps {
  disabledField: Record<keyof DisablePermission, boolean>;
  authRole?: AuthRole;
  disableLocation?: boolean;
}

const ProfileInfoForm: FC<IProps> = ({
  disabledField,
  authRole,
  disableLocation,
}) => {
  const userEntity = useUserData()?.entity;
  const loggedInUserAuthRole = useUserData()?.authRole;
  const loggedInUserType = useUserDataSelector((state) => state.type);
  const { t } = useTranslation(['common', 'profile']);

  const isDeleted = useWatch<InfoFormValues, 'isDeleted'>({
    name: 'isDeleted',
  });

  const userType = useWatch<InfoFormValues, 'type'>({
    name: 'type',
  });

  const accessType = useWatch<InfoFormValues, 'accessDetails'>({
    name: 'accessDetails',
  });

  const {
    control,
    getValues,
    register,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<InfoFormValues>();

  const isReadonly = useIsReadonly();

  const roles = useMemo(() => {
    if (Array.isArray(userEntity?.roles)) {
      return userEntity.roles.map((role) => {
        return {
          label: role.name,
          value: role.name,
        };
      });
    }
    return [];
  }, [userEntity]);

  const locations = useMemo(() => {
    if (Array.isArray(userEntity?.locations)) {
      let _locations: {
        name: string;
        isRoot?: boolean | undefined;
        eid: string;
      }[] = JSON.parse(JSON.stringify(userEntity?.locations));

      let sortedLocations = _locations?.sort((a, b) =>
        a?.name?.localeCompare(b?.name)
      );

      if (accessType === AuthRole.LOCATION_OWNER) {
        sortedLocations = sortedLocations.filter(
          (location) => !location.isRoot
        );
      }

      return sortedLocations.map((location) => {
        return {
          label: location.name,
          value: location.eid,
          id: location.eid,
        };
      });
    }
    return [];
  }, [userEntity, accessType]);

  const getHQ = () => {
    return userEntity?.locations
      ?.filter((location) => location?.isRoot)
      ?.map((location) => location?.eid)?.[0];
  };

  const getTopHierarchyLocationHandler = () => {
    return userEntity?.locations
      ?.filter((loc) => loc?.eid === getValues('initialLocation')?.[0])
      ?.map((loc) => loc?.eid)?.[0];
  };

  const topHierarchyLocationHandler = () => {
    let hq = getHQ();
    if (hq) {
      setValue('location', [hq]);
    } else {
      setValue('location', []);
    }
  };

  const lowHierarchyLocationHandler = () => {
    setValue('location', []);
  };

  // useEffect(() => {
  //   if (accessType === AuthRole.ADMIN || accessType === AuthRole.SUPER_ADMIN) {
  //     topHierarchyLocationHandler();
  //   } else {
  //     lowHierarchyLocationHandler();
  //   }
  // }, [accessType]);

  const hierarchyLocationHandler = (_accessType: AuthRole) => {
    if (
      _accessType === AuthRole.ADMIN ||
      _accessType === AuthRole.SUPER_ADMIN
    ) {
      topHierarchyLocationHandler();
    } else {
      lowHierarchyLocationHandler();
    }
  };

  const locationFieldDisableHandler = () => {
    let shouldDisable = false;
    /**
      authRole === AuthRole.SUPER_ADMIN ||
      disableLocation ||
      loggedInUserAuthRole !== AuthRole.SUPER_ADMIN ||
      accessType === AuthRole.SUPER_ADMIN ||
      accessType === AuthRole.ADMIN
     */
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(accessType) ||
      disableLocation ||
      loggedInUserAuthRole === AuthRole.WORKER ||
      loggedInUserType === 'branch'
    ) {
      shouldDisable = true;
    }
    return shouldDisable;
  };

  return (
    <>
      <Box>
        <Text fontSize='14px' fontWeight='600' textTransform='uppercase'>
          {t('profile:basicDetails')}
        </Text>
        <Grid templateColumns='repeat(2, 1fr)' gap={4} pt={3}>
          <FormControl
            isInvalid={Boolean(errors.firstName)}
            isDisabled={disabledField.firstName || isDeleted}
            isReadOnly={isReadonly}
          >
            <Input
              id='firstName'
              {...register('firstName', {
                required: t('profile:validation.firstNameRequired'),
              })}
              placeholder={t('profile:placeholder.firstName')}
            />
            <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={Boolean(errors.lastName)}
            isDisabled={disabledField.lastName || isDeleted}
            isReadOnly={isReadonly}
          >
            <Input
              id='lastName'
              {...register('lastName', {
                // required: t('profile:validation.lastNameRequired'),
              })}
              placeholder={t('profile:placeholder.lastName')}
            />
            <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
          </FormControl>

          <InputAuthorize
            watch={watch}
            check={(values) => values.type === 'user'}
          >
            <Controller
              control={control}
              name='email'
              rules={{
                required: t('common:validation.email_required'),
                pattern: {
                  // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  value: EMAIL_REGEX,
                  message: t('common:validation.email_invalid'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    gridColumn='1/3'
                    isInvalid={!!fieldState.error}
                    isDisabled={disabledField.email || isDeleted}
                    isReadOnly={isReadonly}
                  >
                    <Input
                      id='email'
                      type='email'
                      {...field}
                      placeholder={t('profile:placeholder.email')}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </InputAuthorize>

          <InputAuthorize
            watch={watch}
            check={(values) => values.type === 'branch'}
          >
            <Controller
              control={control}
              name='username'
              rules={{
                required: t('profile:validation.usernameRequired'),
                pattern: {
                  value: /^[a-zA-Z_0-9]+$/,
                  message: t('profile:validation.usernameInvalid'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    gridColumn='1/3'
                    isInvalid={!!fieldState.error}
                    isDisabled
                  >
                    <Input
                      id='username'
                      {...field}
                      placeholder={t('profile:placeholder.username')}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </InputAuthorize>

          <FormControl
            isInvalid={Boolean(errors.phoneNumber)}
            isDisabled={disabledField.phoneNumber || isDeleted}
            isReadOnly={isReadonly}
          >
            <Input
              id='phoneNumber'
              {...register('phoneNumber', {
                // required: t('profile:validation.phoneRequired'),
              })}
              placeholder={t('profile:placeholder.phone')}
            />
            <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
          </FormControl>

          <Controller
            control={control}
            name='location'
            rules={{ required: 'Location is required' }}
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  isInvalid={!!fieldState.error}
                  isDisabled={locationFieldDisableHandler()}
                  isReadOnly={isReadonly}
                >
                  <LocationSelect
                    locations={locations}
                    value={field.value}
                    onChange={field.onChange}
                    isMulti={
                      accessType === AuthRole.LOCATION_OWNER ? true : undefined
                    }
                  />
                  <FormErrorMessage>
                    {fieldState.error?.message}
                  </FormErrorMessage>
                  {/* <Select
                    id='location'
                    placeholder={t('profile:placeholder.location')}
                    {...register('location', {
                      // required: t('profile:validation.roleRequired'),
                    })}
                  >
                    {locations.map((location, index) => (
                      <option key={index} value={location.value}>
                        {location.label}
                      </option>
                    ))}
                  </Select> */}
                </FormControl>
              );
            }}
          />

          {/* <FormControl
            isDisabled={
              authRole === AuthRole.SUPER_ADMIN ||
              disableLocation ||
              loggedInUserAuthRole !== AuthRole.SUPER_ADMIN
            }
          >
            <Select
              id='location'
              placeholder={t('profile:placeholder.location')}
              {...register('location', {
                // required: t('profile:validation.roleRequired'),
              })}
            >
              {locations.map((location, index) => (
                <option key={index} value={location.value}>
                  {location.label}
                </option>
              ))}
            </Select>
          </FormControl> */}

          <InputAuthorize
            watch={watch}
            check={(values) => values.type === 'user'}
          >
            <Controller
              control={control}
              name='role'
              rules={{
                required: t('profile:validation.roleRequired'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    gridColumn='1/3'
                    isInvalid={!!fieldState.error}
                    isDisabled={disabledField.role || isDeleted || isReadonly}
                  >
                    <Select
                      id='role'
                      {...field}
                      placeholder={t('profile:placeholder.role')}
                    >
                      {roles.map((role, index) => (
                        <option key={index} value={role.value}>
                          {role.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </InputAuthorize>
        </Grid>
      </Box>

      <Box>
        <Text fontSize='14px' fontWeight='600' textTransform='uppercase'>
          {t('profile:accessDetails')}
        </Text>

        <FormControl
          mt={3}
          isInvalid={Boolean(errors.accessDetails)}
          isDisabled={disabledField.accessDetails || isDeleted || isReadonly}
        >
          {userType === 'branch' ? (
            <Select
              id='location'
              placeholder={t('profile:placeholder.accessDetails')}
              value='branch'
            >
              <option value='branch'>Location</option>
            </Select>
          ) : (
            <>
              <Select
                id='accessDetails'
                {...register('accessDetails', {
                  required: t('profile:validation.accessDetailRequired'),
                })}
                // placeholder={t('profile:placeholder.accessDetails')}
                // placeholder='Select Access Details'
                onChange={(e) => {
                  if (e?.target?.value) {
                    setValue('accessDetails', e?.target?.value as AuthRole);
                    hierarchyLocationHandler(e?.target?.value as AuthRole);
                    // if (
                    //   accessType === AuthRole.ADMIN ||
                    //   accessType === AuthRole.SUPER_ADMIN
                    // ) {
                    //   topHierarchyLocationHandler();
                    //   // } else if (accessType === AuthRole.LOCATION_OWNER) {
                    // } else {
                    //   if (getHQ() === getTopHierarchyLocationHandler()) {
                    //     setValue('location', []);
                    //   } else {
                    //     let loc = getTopHierarchyLocationHandler();
                    //     loc && setValue('location', [loc]);
                    //   }
                    // }
                    // setValue('location', [locations?.[0]?.id]);
                  }
                }}
              >
                <option selected hidden disabled value=''>
                  {t('profile:placeholder.accessDetails')}
                </option>
                {accessDetails.map((access, index) => (
                  <option
                    key={index}
                    value={access.value}
                    disabled={DisabledAccess[loggedInUserAuthRole!]?.includes(
                      access.value
                    )}
                  >
                    {access.label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.accessDetails?.message}
              </FormErrorMessage>
            </>
          )}
        </FormControl>
      </Box>
    </>
  );
};

export default ProfileInfoForm;
