import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from 'authorization';
import { PrimaryButton } from 'atoms';
import { ADD_LOCATION } from 'appRoutes';
import DashboardContainer from 'sub-components/DashboardContainer';

import { LocationListContainer } from '../Teams/locations';
// import { LocationListContainer } from '../Teams/new-locations';

import { LocationProvider } from '../Teams/new-locations/context';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const Wrapper = styled.div`
  background: white;
  border-radius: 12px;
  padding-block: 24px;
  padding-inline: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .sop-list-loader {
    flex: 1;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;
    border-radius: 5px;
    display: table;
    max-width: unset;
    // max-width: -moz-fit-content;
    // /* max-width: fit-content; */
    overflow-x: auto;
    white-space: nowrap;
  }

  table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`;

const Header: FC = () => {
  const history = useHistory();

  const { t } = useTranslation(['sidebar', 'team']);

  return (
    <Flex align='center' justify='space-between' mb='20px'>
      <Box fontSize='28px' fontWeight='700' color='#272B30'>
        {t('sidebar:locations')}
      </Box>
      <Authorize
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        permittedFor='user'
      >
        <PrimaryButton
          leftIcon={<AddIcon />}
          title={t('team:addNewLocation')}
          width={'fit-content'}
          variant='outline'
          colorScheme='blue'
          onClick={() => {
            deployEvent(AmplitudeEventNames.LOCATION_ADD_NEW_LOCATION);
            history.push(ADD_LOCATION);
          }}
        />
      </Authorize>
    </Flex>
  );
};

const LocationPage: FC = () => {
  return (
    <DashboardContainer>
      <Header />
      <Wrapper>
        <LocationProvider>
          <LocationListContainer />
        </LocationProvider>
      </Wrapper>
    </DashboardContainer>
  );
};

export default LocationPage;
