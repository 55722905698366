import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import { fileTOBlobData } from '../utils/blobHelper';
import { GET_SIGNED_URL } from '../ui-components/Profile';

type Progress = (progress: number) => void;

const extractNameType = (data: Blob, fileName?: string) => {
  // @ts-ignore
  if (typeof data?.name !== 'string') {
    return {
      name: fileName,
      type: data.type,
    };
  }
  return data as File;
};

type UseUploadImage = (
  file: File | Blob | null,
  fileName?: string,
  uploadProgress?: Progress
) => Promise<string>;

function useUploadImage(): UseUploadImage {
  const [getSignedUrlRequest] = useMutation(GET_SIGNED_URL);

  const getSignedUrl = useCallback(
    async (input) => {
      return await getSignedUrlRequest({
        variables: {
          getSignedUrlInput: input,
        },
      }).then((res) => res?.data?.getSignedUrl);
    },
    [getSignedUrlRequest]
  );

  return useCallback(async (file, fileName, uploadProgress) => {
    return new Promise<string>((resolve, reject) => {
      if (file) {
        const { name: _filename, type } = extractNameType(file, fileName);
        getSignedUrl([{ filename: _filename, mimetype: type }])
          .then(async (signedUrls) => {
            if (Array.isArray(signedUrls) && signedUrls.length) {
              const data = signedUrls[0];
              const signedRequestUrl = data.signedRequestUrl;
              const imageUrl = data.url;

              const blobData = await fileTOBlobData(file);

              await axios
                .put(signedRequestUrl, blobData, {
                  onUploadProgress: (progressEvent) => {
                    uploadProgress?.(
                      Math.floor(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    );
                  },
                })
                .then(() => resolve(imageUrl))
                .catch(reject);
            } else {
              reject('');
            }
          })
          .catch(reject);
      } else {
        reject('File not available');
      }
    });
  }, []);
}

export { useUploadImage };
