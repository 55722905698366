import React, { CSSProperties, FC } from 'react';
import { UserAnalyticsOverviewType } from 'sub-components/AnalyticsBoard/AnalyticsBoard';
import { wrapTextWithSpan } from '../NumericStats';
import ParentWrapper from './ParentWrapper';
import { Text } from '@chakra-ui/react';
import { useUserData } from 'hooks';
import { AuthRole } from 'sop-commons/src/client';
import { shouldRender } from '../../../helper';

const FormsFilledSection: FC<{
  style?: CSSProperties;
  analyticsData: UserAnalyticsOverviewType['userAnalyticsOverview'] | undefined;
}> = ({ style, analyticsData }) => {
  const userData = useUserData();
  const renderText = () => {
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER]?.includes(
        userData?.authRole
      ) &&
      userData?.type === 'user'
    ) {
      return analyticsData?.formsFilled! > 0 ? (
        <>
          {wrapTextWithSpan('Members from all your locations have filled')}
          <Text fontWeight={700} color='#2A85FF'>
            {analyticsData?.formsFilled}
          </Text>
          <Text>{analyticsData?.formsFilled === 1 ? 'form' : 'forms'}</Text>
        </>
      ) : (
        <>
          {wrapTextWithSpan('Click here to')}
          <Text fontWeight={700} color='#2A85FF'>
            create
          </Text>
          <Text>forms</Text>
        </>
      );
    } else {
      return (
        <>
          {wrapTextWithSpan('You have filled')}
          <Text fontWeight={700} color='#2A85FF'>
            {analyticsData?.formsFilled}
          </Text>
          {wrapTextWithSpan(
            `${
              analyticsData?.formsFilled === 1 ? 'form' : 'forms'
            } since you joined.`
          )}
        </>
      );
    }
  };
  const compRender = () => {
    let flag = shouldRender(analyticsData?.formsFilled || 0);
    if (flag) {
      return (
        <ParentWrapper
          title='Forms filled'
          totalCount={analyticsData?.formsFilled + ''}
          style={style}
          redirectPath={
            analyticsData?.formsFilled! > 0 ? '/forms' : '/forms/create'
          }
          backToTitle='Back to Home'
        >
          {renderText()}
        </ParentWrapper>
      );
    } else {
      return null;
    }
  };

  return compRender();
};

export default FormsFilledSection;
