import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';

interface ISubSection {
  isLive?: boolean;
  locationName?: string;
  goToLocation?: () => void;
}

const SubSection: FC<ISubSection> = ({
  isLive,
  locationName,
  goToLocation,
}) => {
  if (isLive) {
    return (
      <PrimaryButton
        title={`Go to ${locationName}`}
        variant='solid'
        colorScheme='blue'
        width='fit-content'
        onClick={() => goToLocation?.()}
      />
    );
  }

  return (
    <Text fontWeight={400} fontSize='14px' color='#00000080' textAlign='center'>
      Once you make your location live, you will gain access to a range of
      powerful features. You will be able to add more members to your team,
      assign tasks to them, track their progress, assign trainings to enhance
      their skills, and much more.
    </Text>
  );
};

export default SubSection;
