// InviteMemberModal.tsx
import React, { FC, useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { gql, useLazyQuery } from '@apollo/client';
import InviteUserForm from './InviteUserForm';
import { IFormInput } from './invite.types';
import Header from './Header';
import ShareLinkTabs from './ShareLinkTabs';
import ChatGroups from './ChatGroups';
import TrainingPaths from './TrainingPaths';
import { AuthRole } from '../../authorization';
import { InvitedUser } from './invite.graphql';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onActionCompleted?: (pathName: string, tabSelected: string) => void;
  shouldRefetch?: (data: Omit<InvitedUser, 'inviteUrl' | 'code'>) => void;
  inviteOnlyFor?: AuthRole;
  defaultValues?: Pick<IFormInput, 'location'>;
  /** Used to disable location select if passing default location value which cannot be changed. Example is inviting location owner for a specific location, there another location cannot be added */
  shouldDisableLocationSelect?: boolean;
  /** Used to hide the 'cross' icon from selected location chips. Specifically used when inviting location owner for a specific location and that location must not be removed as location owner is being added for that location only */
  shouldPreventLocationRemoval?: boolean;
}

const GET_BRANCH = gql`
  query UI_IUN_Branch {
    branch {
      descendantBranches {
        name
        eid
        type
        role
        authRole
        email
        createdAt
        status
        isRoot
      }
      root {
        name
        eid
      }
    }
  }
`;

const InviteUserModal: FC<IProps> = ({
  isOpen,
  onClose,
  onActionCompleted,
  shouldRefetch,
  inviteOnlyFor,
  defaultValues,
  shouldDisableLocationSelect,
  shouldPreventLocationRemoval,
}) => {
  const [rootLocation, setRootLocation] = useState<{
    eid: string;
    name: string;
  }>({ eid: '', name: '' });

  const methods = useForm<IFormInput>({
    defaultValues: {
      currentStep: 'inviteForm',
      permissionLevel: inviteOnlyFor,
      linkSend: {
        email: false,
        phone: false,
      },
      ...defaultValues,
    },
  });

  const [getRootLocationData] = useLazyQuery(GET_BRANCH, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setRootLocation({
        eid: data?.branch?.root?.eid,
        name: data?.branch?.root?.name,
      });
    },
  });

  useEffect(() => {
    if (isOpen) {
      getRootLocationData();
    }
  }, [isOpen]);

  const closeHandler = () => {
    methods.reset();
    onClose();
  };

  const currentStep = methods.watch('currentStep');

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeHandler}
      size='xl'
      isCentered={currentStep !== 'inviteForm'}
      scrollBehavior={currentStep !== 'inviteForm' ? 'inside' : undefined}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent padding='12px' w='full'>
        <FormProvider {...methods}>
          <Header />
          {currentStep === 'inviteForm' && (
            <InviteUserForm
              inviteOnlyFor={inviteOnlyFor}
              rootLocation={rootLocation}
              onClose={closeHandler}
              shouldRefetch={shouldRefetch}
              shouldDisableLocationSelect={shouldDisableLocationSelect}
              shouldPreventLocationRemoval={shouldPreventLocationRemoval}
            />
          )}

          {currentStep === 'shareTabs' && <ShareLinkTabs />}

          {currentStep === 'trainingPath' && (
            <TrainingPaths closeHandler={closeHandler} />
          )}

          {/*{currentStep === 'chatGroup' && (*/}
          {/*  <ChatGroups closeHandler={closeHandler} />*/}
          {/*)}*/}
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default InviteUserModal;
