import React, { FC, useMemo, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

import {
  Column,
  SortingTable,
} from '../../../sub-components/ChakraTable/SortingTable';
import WorkerPathName from '../../../sub-components/TrackTrainingProgress/WorkerPathName';
import UserTrainings from '../../../sub-components/TrackTrainingProgress/UserTrainings';
import EmptyState from '../../../sub-components/EmptyState';
import { SortByDate, SortByName } from '../../../utils/sorting';

import { getAllTracks, TrackResponse } from './training.graphql';
import { MemberTrainingList, MemberTrainingModal } from './modal';
import { MoreTrainingCell } from './Component';
import { SelectOption } from '../../../atoms';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  table {
    tr > td {
      vertical-align: top;
    }
  }
`;

interface IProps {
  filterValue?: SelectOption | null;
  query?: string;
}

const MemberTraining: FC<IProps> = ({ filterValue, query }) => {
  const { t, i18n } = useTranslation(['home', 'training', 'role']);
  const [selectedUser, setSelectedUser] = useState<MemberTrainingModal>();

  const { data, loading } = useQuery<TrackResponse>(getAllTracks, {
    fetchPolicy: 'network-only',
  });

  const onPathnameClick = (value?: MemberTrainingModal) => {
    deployEvent(AmplitudeEventNames.TRAINING_PATH_MEMBER_SELECTED);
    setSelectedUser(value);
  };

  const columns = useMemo((): Column<MemberTrainingModal>[] => {
    return [
      {
        Header: t('home:member'),
        accessor: 'name',
        width: '30%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <WorkerPathName
              pathName={value}
              thumbnail={row?.original.profilePic}
              index={row.index}
              address={row?.original.address}
              status='active'
              onClick={() => onPathnameClick(row?.original)}
            />
          );
        },
        sortType: SortByName,
      },
      {
        Header: t('role:role'),
        accessor: 'authRole',
        width: '16%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Box
              mb='25px'
              fontSize='15px'
              fontWeight='600'
              textTransform='capitalize'
            >
              {value}
              <Text
                fontSize='13px'
                color='#2A85FF'
                pt={1}
                m={0}
                maxW='150px'
                whiteSpace='normal'
              >
                {row.original.role}
              </Text>
            </Box>
          );
        },
        sortType: SortByDate,
      },
      {
        Header: t('home:unfinished_training'),
        accessor: 'unfinishedTrainings',
        width: '27%',
        Cell: ({ cell: { value } }) => {
          return <MoreTrainingCell value={value} />;
        },
        disableSortBy: true,
      },
      {
        Header: t('home:assign_training'),
        accessor: 'assignedTrainings',
        width: '27%',
        Cell: ({ cell: { value } }) => {
          return (
            <MoreTrainingCell
              value={value}
              background='rgba(177, 229, 252, 0.4)'
            />
          );
        },
        disableSortBy: true,
      },
    ];
  }, [i18n.language]);

  const tableData = useMemo(() => {
    return new MemberTrainingList(data?.trackWorkersTraining).data;
  }, [data]);

  const filteredData = useMemo(() => {
    return tableData.filter((it) => {
      let res: unknown = true;
      let queryRes: unknown = true;

      if (query) {
        const searchExp = new RegExp(query, 'gi');
        queryRes =
          it.role?.match(searchExp) ||
          it.name?.match(searchExp) ||
          it.authRole?.match(searchExp) ||
          it.address?.match(searchExp);
      }

      res = it.authRole === filterValue?.value || filterValue?.value === 'all';

      return !!res && !!queryRes;
    });
  }, [tableData, filterValue, query]);

  return (
    <>
      <Wrapper>
        <SortingTable
          colorScheme='blue'
          customWrapperStyles={{ marginTop: '1rem' }}
          emptyData={{
            content: (
              <EmptyState
                image='MemberTraining'
                title={t('training:memberUndergoing')}
                description={t('training:memberUndergoingDesc')}
              />
            ),
          }}
          page={1}
          columns={columns}
          data={filteredData}
          isLoading={loading}
          isResponsive
          isHidePagination={true}
        />
      </Wrapper>
      {selectedUser?.eid ? (
        <UserTrainings
          user={selectedUser.jsonData}
          onClose={() => setSelectedUser(undefined)}
        />
      ) : null}
    </>
  );
};

export default MemberTraining;
