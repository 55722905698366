import { useLazyQuery } from '@apollo/client';
import { Center, Flex, useToast } from '@chakra-ui/react';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DashboardContainer from 'sub-components/DashboardContainer';
import InProgressCard from 'sub-components/LocationLaunchDashboard/InProgress/Singleton';
import OpeningTask from 'sub-components/LocationLaunchDetail/OpeningTask';
import { IFilterBadgeProps } from 'sub-components/LocationLaunchDetail/OpeningTask/Filter/FilterBadge/Singleton/FilterBadge';
import PhaseSection from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/Section/PhaseSection';
import StatusSection from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/Section/StatusSection';
import Loader from 'ui-components/Loader';
import { getCompletedTask } from '../dashboard/launcher.helpers';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import { useUserDataSelector } from 'hooks';
import {
  getPhaseData,
  foundLocationDataHandler,
  taskClickSimulate,
  getLastTask,
  getCurrentTask,
} from './helper';
import { AuthRole } from 'sop-commons/src/client';
import InviteUserModal from 'ui-components/InviteUserNew/InviteUserModal';
import { eventBus } from 'shared/eventEmit';
import { IRefetchType } from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailDrawer/task-detail-drawer.types';
import TaskDetailModal from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailModal';
import useCombinedStore from 'zustandStore/store';
import {
  LAUNCHER_LOCATION_USERS,
  LOCATION_LAUNCH_BY_ID,
} from './graphql/location-launch-details.graphql';
import {
  LauncherLocationByIdDetail,
  LauncherLocationDetail,
  LauncherLocationDetailTask,
} from './types/location-launch-details.types';
import LocationDetailsHeader from './components/LocationDetailsHeader';
import { groupLocationDetails } from './helper/getGroupedData';

const filterOptions: IFilterBadgeProps[] = [
  { label: 'By phase', value: 'phase' },
  { label: 'By status', value: 'status' },
];

/**
 * LocationLaunchDetails component renders the details of a location's launch process.
 * It displays various sections such as progress, help queries, and tasks.
 */
const LocationLaunchDetails: FC = () => {
  const fetchDataEmit = useCombinedStore(
    (state) => state.events['LauncherDetailsPage']['fetchCompleteData']
  );
  const fetchDataFromEditDueDateSideDrawer = useCombinedStore(
    (state) => state.events['EditLauncherTaskSideDrawer']['fetchCompleteData']
  );
  const clearEvent = useCombinedStore((state) => state.clearEvent);
  const launcherId = useRouteMatch<{ locId: string }>().params?.locId;
  const loggedInUserTimezone = useUserDataSelector((state) => state.timezone);
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const loggedInUserEid = useUserDataSelector((state) => state.eid);
  const [openInvite, setOpenInvite] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string>(
    filterOptions[0].value
  );
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });
  const [taskDrawerData, setTaskDrawerData] = useState<{
    modal: boolean;
    selectedData: CustomLauncherTask | undefined;
  }>({ modal: false, selectedData: undefined });

  const [getLauncherLocationById, { data, loading, refetch }] = useLazyQuery<
    { LocationLaunchById: LauncherLocationByIdDetail },
    { eid: string }
  >(LOCATION_LAUNCH_BY_ID, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (launcherId) {
      getLauncherLocationByIdHandler();
    }
  }, [launcherId]);

  const getLauncherLocationByIdHandler = () => {
    getLauncherLocationById({
      variables: {
        eid: launcherId,
      },
    });
  };

  useEffect(() => {
    if (fetchDataEmit) {
      locationLaunchDetailsHandler();
      clearEvent('LauncherDetailsPage', 'fetchCompleteData');
    }
    if (fetchDataFromEditDueDateSideDrawer) {
      locationLaunchDetailsHandler();
      clearEvent('EditLauncherTaskSideDrawer', 'fetchCompleteData');
    }
  }, [fetchDataEmit, fetchDataFromEditDueDateSideDrawer, clearEvent]);

  const locationLaunchDetailsHandler = () => {
    getLauncherLocationByIdHandler();
  };

  const foundLocationData = useMemo(() => {
    let foundLocation = foundLocationDataHandler(
      data?.LocationLaunchById,
      loggedInUserTimezone,
      loggedInUserAuthRole,
      loggedInUserEid
    );
    return foundLocation;
  }, [data?.LocationLaunchById, launcherId]);
  console.log({ foundLocationData });
  const [
    getLauncherLocationUsers,
    {
      data: launcherLocationUserData,
      loading: launcherLocationUserLoading,
      refetch: launcherLocationUsersRefetch,
    },
  ] = useLazyQuery(LAUNCHER_LOCATION_USERS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (foundLocationData?.locationId) {
      getLauncherLocationUsers({
        variables: {
          locationId: foundLocationData?.locationId,
          authRoles: ['locationOwner'],
        },
      });
    }
  }, [foundLocationData?.locationId]);

  // const completed = useMemo(() => {
  //   let _task: LauncherLocation['tasks'] = [];
  //   foundLocationData?.tasks?.map((found) => {
  //     _task?.push({
  //       category: found?.category,
  //       completedAt: found?.completedAt,
  //       dueDate: found?.dueDate,
  //       eid: found?.eid,
  //       isCompleted: found?.isCompleted,
  //       startDate: found?.startDate,
  //       title: found?.title,
  //     });
  //   });
  //   let completedTaskData = getCompletedTask(_task)?.at(-1);
  //   let found = foundLocationData?.tasks?.find(
  //     (t) => t?.eid === completedTaskData?.eid
  //   );
  //   return {
  //     ...completedTaskData,
  //     formattedDueDate: found?.formattedDueDate,
  //     formattedStartDate: found?.formattedStartDate,
  //     formattedCompleteDate: found?.formattedCompleteDate,
  //     formattedDuration: found?.formattedDuration,
  //   };
  // }, [foundLocationData?.tasks]);

  // const currently = useMemo(() => {
  //   let _task: LauncherLocation['tasks'] = [];
  //   foundLocationData?.tasks?.map((found) => {
  //     _task?.push({
  //       category: found?.category,
  //       completedAt: found?.completedAt,
  //       dueDate: found?.dueDate,
  //       eid: found?.eid,
  //       isCompleted: found?.isCompleted,
  //       startDate: found?.startDate,
  //       title: found?.title,
  //     });
  //   });
  //   let currentTaskData = getCurrentTask(_task);
  //   let found = foundLocationData?.tasks?.find(
  //     (t) => t?.eid === currentTaskData?.eid
  //   );
  //   return {
  //     ...currentTaskData,
  //     formattedDueDate: found?.formattedDueDate,
  //     formattedStartDate: found?.formattedStartDate,
  //     formattedCompleteDate: found?.formattedCompleteDate,
  //     formattedDuration: found?.formattedDuration,
  //   };
  // }, [foundLocationData?.tasks]);

  const onTaskClickHandler = (taskData: LauncherLocationDetailTask) => {
    let simulatedTaskData = taskClickSimulate(taskData, foundLocationData);
    setTaskDrawerData({ modal: true, selectedData: simulatedTaskData });
  };

  const onTaskDrawerClose = () => {
    setTaskDrawerData({ modal: false, selectedData: undefined });
  };

  const refetchHandler = async (type: IRefetchType) => {
    if (refetch) {
      try {
        const { data: newData } = await refetch();
        if (type === 'step') {
          eventBus.emit('stepRefetchComplete');
        }
        let foundLocationData = foundLocationDataHandler(
          newData?.LocationLaunchById,
          loggedInUserTimezone,
          loggedInUserAuthRole,
          loggedInUserEid
        );
        if (foundLocationData) {
          let foundTaskData = foundLocationData?.tasks?.find(
            (task) => task?.eid === taskDrawerData?.selectedData?.eid
          );
          if (foundTaskData) {
            let simulatedTaskData = taskClickSimulate(
              foundTaskData,
              foundLocationData
            );
            let modalFlag = false;
            if (
              simulatedTaskData?.completedAt &&
              taskDrawerData?.selectedData?.completedAt
            ) {
              modalFlag = false;
            }
            if (
              simulatedTaskData?.completedAt &&
              taskDrawerData?.selectedData?.completedAt &&
              simulatedTaskData?.files?.length !==
                taskDrawerData?.selectedData?.files?.length
            ) {
              modalFlag = true;
            }
            if (
              simulatedTaskData?.completedAt &&
              !taskDrawerData?.selectedData?.completedAt
            ) {
              modalFlag = false;
            }
            if (
              !simulatedTaskData?.completedAt &&
              taskDrawerData?.selectedData?.completedAt
            ) {
              modalFlag = false;
            }
            if (
              !simulatedTaskData?.completedAt &&
              !taskDrawerData?.selectedData?.completedAt
            ) {
              modalFlag = true;
            }
            setTaskDrawerData({
              modal: modalFlag,
              selectedData: simulatedTaskData,
            });
          }
        }
      } catch (err) {
        if (type === 'step') {
          eventBus.emit('stepRefetchError');
        }
      }
    }
  };

  const phaseSectionData = useMemo(() => {
    return getPhaseData(foundLocationData);
  }, [foundLocationData]);

  // const _currentTaskData = useMemo(() => {
  //   return currentTaskData(foundLocationData, loggedInUserTimezone);
  // }, [phaseSectionData, foundLocationData]);

  // const _lastTaskData = useMemo(() => {
  //   return lastTaskData(foundLocationData, loggedInUserTimezone);
  // }, [phaseSectionData, foundLocationData]);

  const trackTaskData = useMemo(() => {
    const grouped = groupLocationDetails(
      foundLocationData as unknown as LauncherLocationDetail
    );
    const currentTask = getCurrentTask(grouped, loggedInUserTimezone);
    const lastTask = getLastTask(foundLocationData?.tasks || []);
    return { currentTask, lastTask };
  }, [foundLocationData]);

  const onAddLOClick = () => {
    setOpenInvite(true);
  };

  const owners = useMemo(() => {
    return launcherLocationUserData?.locationUser || [];
  }, [launcherLocationUserData]);

  const launcherLocationUsersRefetchHandler = () => {
    launcherLocationUsersRefetch?.();
  };

  return (
    <DashboardContainer>
      {loading || launcherLocationUserLoading ? (
        <Center h='99vh'>
          <Loader />
        </Center>
      ) : (
        // @ts-ignore
        <Flex gap={6} flexDir='column' w='full'>
          <LocationDetailsHeader
            foundLocationData={foundLocationData}
            launcherLocationUsersRefetchHandler={
              launcherLocationUsersRefetchHandler
            }
            owners={owners}
            onAddLOClick={onAddLOClick}
          />
          <InProgressCard
            orientation='horizontal'
            foundLocationData={foundLocationData!}
            callbackFn={locationLaunchDetailsHandler}
            topDetails={{
              completed:
                foundLocationData?.tasks?.filter((task) => task?.isCompleted)
                  ?.length || 0,
              locName: foundLocationData?.details?.locationName!,
              startedOnDate: '',
              status: 'onTrack',
              totalCount: foundLocationData?.tasks?.length || 0,
            }}
            midDetails={{
              currentStage:
                trackTaskData?.currentTask?.currentlyOn?.title || '',
              lastDoneOn: trackTaskData?.lastTask?.lastTask?.completedAt || '',
              lastDoneStep: trackTaskData?.lastTask?.lastTask?.title || '',
              sinceWhen:
                trackTaskData?.currentTask?.currentlyOn?.sinceWhen || '',
              currentPhase:
                trackTaskData?.currentTask?.currentlyOn?.category || '',
              currentPhaseSinceWhen:
                trackTaskData?.currentTask?.currentlyOn?.sinceWhen || '',
              lastDonePhase: trackTaskData?.lastTask?.lastTask?.category || '',
              lastPhaseCompletedOn:
                trackTaskData?.lastTask?.lastTask?.completedAt || '',
            }}
          />
          <OpeningTask
            filterOptions={filterOptions}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
          {selectedFilter === filterOptions?.[0]?.value ? (
            <PhaseSection
              foundLocationData={foundLocationData}
              launchId={launcherId}
              phaseSectionData={phaseSectionData}
              taskClickHandler={onTaskClickHandler}
            />
          ) : (
            <StatusSection
              data={foundLocationData}
              launchId={launcherId}
              taskClickHandler={onTaskClickHandler}
            />
          )}
        </Flex>
      )}
      {taskDrawerData?.modal ? (
        <TaskDetailModal
          isOpen={taskDrawerData?.modal}
          mode={taskDrawerData?.selectedData?.completedAt ? 'view' : 'submit'}
          onClose={onTaskDrawerClose}
          selectedTaskData={taskDrawerData?.selectedData!}
          refetchHandler={refetchHandler}
        />
      ) : null}
      {openInvite && (
        <InviteUserModal
          isOpen={openInvite}
          onClose={() => setOpenInvite(false)}
          onActionCompleted={() => {}}
          shouldRefetch={(data) => {
            setTimeout(() => {
              toast({
                status: 'success',
                title: `${data?.name} invited successfully`,
              });
              launcherLocationUsersRefetch && launcherLocationUsersRefetch();
            }, 4000);
          }}
          inviteOnlyFor={AuthRole.LOCATION_OWNER}
          defaultValues={{
            location: [
              {
                id: foundLocationData?.locationId,
                value: foundLocationData?.details?.locationName!,
                label: foundLocationData?.details?.locationName!,
              },
            ],
          }}
          shouldDisableLocationSelect
          shouldPreventLocationRemoval
        />
      )}
    </DashboardContainer>
  );
};

export default LocationLaunchDetails;
