import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Center, Flex } from '@chakra-ui/react';

import { Loader } from 'sub-components';
import DashboardContainer from 'sub-components/DashboardContainer';

import EditLocationForm, { EditLocationRef } from './EditLocationForm';
import { LOCATION_DATA_QUERY, LocationResponse } from './edit-location.graphql';
import { checkLocationCanEdit, convertToFormData } from './data-transform';

interface Params {
  locationId: string;
}

interface IProps {}

const EditLocation: FC<IProps> = () => {
  const history = useHistory();
  const formRef = useRef<EditLocationRef>(null);

  const locationId = useParams<Params>()?.locationId;

  const [getLocationData, { loading }] = useLazyQuery<LocationResponse>(
    LOCATION_DATA_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        eid: locationId,
      },

      onCompleted: (response) => {
        if (response.userById && checkLocationCanEdit(response.userById)) {
          formRef.current?.initForm(convertToFormData(response.userById));
        } else {
          history.goBack();
        }
      },
    }
  );

  useEffect(() => {
    if (locationId) {
      getLocationData({
        variables: {
          eid: locationId,
        },
      });
    }
  }, [locationId]);

  const onMoveSuccess = useCallback(() => {
    if (locationId) {
      getLocationData({
        variables: {
          eid: locationId,
        },
      });
    }
  }, [locationId]);

  return (
    <DashboardContainer>
      <Flex
        flexDir='column'
        position='relative'
        overflow={loading ? 'hidden' : 'initial'}
      >
        <EditLocationForm
          ref={formRef}
          locationId={locationId}
          onMoveSuccess={onMoveSuccess}
        />

        {loading && (
          <Center
            pos='absolute'
            boxSize='100%'
            top='86px'
            borderRadius='8px'
            bg='blackAlpha.400'
          >
            <Loader size='lg' />
          </Center>
        )}
      </Flex>
    </DashboardContainer>
  );
};

export default EditLocation;
