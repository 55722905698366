import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';

interface IProps {
  status: string;
}

const LocationStatus: FC<IProps> = ({ status }) => {
  const { t } = useTranslation('common');
  return (
    <Flex whiteSpace='pre-wrap'>
      <Box
        as={'span'}
        fontSize='12px'
        fontWeight='600'
        p='5px 16px'
        borderRadius='6px'
        textTransform='capitalize'
        bg={
          status === 'open'
            ? 'rgba(181, 228, 202, 0.4)'
            : 'rgba(255, 188, 153, 0.2)'
        }
        color={status === 'open' ? '#83BF6E' : '#ff6a55'}
      >
        {/*// @ts-ignore */}
        {t(status)}
      </Box>
    </Flex>
  );
};

export default LocationStatus;
