import { Flex, Text } from '@chakra-ui/react';
import React, { CSSProperties, FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

interface ParentWrapperProps {
  title: string;
  totalCount: string;
  style?: CSSProperties;
  children: ReactNode;
  redirectPath?: string;
  backToTitle?: string;
}

const ParentWrapper: FC<ParentWrapperProps> = ({
  title,
  totalCount,
  style,
  children,
  redirectPath,
  backToTitle,
}) => {
  const history = useHistory();
  const redirectHandler = () => {
    if (redirectPath) {
      if (backToTitle) {
        history.push(redirectPath, {
          backToTitle: backToTitle,
        });
      } else {
        history.push(redirectPath);
      }
    }
  };
  return (
    <Flex
      style={style}
      p={4}
      flexDirection='column'
      gap='10px'
      w='full'
      cursor='pointer'
      onClick={redirectHandler}
    >
      <Text fontWeight={600} fontSize='16px'>
        {title}
      </Text>
      <Text fontWeight={700} fontSize='32px'>
        {totalCount}
      </Text>
      <Flex
        fontWeight={400}
        fontSize='12px'
        color='#696974'
        gap='2px'
        flexWrap='wrap'
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default ParentWrapper;
