import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  List,
  Text,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';

import { SearchInput } from 'atoms';
import { searchRegExp } from 'utils';
import { HeaderColorValues } from 'shared/headerColors/header-colors';
import GenericColorHeader from 'sub-components/GenericColorHeader';

import { SelectOption } from '../custom-select-component.types';
import AuthRoleFilter from 'sub-components/CustomDropdowns/AuthRoleFilter';
import Loader from 'ui-components/Loader';
import EmptyState from 'sub-components/EmptyState';
import MenuItem from './MenuItem';
import { AuthRoleFilterOptions } from 'sub-components/CustomDropdowns/AuthRoleFilter/AuthRoleFilter';

interface IProps {
  /** Color of the header bar, defined in HeaderColorValues. */
  headerBarColor: HeaderColorValues;

  /** Title displayed in the header of the dropdown. */
  headerTitle: string;

  /** Controls whether the header is visible. `true` shows the header, `false` hides it. */
  showHeader: boolean;

  /** Controls the visibility of the close icon in the header. */
  showHeaderCloseIcon: boolean;

  /** Function called to reset the dropdown to its initial state. */
  reset: () => void;

  /** Controls the visibility of the search field. `true` shows, `false` hides. */
  showSearchField: boolean;

  /** Placeholder text displayed in the search field. */
  searchFieldPlaceholder: string;

  /** Array of options available in the dropdown, each being a `SelectOption` object. */
  options: SelectOption[];

  /** Function to set the visibility state of the dropdown list. */
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;

  /** Function to handle the event when an option in the dropdown is clicked. */
  handleOptionClick: (eid: string) => void;

  /** Array of strings representing the currently selected values in the dropdown. */
  selectedValues: string[];

  /** Determines whether dividers between items are shown. */
  showDivider: boolean;

  /** Configuration for an optional button displayed at the bottom of the dropdown. */
  bottomOutsideBtn: {
    show: boolean;
    title: string;
  };

  /** Optional property to show or hide filter options. */
  showFilter?: boolean;

  /** Controls the display of the dropdown list. */
  showList: boolean;

  /** Optional property to control the visibility of the 'Select All' option. */
  showSelectAll?: boolean;

  /** Optional custom width for the dropdown. If not provided, full width is used. */
  customWidth?: string;

  /** Indicates if the dropdown is in a loading state. */
  loading?: boolean;

  /** Optional: Auth role filters */
  authRoleFilterOptions: AuthRoleFilterOptions[];

  /** Function called to apply the selections made in the dropdown. */
  applyHandler: () => void;

  /** Function to update the selected values in the dropdown. */
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
}

/**
 * MenuContainer is a functional component that renders the dropdown list for the CustomSelectComponent.
 *
 * @param props - IProps interface elements to configure the component.
 * @returns React Functional Component.
 */
const MenuContainer: FC<IProps> = ({
  headerBarColor,
  headerTitle,
  showHeader,
  showHeaderCloseIcon,
  showSearchField,
  searchFieldPlaceholder,
  options,
  reset,
  setShowList,
  handleOptionClick,
  selectedValues,
  showDivider,
  bottomOutsideBtn,
  showFilter,
  showList,
  showSelectAll,
  customWidth,
  loading,
  authRoleFilterOptions,
  applyHandler,
  setSelectedValues,
}) => {
  const { t } = useTranslation(['handbook']);

  const [searchValue, setSearchValue] = useState('');
  const [displayedSearchValue, setDisplayedSearchValue] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<
    SelectOption | null | undefined
  >(undefined);

  const updateSearchValue = useCallback(
    debounce((value) => setSearchValue(value), 500),
    []
  );

  const handleInputChange = (value: string) => {
    setDisplayedSearchValue(value);
    updateSearchValue(value);
  };

  const matchesSearchValue = (option: SelectOption) => {
    // const normalizedSearchValue = searchValue?.toLowerCase().trim();
    const searchReg = searchRegExp(searchValue, 'gi');

    // const labelMatch = option?.label
    //   ?.toLowerCase()
    //   .trim()
    //   .includes(normalizedSearchValue);
    const labelMatch = option?.label?.match(searchReg);

    // const roleMatch = option?.role
    //   ?.toLowerCase()
    //   .trim()
    //   .includes(normalizedSearchValue);
    const roleMatch = option?.role?.match(searchReg);

    // const locationMatch = option?.locations?.some((loc) =>
    //   loc?.name?.toLowerCase().trim().includes(normalizedSearchValue)
    // );
    const locationMatch = option?.locations?.some((loc) =>
      loc?.name?.match(searchReg)
    );

    return labelMatch || roleMatch || locationMatch;
  };

  const filteredOptions = useCallback(() => {
    if (!searchValue && !selectedFilter?.value) {
      return options;
    }

    return options?.filter((option) => {
      const searchMatch = !searchValue || matchesSearchValue(option);
      const filterMatch =
        !selectedFilter?.value || option?.authRole === selectedFilter?.value;

      return searchMatch && filterMatch;
    });
  }, [options, searchValue, selectedFilter]);

  const areAllOptionsSelected = useMemo(() => {
    return filteredOptions()?.every((option) =>
      selectedValues.includes(option.value)
    );
  }, [filteredOptions, selectedValues]);

  const onFilterChange = (option: SelectOption) => {
    if (option?.value === selectedFilter?.value) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(option);
    }
  };

  const calcAuthRole = (authRole?: string) => {
    if (!authRole) return '-';
    return authRole?.split(/(?=[A-Z])/).join(' ');
  };

  return (
    <Flex
      pos='absolute'
      maxH='50vh'
      overflowY='scroll'
      bg='white'
      w={customWidth ? customWidth : 'full'}
      zIndex='999'
      right='0px'
      top='45px'
      borderRadius='10px'
      boxShadow='0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)'
      flexDir='column'
    >
      {loading ? (
        <Center h='30vh' p={4}>
          <Loader />
        </Center>
      ) : (
        <>
          <Flex w='full' align='center' justify='space-between' p={4}>
            {showHeader && (
              <GenericColorHeader
                title={headerTitle}
                color={headerBarColor as string}
              />
            )}
            {showHeaderCloseIcon && (
              <FontAwesomeIcon
                icon={faCircleXmark as IconProp}
                color='#6F767E'
                size='lg'
                cursor='pointer'
                onClick={() => {
                  reset();
                }}
              />
            )}
          </Flex>
          <Flex gap='10px' px={4}>
            {showSearchField && (
              <Box minW='60%'>
                <SearchInput
                  placeholder={searchFieldPlaceholder}
                  hideShortcuts
                  value={displayedSearchValue}
                  onChange={(e) => handleInputChange(e?.target?.value)}
                />
              </Box>
            )}
            {showFilter && (
              <Box minW='40%'>
                <AuthRoleFilter
                  options={authRoleFilterOptions}
                  selectedFilter={selectedFilter}
                  onFilterChange={onFilterChange}
                />
              </Box>
            )}
          </Flex>
          {filteredOptions()?.length > 0 && (
            <List spacing='1px' overflowY='scroll' py={4}>
              {showSelectAll && (
                <HStack
                  py={2}
                  px={6}
                  cursor='pointer'
                  w='full'
                  align='center'
                  justify='space-between'
                  onClick={() => {
                    if (areAllOptionsSelected) {
                      const newSelectedValues = selectedValues.filter(
                        (value) =>
                          !filteredOptions()?.some(
                            (option) => option.value === value
                          )
                      );
                      setSelectedValues(newSelectedValues);
                    } else {
                      const newSelectedValues = [
                        ...new Set([
                          ...selectedValues,
                          ...filteredOptions()?.map((option) => option.value),
                        ]),
                      ];
                      setSelectedValues(newSelectedValues);
                    }
                  }}
                >
                  <Text fontWeight={600} userSelect='none'>
                    Select All
                  </Text>
                  {areAllOptionsSelected ? (
                    <FontAwesomeIcon
                      icon={faSquareCheck as IconProp}
                      color='#2A85FF'
                      size='lg'
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSquare as IconProp}
                      color='#999999'
                      size='lg'
                    />
                  )}
                </HStack>
              )}
              {filteredOptions()?.map((option) => (
                <MenuItem
                  key={option?.value}
                  option={option}
                  isSelected={selectedValues.includes(option?.value!)}
                  onClick={handleOptionClick}
                  showDivider={showDivider}
                />
              ))}
            </List>
          )}
          {filteredOptions()?.length === 0 && (
            <Box p={4}>
              <EmptyState
                image={
                  (searchValue && selectedFilter?.value) || searchValue
                    ? 'Search'
                    : selectedFilter?.value
                    ? 'Filter'
                    : 'ChatGroup'
                }
                imageSize='170px'
                title={
                  (searchValue && selectedFilter?.value) || searchValue
                    ? 'Result not found'
                    : selectedFilter?.value
                    ? `No ${selectedFilter?.label} found`
                    : 'No data available'
                }
              />
            </Box>
          )}
          {filteredOptions()?.length > 0 &&
            bottomOutsideBtn &&
            bottomOutsideBtn?.show && (
              <Flex w='full' px={4} pb={2} pt={0}>
                <Button
                  colorScheme='blue'
                  variant='solid'
                  w='full'
                  onClick={applyHandler}
                  // isDisabled={selectedValues?.length === 0}
                >
                  {bottomOutsideBtn?.title}
                </Button>
              </Flex>
            )}
        </>
      )}
    </Flex>
  );
};

export default MenuContainer;
