import React, { FC, useContext } from 'react';
import { Authorize, AuthRole } from 'authorization';
import SidebarItem from 'sub-components/Sidebar/SidebarItem';
import { REPORT_URL, SETTINGS_URL } from 'appRoutes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import SidebarSection from 'sub-components/Sidebar/SidebarSection';

import { ReactComponent as ChartIcon } from '../../../../assets/images/sidebar//report.svg';
import { ReactComponent as ToolIcon } from '../../../../assets/images/sidebar/setting.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/images/sidebar/help.svg';
import { useRenderFlag } from '../helper';

const OthersSection: FC = () => {
  const { t } = useTranslation('sidebar');
  const flag = useRenderFlag();
  const pathname = useLocation().pathname;

  return (
    <SidebarSection title={t('othersSection')}>
      {flag && (
        <Authorize
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
          permittedFor='user'
        >
          <SidebarItem
            icon={ChartIcon}
            title={t('reports')}
            link={REPORT_URL}
            isSelected={pathname === REPORT_URL}
          />
        </Authorize>
      )}
      {flag && (
        <Authorize
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          permittedFor='user'
        >
          <SidebarItem
            icon={ToolIcon}
            title={t('setting')}
            isSelected={pathname === '/settings'}
            link={SETTINGS_URL}
          />
        </Authorize>
      )}
      {/* <SidebarItem
        icon={InfoIcon}
        title={t('help')}
        isSelected={pathname === '/'}
        link='#'
        isHelp
      /> */}
      <SidebarItem
        icon={InfoIcon}
        title='Report a bug'
        isSelected={pathname === '/'}
        link='#'
        isHelp
      />
    </SidebarSection>
  );
};

export default OthersSection;
