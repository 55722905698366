import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';

import { Container, SaveNextButton, useStepperIsOpen } from '../common';
import { IFormInput } from '../task.types';
import FormInput from '../../../../atoms/FormInput';
import TitleHeader from '../../../CardEditor/TitleHeader';
import TaskDetailsStepper from './TaskDetailsStepper';

interface IProps {}

const TaskDetails: FC<IProps> = () => {
  const { t } = useTranslation(['task']);
  const { control, handleSubmit, setValue } = useFormContext<IFormInput>();

  const isOpen = useStepperIsOpen('details');

  const saveAndNext = () => {
    setValue('completed.details', true);
    setValue('filled.details', true);
    setValue('currentStep', 'scheduler');
  };

  if (!isOpen) {
    return <TaskDetailsStepper />;
  }

  return (
    <Container flexDir='column' p='20px'>
      <TitleHeader
        title={t('task:taskTitle')}
        desc={t('task:titleDesc')}
        isRequired
      />
      <Controller
        control={control}
        name='title'
        defaultValue=''
        rules={{
          required: t('task:validation.titleRequired'),
          validate: (value) => {
            if (value?.trim()?.length === 0) {
              return t('task:validation.titleEnter');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={1} isInvalid={!!fieldState?.error}>
              <FormInput
                id='title'
                size='lg'
                placeholder={t('task:placeholder.title')}
                {...field}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    const inputFields = document.getElementsByTagName('input');

                    for (let i = 0; i < inputFields.length; i++) {
                      if (
                        document.activeElement.id === inputFields[i].id &&
                        i + 1 < inputFields.length
                      ) {
                        inputFields[i + 1].focus();
                        break;
                      }
                    }
                  }
                }}
              />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <Controller
        control={control}
        name='description'
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={3} isInvalid={!!fieldState?.error}>
              <FormInput
                id='description'
                size='lg'
                placeholder={t('task:placeholder.description')}
                {...field}
              />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <SaveNextButton onClick={handleSubmit(saveAndNext)} />
    </Container>
  );
};

export default TaskDetails;
