import React, { FC, useRef } from 'react';
import { DocumentNode, useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useToast } from '@chakra-ui/react';
import { cloneDeep } from '@apollo/client/utilities';

import { AmplitudeEvent, deployEvent } from '../../../shared';
import { TASKS_CREATE_URL } from '../../../appRoutes';

import { IFormInput, TaskCreationBase, TaskCreationRef } from '../create-task';
import { TASK_FOR_EDIT_QUERY, TaskEditableResponse } from './task.graphql';
import { toEditableTask } from './dataConvertToEditable';
import { TaskEditScheme, validateTaskData } from '../create-task/validation';

import { editTaskQuery, EditTaskVariable } from './edit-task.graphql';
import { dataTransformToUpdate } from './dataTransformForUpdate';
import { ITaskAction } from '../create-task/task.types';
import {
  TaskDeleteContent,
  TaskRestartContent,
  TaskTerminateContent,
  useTaskActionModal,
} from '../create-task/delete';
import {
  ActionVariable,
  deleteTaskQuery,
  restartTaskQuery,
  terminateTaskQuery,
} from '../create-task/task.graphql';

interface IProps {}

const TaskUpdate: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'task']);
  const history = useHistory();
  const taskId = useParams<{ taskId: string }>()?.taskId;
  const taskFormRef = useRef<TaskCreationRef>(null);

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
  });

  const actionModal = useTaskActionModal();

  const { loading, data, client } = useQuery<TaskEditableResponse>(
    TASK_FOR_EDIT_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: { eid: taskId },
      onCompleted: (response) => {
        if (response.TaskById && response.TaskById?.status !== 'TASK_DELETED') {
          const editableTask = toEditableTask(response.TaskById);
          taskFormRef.current?.initEditTask(editableTask);
        } else {
          history.replace(TASKS_CREATE_URL);
        }
      },
    }
  );

  const [submitTask] = useMutation<never, EditTaskVariable>(editTaskQuery, {
    onCompleted: () => {
      toast({
        title: t('common:success'),
        description: t('task:updateSuccess'),
        status: 'success',
      });
      history.goBack();
    },
    onError: (error) => {
      // TODO populate error to form field
      console.log(error);
      toast({
        title: t('common:error'),
        description: t('common:something_wrong'),
        status: 'error',
      });
    },
  });

  const handleSubmit = async (values: IFormInput) => {
    try {
      await validateTaskData(
        TaskEditScheme,
        cloneDeep(values),
        taskFormRef.current!
      );

      const dataToSubmit = dataTransformToUpdate(taskId, cloneDeep(values));

      const res = await submitTask({
        variables: {
          input: dataToSubmit,
        },
      });
      if (res.errors) {
        return Promise.reject('Task update failed');
      }
      deployEvent(AmplitudeEvent.TASK_UPDATED);
    } catch (e) {
      console.log(e);
    }
  };

  const handleMutation = async (
    docNode: DocumentNode,
    message: string,
    redirectTo?: string
  ) => {
    await client.mutate<never, ActionVariable>({
      mutation: docNode,
      variables: {
        eid: taskId,
      },
    });
    toast({
      title: t('common:success'),
      description: message,
      status: 'success',
    });
    if (redirectTo) {
      history.replace(redirectTo);
    } else {
      history.goBack();
    }
  };

  const handleAction = (type: ITaskAction) => {
    switch (type) {
      case 'terminate':
        deployEvent(AmplitudeEvent.TASK_TERMINATE);
        return actionModal({
          // @ts-ignore
          title: t('task:terminateNameQues', {
            name: data?.TaskById?.title,
          }),
          content: <TaskTerminateContent />,
          buttonTitle: t('task:terminate'),
          colorScheme: 'red',
          onClick: () => {
            return handleMutation(
              terminateTaskQuery,
              t('task:terminateSuccess')
            );
          },
        });
      case 'softDelete':
        deployEvent(AmplitudeEvent.TASK_DELETE);
        return actionModal({
          // @ts-ignore
          title: t('common:deleteNameQues', {
            name: data?.TaskById?.title,
          }),
          content: <TaskDeleteContent />,
          buttonTitle: t('common:delete'),
          colorScheme: 'red',
          onClick: () => {
            return handleMutation(
              deleteTaskQuery,
              t('task:deleteSuccess'),
              '/tasks/supervised'
            );
          },
        });
      case 'restart':
        deployEvent(AmplitudeEvent.TASK_RESTART);
        return actionModal({
          // @ts-ignore
          title: t('task:restartNameQues', {
            name: data?.TaskById?.title,
          }),
          content: <TaskRestartContent />,
          buttonTitle: t('task:restart'),
          colorScheme: 'blue',
          onClick: () => {
            return handleMutation(restartTaskQuery, t('task:restartSuccess'));
          },
        });
    }
  };

  return (
    <TaskCreationBase
      ref={taskFormRef}
      isEdit
      isLoading={loading}
      title={t('task:editTask')}
      handleSubmit={handleSubmit}
      handleAction={handleAction}
    />
  );
};

export default TaskUpdate;
