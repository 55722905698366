import {
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import React, { FC } from 'react';
import {
  ICityStateList,
  ICityStateListEntity,
  IDropdownType,
} from '../LocationListContainer';

interface IProps {
  cityStateList: ICityStateList;
  fetchData: () => void;
  listRender: (
    data: ICityStateListEntity,
    index: number,
    type: IDropdownType
  ) => React.ReactNode;
}

const LocationStatusHeader: FC<IProps> = ({
  cityStateList,
  fetchData,
  listRender,
}) => {
  return (
    <Flex gap='10px' align='center'>
      <Text m={0}>Status</Text>
      <Menu closeOnSelect={false} onClose={fetchData}>
        <MenuButton>
          <FontAwesomeIcon
            icon={faSortDown as IconProp}
            style={{ marginBottom: '2px' }}
          />
        </MenuButton>
        <MenuList p={0}>
          {cityStateList?.locationStatus?.map((type, index) =>
            listRender(type, index, 'locationStatus')
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default LocationStatusHeader;
