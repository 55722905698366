import {
  components as Components,
  Select,
  Props,
  chakraComponents,
} from 'chakra-react-select';
import { CSSProperties, forwardRef } from 'react';
import { getChakraSelectStyles, SelectStyles } from './dropdown.styles';

export interface SelectOption<T extends string = string> {
  label: string;
  value: T;
  [key: string]: any;
}

export interface DropDownProps<T>
  extends Pick<
    Props<T>,
    | 'menuIsOpen'
    | 'onMenuOpen'
    | 'onMenuClose'
    | 'menuPlacement'
    | 'closeMenuOnSelect'
  > {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  inputStyle?: CSSProperties;
  isDisabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  value?: T | null;
  onChange?: (newValue: T, actionMeta: any) => void;
  onInputChange?: (newValue: string) => void;
  options?: Array<T>;
  menuShouldScrollIntoView?: boolean;
  components?: Partial<typeof Components>;
  selectStyles?: SelectStyles;
  isReadOnly?: boolean;
  getOptionValue?: (option: any) => string;
  getOptionLabel?: (option: any) => string;
  isOptionSelected?: (option: any, selectedValue: any) => boolean;
  formatOptionLabel?: (option: any, meta: any) => any;
  id?: string;
}

const Dropdown = forwardRef<never, DropDownProps<SelectOption<any>>>(
  ({ inputStyle, selectStyles = {}, onChange, options, ...props }, ref) => {
    const mergedStyles: SelectStyles = {
      ...selectStyles,
      control: {
        ...inputStyle,
        ...selectStyles?.control,
      },
    };

    return (
      <Select
        ref={ref}
        onChange={onChange as any}
        options={options}
        value={props.value || {}}
        chakraStyles={getChakraSelectStyles(mergedStyles) as any}
        {...props}
      />
    );
  }
);

Dropdown.displayName = 'Dropdown';

const Option = chakraComponents.Option;

export { Option };

export default Dropdown;
