import { useCallback } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import CryptoJS from 'crypto-js';
import S3, { ManagedUpload } from 'aws-sdk/clients/s3';
import { AES_DECRYPTION_KEY } from 'utils/constants';
import { useUserDataSelector } from './useUserDataQuery';

const getRandomArbitrary = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min) + min);
};

const s3FileName = (key: string): string => {
  return new Date()
    .getTime()
    .toString()
    .concat('_', getRandomArbitrary(100, 999).toString(), '_', key);
};

const decryptKey = (hash: string): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    try {
      const string = CryptoJS.AES.decrypt(hash, AES_DECRYPTION_KEY).toString(
        CryptoJS.enc.Utf8
      );

      resolve(string);
    } catch (e) {
      reject(e);
    }
  });
};

interface AwsCredentials {
  AWS_ACCESS_KEY: string;
  AWS_BUCKET_REGION: string;
  AWS_BUCKET_URL: string;
  AWS_SECRET_ACCESS_KEY: string;
}

const callGetS3Cred = gql`
  query Query {
    GetS3Creds
  }
`;

type Progress = (progress: number) => void;

type UseUploadToAws = (
  file: File,
  uploadProgress?: Progress
) => Promise<string>;

export const useUploadToAws = (): UseUploadToAws => {
  const client = useApolloClient();
  const entityId = useUserDataSelector((state) => state.entityId);

  return useCallback(
    async (file, uploadProgress) => {
      const credentials = await client
        .query<Record<'GetS3Creds', string>>({
          query: callGetS3Cred,
        })
        .then(async (value) => {
          const text = await decryptKey(value.data.GetS3Creds);
          return JSON.parse(text) as AwsCredentials;
        });

      const s3 = new S3({
        region: credentials.AWS_BUCKET_REGION,
        credentials: {
          accessKeyId: credentials.AWS_ACCESS_KEY,
          secretAccessKey: credentials.AWS_SECRET_ACCESS_KEY,
        },
      });

      const response: ManagedUpload.SendData = await s3
        .upload({
          Bucket: credentials.AWS_BUCKET_URL.concat('/', entityId),
          Key: s3FileName(file?.name!),
          Body: file!,
          ACL: 'public-read',
        })
        .on('httpUploadProgress', (progress) => {
          uploadProgress?.(
            Math.floor((progress.loaded * 100) / progress.total)
          );
        })
        .promise();

      return decodeURI(response.Location);
    },
    [entityId]
  );
};
