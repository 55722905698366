import React, { FC } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationDot,
  faRocketLaunch,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Button } from '../../../atoms';
import { ADD_LOCATION, LAUNCHER_ADD_LOCATION } from '../../../appRoutes';
import { Authorize, AuthRole } from 'authorization';
import { AmplitudeEvent, deployEvent } from '../../../shared';
import { HeaderColors } from '../../../shared/headerColors/header-colors';

interface IProps {}

const LauncherDashHeader: FC<IProps> = () => {
  const history = useHistory();

  return (
    <Flex align='center' justify='space-between' mt={2}>
      <Text fontWeight={700} fontSize='24px' color='#272B30' p={0} m={0}>
        Locations
      </Text>
      {/* <PrimaryButton
        title='Add new location'
        leftIcon={<AddIcon />}
        variant='outline'
        style={{
          color: HeaderColors.DarkBlue,
          backgroundColor: 'white',
          width: 'fit-content',
        }}
        onClick={() => history.push(LAUNCHER_ADD_LOCATION)}
      /> */}

      <Authorize
        permittedFor={['user']}
        permittedRoles={[AuthRole.SUPER_ADMIN]}
      >
        <Popover placement='bottom-start'>
          {/* @ts-ignore */}
          <PopoverTrigger>
            <Box>
              <Button
                leftIcon={<AddIcon />}
                variant='outline'
                style={{
                  color: HeaderColors.DarkBlue,
                  backgroundColor: 'white',
                }}
                event={AmplitudeEvent.LAUNCHER_ADD_LOCATION_HOVER}
              >
                Add new location
              </Button>
            </Box>
          </PopoverTrigger>
          <PopoverContent
            w='fit-content'
            p='16px'
            borderRadius='16px'
            style={{ boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' }}
          >
            <PopoverBody>
              <Flex flexDir='column' gap={4}>
                <Flex
                  align='center'
                  cursor='pointer'
                  onClick={() => {
                    deployEvent(
                      AmplitudeEvent.LAUNCHER_ADD_LOCATION_WITHOUT_CONFIG
                    );
                    history.push(ADD_LOCATION, {
                      backToTitle: 'Back to Launcher dashboard',
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faLocationDot as IconProp}
                    fontSize='16px'
                  />
                  <Text ml='15px' fontSize='15px' fontWeight={500}>
                    Location without launcher
                  </Text>
                </Flex>
                <Flex
                  align='center'
                  cursor='pointer'
                  onClick={() => {
                    deployEvent(AmplitudeEvent.LAUNCHER_ADD_LOCATION);
                    history.push(LAUNCHER_ADD_LOCATION);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRocketLaunch as IconProp}
                    fontSize='16px'
                  />
                  <Text ml='12px' fontSize='15px' fontWeight={500}>
                    Location with launcher
                  </Text>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Authorize>
    </Flex>
  );
};

export default LauncherDashHeader;
