import { useReactiveVar } from '@apollo/client';
import { useUserData } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { EventBusEmits } from 'shared/eventEmit/defaults/defaults';
import { eventBus } from 'shared/eventEmit/eventBus';
import { loginUserBranchRootObj, usersEntityObj } from 'sub-components/Header';
import useCombinedStore from 'zustandStore/store';
import { IVisibility } from '../CreateChapterModal/UploadFile/UploadFile';
import {
  locationUserRoleHelper,
  rolesListSetHandler,
  selectedDataSetHandler,
} from '../EditChapterModal/helper';
import { FinalRender, InitialRender, VisibilityOperation } from './components';

export interface FolderVisibilityInitialData {
  name: string;
  eid: string;
}

export type IFolderVisibilityType = 'folder' | 'subFolder' | 'chapter';

export interface FolderVisibilityRef {}

export type IModalStep = 0 | 1 | 2 | 3;

interface IProps {
  getChaptersList?: () => void;
}

const FolderVisibility: FC<IProps> = ({ getChaptersList }) => {
  const {
    hierarchyData,
    selectedData,
    visibilityScope,
    visibilitySelection,
    updateSelectedData,
    updateVisibilitySelection,
  } = useCombinedStore();
  const store = useCombinedStore();
  const userBranchRoot = useReactiveVar(loginUserBranchRootObj);
  const userData = useUserData();
  const [membersList, setMembersList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [rolesList, setRolesList] = useState<any[]>([]);
  const [modalType, setModalType] = useState<IFolderVisibilityType>('folder');

  console.log('Folder Visibility store : ', store);

  const initialData =
    visibilityScope === 'folder'
      ? hierarchyData?.parentFolder?.details
      : hierarchyData?.subFolder?.details;

  useEffect(() => {
    setModalStep(1);
  }, []);
  const [modalStep, setModalStep] = useState<IModalStep>(1);

  // useEffect(() => {
  //   if (userBranchRoot) {
  //     let _data = JSON.parse(JSON.stringify({ branch: userBranchRoot }));
  //     locationUserRoleHelper(
  //       _data,
  //       userData,
  //       setMembersList,
  //       setLocationsList,
  //       selectedData,
  //       updateSelectedData
  //     );
  //   }
  // }, [userBranchRoot]);

  // useEffect(() => {
  //   rolesListSetHandler(userData, setRolesList);
  // }, [userData]);

  // useEffect(() => {
  //   selectedDataSetHandler(
  //     membersList,
  //     locationsList,
  //     visibilitySelection!,
  //     rolesList,
  //     updateSelectedData
  //   );
  // }, [locationsList, membersList, visibilitySelection]);

  const updateModalStepHandler = (step: IModalStep) => {
    setModalStep(step);
  };

  const closeClickEmit = () => {
    eventBus.emit(
      EventBusEmits.chapterContainer.CHAPTERS_FOLDER_VISIBILITY_CLOSE_MODAL
    ); // emit to ChapterContainer
  };

  const categoryIdFn = () => {
    let categoryId = '';
    if (visibilityScope === 'folder') {
      categoryId = hierarchyData?.parentFolder?.details?.eid || '';
    } else {
      categoryId = hierarchyData?.subFolder?.details?.eid || '';
    }
    return categoryId;
  };

  const modalStep1Render = () => {
    return (
      <InitialRender
        isOpen={modalStep === 1}
        categoryId={categoryIdFn()}
        locationsList={locationsList}
        membersList={membersList}
        modalType={modalType}
        rolesList={rolesList}
        onClose={() => {
          setModalStep(0);
          closeClickEmit();
        }}
        updateModalStep={updateModalStepHandler}
      />
    );
  };

  const modalStep2Render = () => {
    return (
      <VisibilityOperation
        modalType={modalType}
        onClose={() => {
          setModalStep(0);
          closeClickEmit();
        }}
        updateClick={updateModalStepHandler}
      />
    );
  };

  const modalStep3Render = () => {
    return (
      <FinalRender
        locationsList={locationsList}
        membersList={membersList}
        rolesList={rolesList}
        modalType={modalType}
        categoryId={initialData?.eid!}
        getChaptersList={getChaptersList}
        onClose={() => {
          setModalStep(0);
          closeClickEmit();
        }}
        onGoBack={() => setModalStep(2)}
      />
    );
  };

  const compRender = () => {
    switch (modalStep) {
      case 3:
        return modalStep3Render();
      case 2:
        return modalStep2Render();
      case 1:
        return modalStep1Render();
      case 0:
        return null;
    }
  };

  return compRender();
};

FolderVisibility.displayName = 'Pages/Chapters/FolderVisibility';

export default FolderVisibility;
