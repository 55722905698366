import React, { useCallback, useRef } from 'react';
import { BoxHeader, UseConfirm, useConfirm } from '../../../ui-components';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { TeamItem } from '../members/team.types';
import InvitedMemberContent from './InvitedMemberContent';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { InvitedUser } from './update-invite.graphql';

interface IProps {
  data: TeamItem;
  onResendClick?: (data: InvitedUser) => void;
  onDataRefetch?: () => void;
}

type IUpdateInvitedMember = (props: IProps) => void;

export const useUpdateInvitedMember = (): IUpdateInvitedMember => {
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const apolloClient = useApolloClient();

  return useCallback(({ data, onResendClick, onDataRefetch }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader color='#CABDFF' title={data?.name} fontSize='18px' />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <InvitedMemberContent
          userId={data.eid}
          onResendClick={onResendClick}
          onDataRefetch={onDataRefetch}
        />
      ),
      size: 'full',
      contentProps: {
        padding: '12px',
        borderRadius: 0,
        bg: '#FFFFFF',
        containerProps: {
          maxW: '836px',
          left: 'unset',
          right: 0,
        },
      },
      footer: null,
    });
  }, []);
};
