import React, { FC, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';

import { ReactComponent as LauncherIcon } from '../../../../assets/images/sidebar/launcher.svg';
import {
  LAUNCHER_DASHBOARD,
  LAUNCHER_OPENING_TASKS,
  LAUNCHER_SETUP,
} from '../../../../appRoutes';
import { useUserDataSelector, useUserEntity } from '../../../../hooks';
import { BetaTag, SidebarBaseItem, SubItem } from '../../common';
import { Authorize, AuthRole } from '../../../../authorization';

const QUERY = gql`
  query LocationLaunchSupervisorTasks {
    LocationLaunchSupervisorTasks {
      launchId
    }
  }
`;

interface Response {
  LocationLaunchSupervisorTasks: never[];
}

const Title: FC = () => {
  const { t } = useTranslation(['sidebar']);
  return (
    <>
      <Box as='span'>{t('sidebar:locationLauncher')}</Box>
      <BetaTag />
    </>
  );
};

interface IProps {
  isSelected: boolean;
  isExpand: boolean;
}

interface LProps extends IProps {
  onClick: () => void;
}

const Launcher: FC<LProps> = ({
  isSelected,
  isExpand: isExpanded,
  onClick,
}) => {
  const { t } = useTranslation(['sidebar']);
  const history = useHistory();
  const isSuperAdmin = useUserDataSelector(
    (state) => state.type === 'user' && state.authRole === AuthRole.SUPER_ADMIN
  );

  const selectedType = history.location.pathname;

  const { data } = useQuery<Response>(QUERY, {
    skip: isSuperAdmin,
  });

  const haveLocation = !!data?.LocationLaunchSupervisorTasks?.length;

  if (!(isSuperAdmin || haveLocation)) {
    return null;
  }

  return (
    <>
      <SidebarBaseItem
        icon={LauncherIcon}
        title={<Title />}
        link={LAUNCHER_DASHBOARD}
        isSelected={isSelected}
        onClick={onClick}
      />

      {isExpanded && (
        <div
          className='sidebar-item-list-container'
          style={{ marginBottom: '20px' }}
        >
          <Authorize
            permittedFor={['user']}
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <Link to={LAUNCHER_DASHBOARD}>
              <SubItem
                isSelected={selectedType === LAUNCHER_DASHBOARD}
                title={t('sidebar:dashboard')}
                hideIcon
              />
            </Link>
          </Authorize>

          <Authorize
            permittedFor={['user']}
            permittedRoles={[AuthRole.SUPER_ADMIN]}
          >
            <Link to={LAUNCHER_OPENING_TASKS}>
              <SubItem
                isSelected={selectedType === LAUNCHER_OPENING_TASKS}
                title={t('sidebar:openingTasks')}
                hideIcon
              />
            </Link>
          </Authorize>
        </div>
      )}
    </>
  );
};

const SidebarLauncher: FC<IProps> = ({ isSelected, isExpand }) => {
  const launcherPublished = useUserEntity(
    (entity) => entity?.launcher?.published
  );
  const haveLauncher = useUserEntity((entity) => !!entity?.launcher?.eid);
  const history = useHistory();

  const selectedType = history.location.pathname;

  const [isExpanded, setIsExpanded] = useState(isExpand);

  const onExpandStateChange = () => {
    setIsExpanded((s) => !s);
  };

  if (!launcherPublished) {
    return (
      <Authorize
        permittedFor={['user']}
        permittedRoles={[AuthRole.SUPER_ADMIN]}
      >
        <SidebarBaseItem
          icon={LauncherIcon}
          title={<Title />}
          link={haveLauncher ? LAUNCHER_OPENING_TASKS : LAUNCHER_SETUP}
          isSelected={
            haveLauncher
              ? selectedType === LAUNCHER_OPENING_TASKS
              : selectedType === LAUNCHER_SETUP
          }
        />
      </Authorize>
    );
  }

  return (
    <Launcher
      isSelected={isSelected}
      isExpand={isExpanded}
      onClick={onExpandStateChange}
    />
  );
};

export default SidebarLauncher;
