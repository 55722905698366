import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import { useButtonProps } from '../useButtonProps';

export interface PickerButtonProps extends ButtonProps {
  isSelected?: boolean;
  readOnly?: boolean;
  transKey?: 'task:customDateValue';
}

const PickerButton = forwardRef<never, PickerButtonProps>(
  ({ isSelected, value, onClick, placeholder, readOnly, transKey }, ref) => {
    const { t } = useTranslation();

    const otherProps = useButtonProps({
      isReadOnly: readOnly,
      isSelected: isSelected,
    });

    return (
      <Button
        size='lg'
        fontSize='14px'
        fontWeight='500'
        borderRadius='12px'
        leftIcon={<FontAwesomeIcon icon={faCalendar as IconProp} />}
        rightIcon={<FontAwesomeIcon icon={faCaretDown as IconProp} size='lg' />}
        {...otherProps}
        aria-readonly={readOnly}
        onClick={onClick}
        ref={ref}
      >
        {value && isSelected
          ? transKey
            ? // @ts-ignore
              t(transKey, {
                value: value,
              })
            : value
          : placeholder}
      </Button>
    );
  }
);

PickerButton.displayName = 'Picker Button';

export default PickerButton;
