import React, { FC } from 'react';
import { Box, Center, Checkbox, Flex, Image, useToast } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CometChat } from '@cometchat-pro/chat';
import { Trans, useTranslation } from 'react-i18next';

import { FormInput } from '../../../../atoms';
import { ActionButton } from '../../../../ui-components';
import { useUserEntity } from '../../../../hooks';
import { ChatGroupEntity } from '../../../../types';

import {
  AddGroupVariable,
  GroupType,
  UPDATE_GROUP_QUERY,
  UpdateGroupVariable,
} from './create-channel.graphql';

import chatGroupIcon from '../../../../assets/images/chat-group.svg';
import {
  GroupMetaVariable,
  UPDATE_GROUP_METADATA,
} from './update-channel/update-channel.graphql';

interface IFormInput {
  groupName: string;
  isPublic: boolean;
}

interface IProps {
  initialValue: ChatGroupEntity;
  onGroupUpdated?: (group: CometChat.Group) => void;
}

const UpdateChannelForm: FC<IProps> = ({ initialValue, onGroupUpdated }) => {
  const { t } = useTranslation(['common', 'setting']);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm<IFormInput>({
    defaultValues: {
      groupName: initialValue.name,
      isPublic: initialValue.details?.type === CometChat.GROUP_TYPE.PUBLIC,
    },
  });

  const entityName = useUserEntity((entity) => entity.name);

  const isDefault = initialValue?.details?.isEditable === false;
  const showDelete = !isDefault && initialValue?.hasJoined;

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const [updateGroup] = useMutation<
    Record<'updateChatGroupByGuid', AddGroupVariable>,
    Partial<UpdateGroupVariable>
  >(UPDATE_GROUP_QUERY, {
    onCompleted: (response) => {
      toast({
        status: 'success',
        title: t('common:success'),
        // @ts-ignore
        description: t('setting:channelUpdateSuccess', {
          name: response.updateChatGroupByGuid.name,
        }),
      });
    },
  });

  const groupName = watch('groupName');

  const [updateMetaData] = useMutation<never, GroupMetaVariable>(
    UPDATE_GROUP_METADATA
  );

  const onSubmit = async (values: IFormInput) => {
    try {
      const group = await CometChat.getGroup(initialValue.guid);

      group.setName(values.groupName?.trim());
      group.setType(
        values.isPublic
          ? CometChat.GROUP_TYPE.PUBLIC
          : CometChat.GROUP_TYPE.PRIVATE
      );

      const res = await updateMetaData({
        variables: {
          guid: group.getGuid(),
          name: group.getName(),
          type: group.getType(),
        },
      });

      if (res.errors) {
        return Promise.reject(res.errors);
      }

      await updateGroup({
        variables: {
          guid: group.getGuid(),
          name: group.getName(),
          type: group.getType() as GroupType,
          ...(group.getType() === CometChat.GROUP_TYPE.PUBLIC
            ? {
                roles: [],
                authRoles: [],
                locations: [],
              }
            : undefined),
        },
      });
      onGroupUpdated?.(group);
    } catch (e) {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('setting:channelUpdateError'),
      });
      return Promise.reject(e);
    }
  };

  return (
    <div>
      <Flex gap={2}>
        <Center borderRadius='8px' minW='48px' boxSize='48px' bg='#EEEEEE'>
          <Image src={chatGroupIcon} />
        </Center>

        <Controller
          control={control}
          defaultValue=''
          name='groupName'
          render={({ field }) => {
            return (
              <FormInput
                size='lg'
                placeholder={t('setting:placeholder.channelName')}
                {...field}
              />
            );
          }}
        />
      </Flex>

      {showDelete && (
        <Controller
          control={control}
          defaultValue={false}
          name='isPublic'
          render={({ field }) => {
            return (
              <Checkbox
                mt={4}
                color='#6F767E'
                isChecked={field.value}
                onChange={field.onChange}
                ref={field.ref}
              >
                <Box fontSize='14px'>
                  <Trans
                    t={t}
                    i18nKey='setting:everyoneInOrgAddedToChannel'
                    values={{
                      name: entityName,
                    }}
                  />
                </Box>
              </Checkbox>
            );
          }}
        />
      )}

      <Flex pt={4} pb={2} gap={3}>
        <ActionButton
          flex={1}
          fontSize='15px'
          colorScheme='blue'
          isDisabled={!groupName || !isDirty}
          actionFn={handleSubmit(onSubmit)}
        >
          {t('common:save')}
        </ActionButton>
      </Flex>
    </div>
  );
};

export default UpdateChannelForm;
