import React, { FC } from 'react';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CellProps {
  selected?: string[];
  onChange?: (value: string) => void;
}

export const LocationCellRender: FC<CellProps> = ({
  selected = [],
  onChange,
}) => {
  return (
    <Flex gap={2} userSelect='none' flexWrap='wrap'>
      {selected?.map((value) => {
        return (
          <Flex
            key={value}
            border='1px solid #E3E1E5'
            bg='rgba(182, 180, 186, 0.1)'
            borderRadius='full'
            align='center'
            padding='6px 10px'
            gap={2}
          >
            <Box fontSize='12px' fontWeight='500' color='#84818A'>
              {value}
            </Box>
            <CloseIcon
              color='#6F767E'
              boxSize='10px'
              cursor='pointer'
              onClick={() => onChange?.(value)}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

interface IProps extends CellProps {
  locations: string[];
}

const GraphLocationSelection: FC<IProps> = ({
  locations,
  selected = [],
  onChange,
}) => {
  const { t } = useTranslation(['location']);

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        variant='outline'
        fontSize='14px'
        fontWeight='600'
        disabled={!locations?.length}
        size='lg'
        rightIcon={<ChevronDownIcon boxSize={5} />}
        border='1.5px solid #E3E1E5'
        gap='10px'
      >
        {t('location:selectLocation')}
      </MenuButton>

      <MenuList padding='0' overflowY='auto' maxHeight='230px'>
        {locations?.map((value, index) => {
          const isSelected = selected.includes(value);
          return (
            <MenuItem
              key={index}
              padding='10px'
              onClick={() => onChange?.(value)}
            >
              <Flex justify='space-between' align='center' width='full'>
                <Flex align='center' gap={2}>
                  <Text isTruncated maxWidth='150px'>
                    {value}
                  </Text>
                </Flex>
                {isSelected && (
                  <FontAwesomeIcon icon={faCheck as IconProp} color='#7caf6a' />
                )}
              </Flex>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default GraphLocationSelection;
