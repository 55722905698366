import { Flex, Text } from '@chakra-ui/react';
import { ILocationDetails } from 'pages/LocationDetails/utils/location-details.types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GenericColorHeader from 'sub-components/GenericColorHeader';

interface IProps {
  details: ILocationDetails | undefined;
}

const ColorText: FC<{ text: string; color: string; bg: string }> = ({
  text,
  bg,
  color,
}) => {
  return (
    <Text
      fontWeight={600}
      fontSize='14px'
      color={color}
      bg={bg}
      borderRadius='6px'
      align='center'
      p='3px 15px'
      w='fit-content'
      textTransform='capitalize'
    >
      {text}
    </Text>
  );
};

const LocationDetailsComponent: FC<IProps> = ({ details }) => {
  const { t } = useTranslation(['common', 'location', 'setting']);
  return (
    <Flex flexDir='column' gap='20px'>
      <GenericColorHeader title='Location details' color='#CABDFF' />
      <Flex flexDir='column' gap='20px'>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('common:status')}:</Text>
          <ColorText
            text={details?.status || '-'}
            bg='#B5E4CA4D'
            color='#83BF6E'
          />
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:address')}:</Text>
          <Text>{details?.address?.address || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:city')}:</Text>
          <Text>{details?.address?.city || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:state')}:</Text>
          <Text>{details?.address?.state || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:userName')}:</Text>
          <Text>{details?.username || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:locationType')}:</Text>
          <ColorText
            text={details?.locationType || '-'}
            bg='#B1E5FC4D'
            color='#2A85FF'
          />
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('setting:email')}:</Text>
          {details?.locationEmail?.map((email, index) => (
            <Text key={index} color='#2A85FF'>
              {email}
            </Text>
          ))}
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('common:phone')}:</Text>
          {details?.locationPhone?.map((phone, index) => (
            <Text key={index} color='#2A85FF'>
              {phone}
            </Text>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LocationDetailsComponent;
