import { useCallback } from 'react';
import {
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useFormContext,
} from 'react-hook-form';

import { LauncherTask, TaskVariable } from '../common';

import { IFormInput } from '../editor-form/editor.types';
import { EditorEvent, useEditorContext } from '../editor-form';
import { ITaskInput } from '../editor-form/section/BaseTaskForm';
import { TPhaseInput } from './task-form/SelectTaskPhase';
import { transformTask } from '../common/helpers';

interface IProps {
  sectionIndex: number;
  insert: UseFieldArrayInsert<IFormInput, `contents.${number}.tasks`>;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IFormInput, `contents.${number}.tasks`>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTaskItemUpdate = ({
  sectionIndex,
  insert,
  remove,
  update,
}: IProps) => {
  const { emit } = useEditorContext();

  const { setValue, getValues } = useFormContext<IFormInput>();

  return useCallback(
    async (values: ITaskInput & TPhaseInput, index: number) => {
      if (!values.eid && index >= 0) {
        const res = await emit<
          { index: number; task: TaskVariable; categoryId: string },
          LauncherTask
        >(EditorEvent.ADD_NEW_TASK, {
          index: index,
          categoryId: getValues(`contents.${sectionIndex}.eid`),
          task: transformTask({ ...values, completed: true }, index),
        });
        if (res) {
          insert(index, { ...res, tempEid: res.eid });
        }
      } else {
        /**
         * @return [LauncherTask, newCategoryId, newCategoryIndex]
         * @return [LauncherTask]
         */
        const res = await emit<
          unknown,
          [LauncherTask, string | undefined, number | undefined]
        >(EditorEvent.SINGLE_TASK_UPDATE, {
          index: index,
          data: values,
        });

        if (res) {
          if (res[1]) {
            setValue(`contents.${res[2]!}.tasks`, [
              { ...res[0], tempEid: res[0]?.eid },
              ...getValues(`contents.${res[2]!}.tasks`),
            ]);
            remove(index);
          } else {
            update(index, { ...res[0], tempEid: res[0]?.eid });
          }
        }
      }
    },
    [setValue, getValues, sectionIndex, insert, remove, update]
  );
};
