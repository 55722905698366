import React, { FC, useContext, useState } from 'react';
import { Authorization, AuthRole } from 'authorization';
import { Box, Divider, TabIndicator, Tabs } from '@chakra-ui/react';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import FormTabList from './FormTabList';
import FormTabPanelsRoot from './FormTabPanelsRoot';
import { AmplitudeEvent, deployEvent } from '../../../../shared';

interface IProps {}

export interface IFormAppearsEntity {
  cards: { title: string }[];
  decks: { title: string }[];
  trainings: { title: string }[];
}

const FormListInternal: FC<IProps> = () => {
  const dashboardCtx = useContext(DashboardDataContext);
  const activeTabIndex =
    dashboardCtx?.navigationPersistData?.forms?.selectedTab;
  const [searchValue, setSearchValue] = useState('');

  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);

  // const fetchMthdHandler = (item: any) => {
  //   getFormAppearsIn({
  //     variables: {
  //       formId: item?.eid,
  //     },
  //   });
  // };

  const handleTabChange = (index: number) => {
    deployEvent(
      index === 0
        ? AmplitudeEvent.FORM_TAB_ALL_FORMS
        : AmplitudeEvent.FORM_TAB_FORM_RESPONSE
    );
    setSearchValue('');
    setCategoryFilter([]);
    // setActiveTabIndex(index);
    // dashboardCtx?.navigationPersistFormPageUpdateHandler(1);
    // dashboardCtx?.navigationPersistFormTabUpdateHandler(index);
    let navigationData = dashboardCtx?.navigationPersistData;
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationData,
      forms: { selectedPage: 1, selectedTab: index },
    });
  };

  const searchHandler = (e: any) => {
    setSearchValue(e?.target?.value);
    dashboardCtx?.navigationPersistFormPageUpdateHandler(1);
  };

  return (
    <Tabs variant='unstyled' index={activeTabIndex} onChange={handleTabChange}>
      <Box>
        <FormTabList
          searchValue={searchValue}
          searchHandler={searchHandler}
          category={categoryFilter}
          onCategoryChange={setCategoryFilter}
        />
        <Authorization restrictedRoles={AuthRole.WORKER} permittedFor='user'>
          <Divider maxW={'34%'} id='form-list-internal-divider' />
        </Authorization>
      </Box>
      <TabIndicator mt='-1.5px' height='2px' bg='#2A85FF' borderRadius='1px' />
      <FormTabPanelsRoot
        activeTabIndex={activeTabIndex}
        searchValue={searchValue}
        categoryFilter={categoryFilter}
      />
    </Tabs>
  );
};

export default FormListInternal;
