import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import moment from 'moment/moment';

import { LauncherLocation } from '../dashboard.graphql';

import CommonLabel from '../../../../sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';
import {
  ICurrentTaskData,
  ILastTaskData,
} from 'pages/launcher/details/helper/types';
import { TaskStatusDetail } from 'pages/launcher/details/helper/getGroupedData';
import { useUserDataSelector } from 'hooks';
import { getUserTimezoneConvertedNow } from 'pages/launcher/details/helper';

interface ItemProps {
  label: string;
  value?: string;
  date?: string;
  align?: 'end' | 'start';
}

const RenderItem: FC<ItemProps> = ({ label, value, date, align }) => {
  return (
    <Flex flex={1} flexDir='column' align={align}>
      <CommonLabel
        label={label}
        fontWeight={500}
        fontSize='12px'
        lineHeight='unset !important'
      />

      <CommonLabel
        label={value || '-'}
        fontWeight={500}
        fontSize='15px'
        color='#111315'
        lineHeight='unset !important'
        noOfLines={1}
        aria-label={value}
      />

      {value && date && (
        <CommonLabel
          label={date}
          fontWeight={500}
          fontSize='12px'
          lineHeight='unset !important'
        />
      )}
    </Flex>
  );
};

interface IProps {
  lastDone?: TaskStatusDetail | undefined;
  currently?: TaskStatusDetail | undefined;
}

const ItemContent: FC<IProps> = ({ currently, lastDone }) => {
  const loggedInUserTimezone = useUserDataSelector((state) => state.timezone);
  const now = getUserTimezoneConvertedNow(loggedInUserTimezone);
  const phaseDate = moment(currently?.sinceWhen);
  const isPast = phaseDate.isBefore(now);
  return (
    <Flex justify='space-between' gap={4}>
      <RenderItem
        label='Currently on'
        value={currently?.title}
        date={
          currently?.sinceWhen
            ? `${isPast ? 'since' : 'begins on'} ${moment(
                currently?.sinceWhen
              )?.format('DD MMM')}`
            : '-'
        }
      />

      <RenderItem
        align='end'
        label='Last done'
        value={lastDone?.title}
        date={
          lastDone?.completedAt
            ? `on ${moment(lastDone?.completedAt)?.format('DD MMM')}`
            : '-'
        }
      />
    </Flex>
  );
};

export default ItemContent;
