import { useState, useEffect } from 'react';
import { useLazyQuery, makeVar } from '@apollo/client';
import { GET_USERS } from '../pages/TasksDashboard/SupervisedTasks/services/supervised-tasks.graphql';
import { useUserDataSelector } from './useUserDataQuery';

// Reactive variable (if needed for global state)
export const usersEntityObj = makeVar([]);

export const useUsersEntity = () => {
  const entityId = useUserDataSelector((state) => state?.entityId);
  const [getUsersEntityHandler, { data, loading, error }] = useLazyQuery(
    GET_USERS,
    {
      fetchPolicy: 'network-only',
      variables: {
        entityId: entityId,
      },
    }
  );

  const [usersEntity, setUsersEntity] = useState([]);

  useEffect(() => {
    if (data) {
      setUsersEntity(data.EntityUser);
      usersEntityObj(data.EntityUser); // Update the reactive variable
    }
  }, [data]);

  // Function to refetch data
  const refetchUsersEntity = () => {
    getUsersEntityHandler();
  };

  return { usersEntity, refetchUsersEntity, loading, error };
};
