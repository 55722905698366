import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarLines,
  faCalendarXmark,
  faCheck,
  faRepeat,
} from '@fortawesome/pro-light-svg-icons';
import {
  faClockFour,
  faEllipsis,
  faPenToSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthRole, userObj } from 'sop-commons/src/client';

import { AmplitudeEvent, deployEvent } from 'shared';

import RoundProgress from '../../Components/sub-components/RoundProgress';
import {
  ISupervisedListDataEntity,
  ISupervisedTasksEntity,
} from '../../tasks.types';
import {
  ActionMenu,
  IActionMenuData,
} from '../../../../ui-components/ActionMenu';

import OpenIcon from '../../../../assets/images/open.png';
import MoreTaskAssignees from './MoreTaskAssignees';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';
import { taskRepeat } from 'utils/taskRepeat';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { getDateInUserTimezone } from '../../helper';
import moment from 'moment-timezone';
import { showTimeText } from 'pages/TasksDashboard/TaskDetails/components/Details/Details';
import TaskStatusIcon from 'ui-components/TaskStatusIcon';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';

interface IProps {
  backToTitle?: string;
  task: ISupervisedListDataEntity;
  /** Used to truncate the repeat string. Default will be 'FALSE' */
  truncRepeat?: boolean;
}

const SupervisedTaskItem: FC<IProps> = ({ backToTitle, task, truncRepeat }) => {
  const { t, i18n } = useTranslation(['common']);
  const history = useHistory();
  const userData = useReactiveVar(userObj);

  const [data, moreAssignees] = useMemo(() => {
    const firstThree = task?.userProgressData?.slice(0, 2) || [];
    const rest = task?.userProgressData?.slice(2) || [];

    return [firstThree, rest];
  }, [task?.userProgressData]);

  const redirectHandler = (type: 'open' | 'edit') => {
    if (type === 'open') {
      let url = '';
      if (task?.sessionId) {
        url = `/tasks/supervised/details/${task.eid}/${task?.sessionId}`;
      } else {
        url = `/tasks/supervised/details/${task.eid}`;
      }
      if (backToTitle) {
        deployEvent(AmplitudeEvent.TASK_SUPERVISED_DETAILS);
        history.push(url, { backToTitle: backToTitle });
      } else {
        deployEvent(AmplitudeEvent.TASK_SUPERVISED_DETAILS);
        history.push(url);
      }
    } else {
      deployEvent(AmplitudeEvent.TASK_EDIT);
      history.push(`/tasks/edit/${task.eid}`, { backToTitle: backToTitle });
    }
  };

  const clickedItemsHandler = (_: string, clickedName: string) => {
    switch (clickedName) {
      case 'open':
        return redirectHandler('open');
      case 'edit':
        return redirectHandler('edit');
    }
  };

  const backgroundHandler = () => {
    let color = '';
    switch (task?.status) {
      case 'overdue':
        color = '#FF6A551A';
        break;
      case 'dueToday':
        color = '#FFC16233';
        break;
      case 'completed':
        color = '#dcedd6';
        break;
      case 'thisWeek':
      case 'scheduled':
        color = '#EFEFEF';
        break;
      case 'upcoming':
        color = '#B1E5FC33';
        break;
      default:
        color = '#FF6A551A';
        break;
    }
    return color;
  };
  const borderHandler = () => {
    let border = '';
    switch (task?.status) {
      case 'overdue':
        border = '1px solid #FF6A55';
        break;
      case 'dueToday':
        border = '1px solid #FF9B00';
        break;
      case 'thisWeek':
      case 'scheduled':
        border = '1px solid #6F767E';
        break;
      case 'completed':
        border = '1px solid #83BF6E';
        break;
      case 'upcoming':
        border = '1px solid #2A85FF';
        break;
      default:
        border = '1px solid #FF6A55';
        break;
    }
    return border;
  };

  const durationReplace = (durationString: string) => {
    let units: any = {
      seconds: 's',
      second: 's',
      minutes: 'm',
      minute: 'm',
      hours: 'h',
      hour: 'hour',
      days: 'd',
      day: 'd',
    };

    let words = durationString.split(' ');

    return words.map((word) => units[word] || word).join(' ');
  };

  const getStatusHandler = () => {
    if (task?.status === 'completed') return;
    switch (task?.status) {
      case 'scheduled':
        return (
          <Flex
            bg='#EFEFEF'
            color='#6F767E'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            Scheduled
          </Flex>
        );
      case 'overdue':
        return (
          <Flex
            bg='#FF6A5533'
            color='#FF6A55'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            Overdue
          </Flex>
        );
      case 'partiallyDone':
        return (
          <></>
          // <Flex
          //   bg='#FFD88D4D'
          //   color='#FF6B00'
          //   gap='5px'
          //   p='5px 10px'
          //   borderRadius='8px'
          //   align='center'
          // >
          //   <FontAwesomeIcon icon={faClockFour as IconProp} />
          //   Partially ended
          // </Flex>
        );
      case 'dueToday':
        return (
          <Flex
            bg='#EFEFEF'
            color='#6F767E'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            <Text m={0}>{durationReplace(task?.duration!)}</Text>
          </Flex>
        );
      case 'upcoming':
        return (
          <Flex
            bg='#EFEFEF'
            color='#6F767E'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            <Text m={0}>{durationReplace(task?.duration!)}</Text>
            {/* <Text m={0}>{task?.dateMoment?.fromNow(true)}</Text> */}
          </Flex>
        );
      default:
        return (
          <Flex
            bg='#EFEFEF'
            color='#6F767E'
            gap='5px'
            p='5px 10px'
            borderRadius='8px'
            align='center'
          >
            <FontAwesomeIcon icon={faClockFour as IconProp} />
            <Text m={0}>{durationReplace(task?.duration!)}</Text>
          </Flex>
        );
    }
  };

  const menuData = useMemo(() => {
    const _menuData: IActionMenuData[] = [
      {
        name: t('common:open'),
        value: 'open',
        icon: OpenIcon,
      },
    ];

    if (
      userData?.authRole === AuthRole.SUPER_ADMIN ||
      userData?.eid === task.createdBy
    ) {
      _menuData.push({
        name: t('common:edit'),
        value: 'edit',
        normalIcon: 'fontAwesome',
        icon: faPenToSquare,
      });
    }
    return _menuData;
  }, [t, i18n.language, userData, task.createdBy]);

  const getTaskStatus = useMemo((): ITaskStatus | undefined => {
    switch (task?.status) {
      case 'completed':
        return 'completed';
      case 'currentRunning':
        return 'inProgress';
      case 'dueToday':
        return 'inProgress';
      case 'overdue':
        return 'overdue';
      case 'partiallyDone':
        return 'partiallyCompleted';
      case 'scheduled':
      case 'upcoming':
        return 'scheduled';
      case 'thisWeek':
        return 'inProgress';
      default:
        return undefined;
    }
  }, [task]);

  return (
    <>
      <Flex align='center' w='full' gap='20px'>
        <Flex
          gap='7px'
          w='50%'
          justify='space-between'
          cursor='pointer'
          onClick={() => redirectHandler('open')}
        >
          <Flex gap='10px'>
            {task?.status !== 'completed' ? (
              // <Box
              //   boxSize='18.5px'
              //   mt='4px'
              //   bg={backgroundHandler()}
              //   borderRadius='full'
              //   border={borderHandler()}
              // />
              <TaskStatusIcon
                status={getTaskStatus}
                iconStyles={{ marginTop: '4px' }}
                imgSize='18px'
              />
            ) : (
              <TaskStatusIcon
                status={
                  task?.taskDetails?.status === 'TASK_TERMINATED'
                    ? 'terminated'
                    : 'completed'
                }
                iconStyles={{ marginTop: '4px' }}
                imgSize='18px'
              />
              // <Center
              //   boxSize='18.5px'
              //   mt='4px'
              //   borderRadius='full'
              //   border='1px solid #83BF6E'
              //   bg='#83BF6E1A'
              //   p='1px'
              // >
              //   <FontAwesomeIcon
              //     icon={
              //       task?.taskDetails?.status === 'TASK_TERMINATED'
              //         ? (faXmark as IconProp)
              //         : (faCheck as IconProp)
              //     }
              //     color='#83BF6E'
              //     fontSize={'12px'}
              //   />
              // </Center>
            )}
            <Flex flexDirection='column'>
              <Text m={0}>{task?.title}</Text>
              <Flex gap='10px' align='center'>
                <Flex
                  gap='5px'
                  align='center'
                  borderRadius='5px'
                  h='30px'
                  fontSize='12px'
                  // bg={
                  //   task?.status === 'overdue'
                  //     ? '#FF6A551A'
                  //     : task?.status === 'dueToday'
                  //     ? '#33383F12'
                  //     : '#83BF6E1A'
                  // }
                  color={
                    task?.status === 'overdue'
                      ? '#FF6A55'
                      : task?.status === 'dueToday' ||
                        task?.status === 'completed'
                      ? '#83BF6E'
                      : '#2985ff'
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      (task?.taskDetails?.status !== 'TASK_TERMINATED'
                        ? faCalendarLines
                        : faCalendarXmark) as IconProp
                    }
                  />
                  <Text m={0}>
                    {task?.taskDetails?.status !== 'TASK_TERMINATED'
                      ? task?.status === 'completed'
                        ? `Completed on ${task?.date}`
                        : task?.status === 'scheduled'
                        ? `Will publish on ${getDateInUserTimezone(
                            task?.taskDetails?.startDate
                          )?.format('MMM DD')}`
                        : task?.date
                      : `Terminated on ${getDateInUserTimezone(
                          task?.taskDetails?.terminateDetails?.date
                        )?.format('MMM DD')}`}
                  </Text>
                  <>
                    <Text m={0}>&bull;</Text>
                    <FontAwesomeIcon icon={faRepeat as IconProp} />
                    <Text
                      m={0}
                      textTransform='capitalize'
                      isTruncated={truncRepeat}
                      maxW={truncRepeat ? '150px' : 'unset'}
                    >
                      {/* {task?.repeatString} */}
                      {taskRepeat(task?.repeatDetails as RepeatDetails<Date>)}
                    </Text>
                  </>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex w='50%' align='center' justify='flex-end' gap='20px'>
          {getStatusHandler()}
          <Flex align='center' minW='136px' justify='flex-end'>
            {data?.map((user, index) => {
              return (
                <Tooltip
                  key={index}
                  hasArrow
                  label={`${user?.name} - ${user?.progressPercent?.toFixed(
                    0
                  )}%`}
                  placement='bottom-end'
                >
                  <Box>
                    <RoundProgress
                      img={user?.profilePic}
                      value={+user?.progressPercent?.toFixed(0)}
                      thickness={6}
                      overDue={task?.status === 'overdue'}
                    />
                  </Box>
                </Tooltip>
              );
            })}

            <MoreTaskAssignees
              assignees={moreAssignees}
              redirectHandler={redirectHandler}
            />
          </Flex>

          <ActionMenu
            menuData={menuData}
            arrowSize={10}
            closeOnBlur={true}
            offset={[0, 0]}
            clickedItem={clickedItemsHandler}
          >
            <Box cursor='pointer'>
              <FontAwesomeIcon icon={faEllipsis as IconProp} fontSize='20px' />
            </Box>
          </ActionMenu>
        </Flex>
      </Flex>
    </>
  );
};

export default SupervisedTaskItem;
