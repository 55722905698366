import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { LOCATION_LAUNCH_TASKS } from './loProgress.graphql';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import Loader from 'ui-components/Loader';
import { ILaunchTaskEntity, tasksDataFormatter } from './helper';
import { CustomLauncherTask, LocLaunchTaskList } from './types';
import { AuthRole, userObj } from 'sop-commons/src/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarCheck,
  faCalendarLines,
  faDiagramSubtask,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faClockTenThirty,
  faLockKeyhole,
} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { TaskDetailDrawer } from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailDrawer';
import EmptyState from 'sub-components/EmptyState';
import InProgressTaskIcon from '../../../../assets/images/inProgressTask.svg';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { usersEntityObj } from 'sub-components/Header';
import useCombinedStore from 'zustandStore/store';
import { eventBus } from 'shared/eventEmit';
import { IRefetchType } from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailDrawer/task-detail-drawer.types';
import TaskStatusIcon from 'ui-components/TaskStatusIcon';
import TaskDetailModal from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailModal';

const LOInProgress: FC = () => {
  const { selectedInProgressLocation } = useCombinedStore();
  const userData = useReactiveVar(userObj);
  const entityUserData: any = useReactiveVar(usersEntityObj);
  const loggedInUserTimezone = userData?.timezone;
  const [selectedTaskData, setSelectedTaskData] = useState<
    CustomLauncherTask | undefined
  >(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredTaskData, setFilteredTaskData] = useState<
    ILaunchTaskEntity | undefined
  >(undefined);

  const [getLocationLaunchTasks, { data, loading, refetch }] =
    useLazyQuery<LocLaunchTaskList>(LOCATION_LAUNCH_TASKS, {
      fetchPolicy: 'network-only',
    });

  const getLocationLaunchTasksFn = () => {
    getLocationLaunchTasks({
      variables: {
        locationIds: selectedInProgressLocation?.eid,
      },
    });
  };

  useEffect(() => {
    if (selectedInProgressLocation?.eid) {
      getLocationLaunchTasksFn();
    }
  }, [selectedInProgressLocation?.eid]);

  const formatTaskData = (newData?: LocLaunchTaskList) => {
    let _data = newData || data;
    let uniqueTaskData = [
      ...new Map(
        _data?.LocationLaunchTasks?.filter((task) => task?.isPhaseSelected).map(
          (task) => [task['eid'], task]
        )
      ).values(),
    ];
    const grouped = (uniqueTaskData || [])?.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {} as Record<string, any>);
    const formattedTaskData = tasksDataFormatter(grouped, loggedInUserTimezone);
    Object.keys(formattedTaskData)?.map((key) => {
      formattedTaskData?.[key]?.map((obj) => {
        if (obj?.eid === selectedTaskData?.eid) {
          setSelectedTaskData(obj);
        }
      });
    });
    setFilteredTaskData(formattedTaskData);
  };

  useEffect(() => {
    formatTaskData();
  }, [data]);

  const refetchHandler = async (type: IRefetchType) => {
    if (refetch) {
      try {
        const { data: newData } = await refetch();
        if (type === 'step') {
          eventBus.emit('stepRefetchComplete');
        }
        formatTaskData(newData);
      } catch (err) {
        if (type === 'step') {
          eventBus.emit('stepRefetchError');
        }
      }
    }
  };

  const getUserName = (userId: string) => {
    if (userId === userData?.eid) {
      return 'You';
    }
    return (
      entityUserData?.find((userData: any) => userData?.eid === userId)?.name ||
      ''
    );
  };

  const taskContainer = (task: CustomLauncherTask) => {
    switch (task?.taskStatus) {
      case 'locked':
        return (
          <Flex
            key={task?.eid}
            gap='7px'
            align='flex-start'
            bg='white'
            p={4}
            borderRadius='8px'
            w='300px'
            cursor='no-drop'
          >
            <FontAwesomeIcon
              icon={faLockKeyhole as IconProp}
              color='#999999'
              style={{ marginTop: '3px' }}
            />
            <Flex flexDir='column' gap='5px'>
              <Text as='span'>{task?.title}</Text>
              <Box>
                <Text
                  as='span'
                  color='#6F767E'
                  fontSize='12px'
                  fontWeight={400}
                  mr='5px'
                >
                  Unlocks when the above task is completed
                </Text>
              </Box>
            </Flex>
          </Flex>
        );
      case 'overdue':
        return (
          <Flex
            key={task?.eid}
            gap='7px'
            align='flex-start'
            bg='white'
            p={4}
            borderRadius='8px'
            w='300px'
            cursor='pointer'
            onClick={() => {
              setIsOpen(true);
              setSelectedTaskData(task);
            }}
          >
            {/* <FontAwesomeIcon
              icon={faClockTenThirty as IconProp}
              color='#FF6A55'
              style={{ marginTop: '2px' }}
            /> */}
            <TaskStatusIcon
              status='overdue'
              iconStyles={{ marginTop: '4px' }}
            />
            <Flex flexDir='column' gap='5px'>
              <Text as='span'>{task?.title}</Text>
              <Flex align='center' gap='5px' fontSize='12px'>
                <FontAwesomeIcon
                  icon={faCalendarLines as IconProp}
                  color='#FF6A55'
                />
                <Text as='span' color='#FF6A55'>
                  {moment(task?.formattedDueDate)?.format('MMM DD')}
                </Text>
                <Text as='span' color='#D1D8E0' fontSize='16px'>
                  &bull;
                </Text>
                <Text as='span' color='#FF6A55'>
                  Overdue
                </Text>
              </Flex>
            </Flex>
          </Flex>
        );
      case 'inProgress':
        return (
          <Flex
            key={task?.eid}
            gap='7px'
            align='flex-start'
            bg='white'
            p={4}
            borderRadius='8px'
            cursor='pointer'
            w='300px'
            onClick={() => {
              setIsOpen(true);
              setSelectedTaskData(task);
            }}
          >
            {/* <Image src={InProgressTaskIcon} boxSize='15px' mt='2px' /> */}
            <TaskStatusIcon
              status='inProgress'
              iconStyles={{ marginTop: '4px' }}
            />
            <Flex flexDir='column' gap='5px'>
              <Text as='span'>{task?.title}</Text>
              <Flex align='center' gap='5px' fontSize='12px'>
                {task?.steps?.length > 0 && (
                  <Flex align='center' gap='5px'>
                    <FontAwesomeIcon
                      icon={faDiagramSubtask as IconProp}
                      color='#2A85FF'
                    />
                    <Text color='#2A85FF'>
                      {task?.steps?.filter((step) => step.isCompleted)?.length}/
                      {task?.steps?.length} subtasks
                    </Text>
                    <Text as='span' color='#D1D8E0' fontSize='16px'>
                      &bull;
                    </Text>
                  </Flex>
                )}
                <Text as='span' color='#2A85FF'>
                  Due in {task?.formattedDuration}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        );
      case 'complete':
        return (
          <Flex
            key={task?.eid}
            gap='7px'
            align='flex-start'
            bg='white'
            p={4}
            borderRadius='8px'
            cursor='pointer'
            w='300px'
            onClick={() => {
              setIsOpen(true);
              setSelectedTaskData(task);
            }}
          >
            {/* <FontAwesomeIcon
              icon={faCircleCheck as IconProp}
              color='#83BF6E'
              style={{ marginTop: '2px' }}
            /> */}
            <TaskStatusIcon
              status='completed'
              iconStyles={{ marginTop: '4px' }}
            />
            <Flex flexDir='column' gap='5px'>
              <Text as='s'>{task?.title}</Text>
              <Flex align='flex-start' gap='5px' fontSize='12px'>
                <FontAwesomeIcon
                  icon={faCalendarCheck as IconProp}
                  color='#83BF6E'
                  style={{ marginTop: '2px' }}
                />
                <Box color='#83BF6E'>
                  <Text as='span' mr='5px'>
                    Marked Completed on{' '}
                    {moment(task?.formattedCompleteDate)?.format('MMM DD')} by
                  </Text>
                  <Text as='span' fontWeight={700}>
                    {getUserName(task?.completedBy!)}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        );
      default:
        return null;
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const gridRender = () => {
    if (filteredTaskData) {
      return Object.keys(filteredTaskData!)?.map((_filteredTaskData, index) => {
        return (
          <Flex key={index} flexDir='column' gap={4}>
            <Flex flexDir='column'>
              <Text fontWeight={700} fontSize='16px'>
                {_filteredTaskData}
              </Text>
              <Text color='#6F767E' fontSize='15px'>{`${
                filteredTaskData![_filteredTaskData]?.length
              } ${
                filteredTaskData![_filteredTaskData]?.length === 1
                  ? 'task'
                  : 'tasks'
              }`}</Text>
            </Flex>
            {filteredTaskData![_filteredTaskData]?.length > 0 ? (
              <Flex flexDir='column' gap={4}>
                {filteredTaskData![_filteredTaskData]?.map((taskData) =>
                  taskContainer(taskData)
                )}
              </Flex>
            ) : (
              <Flex>
                <EmptyState
                  image='MyTask'
                  title={`No completed task for ${_filteredTaskData}`}
                />
              </Flex>
            )}
          </Flex>
        );
      });
    }
    return <></>;
  };

  if (loading) {
    return (
      <Center w='full' h='75vh'>
        <Loader />
      </Center>
    );
  }

  const compRender = () => {
    if (userData?.authRole === AuthRole.LOCATION_OWNER) {
      return (
        <TaskDetailDrawer
          isOpen={isOpen}
          mode='submit'
          onClose={onClose}
          selectedTaskData={selectedTaskData!}
          refetchHandler={refetchHandler}
        />
      );
    } else {
      if (isOpen) {
        return (
          <TaskDetailModal
            isOpen={isOpen}
            mode='submit'
            onClose={onClose}
            selectedTaskData={selectedTaskData!}
            refetchHandler={refetchHandler}
          />
        );
      } else {
        return null;
      }
    }
  };

  return (
    // @ts-ignore
    <Flex flexDir='column' w='full' gap={4}>
      <Flex gap={4} overflow='auto'>
        {gridRender()}
      </Flex>

      {compRender()}
    </Flex>
  );
};

export default LOInProgress;
