import React, { FC } from 'react';
import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { TabItem } from 'atoms';

type IDataProps = Array<{
  label: string;
  content: React.ReactElement;
}>;

interface DataTabsProps {
  data: IDataProps;
}

export const ProfileTabs: FC<DataTabsProps> = ({ data }) => {
  return (
    <Tabs isLazy>
      <TabList borderColor='#F5F5F5'>
        {data.map((tab, index) => (
          <TabItem key={index}>{tab.label}</TabItem>
        ))}
      </TabList>
      <TabPanels>
        {data.map((tab, index) => {
          return (
            <TabPanel p='0 0 16px' key={index}>
              {tab.content}
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

export default ProfileTabs;
