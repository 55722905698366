import React, { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Switch,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FormInput } from '../../../atoms';

import TitleHeader from '../../../sub-components/CardEditor/TitleHeader';
import { IFormInput } from './location-setting.types';
import FieldOptions from './FieldOptions';

interface IProps {}

const OtherFields: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'setting', 'form']);

  const { control } = useFormContext<IFormInput>();

  const fieldType = useWatch<IFormInput, 'fieldType.value'>({
    name: 'fieldType.value',
  });

  if (!fieldType) {
    return null;
  }

  return (
    <>
      <Controller
        control={control}
        name={'validations.isRequired'}
        render={({ field }) => {
          return (
            <Flex justify='space-between' pt={2}>
              <Box color='#33383f' fontWeight='600'>
                {t(
                  fieldType === 'document'
                    ? 'setting:isRequired'
                    : 'setting:mandatory'
                )}
              </Box>
              <Switch
                size='md'
                isChecked={field.value}
                onChange={field.onChange}
              />
            </Flex>
          );
        }}
      />

      <Controller
        control={control}
        name='fieldName'
        rules={{
          required: t('setting:validation.fieldNameRequired'),
          validate: (value) => {
            if (value?.trim()?.length === 0) {
              return t('setting:validation.fieldNameInvalid');
            }
          },
        }}
        shouldUnregister
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <TitleHeader
                title={t(
                  fieldType === 'document'
                    ? 'setting:attachmentTitle'
                    : 'setting:nameOfTheField'
                )}
                desc={t('setting:nameOfTheFieldDesc')}
              />
              <FormInput mt={1} {...field} />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <FieldOptions />
    </>
  );
};

export default OtherFields;
