import { gql } from '@apollo/client';

export const LOCATION_LAUNCH_TASKS = gql`
  query LocationLaunchTasks($locationIds: [String]) {
    LocationLaunchTasks(locationIds: $locationIds) {
      buffer
      completedAt
      completedBy
      category
      dependency
      description
      docRequired
      dueDate
      duration
      eid
      files {
        createdAt
        createdBy {
          eid
          name
        }
        fileSize
        mimetype
        name
        type
        updatedAt
        url
      }
      isCompleted
      isPhaseSelected
      launchId
      locationId
      startDate
      steps {
        completedAt
        completedBy
        isCompleted
        formId
        stepId
        title
        type
        trainingAssignee
      }
      title
    }
  }
`;

export const RAISE_LOCATION_LAUNCH_QUERY = gql`
  mutation RaiseLocationLaunchQuery($input: LocationLaunchQueryInputInput) {
    RaiseLocationLaunchQuery(input: $input) {
      comment
      conversationId
      createdAt
      createdBy
      createdByUser {
        eid
        name
      }
      eid
      launchId
      locationId
      resolvedBy
      resolvedByUser {
        eid
        name
      }
      resolvedOn
      status
      supervisorId
      taskId
      title
      updatedAt
    }
  }
`;
