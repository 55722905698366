// useUploadMultipleImages.ts

import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import { fileTOBlobData } from '../utils/blobHelper';
import { GET_SIGNED_URL } from '../ui-components/Profile';

// Type to provide feedback about the progress of each file's upload
type Progress = (progress: number, fileIndex: number) => void;

const extractNameType = (data: Blob, fileName?: string) => {
  // @ts-ignore
  if (typeof data?.name !== 'string') {
    return {
      name: fileName,
      type: data.type,
    };
  }
  return data as File;
};

const useUploadMultipleImages = () => {
  const [getSignedUrlRequest] = useMutation(GET_SIGNED_URL);

  const getSignedUrl = useCallback(
    async (input) => {
      return await getSignedUrlRequest({
        variables: {
          getSignedUrlInput: input,
        },
      }).then((res) => res?.data?.getSignedUrl);
    },
    [getSignedUrlRequest]
  );

  return useCallback(
    async (files: File[], uploadProgress?: Progress) => {
      const urls: string[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { name: _filename, type } = extractNameType(file, file.name);

        const signedUrls = await getSignedUrl([
          { filename: _filename, mimetype: type },
        ]);

        if (Array.isArray(signedUrls) && signedUrls.length) {
          const data = signedUrls[0];
          const signedRequestUrl = data.signedRequestUrl;
          const imageUrl = data.url;

          const blobData = await fileTOBlobData(file);

          await axios.put(signedRequestUrl, blobData, {
            onUploadProgress: (progressEvent) => {
              const progress = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              uploadProgress?.(progress, i); // Provide progress and file index
            },
          });

          urls.push(imageUrl);
        }
      }

      return urls;
    },
    [getSignedUrl]
  );
};

export { useUploadMultipleImages };
