import {
  ApolloError,
  OperationVariables,
  QueryTuple,
  useLazyQuery,
  useReactiveVar,
} from '@apollo/client';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';
import { UserData, userObj } from 'sop-commons/src/client';
import { GET_USER } from '../pages/Login/login.graphql';
import { roleObj } from 'ui-components/DashboardMenu';

export interface UserDataResponse {
  user: UserData;
}

interface IProps {
  onCompleted?: (data: UserDataResponse) => void;
  onError?: (error: ApolloError) => void;
}

export const useUserDataQuery = ({
  onCompleted,
  onError,
}: IProps = {}): QueryTuple<UserDataResponse, OperationVariables> => {
  return useLazyQuery<UserDataResponse>(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      userObj(data.user);
      const roles = data?.user?.entity?.roles;
      if (roles && roles?.length) {
        let roleWithColor: any = {};
        roles?.forEach((role: any) => {
          roleWithColor[role.name] = role?.color;
        });

        roleObj(roleWithColor);
      }
      onCompleted?.(data);
    },
    onError: onError,
  });
};

/**
 * @deprecated Use `useUserDataSelector` to reduce re-rendering when object updates
 */
export const useUserData = (): UserData => {
  return useReactiveVar(userObj);
};

/**
 * @returns selected data using selector
 *
 * @example
 *
 * ```ts
 * import { useUserDataSelector } from 'hooks';
 * import { shallowEqual } from 'utils';
 * const EntityList = () => {
 *    const locations = useUserDataSelector(state => state.locations, shallowEqual);
 *
 *    return (
 *      <div>
 *        {locations?.map((loc) => (
 *          <div key={loc.eid}>{loc.name}</div>
 *        ))}
 *      </div>
 *    );
 * }
 * ```
 */
export const useUserDataSelector = <Selection>(
  // eslint-disable-next-line no-unused-vars
  selector: (state: UserData) => Selection,
  // eslint-disable-next-line no-unused-vars
  isEqual?: (a: Selection, b: Selection) => boolean
): Selection => {
  const value = useSyncExternalStoreWithSelector(
    (update) => {
      let unsubscribe: () => void;
      const listener = () => {
        // Notify parent listener that the variable has changed
        update();

        unsubscribe = userObj.onNextChange(listener);
      };

      unsubscribe = userObj.onNextChange(listener);
      return () => unsubscribe();
    },
    userObj,
    userObj,
    selector || ((state) => state),
    isEqual
  );

  return value;
};

export const useUserEntity = <Selection>(
  // eslint-disable-next-line no-unused-vars
  selector: (entity: UserData['entity']) => Selection,
  // eslint-disable-next-line no-unused-vars
  isEqual?: (a: Selection, b: Selection) => boolean
): Selection => {
  const value = useSyncExternalStoreWithSelector(
    (update) => {
      let unsubscribe: () => void;
      const listener = () => {
        // Notify parent listener that the variable has changed
        update();

        unsubscribe = userObj.onNextChange(listener);
      };

      unsubscribe = userObj.onNextChange(listener);
      return () => unsubscribe();
    },
    () => userObj()?.entity,
    () => userObj()?.entity,
    selector || ((entity) => entity),
    isEqual
  );

  return value;
};
