import { ApolloQueryResult, QueryLazyOptions } from '@apollo/client';
import { Flex, VStack } from '@chakra-ui/react';
import {
  IFilesEntity,
  ILocationDetails,
} from 'pages/LocationDetails/utils/location-details.types';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentListing, Heading } from './components';
import { useDocumentsUpload } from './hooks/useDocumentsUpload';

interface IProps {
  details: ILocationDetails | undefined;
  refetch:
    | ((
        variables?:
          | Partial<{
              eid: string;
            }>
          | undefined
      ) => Promise<
        ApolloQueryResult<{
          userById: ILocationDetails;
        }>
      >)
    | undefined;
  getLocationDetailsHandler: () => void;
}

const Documents: FC<IProps> = ({
  details,
  getLocationDetailsHandler,
  refetch,
}) => {
  const { locationId } = useParams<{ locationId: string }>();
  const documentsUpload = useDocumentsUpload();

  const onClickHandler = () => {
    documentsUpload({
      uploadedFiles: details?.files || [],
      refetchHandler,
      locationId,
      type: 'add',
    });
  };

  const refetchHandler = () => {
    refetch && refetch();
  };

  const onEditFileClickHandler = (index: number) => {
    documentsUpload({
      uploadedFiles: details?.files || [],
      refetchHandler,
      locationId,
      type: 'edit',
      index,
    });
  };

  return (
    <VStack align='baseline' gap={4}>
      <Heading onClickHandler={onClickHandler} />
      <DocumentListing
        details={details?.files}
        refetchHandler={refetchHandler}
        locationId={locationId}
        getLocationDetailsHandler={getLocationDetailsHandler}
        onEditFileClickHandler={onEditFileClickHandler}
      />
    </VStack>
  );
};

export default Documents;
