import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import {
  Container,
  OpenStepHeader,
  SaveNextButton,
  useStepperIsOpen,
} from '../common';
import TaskStepStepper from './TaskStepStepper';

import TaskStepForm from './TaskStepForm';
import { IFormInput } from '../task.types';

interface IProps {}

const TaskSteps: FC<IProps> = () => {
  const { t } = useTranslation(['task']);
  const isOpen = useStepperIsOpen('taskSteps');
  const { handleSubmit, setValue } = useFormContext<IFormInput>();

  const saveAndNext = () => {
    setValue('completed.taskSteps', true);
    setValue('filled.taskSteps', true);
    setValue('currentStep', 'assignees');
  };

  if (!isOpen) {
    return <TaskStepStepper />;
  }

  return (
    <Container flexDir='column' p='20px'>
      <OpenStepHeader title={t('task:steps')} />

      <TaskStepForm />

      <SaveNextButton onClick={handleSubmit(saveAndNext)} />
    </Container>
  );
};

export default TaskSteps;
