import { toArray } from './utils';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type Any = any;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function updateObject(
  object: Any,
  key: string | (number | string)[],
  value: Any
) {
  const result = object;
  const _key = Array.isArray(key) ? key : [key];
  let currentIndex = 0;
  const size = _key.length;
  while (currentIndex < size - 1) {
    if (object[_key[currentIndex]]) {
      if (typeof object[_key[currentIndex]] !== 'object') {
        object[_key[currentIndex]] = {};
      }
      object = object[_key[currentIndex]];
      ++currentIndex;
    } else {
      const K = _key[currentIndex + 1] as unknown;

      if (
        typeof K === 'number' ||
        (typeof K === 'string' && (K as never) % 1 === 0)
      ) {
        object[_key[currentIndex]] = [];
      } else {
        object[_key[currentIndex]] = {};
      }
    }
  }
  object[_key[size - 1]] = value;
  return result;
}

export function getObject<T extends object>(
  object: T,
  paths: string | (number | string)[]
): Any {
  const _key = toArray(paths);

  return _key.reduce((obj, key) => {
    // @ts-ignore
    return obj && obj[key] !== 'undefined' ? obj[key] : undefined;
  }, object) as Any;
}
