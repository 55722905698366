import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const Loader = () => {
  return <Spin className='eventclass-loader' indicator={antIcon} />;
};

export default Loader;
