import React, {
  FC,
  Fragment,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  ChakraProvider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Portal,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { useTranslation } from 'react-i18next';
import GenericColorHeader from '../GenericColorHeader';
import {
  getUsersTrainings,
  UserTrainingResponse,
  WorkerTrackData,
  getTrainingContentTinyData,
} from './training-progress.graphql';
import { Column, SortingTable } from '../ChakraTable/SortingTable';
import PathName from '../../pages/Training/Training/PathName';
import AlarmIcon from '../../assets/images/alarm.svg';
import RetakeIcon from '../../assets/images/retake.svg';
import SingleUserChatModal from '../../ui-components/SingleUserChatModal';
import ProgressTimeline from 'ui-components/ProgressTimeline';
import MilestoneSuccess from '../../assets/images/milestone_success.png';
import MedalIcon from '../../assets/images/medal.svg';
import progressDataHandler from './track-training-progress-functions';
import { useRetakeTraining } from '../../ui-components/Training';
import { compareArrayReturnSame, toArray } from '../../utils/utils';
import { UserTrainingItem } from './user-training.types';
import { reAssignTraining } from '../../pages/Training/TrainingList/training.graphql';
import { ActionButton } from '../../ui-components/Confirm';
import EmptyState from 'sub-components/EmptyState';

const Wrapper = styled.div`
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;
    border-radius: 5px;
    display: table;
    max-width: unset;
    // max-width: -moz-fit-content;
    // /* max-width: fit-content; */
    overflow-x: auto;
    white-space: nowrap;
  }

  thead {
    height: unset !important;
  }
`;

interface IProps {
  user: Pick<WorkerTrackData, 'eid' | 'name'>;
  onClose: () => void;
}

const UserTrainings: FC<IProps> = ({ user, onClose: onCloseRequest }) => {
  const { t, i18n } = useTranslation(['common', 'training']);
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: true,
  });
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const retakeTraining = useRetakeTraining();
  const [showChatModal, setShowChatModal] = useState(false);
  const [showRemindUserChatModal, setShowRemindUserChatModal] = useState(false);
  const [trainingPathData, setTrainingPathData] = useState<UserTrainingItem>();
  const [userName, setUserName] = useState('');
  const [progressData, setProgressData] = useState<{
    allMilestoneData?: any[];
    showEmptyState?: boolean;
    userData: any;
    trainingName?: string;
    userId?: string;
  }>({ allMilestoneData: [], showEmptyState: false, userData: null });
  const [progressTimelineModal, setProgressTimelineModal] = useState(false);
  const userData = useReactiveVar(userObj);

  const getTrainingStatus = useCallback(
    (filteredResult): UserTrainingItem['status'] => {
      if (filteredResult) {
        if (
          (filteredResult?.started && filteredResult?.completed) ||
          filteredResult?.completed
        ) {
          return 'common:completed';
        } else if (filteredResult?.started && !filteredResult?.completed) {
          return 'common:pending';
        } else return 'common:pending';
      } else return 'common:pending';
    },
    []
  );

  function filterCard(val: any) {
    return val.type === 'card' || val.type === 'sop';
  }

  const { loading, data, refetch } = useQuery<UserTrainingResponse>(
    getUsersTrainings,
    {
      fetchPolicy: 'network-only',
      variables: {
        userId: user.eid,
        userIds: [user.eid],
      },
    }
  );

  const trainings = useMemo((): UserTrainingItem[] => {
    return toArray(data?.userTrainingDetails)
      ?.filter((tr) => tr?.status === 'active')
      ?.map((trainData) => {
        const filteredResult = trainData?.rawUserProgress.find(
          (e) => e.userId === user.eid
        );

        const viewedCards = toArray(filteredResult?.viewedContents).filter(
          filterCard
        );
        const totalCards = toArray(trainData?.contents).filter(filterCard);
        let comparedViewedCards = compareArrayReturnSame(
          totalCards,
          viewedCards,
          'eid',
          'uid'
        );
        const status = getTrainingStatus(filteredResult);

        const viewedCardsLength = comparedViewedCards?.length || 0;
        const totalCardsLength = totalCards?.length || 0;

        return {
          ...trainData,
          viewedCards: viewedCardsLength,
          totalCards: totalCardsLength,
          status: status,
          progress: `${viewedCardsLength}/${totalCardsLength}`,
          isCompleted: status === 'common:completed',
        } as UserTrainingItem;
      });
  }, [data?.userTrainingDetails]);

  const [reassignTraining] = useMutation(reAssignTraining, {
    onCompleted: async () => {
      toast({
        status: 'success',
        title: t('common:update'),
      });
      await refetch?.({
        userId: user.eid,
      });
    },
    onError: (error) => {
      toast({
        status: 'error',
        title: t('common:error'),
      });
    },
  });

  const confirmRetake = (data: UserTrainingItem) => {
    setTrainingPathData(data);
    setShowChatModal(true);
    onClose();
    return reassignTraining({
      variables: {
        userId: user.eid,
        trainingId: data.eid,
      },
    });
  };

  const onRetake = (data: UserTrainingItem) => {
    return new Promise((resolve) => {
      retakeTraining({
        // @ts-ignore
        title: t('training:retake_confirm_user', {
          name: user.name,
        }),
        onOk: async () => {
          await confirmRetake(data);
          resolve(t('common:success'));
        },
        onCancel: () => resolve(null),
      });
    });
  };

  const onItemClicked = (data?: UserTrainingItem) => {
    if (data) {
      if (data.status === 'common:completed') {
        return onRetake(data);
      } else {
        setShowRemindUserChatModal(true);
        setTrainingPathData(data);
        setShowChatModal(true);
        setProgressTimelineModal(false);
        onClose();
      }
    }
  };

  const onTrackProgress = (data: UserTrainingItem) => {
    setTrainingPathData(data);
    setProgressData({ userData: data, userId: user?.eid });
    setProgressTimelineModal(true);

    // progressDataHandler(
    //   {
    //     ...data,
    //     userProgress: data.rawUserProgress,
    //   },
    //   setUserName,
    //   user,
    //   data,
    //   setProgressData,
    //   setProgressTimelineModal,
    //   userData?.eid
    // );
  };

  const columns = useMemo((): Column<UserTrainingItem>[] => {
    return [
      {
        Header: '',
        accessor: 'title',
        width: '46%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <PathName
              pathName={value}
              thumbnail={row?.original?.thumbnail}
              updatedAt={row?.original?.contentLastUpdate}
              index={row.index}
              status='active'
            />
          );
        },
      },
      {
        Header: '',
        accessor: 'status',
        width: '24%',
        Cell: ({ cell: { value } }) => {
          const isPending = value === 'common:pending';
          return (
            <Box
              mb='25px'
              fontSize='14px'
              fontWeight='600'
              lineHeight='24px'
              textAlign='center'
              width='max-content'
              px='10px'
              borderRadius='8px'
              bg={isPending ? 'rgba(255, 188, 153, 0.2)' : '#EAFAE4'}
              color={isPending ? '#FF6A55' : '#83BF6E'}
            >
              {t(value)}
            </Box>
          );
        },
      },
      {
        Header: '',
        accessor: 'progress',
        width: '12%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Box
              mb='25px'
              fontSize='15px'
              fontWeight='600'
              lineHeight='24px'
              textAlign='center'
              width='max-content'
              px='10px'
              borderRadius='5px'
              bg={row?.original?.isCompleted ? '#EAFAE4' : '#EFEFEF'}
              color={row?.original?.isCompleted ? '#83BF6E' : '#1A1D1F'}
              cursor='pointer'
              onClick={() => onTrackProgress(row?.original)}
            >
              {value}
            </Box>
          );
        },
      },
      {
        Header: '',
        accessor: 'isCompleted',
        id: 'action',
        width: '18%',
        Cell: ({ cell: { value, row } }) => {
          const icon = value ? RetakeIcon : AlarmIcon;
          return (
            <Box mb='25px' display='flex' gap={4} float='right'>
              <ActionButton
                leftIcon={<Image src={icon} w='17px' h='17px' />}
                fontSize='14px'
                borderRadius='8px'
                fontWeight='600'
                size='sm'
                variant='outline'
                actionFn={() => onItemClicked(row?.original)}
              >
                {value
                  ? t('training:retake')
                  : t('training:training_details_section.remind_button')}
              </ActionButton>
            </Box>
          );
        },
      },
    ];
  }, [i18n.language]);

  const onChatClose = () => {
    onOpen();
    setShowRemindUserChatModal(false);
    setTrainingPathData(undefined);
    setShowChatModal(false);
  };

  const progressTimelineHandler = () => {
    setProgressTimelineModal(false);
  };

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onClose={onCloseRequest}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW='860px' borderRadius='16px' py='16px' px='8px'>
          <ModalHeader>
            <Flex align='center' justify='space-between'>
              <GenericColorHeader
                color='#B5E4CA'
                title={t('training:user_trainings', { value: user?.name })}
              />
              <ModalCloseButton
                pos='relative'
                top='initial'
                right='initial'
                borderRadius='full'
              />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Wrapper>
              <SortingTable
                colorScheme='blue'
                emptyData={{
                  // text: t('common:no_data_found'),
                  content: (
                    <EmptyState
                      image='MemberTraining'
                      title='No Trainings found'
                    />
                  ),
                }}
                page={1}
                columns={columns}
                data={trainings}
                isLoading={loading}
                isResponsive
                isHidePagination={true}
                options={{
                  disableSortBy: true,
                }}
              />
            </Wrapper>
          </ModalBody>
        </ModalContent>
      </Modal>
      {showChatModal && (
        <SingleUserChatModal
          onClose={onChatClose}
          selectedUser={user.eid}
          prefilledMessage={
            showRemindUserChatModal
              ? `It seems you have been stuck/halted on the training module. Sending you a gentle reminder to resume your training.\n${process.env.REACT_APP_FRONTEND_URL}/view/${trainingPathData?.eid}`
              : `To refresh your knowledge and review the content to solidify your understanding, I would like to ask you to retake the training.\n${process.env.REACT_APP_FRONTEND_URL}/view/${trainingPathData?.eid}`
          }
        />
      )}
      {progressTimelineModal && (
        <ChakraProvider portalZIndex={9999}>
          <ProgressTimeline
            isOpen={progressTimelineModal}
            timelineData={progressData?.allMilestoneData}
            // trainingName={progressData?.trainingName}
            trainingName={progressData?.userData?.title}
            onCloseClick={progressTimelineHandler}
            milestoneSuccessImg={MilestoneSuccess}
            medalIcon={MedalIcon}
            userId={progressData?.userId}
            userName={user?.name || 'User'}
            remindUserChatModalHandler={() => {
              setShowRemindUserChatModal(true);
              setShowChatModal(true);
            }}
            openChatModal={(selectedUserId: string) => {
              setShowRemindUserChatModal(true);
              setShowChatModal(true);
            }}
            showEmptyState={progressData?.showEmptyState}
            userData={progressData?.userData}
          />
        </ChakraProvider>
      )}
    </Fragment>
  );
};

export default UserTrainings;

UserTrainings.displayName = 'UserTrainings';
