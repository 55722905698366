import React, { FC, useState } from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { Spinner } from '@chakra-ui/spinner';

import { useMountedRef } from 'hooks';
import { isThenable } from 'utils/utils';

const Wrapper = styled(motion.div)`
  margin-top: 16px;
  width: 116px;
  height: 64px;
  background-color: #d9d9d9;
  display: flex;
  justify-content: flex-start;
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;

  &[data-ison='true'] {
    background-color: #83bf6e;
    justify-content: flex-end;
  }

  &[data-loading='true'] {
    background-color: #d9d9d9;
    pointer-events: none;
  }

  .handle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background-color: white;
    border-radius: 40px;
  }
`;

interface IProps {
  onClick?: () => void | PromiseLike<void>;
  value?: boolean;
}

const ToggleSwitch: FC<IProps> = ({ onClick, value }) => {
  const [isLoading, setIsLoading] = useState(false);

  const mounted = useMountedRef();

  const [isOn, setIsOn] = useState(typeof value === 'boolean' ? value : false);

  const handlePromise = (returnValue: PromiseLike<void>) => {
    if (!isThenable(returnValue)) {
      return;
    }
    setIsLoading(true);
    returnValue?.then(
      () => {
        mounted.current && setIsLoading(false);
      },
      (e: Error) => {
        mounted.current && setIsOn(false);
        mounted.current && setIsLoading(false);
        // eslint-disable-next-line no-console
        console.error(e);
      }
    );
  };

  const toggleSwitch = () => {
    let returnValue = onClick?.();
    if (!returnValue) {
      return;
    }
    setIsOn(!isOn);
    handlePromise(returnValue);
  };

  return (
    <Wrapper
      layout
      // @ts-ignore
      layoutRoot
      data-isOn={isOn}
      data-loading={isLoading}
      onClick={isLoading ? undefined : toggleSwitch}
    >
      <motion.div className='handle' layout transition={spring}>
        {isLoading && <Spinner color='gray.400' boxSize='20px' speed='0.65s' />}
      </motion.div>
    </Wrapper>
  );
};

export default ToggleSwitch;

const spring = {
  type: 'spring',
  damping: 15,
};
