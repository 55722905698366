import React, { useMemo } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  MenuItem,
  PopoverTrigger as OrigPopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { NullShorting } from 'utils/sorting';
import { shallowEqual } from 'utils';

import { Column } from '../../../sub-components/ChakraTable/SortingTable';
import { IndeterminateCheckbox } from '../common';
import {
  LocationName,
  LocationMembers,
  LocationActions,
  LocationStatus,
  LocationType,
  LocationCityHeader,
  LocationState,
  LocationZipCodeHeader,
  LocationTypeHeader,
  LocationEmailPhone,
  LocationStatusHeader,
} from './Components';
import { ILocation } from '../../../sub-components/Locations/locationTypes/location-types';
import {
  ICityStateList,
  ICityStateListEntity,
  IDropdownType,
} from './LocationListContainer';

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

export const useColumnHandler = (
  onEditClick: (location: ILocation) => void,
  onShareClick: (location: ILocation) => void,
  onMessageClick: (location: ILocation) => void,
  onLocationClick: (location: ILocation) => void,
  onCityStateClick: (type: IDropdownType, data: ICityStateListEntity) => void,
  fetchData: () => void,
  cityStateList: ICityStateList,
  shouldHideEdit?: boolean,
  locationOwnerShare?: boolean
): Column<ILocation>[] => {
  const { t, i18n } = useTranslation(['location', 'setting']);
  const userData = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      type: state.type,
    }),
    shallowEqual
  );

  const listRender = (
    data: ICityStateListEntity,
    index: number,
    type: IDropdownType
  ) => {
    return (
      <MenuItem key={index} onClick={() => onCityStateClick(type, data)}>
        <Flex align='center' justify='space-between' w='full'>
          <Text as='b' textTransform='capitalize'>
            {data?.name}
          </Text>
          <Checkbox isChecked={data?.selected} />
        </Flex>
      </MenuItem>
    );
  };

  return useMemo((): Column<ILocation>[] => {
    const actionColumn =
      (userData?.authRole === AuthRole.SUPER_ADMIN ||
        userData?.authRole === AuthRole.ADMIN ||
        userData?.authRole === AuthRole.LOCATION_OWNER) &&
      userData?.type === 'user'
        ? [
            {
              Header: '',
              accessor: 'eid' as any,
              sticky: 'right',
              width: 90,
              id: 'action',
              // @ts-ignore
              Cell: ({ cell: { row, value } }) => {
                return (
                  <LocationActions
                    locationId={value}
                    shouldHideEdit={shouldHideEdit}
                    locationOwnerShare={locationOwnerShare}
                    onEditClick={() => onEditClick(row.original)}
                    onShareClick={() => onShareClick(row.original)}
                    onMessageClick={() => onMessageClick?.(row.original)}
                  />
                );
              },
              disableSortBy: true,
            },
          ]
        : [];
    return [
      {
        accessor: 'eid',
        id: 'checkbox',
        width: 50,
        // @ts-ignore
        Header: ({ getToggleAllRowsSelectedProps }) => {
          return (
            <IndeterminateCheckbox
              borderRadius='5px'
              ml='12px'
              {...getToggleAllRowsSelectedProps?.()}
            />
          );
        },
        Cell: ({ row }) => {
          return (
            <IndeterminateCheckbox
              borderRadius='5px'
              ml='12px'
              // @ts-ignore
              {...row.getToggleRowSelectedProps?.()}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('setting:locations_section.name_table_heading'),
        accessor: 'name',
        width: 300,
        Cell: ({ cell: { value, row } }) => {
          return (
            <Box>
              <LocationName
                name={value}
                username={row.original.username}
                thumbnail={row.original.thumbnail}
                onClick={() => onLocationClick(row?.original)}
              />
            </Box>
          );
        },
        sortType: NullShorting,
      },
      {
        // Header: t('setting:locations_section.type'),
        Header: (
          <LocationTypeHeader
            cityStateList={cityStateList}
            fetchData={fetchData}
            listRender={listRender}
          />
        ),
        accessor: 'locationType',
        width: 250,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return <LocationType value={value} />;
        },
        sortType: NullShorting,
      },
      {
        Header: t('setting:locations_section.owner'),
        accessor: 'owner',
        width: 250,
        Cell: ({ cell: { value, row } }) => {
          let membersData = row?.original?.members?.filter(
            (member) => member?.authRole === AuthRole.LOCATION_OWNER
          );
          return membersData?.length > 0 ? (
            <LocationMembers
              members={membersData}
              locationName={row.original.name}
            />
          ) : (
            <Text>-</Text>
          );
        },
        disableSortBy: true,
      },
      {
        Header: (
          <LocationStatusHeader
            cityStateList={cityStateList}
            fetchData={fetchData}
            listRender={listRender}
          />
        ),
        accessor: 'locationStatus',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return <LocationStatus status={value} />;
        },
      },
      {
        Header: 'Email',
        accessor: 'locationEmail',
        disableSortBy: true,
        width: 300,
        Cell: ({ cell: { value } }) => {
          return (
            <LocationEmailPhone
              values={value}
              message='Email copied to clipboard'
            />
          );
        },
      },
      {
        Header: 'Phone',
        accessor: 'locationPhone',
        disableSortBy: true,
        width: 300,
        Cell: ({ cell: { value } }) => {
          return (
            <LocationEmailPhone
              values={value}
              message='Phone number copied to clipboard'
            />
          );
        },
      },
      {
        Header: (
          <LocationCityHeader
            cityStateList={cityStateList}
            fetchData={fetchData}
            listRender={listRender}
          />
        ),
        accessor: 'address',
        id: 'city',
        width: 250,
        Cell: ({ cell: { value } }) => {
          return <Text>{value?.city || '-'}</Text>;
        },
        disableSortBy: true,
      },
      {
        Header: (
          <LocationState
            cityStateList={cityStateList}
            fetchData={fetchData}
            listRender={listRender}
          />
        ),
        accessor: 'address',
        id: 'state',
        width: 250,
        Cell: ({ cell: { value } }) => {
          return <Text>{value?.state || '-'}</Text>;
        },
        disableSortBy: true,
      },
      {
        Header: (
          <LocationZipCodeHeader
            cityStateList={cityStateList}
            fetchData={fetchData}
            listRender={listRender}
          />
        ),
        accessor: 'address',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return <Text>{value.zipCode || '-'}</Text>;
        },
      },
      ...actionColumn,
    ];
  }, [
    t,
    i18n.language,
    onEditClick,
    onShareClick,
    onMessageClick,
    shouldHideEdit,
    locationOwnerShare,
    cityStateList,
  ]);
};
