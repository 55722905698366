import { gql } from '@apollo/client';
import { Address, FileEntity, LocationStatus, LocationType } from 'types';
import { IEntityTypes } from './static-data';

export interface OtherFieldsEntity {
  fieldId: string;
  value: string;
  options: string[];
  files: FileEntity[];
}

export interface LocationInputs {
  // TODO Basic Details
  locationType: LocationType;
  name: string;
  locationStatus: LocationStatus;

  locationEmail: string[];
  locationPhone: string[];
  locationOwners: string[];

  // TODO Login Details
  username: string;
  password: string;

  // TODO Address
  timezone: string;
  address: Address;

  // TODO Entity details
  entityName: string;
  entityType: IEntityTypes;
  countryOfFormation: string;
  stateOfFormation: string;
  taxPayerId: string;

  // TODO Other details
  otherFields: OtherFieldsEntity[];
}

export interface CreateLocationInputs {
  username: string;
  // taskLists: string[];
  supervisors: string[];
  liveDate: string;
  password: string;
  launcherId: string;
  phases: {
    assignees: string[];
    phaseEid: string;
    selected: boolean;
  }[];
  details: {
    timezone: string;
    taxPayerId: string;
    stateOfFormation: string;
    locationType: string;
    locationStatus: string;
    locationPhone: string[];
    locationName: string;
    locationEmail: string[];
    files: {
      createdBy: string;
      fileSize: number;
      mimetype: string;
      name: string;
      type: string;
      url: string;
    }[];
    entityType: string;
    entityName: string;
    countryOfFormation: string;
    address: {
      address: string;
      city: string;
      state: string;
      zipCode: string;
    };
  };
}

export interface AddLocationVariable {
  input: LocationInputs;
}

export interface ILocationContents {
  phaseId: string;
  trainingId: string[];
  sopId: string[];
  formId: {
    eid: string;
    type: string;
    visibility: string;
    assignedTo: string[];
    assignedRoles: string[];
    assignedLocations: string[];
    visibleTo: {
      users: string[];
      locations: string[];
      roles: string[];
    };
  }[];
}

export const ADD_LOCATION_QUERY = gql`
  mutation AddLocation($input: NewLocationInput) {
    addLocation(input: $input) {
      eid
      name
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation CreateLocationWithLauncher($input: LocationLaunchInputInput) {
    CreateLocationWithLauncher(input: $input) {
      eid
    }
  }
`;

export const LOCATION_CONTENTS = gql`
  query Query($launcherId: String!) {
    LauncherContents(eid: $launcherId)
  }
`;
