/*
    The structure coming from backend is:

    chatConfig: {
        locationChat: boolean;
        restrictions: boolean;
        newChannel: {
            authRoles: string[];
            members: string[];
            restrictionType: role | authRole | anyone | creator;
            roles: string[];
        };
        startDM: {
            authRoles: string[];
            members: string[];
            restrictionType: role | authRole | anyone | creator;
            roles: string[];
        }
    }

*/

import { useCallback } from 'react';
import { checkChatAccess } from 'utils/checkChatAccess';
import { AuthRole } from '../authorization';
import { shallowEqual } from '../utils';
import { useUserDataSelector, useUserEntity } from './useUserDataQuery';

export const useHasChatAccess = () => {
  const config = useUserEntity((entity) => entity?.chatConfig, shallowEqual);
  const loggedInUserDetails = useUserDataSelector((state) => state);
  const entityLocations = useUserEntity(
    (entity) => entity?.locations,
    shallowEqual
  );
  return useCallback(
    (userDetails: {
      eid: string;
      role: string;
      authRole: AuthRole;
      [k: string]: string;
    }) => {
      return checkChatAccess(
        config,
        userDetails,
        loggedInUserDetails,
        entityLocations
      );
    },
    [config]
  );
};
