export enum AuthRole {
  SUPER_ADMIN = "superadmin",
  ADMIN = "admin",
  MANAGER = "locationOwner",
  LOCATION_OWNER = "locationOwner",
  WORKER = "worker",
  LOCATION_USER = "locationUser",
}

interface ArchivedChat {
  type: "group" | "user";
  uid: string;
}

export interface UserSignature {
  font: string;
  text: string;
}

export interface UserData {
  email: string;
  name: string;
  phone: string;
  profilePic: string;
  preferredLanguage: string;
  authRole: AuthRole;
  onboarded: boolean;
  onboardedAt?: null;
  role: string;
  isDemo: boolean;
  username?: string;
  status: string;
  account: Account;
  updatedAt: string;
  createdAt: string;
  eid: string;
  locations?: LocationsEntity[];
  entity: Entity;
  isChatLoggedIn: boolean;
  type: "branch" | "user";
  entityId: string;
  timezone: string;
  archivedChats?: ArchivedChat[];
  signatures?: UserSignature[];
}

export interface Account {
  verification: Verification;
  verificationCode?: null;
  onboardingStage: string;
}
export interface Verification {
  verified: boolean;
}
export interface LocationsEntity {
  name: string;
  eid: string;
  onboarded: boolean;
  launchId: string | undefined;
  address?: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

export type FieldType =
  | "text"
  | "date"
  | "dropDown"
  | "singleChoice"
  | "document";

export interface LocationFieldEntity {
  eid: string;
  fieldName: string;
  fieldType: FieldType;
  options: string[];
  validations: {
    isRequired: boolean;
    [key: string]: any;
  };
  createdAt: string;
  createdBy: {
    eid: string;
    name: string;
    profilePic?: string;
  };
}

export type ILauncherContenTaskStepType = "text" | "form";

export interface ILauncherStepsEntity {
  formId: string;
  stepId: string;
  title: string;
  type: ILauncherContenTaskStepType;
}

export interface ILauncherTaskEntity {
  completed: boolean;
  dependency: string;
  description: string;
  docRequired: boolean;
  duration: number;
  eid: string;
  steps: ILauncherStepsEntity[];
  title: string;
  triggerTask: string;
}

export interface ILauncherContentsEntity {
  category: string;
  eid: string;
  tasks: ILauncherTaskEntity[];
}

export interface LauncherEntity {
  createdAt: string;
  createdBy: string;
  eid: string;
  published: boolean;
  entityId: string;
  updatedAt: string;
  updatedBy: string;
  contents: ILauncherContentsEntity[];
}

export type RestrictChatType = "role" | "authRole" | "anyone" | "creator";

export interface ChatConfigEntity {
  locationChat: boolean;
  newChannel: {
    authRoles: string[];
    members: string[];
    restrictType: RestrictChatType;
    roles: string[];
  };
  restrictions: boolean;
  startDM: {
    restrictType: RestrictChatType;
    roles: string[];
    authRoles: string[];
    members: string[];
  };
}

export interface Entity {
  name: string;
  address?: null;
  analytics: boolean;
  chatConfig: ChatConfigEntity;
  profilePic?: string;
  brandColor?: string;
  isDemo: boolean;
  isActive: boolean;
  roles?: RolesEntity[];
  bundles?: null[];
  category?: CategoryEntity[];
  updatedAt: string;
  createdAt: string;
  eid: string;
  headquarters: string;
  email: string;
  phone: string;
  locations?: {
    name: string;
    isRoot?: boolean;
    eid: string;
    launchId?: string;
  }[];
  launcher: LauncherEntity;

  locationFields: LocationFieldEntity[];

  features: {
    chat: boolean;
    launcher: boolean;
  };
}

export interface RolesEntity {
  color: string;
  createdAt: Date;
  createdBy: string;
  eid: string;
  name: string;
  status: string;
  updatedAt: string;
}

export interface CategoryEntity {
  eid: string;
  name: string;
  status: string;
  icon: string;
  parent?: string;
  color: string;
  createdBy: string;
  createdByUser: {
    eid: string;
    name: string;
    profilePic: string;
  };
  visibility: "public" | "private";
  visibleTo: {
    condition: string;
    locations: string[];
    roles: string[];
    users: string[];
  };
  children: CategoryEntity[];
}
