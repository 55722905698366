import React, { FC } from 'react';
import { Accordion, Box, Checkbox, Flex, useToast } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { CometChat } from '@cometchat-pro/chat';
import { useTranslation } from 'react-i18next';

import { useUserDataSelector } from '../../../../../hooks';
import { shallowEqual } from '../../../../../utils';
import { ActionButton } from '../../../../../ui-components';

import InviteRemoveMember from './InviteRemoveMember';
import BaseAccordionItem from './BaseAccordionItem';
import WhoCanPost from './WhoCanPost';
import { IFormInput } from '../create-channel.types';
import { useSubmitChannel } from '../useSubmitChannel';
import { useInviteSubtitle, usePostSubtitle } from './subtitle-generator';

export interface ChannelConfigProps {
  onGroupCreated?: (
    values: IFormInput,
    group: CometChat.Group
  ) => Promise<void> | void;
}

const ChannelConfigForm: FC<ChannelConfigProps> = ({ onGroupCreated }) => {
  const { t } = useTranslation(['common', 'setting']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const owner = useUserDataSelector(
    (state) => ({
      eid: state.eid,
      name: state.name,
      email: state.email,
      profilePic: state.profilePic,
      role: state.role,
      authRole: state.authRole,
      type: state.type,
    }),
    shallowEqual
  );

  const { control, handleSubmit, watch } = useFormContext<IFormInput>();

  const isPublic = watch('isPublic');

  const createChannel = useSubmitChannel();

  const onSubmit = async (values: IFormInput) => {
    try {
      const group = await createChannel(values);
      toast({
        status: 'success',
        title: t('common:success'),
        // @ts-ignore
        description: t('setting:channelCreateSuccess', {
          name: values.groupName?.trim(),
        }),
      });
      await onGroupCreated?.(values, group);
    } catch (e) {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('setting:channelCreateError'),
      });
      return Promise.reject(e);
    }
  };

  const inviteSubtitle = useInviteSubtitle(control, owner);
  const postSubtitle = usePostSubtitle(control);

  return (
    <div>
      <Accordion defaultIndex={[]} reduceMotion allowToggle>
        <BaseAccordionItem
          title='Who can invite/remove members?'
          subTitle={inviteSubtitle}
        >
          <InviteRemoveMember control={control} owner={owner} />
        </BaseAccordionItem>

        <BaseAccordionItem title='Who can post?' subTitle={postSubtitle}>
          <WhoCanPost control={control} />
        </BaseAccordionItem>
      </Accordion>

      <Controller
        control={control}
        defaultValue={false}
        name='channelCantLeave'
        render={({ field }) => {
          return (
            <Flex justify='space-between' my={5}>
              <Box fontSize='16px' fontWeight='500' color='#1A1D1F'>
                Do not allow members to leave this channel
              </Box>
              <Checkbox
                size='lg'
                color='#6F767E'
                isChecked={field.value}
                onChange={field.onChange}
                ref={field.ref}
              />
            </Flex>
          );
        }}
      />

      <Box pt={1} pb={2}>
        <ActionButton
          width='full'
          fontSize='15px'
          colorScheme='blue'
          // isDisabled={!groupName}
          actionFn={handleSubmit(onSubmit)}
        >
          {`Create channel ${isPublic ? '2/2' : '3/3'}`}
        </ActionButton>
      </Box>
    </div>
  );
};

export default ChannelConfigForm;
