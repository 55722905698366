import { gql } from '@apollo/client';

export const GET_MY_TASKS = gql`
  query MyTaskSession($endDate: Date!) {
    MyTaskSession(endDate: $endDate) {
      name
      title
      taskId
      startDate
      dueDate
      task {
        status
        repeatCycle
        repeatDetails
        terminateDetails {
          date
          user
          reason
        }
      }
      eid
      currentUserProgress
      itemCount
      isLastSession
      isRepeating
      maxDueDate
    }
  }
`;

export const TASK_BY_ID = gql`
  query TaskById($eid: String!) {
    TaskById(eid: $eid) {
      supervisors
      supervisorsUsers {
        eid
        authRole
        email
        name
        role
        locations {
          eid
          name
        }
        profilePic
      }
      createdBy
      createdByUser {
        eid
        name
        profilePic
        locations {
          eid
          name
        }
      }
    }
  }
`;

export const SESSION_BY_ID_FOR_MY_TASK_DETAILS = gql`
  query SessionById($eid: String!) {
    SessionById(eid: $eid) {
      createdAt
      startDate
      taskItems
      title
      eid
      progress
      currentUserProgress
      itemCount
      updatedAt
      dueDate
      assigneeUsers {
        eid
        name
        email
        profilePic
      }
      assignees {
        userId
        locations
        role
        authRole
        completedTasks {
          contentId
          isCompleted
          completedAt
        }
        isCompleted
        completedAt
      }
      task {
        timezone
        thumbnail
        status
        repeatDetails
        repeatCycle
        lastUpdated
        description
        createdByUser {
          eid
          name
        }
        assignedToLocations {
          eid
          email
          name
          profilePic
        }
        assignedToUsers {
          eid
          email
          name
          profilePic
        }
      }
    }
  }
`;

export const TASK_BY_ID_FOR_MY_TASK_DETAILS = gql`
  query TaskById($eid: String!) {
    TaskById(eid: $eid) {
      createdByUser {
        eid
        name
        profilePic
      }
      assignedLocations
      assignedRoles
      assignedTo
      assignedToLocations {
        eid
        email
        name
        profilePic
      }
      description
      eid
      assignedToUsers {
        eid
        email
        name
        profilePic
      }
      completedOn
      contents {
        backgroundColor
        eid
        isMandatory
        title
        type
      }
      createdAt
      endCondition {
        endType
        endDate
        repeatCount
        _id
      }
      entityId
      expiresAt
      isRepeating
      lastUpdated
      lastUpdatedBy
      nextRepeat
      repeatCycle
      repeatDetails
      repeatString
      startDate
      status
      taskItems
      thumbnail
      timezone
      title
      updatedAt
      lastSession {
        name
        title
        contents {
          type
          id
          title
          isMandatory
          backgroundColor
          dateAdded
          _id
        }
        taskId
        taskItems
        startDate
        dueDate
        assignees {
          userId
          locations
          role
          authRole
          completedTasks {
            contentId
            isCompleted
            completedAt
          }
          isCompleted
          notifications {
            type
            template
            timestamp
            jobId
            _id
          }
          completedAt
        }
        createdAt
        updatedAt
        eid
        progress
        currentUserProgress
        itemCount
      }
    }
  }
`;

export interface TaskProgressInput {
  contentId: string;
  isCompleted?: boolean;
  response?: string;
  sessionId: string;
}

export const UPDATE_TASK_PROGRESS = gql`
  mutation UpdateUserTaskProgress($input: UserTaskProgressInputInput) {
    UpdateUserTaskProgress(input: $input) {
      eid
    }
  }
`;
