import { useReactiveVar } from '@apollo/client';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import FileVisibility from 'pages/Chapters/CreateChapterModal/FileVisibility';
import {
  compareVisibilityHandler,
  ICompareVisibilityType,
} from 'pages/Chapters/CreateEditSubFolder/components/helper/visibilityHelper';
import { IVisibilityEntity } from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import React, { FC, useEffect, useState } from 'react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { usersEntityObj } from 'sub-components/Header';
import VisibilityStatusInfo from 'ui-components/VisibilityStatusInfo';
import useCombinedStore from 'zustandStore/store';
import { IShowState } from '../hooks/types';
import { useNotifyUserModal } from '../NotifyUserModal';

interface IProps {
  isEditMode: boolean;
  locationsList: any[];
  membersList: any[];
  notifyUsers: boolean;
  parentFolderData: any;
  rolesList: any[];
  showState: IShowState;
  onPublishHandler: (
    _notifyUsers: boolean,
    visibilityObject?: IVisibilityEntity | undefined
  ) => Promise<void>;
  name?: string;
  setNotifyUsers: React.Dispatch<React.SetStateAction<boolean>>;
  setShowState: React.Dispatch<React.SetStateAction<IShowState>>;
}

const VisibilityOperationModal: FC<IProps> = ({
  isEditMode,
  locationsList,
  membersList,
  notifyUsers,
  rolesList,
  parentFolderData,
  showState,
  name,
  onPublishHandler,
  setNotifyUsers,
  setShowState,
}) => {
  const usersEntityData = useReactiveVar(usersEntityObj);
  const { selectedData, visibilitySelection, updateVisibilitySelection } =
    useCombinedStore();
  console.log({
    VisibilityOperationModal: { parentFolderData, name, visibilitySelection },
  });
  const [compareVisiblityType, setCompareVisibilityType] =
    useState<ICompareVisibilityType>(undefined);
  const notifyUserModal = useNotifyUserModal();
  const closeHandler = (type: string) => {
    if (type === 'close') {
      setShowState({
        showFolder: false,
        showVisibility: false,
        showTrainingPathFlowModal: false,
      });
    } else {
      notifyUserModal({
        isEditMode: isEditMode,
        notifyUsers: notifyUsers,
        onPublishClick: async (canNotify) => {
          setNotifyUsers(canNotify);
          await onPublishHandler(canNotify, visibilitySelection);
        },
      });
      setShowState({
        showFolder: false,
        showVisibility: false,
        showTrainingPathFlowModal: false,
      });
    }
    setShowState((prev) => {
      return {
        ...prev,
        showVisibility: false,
      };
    });
  };
  // useEffect(() => {
  //   const parent = {
  //     eid: parentFolderData?.eid!,
  //     type: parentFolderData?.visibility || 'public',
  //     condition: parentFolderData?.visibleTo?.condition,
  //     jobs: parentFolderData?.visibleTo?.roles,
  //     locations: parentFolderData?.visibleTo?.locations,
  //     members: parentFolderData?.visibleTo?.users,
  //   };
  //   const child = {
  //     eid: '',
  //     type: visibilitySelection?.visibility || 'public',
  //     condition: visibilitySelection?.condition || undefined,
  //     jobs: visibilitySelection?.roles,
  //     locations: visibilitySelection?.locations,
  //     members: visibilitySelection?.users,
  //   };
  //   let visibilityConditionType = compareVisibilityHandler(
  //     parent,
  //     child,
  //     usersEntityData
  //   );
  //   setCompareVisibilityType(visibilityConditionType);
  // }, [visibilitySelection, selectedData]);
  return (
    <Modal
      isOpen={showState.showVisibility}
      onClose={() => closeHandler('close')}
      size='3xl'
    >
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader>
          <GenericColorHeader
            title='Set Visiblity'
            color={HeaderColors.Purple}
          />
          <ModalCloseButton m={4} />
        </ModalHeader>
        <ModalBody>
          <Flex flexDir='column' gap={4}>
            <FileVisibility
              isEditMode
              viewDirection='top-bottom'
              type={'chapter'}
            />
            <Flex>
              <VisibilityStatusInfo
                parentDetails={{
                  locations: parentFolderData?.visibleTo?.locations,
                  roles: parentFolderData?.visibleTo?.roles,
                  users: parentFolderData?.visibleTo?.users,
                  condition: parentFolderData?.visibleTo?.condition,
                  visibility: parentFolderData?.visibility,
                }}
                childDetails={visibilitySelection}
                type='chapter'
                folderName={name || 'Chapter'}
                parentFolderName={parentFolderData?.label}
              />
            </Flex>
            <Flex w='full' justify='flex-end'>
              <PrimaryButton
                title='Next'
                variant='solid'
                colorScheme='blue'
                style={{ width: 'fit-content' }}
                onClick={() => closeHandler('notify')}
              />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VisibilityOperationModal;
