import { toArray } from 'utils/utils';

import { IFormInput } from './add-location.types';
import { LocationInputs } from './add-location.graphql';

export const addLocationFormat = (data: IFormInput): LocationInputs => {
  return {
    // Basic details
    locationType: data.locationType,
    name: data.locationName,
    locationStatus: data.locationStatus?.value,
    locationEmail: toArray(data.locationEmail?.map((v) => v.email)).filter(
      Boolean
    ),
    locationPhone: toArray(data.locationPhone?.map((v) => v.phone)).filter(
      Boolean
    ),
    locationOwners: toArray(
      data.locationOwners?.map((v) => v.owner?.value)
    ).filter(Boolean),

    // Login Details
    username: data.username,
    password: data.password!,

    // Address
    address: {
      address: data.address,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
    },
    timezone: data.timezone,

    // Entity details
    entityName: data.entityName,
    entityType: data.entityType?.value,
    countryOfFormation: data.countryOfFormation?.value!,
    stateOfFormation: data.stateOfFormation?.value!,
    taxPayerId: data.taxPayerId,

    // Other details
    otherFields: toArray(data.otherFields).map((field) => {
      return {
        fieldId: field.fieldId,
        value: field.value,
        files: field.files || [],
        options: toArray(field.options)
          .map((value) => {
            return value?.value;
          })
          .filter(Boolean),
      };
    }),
  };
};
