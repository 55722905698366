import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../ui-components';

import { EditorEvent, useEditorContext } from '../editor-form';
import { useLocalChangesCount, ValidationError } from '../common';

interface IProps {}

const PublishButton: FC<IProps> = () => {
  const { t } = useTranslation('launcher');
  const { emit, initialising } = useEditorContext();
  const changes = useLocalChangesCount();

  // Define an asynchronous function to handle the click event
  const onProceedClick = async () => {
    try {
      await emit(EditorEvent.PUBLISH_CONFIG);
    } catch (err) {
      console.log((err as ValidationError)?.data);
    }
  };

  const isPublished: boolean = useWatch({ name: 'published' });

  if (initialising || (isPublished && +changes! !== changes)) {
    return null;
  }

  return (
    <Flex position='sticky' bottom='0' width='full'>
      <ActionButton
        w='full'
        fontSize='15px'
        fontWeight='700'
        colorScheme='blue'
        actionFn={onProceedClick}
      >
        <Trans
          t={t}
          i18nKey={changes ? 'publishLauncherChanges' : 'saveAndPublishTasks'}
          count={changes}
          values={{ value: changes }}
        />
      </ActionButton>
    </Flex>
  );
};

export default PublishButton;
