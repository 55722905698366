import React, { FC, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import clipboardCopy from 'copy-to-clipboard';
import { Box, useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReInviteUserModal } from '../../../ui-components/InviteUserNew';

import {
  InfoBaseComponent,
  InfoBaseRef,
  ProfileActionButtons,
} from '../../Profile/TabComponents';
import {
  MAGIC_LINK_QUERY,
  MagicLinkResponse,
  MagicLinkVariable,
} from './member-detail.graphql';
import { InvitedUser } from '../InvitedMember/update-invite.graphql';
import {
  DeleteAccount,
  UpdateOrResendInvite,
} from '../../Profile/TabComponents/InfoComponent/ActionButtons';
import UpdatePassword from '../../Profile/TabComponents/InfoComponent/ActionButtons/UpdatePassword';
import { useProfileUpdateMutation } from './useUpdateMutation';
import { UserDataResponse } from '../../Profile/TabComponents/InfoComponent/info.graphql';

interface IProps {
  userId: string;
  onUserDataFetched?: (data: UserDataResponse['userById']) => void;
}

const UpdateMember: FC<IProps> = ({ userId, onUserDataFetched }) => {
  const history = useHistory();
  const [reInviteUser, setReInviteUser] = useState<InvitedUser>();
  const infoBaseRef = useRef<InfoBaseRef>(null);
  const { t } = useTranslation(['common', 'profile']);
  const toast = useToast({
    duration: 2000,
    position: 'top-right',
    isClosable: true,
  });

  const { refetch } = useQuery<MagicLinkResponse, MagicLinkVariable>(
    MAGIC_LINK_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        eid: userId,
      },
    }
  );

  const onSubmit = useProfileUpdateMutation({
    userId: userId,
    onCompleted: (response) => {
      if (response.updateUserById?.status === 'pending') {
        setReInviteUser(response.updateUserById);
      }
    },
  });

  const onMagicLinkPress = async () => {
    await refetch({
      eid: userId,
    }).then((value) => {
      if (value.data.getMagicLink) {
        const result = clipboardCopy(value.data.getMagicLink, undefined);
        if (result) {
          toast({
            status: 'success',
            title: t('common:success'),
            description: t('profile:magicLinkClipboard'),
          });
        }
      }
    });
  };

  return (
    <Box p={2} mt='12px'>
      <InfoBaseComponent
        ref={infoBaseRef}
        userId={userId}
        onMagicLinkPress={onMagicLinkPress}
        onUserDataFetched={onUserDataFetched}
      >
        <ProfileActionButtons>
          <DeleteAccount userId={userId} onDeleted={history.goBack} />
          <Box flex={1} />
          <UpdatePassword userId={userId} />
          <UpdateOrResendInvite
            userId={userId}
            onSubmit={onSubmit}
            onResendClick={setReInviteUser}
          />
        </ProfileActionButtons>
      </InfoBaseComponent>

      {reInviteUser?.eid && (
        <ReInviteUserModal
          isOpen={!!reInviteUser?.eid}
          data={reInviteUser}
          onClose={() => setReInviteUser(undefined)}
        />
      )}
    </Box>
  );
};

export default UpdateMember;
