import { Flex, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import {
  ICityStateList,
  ICityStateListEntity,
  IDropdownType,
} from '../LocationListContainer';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface IProps {
  cityStateList: ICityStateList;
  fetchData: () => void;
  listRender: (
    data: ICityStateListEntity,
    index: number,
    type: IDropdownType
  ) => React.ReactNode;
}

const LocationTypeHeader: FC<IProps> = ({
  cityStateList,
  fetchData,
  listRender,
}) => {
  const { t } = useTranslation(['setting']);
  return (
    <Flex gap='10px' align='center'>
      <Text m={0}>{t('setting:locations_section.type')}</Text>
      {cityStateList?.type?.length > 0 && (
        <Menu closeOnSelect={false} onClose={fetchData}>
          <MenuButton>
            <FontAwesomeIcon
              icon={faSortDown as IconProp}
              style={{ marginBottom: '2px' }}
            />
          </MenuButton>
          <MenuList p={0}>
            {cityStateList?.type?.map((type, index) =>
              listRender(type, index, 'type')
            )}
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};

export default LocationTypeHeader;
