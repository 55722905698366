import { IAmplitudeEventNamesEntity } from './amplitude-events-types';
import amplitude from 'amplitude-js';
import mixpanel from 'mixpanel-browser';
import { userObj } from 'sop-commons/src/client';

export const deployEvent = (eventName: IAmplitudeEventNamesEntity): void => {
  let loggedInUserDetails = userObj();
  if (
    process?.env?.REACT_APP_DEPLOYMENT_TYPE &&
    process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'production' &&
    loggedInUserDetails?.entity?.analytics
  ) {
    amplitude.getInstance().logEvent(eventName);
    mixpanel?.track(eventName);
  } else {
    console.log({
      dep_type: process?.env?.REACT_APP_DEPLOYMENT_TYPE,
      node_env: process?.env?.NODE_ENV,
      eventName,
      REACT_APP_DEPLOYMENT_TYPE: process?.env?.REACT_APP_DEPLOYMENT_TYPE,
      NODE_ENV: process?.env?.NODE_ENV,
    });
    if (process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'development') {
      console.log('deployEvent --> eventName = ', eventName);
    }
  }
};
