import React, { FC, useEffect } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import DashboardContainer from 'sub-components/DashboardContainer';
import {
  LocationAssetsCard,
  LocationDetailsCard,
  LocationDetailsHeading,
} from './components';
import { LOCATION_DETAILS_QUERY } from './service/location-details.graphql';
import { ILocationDetails } from './utils/location-details.types';
import Loader from 'ui-components/Loader';

interface IProps {}

const LocationDetails: FC<IProps> = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const [getLocationDetails, { data, loading, refetch }] = useLazyQuery<
    { userById: ILocationDetails },
    { eid: string }
  >(LOCATION_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getLocationDetailsHandler();
  }, [locationId]);

  const getLocationDetailsHandler = () => {
    getLocationDetails({
      variables: {
        eid: locationId,
      },
    });
  };

  return (
    <DashboardContainer>
      {loading ? (
        <Center w='full' h='80vh'>
          <Loader />
        </Center>
      ) : (
        <Flex flexDir='column' gap='15px'>
          <LocationDetailsHeading title={data?.userById?.name || ''} />
          <Flex w='full' gap='20px'>
            <Flex flex={1} bg='white' borderRadius='12px' p={8}>
              <LocationDetailsCard details={data?.userById} />
            </Flex>
            <Flex flex={2} bg='white' borderRadius='12px' p={2}>
              <LocationAssetsCard
                details={data?.userById}
                refetch={refetch}
                getLocationDetailsHandler={getLocationDetailsHandler}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </DashboardContainer>
  );
};

export default LocationDetails;
