import { extendTheme } from '@chakra-ui/react';

const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#FFFFFF',
  gray: {
    100: '#f4f4f4',
  },
  blue: {
    500: '#2a85ff',
    600: '#2a85ff',
  },
  red: {
    500: '#ff6a55',
  },

  green: {
    '50': '#F0F7ED',
    '100': '#D5E9CD',
    '200': '#B9DBAE',
    '300': '#9ECD8E',
    '400': '#83BF6E',
    '500': '#68B14E',
    '600': '#538E3E',
    '700': '#3E6A2F',
    '800': '#29471F',
    '900': '#152310',
  },

  // ...
};

const shadows = {
  outline: 'none',
};

export const breakpoints = {
  sm: 320,
  md: 768,
  lg: 960,
  xl: 1200,
  '2xl': 1536,
};

export const zIndices = {
  tooltip: 2400,
};

export const theme = extendTheme({
  colors,
  breakpoints,
  shadows,
  zIndices,
  components: {
    Tooltip: {
      baseStyle: {
        borderRadius: '4px',
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '7px',
      },
    },
  },
});
