import React, { FC } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ActionButton } from 'ui-components';

import DashboardContainer from 'sub-components/DashboardContainer';
import {
  Address,
  BasicDetails,
  EntityDetails,
  LoginDetails,
  OtherDetails,
} from '../Components';
import AddLocationHeader from './AddLocationHeader';
import { IFormInput } from './add-location.types';
import { addLocationFormat } from './formatSubmitData';
import {
  ADD_LOCATION_QUERY,
  AddLocationVariable,
} from './add-location.graphql';
import { getDefaultFormData } from './default-form-data';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { getLoggedInUserDataHandler } from 'shared/graphql/SharedGraphql';
import { LOCATIONS_PAGE } from 'appRoutes';

interface IProps {}

const AddLocation: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'location']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const history = useHistory();

  const methods = useForm<IFormInput>({
    defaultValues: getDefaultFormData(),
  });

  const onGetUserSuccess = () => {
    history.push(LOCATIONS_PAGE);
  };

  const onGetUserError = () => {
    history.push(LOCATIONS_PAGE);
  };

  const { execute, loading: gettingLoggedInUserData } =
    getLoggedInUserDataHandler(onGetUserSuccess, onGetUserError);

  const [addLocation, { loading }] = useMutation<never, AddLocationVariable>(
    ADD_LOCATION_QUERY,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('location:location_created'),
        });
        execute?.();
      },
      onError: (error) => {
        if (error?.message?.includes('Username')) {
          methods.setError(
            'username',
            {
              type: 'custom',
              message: error.message,
            },
            {
              shouldFocus: true,
            }
          );
        } else {
          toast({
            status: 'error',
            title: t('common:error'),
            description: t('location:location_error'),
          });
        }
      },
    }
  );

  const onSubmit = async (data: IFormInput) => {
    deployEvent(AmplitudeEventNames.LOCATION_ADD_BUTTON);
    const inputData = addLocationFormat(cloneDeep(data));

    await addLocation({
      variables: {
        input: inputData,
      },
    });
  };

  return (
    <DashboardContainer>
      <FormProvider {...methods}>
        <Flex flexDir='column' gap={4} pb={5}>
          <AddLocationHeader />

          <BasicDetails />

          <LoginDetails />

          <Address />

          <EntityDetails />

          <OtherDetails />

          <ActionButton
            size='lg'
            type='submit'
            colorScheme='blue'
            width='fit-content'
            minW='170px'
            alignSelf='flex-end'
            fontSize='15px'
            fontWeight='600'
            borderRadius='7px'
            isLoading={loading || gettingLoggedInUserData}
            actionFn={methods.handleSubmit(onSubmit)}
          >
            {t('common:add')}
          </ActionButton>
        </Flex>
      </FormProvider>
    </DashboardContainer>
  );
};

export default AddLocation;
