export const HeaderColors = {
  Blue: '#b1e5fc',
  DarkBlue: '#2A85FF',
  Green: '#b5e4ca',
  GreenDark: '#83BF6E',
  Orange: '#ffbc99',
  Purple: '#cabdff',
  Red: {
    Light: '#FF1F0033',
    Dark: '#FF6A55',
  },
  Yellow: '#FFD88D',
  Black: '#111315',
};

type ValueOf<T> = T[keyof T];
export type HeaderColorValues = ValueOf<typeof HeaderColors>;
