import { useLazyQuery, useMutation } from '@apollo/client';
import { IMyTask, ITaskAPIArgs } from 'pages/TasksDashboard/tasks.types';
import { ISessionByIdForMyTasks } from './my-tasks-types';
import {
  GET_MY_TASKS,
  TASK_BY_ID,
  TASK_BY_ID_FOR_MY_TASK_DETAILS,
  TaskProgressInput,
  UPDATE_TASK_PROGRESS,
  SESSION_BY_ID_FOR_MY_TASK_DETAILS,
} from './my-tasks.graphql';

export const getMyTasks = () => {
  const [execute, { loading, error, data }] = useLazyQuery<
    IMyTask,
    ITaskAPIArgs
  >(GET_MY_TASKS, { fetchPolicy: 'network-only' });
  return { execute, loading, error, data };
};

export const getTaskById = () => {
  const [execute, { loading, error, data }] = useLazyQuery(TASK_BY_ID, {
    fetchPolicy: 'network-only',
  });
  return { execute, loading, error, data };
};

export const getTaskByIdForMyTaskDetails = () => {
  const [execute, { loading, error, data }] = useLazyQuery(
    TASK_BY_ID_FOR_MY_TASK_DETAILS,
    { fetchPolicy: 'network-only' }
  );
  return { execute, loading, error, data };
};

export const getSessionByIdForMyTaskDetails = () => {
  const [execute, { loading, error, data, refetch }] = useLazyQuery<
    ISessionByIdForMyTasks,
    { eid: string }
  >(SESSION_BY_ID_FOR_MY_TASK_DETAILS, { fetchPolicy: 'network-only' });
  return { execute, loading, error, data, refetch };
};

export const updateTaskProgress = () => {
  const [execute, { loading, error, data }] = useMutation<
    { UpdateUserTaskProgress: { eid: string } },
    Record<'input', TaskProgressInput>
  >(UPDATE_TASK_PROGRESS);
  return { execute, loading, error, data };
};
