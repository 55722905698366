import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_LABEL_JSON, DEFAULT_LOGO_JSON, LABEL_MAP, LOGO_MAP } from 'shared/login-page-static-json/login-page';

const useCustomLoginPage = () => {
  const hostname = window.location.hostname;

  const isDefaultDomainUrl = useMemo(() => {
    return !Object.keys(LOGO_MAP).some((domain) => hostname.includes(domain));
  }, [hostname]);

  const getLogoData = () => {
    for (const domain in LOGO_MAP) {
      if (hostname.includes(domain)) {
        return LOGO_MAP[domain];
      }
    }
    return DEFAULT_LOGO_JSON;
  };

  const getLabelData = () => {
    for (const domain in LABEL_MAP) {
      if (hostname.includes(domain)) {
        return LABEL_MAP[domain];
      }
    }
    return DEFAULT_LABEL_JSON;
  };

  const logoData = useMemo(getLogoData, [hostname]);
  const labelData = useMemo(getLabelData, [hostname]);

  const [currentLogo, setCurrentLogo] = useState(
    DEFAULT_LOGO_JSON.maxAspectLogo
  );

  const [currentLabelData, setCurrentLabelData] = useState({
    title: DEFAULT_LABEL_JSON.signInTitle,
    description: DEFAULT_LABEL_JSON.signInDescription,
    sideCompanyTitle: DEFAULT_LABEL_JSON.maxAspectLeftCompanyTitle,
    sideCompanyDescription: DEFAULT_LABEL_JSON.maxAspectLeftCompanyDescription,
  });

  useEffect(() => {
    const updateData = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setCurrentLogo(logoData.smallAspectLogo);
        setCurrentLabelData({
          title: labelData?.smallAspectSignInTitle,
          description: labelData?.smallAspectSignInDescription,
          sideCompanyDescription: labelData?.maxAspectLeftCompanyDescription,
          sideCompanyTitle: labelData?.maxAspectLeftCompanyTitle,
        });
      } else if (width >= 768 && width <= 1199) {
        setCurrentLogo(logoData.midAspectLogo);
        setCurrentLabelData({
          title: labelData?.midAspectSignInTitle,
          description: labelData?.midAspectSignInDescription,
          sideCompanyDescription: labelData?.maxAspectLeftCompanyDescription,
          sideCompanyTitle: labelData?.maxAspectLeftCompanyTitle,
        });
      } else {
        setCurrentLogo(logoData.maxAspectLogo);
        setCurrentLabelData({
          title: labelData?.signInTitle,
          description: labelData?.signInDescription,
          sideCompanyDescription: labelData?.maxAspectLeftCompanyDescription,
          sideCompanyTitle: labelData?.maxAspectLeftCompanyTitle,
        });
      }
    };
    updateData();
    window.addEventListener('resize', updateData);
    return () => window.removeEventListener('resize', updateData);
  }, []);

  return { currentLogo, currentLabelData, isDefaultDomainUrl };
};

export default useCustomLoginPage;