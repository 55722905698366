import { SelectOption } from 'atoms';
import { LocationStatus } from 'types';

export const EntityTypes = [
  'SOLE_PROPRIETORSHIP',
  'GENERAL_PARTNERSHIP',
  'LIMITED_PARTNERSHIP',
  'C_CORPORATION',
  'S_CORPORATION',
  'LLC',
] as const;

export type IEntityTypes = typeof EntityTypes[number];

export const EntityLabel: Record<IEntityTypes, string> = {
  SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
  GENERAL_PARTNERSHIP: 'General Partnership',
  LIMITED_PARTNERSHIP: 'Limited Partnership',
  C_CORPORATION: 'C-Corporation',
  S_CORPORATION: 'S-corporation',
  LLC: 'LLC',
};

export const LocationStatusOption: SelectOption<LocationStatus>[] = [
  { label: 'In development', value: 'development' },
  { label: 'Open', value: 'open' },
];
