import React, { FC, useMemo } from 'react';
import { Badge, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TabItem } from 'atoms';

import CreateFromScratch from './CreateFromScratch';
import ExtractFromPdf from './ExtractFromPdf';
import { UploadFile } from './UploadFile';
import { PushProps } from './types';
import { CategoryEntity } from 'sop-commons/src/client';

interface IProps extends PushProps {
  categoryData: {
    foundParentData?: CategoryEntity;
    foundChildData?: CategoryEntity;
  };
  onChapterCreated?: () => void;
}

const ModalContent: FC<IProps> = ({
  pushTo,
  categoryData,
  onChapterCreated,
}) => {
  let category = categoryData?.foundChildData || categoryData?.foundParentData;
  const { t, i18n } = useTranslation(['common', 'form', 'chapter']);

  const tabData = useMemo(() => {
    return [
      {
        label: t('form:create_scratch'),
        content: (
          <CreateFromScratch category={category?.name} pushTo={pushTo} />
        ),
      },
      {
        label: t('chapter:uploadAFile'),
        content: (
          <UploadFile
            defaultCategory={categoryData}
            onChapterCreated={onChapterCreated}
          />
        ),
      },
      {
        label: (
          <>
            {t('chapter:extractFromPdf')}
            <Badge colorScheme='red' ml={2} textTransform='initial'>
              {t('common:beta')}
            </Badge>
          </>
        ),
        content: <ExtractFromPdf pushTo={pushTo} />,
      },
    ];
  }, [t, i18n.language, pushTo, category, onChapterCreated]);

  return (
    <div>
      <Tabs isLazy>
        <TabList borderColor='#F5F5F5'>
          {tabData.map((tab, index) => (
            <TabItem key={index}>{tab.label}</TabItem>
          ))}
        </TabList>
        <TabPanels mt={4}>
          {tabData.map((tab, index) => {
            return (
              <TabPanel p='0 0 16px' key={index}>
                {tab.content}
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default ModalContent;
