import React from 'react';
import { GenericColorHeaderWrapper } from './GenericColorHeader.styles';

interface IGenericColorHeaderProps {
  title: string;
  color: string;
}

const GenericColorHeader: React.FC<IGenericColorHeaderProps> = ({
  title,
  color,
}) => {
  return (
    <GenericColorHeaderWrapper color={color}>
      <div className='color-div' />
      <div className='title-div'>{title}</div>
    </GenericColorHeaderWrapper>
  );
};

export default GenericColorHeader;
