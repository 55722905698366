import React, { FC } from 'react';
import {
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { MessageIconButton } from 'atoms';
import { Authorize, AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { shallowEqual } from 'utils';

import { ReactComponent as EditIcon } from '../../../../assets/images/editIcon.svg';
import { ReactComponent as ShareLight } from '../../../../assets/images/shareLight.svg';
import { useHasChatAccess } from 'hooks/useChatRestriction';

export const SuperAdminAdmin = [
  AuthRole.SUPER_ADMIN,
  AuthRole.ADMIN,
  AuthRole.LOCATION_OWNER,
];

interface IProps {
  locationId?: string;
  shouldHideEdit?: boolean;
  locationOwnerShare?: boolean;
  onEditClick?: () => void;
  onShareClick?: () => void;
  onMessageClick?: () => void;
}

const LocationActions: FC<IProps> = ({
  locationId,
  locationOwnerShare,
  shouldHideEdit,
  onEditClick,
  onShareClick,
  onMessageClick,
}) => {
  const { userType, authRole } = useUserDataSelector(
    (state) => ({
      userType: state.type,
      authRole: state.authRole,
    }),
    shallowEqual
  );

  const hasChatAccess = useHasChatAccess();

  return (
    <Flex align='center' justify='space-between' gap='12px'>
      <MessageIconButton
        size='sm'
        hasChatAccess={hasChatAccess({
          eid: locationId!,
          role: 'location',
          authRole: AuthRole.LOCATION_OWNER,
          type: 'branch',
        })}
        onClick={onMessageClick}
      />

      <Menu>
        <MenuButton>
          <Center boxSize='24px'>
            <FontAwesomeIcon
              icon={faEllipsis as IconProp}
              fontSize='20px'
              color='#6F767E'
            />
          </Center>
        </MenuButton>
        <Portal>
          <MenuList p={0}>
            {!shouldHideEdit && (
              <Authorize
                permittedFor={'user'}
                permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
                canAccess={(authUser) =>
                  authUser.authRole === AuthRole.LOCATION_OWNER &&
                  !!authUser.locations?.some((it) => it.eid === locationId)
                }
              >
                <MenuItem onClick={() => onEditClick?.()} h='40px'>
                  <Flex p='10px' cursor='pointer' align='center' gap='20px'>
                    <EditIcon />
                    <Text m={0}>Edit</Text>
                  </Flex>
                </MenuItem>
              </Authorize>
            )}
            <MenuItem
              h='40px'
              onClick={() => {
                if (
                  (SuperAdminAdmin.includes(authRole) && userType === 'user') ||
                  locationOwnerShare
                ) {
                  return onShareClick?.();
                }
              }}
            >
              <Flex
                align='center'
                gap='20px'
                style={{
                  cursor:
                    (SuperAdminAdmin.includes(authRole) &&
                      userType === 'user') ||
                    locationOwnerShare
                      ? 'pointer'
                      : 'no-drop',
                  padding: '10px',
                }}
              >
                <ShareLight />
                <Text m={0}>Share</Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  );
};

export default LocationActions;
