import { useReactiveVar } from '@apollo/client';
import {
  Flex,
  Image,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  IFilesEntity,
  ILocationDetails,
} from 'pages/LocationDetails/utils/location-details.types';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userObj } from 'sop-commons/src/client';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  details: ILocationDetails | undefined;
}

interface IOtherFields {
  fieldName: string | null;
  createdAt: string;
  fieldId: string;
  files: IFilesEntity[];
  options: string[];
  updatedAt: string;
  value: string;
}

const ImageViewModal: FC<{
  name: string;
  url: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ name, url, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader>
          <GenericColorHeader color='#CABDFF' title={name} />
        </ModalHeader>
        <ModalCloseButton mt={6} mr={4} />
        <ModalBody>
          <Flex>
            <Image src={url} w='full' h='auto' />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const OtherDetails: FC<IProps> = ({ details }) => {
  const { t } = useTranslation(['location']);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImageDetails, setSelectedImageDetails] = useState<{
    name: string;
    url: string;
  }>({ name: '', url: '' });
  const userData = useReactiveVar(userObj);

  const otherDetailsList = useCallback(() => {
    if (
      details?.otherFields &&
      details?.otherFields?.length > 0 &&
      userData?.entity?.locationFields?.length > 0
    ) {
      const mergedArray = details?.otherFields.map((field) => {
        const locationField = userData?.entity?.locationFields.find(
          (location) => location.eid === field.fieldId
        );
        return {
          ...field,
          fieldName: locationField ? locationField.fieldName : null,
        };
      });
      return mergedArray;
    }
  }, [userData?.entity?.locationFields, details]);

  const valueRenderer = (detail: IOtherFields) => {
    // Type is Date
    if (moment(detail?.value, true)?.isValid()) {
      return (
        <Text m={0} p={0}>
          {moment(detail?.value)?.format('LLL')}
        </Text>
      );
    }

    // Type is File
    if (detail?.files?.length > 0) {
      return (
        <Flex flexDir='column' gap={3}>
          {detail?.files?.map((file, index) => (
            <Flex key={index} align='center' justify='space-between'>
              <Flex gap='5px' align='center'>
                {file?.type === 'pdf' && (
                  <FontAwesomeIcon
                    icon={faFilePdf as IconProp}
                    color='#6F767E'
                    fontSize='20px'
                  />
                )}
                {file?.type === 'image' && (
                  <Image src={file?.url} boxSize='25px' borderRadius='3px' />
                )}
                <Text
                  fontWeight={400}
                  fontSize='14px'
                  color='#2A85FF'
                  textDecoration='underline'
                  cursor='pointer'
                  isTruncated
                  maxW='300px'
                  onClick={() => {
                    if (file?.type === 'pdf') {
                      window.open(file?.url, '_blank');
                    } else if (file?.type === 'image') {
                      setSelectedImageDetails({
                        name: file?.name,
                        url: file?.url,
                      });
                      setOpenImageModal(true);
                    }
                  }}
                >
                  {file?.name}
                </Text>
              </Flex>
              <FontAwesomeIcon
                icon={faArrowDownToLine as IconProp}
                color='#2A85FF'
                cursor='pointer'
                fontSize='20px'
                onClick={() => {
                  window.open(file?.url, '_blank');
                }}
              />
            </Flex>
          ))}
        </Flex>
      );
    }

    return (
      <Text m={0} p={0}>
        {detail?.value}
      </Text>
    );
  };

  return (
    <Flex flexDir='column' gap='20px'>
      <GenericColorHeader
        title={t('location:otherDetails')}
        color='#FF6A5566'
      />
      <Flex flexDir='column' gap='20px'>
        <List spacing={4}>
          {otherDetailsList()?.map((detail) => (
            <ListItem key={detail?.fieldId}>
              <Flex flexDir='column'>
                <Text m={0} p={0} fontWeight={600} fontSize='14px'>
                  {detail?.fieldName}
                </Text>
                {valueRenderer(detail)}
              </Flex>
            </ListItem>
          ))}
        </List>
      </Flex>
      {openImageModal && (
        <ImageViewModal
          name={selectedImageDetails?.name}
          url={selectedImageDetails?.url}
          isOpen={openImageModal}
          onClose={() => {
            setOpenImageModal(false);
            setSelectedImageDetails({ name: '', url: '' });
          }}
        />
      )}
    </Flex>
  );
};

export default OtherDetails;
