import React from 'react';

const usePaginateData = (data, itemPerPage) => {
  const pages = Math.ceil(data.length / itemPerPage);
  const paginatedData = Array.from({ length: pages }, (_, index) => {
    const start = index * itemPerPage;
    const end = start + itemPerPage;
    return data.slice(start, end);
  });

  return paginatedData;
};

export default usePaginateData;
