export const getUrlFileSize = async (url: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const byteSize = blob.size;
    return byteSize;
  } catch (error) {
    console.error('Error fetching image:', error);
    return 0;
  }
};
