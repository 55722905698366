import { cloneDeep } from '@apollo/client/utilities';
import {
  Flex,
  ListItem as ChakraListItem,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faChevronRight,
  faFolder,
  faFolderPlus,
  faFolderUser,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect } from 'react';
import { IDashboardDataContext } from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { ICustomCategoryEntity } from './List';
import * as H from 'history';
import SidebarAction from 'sub-components/Sidebar/category/SidebarAction';
import { useCategoryDelete } from 'sub-components/Sidebar/category/useCategoryDelete';
import { Authorize, AuthRole } from 'authorization';
import './list-item.scss';
import { useSubFolderCreate } from 'pages/Chapters/CreateEditSubFolder/hooks';
import { CategoryEntity } from 'sop-commons/src/client';
import { useFolderSubFolder } from 'hooks';
import { useReactiveVar } from '@apollo/client';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { IConditionType } from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { useRouteMatch } from 'react-router-dom';
import { eventBus } from 'shared/eventEmit/eventBus';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import useCombinedStore from 'zustandStore/store';
import { EventBusEmits } from 'shared/eventEmit/defaults/defaults';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

interface IProps {
  dashboardCtx: IDashboardDataContext;
  folder: ICustomCategoryEntity;
  history: H.History<unknown>;
  index: number;
  selectedFolderId: string;
  selectedSubFolder: string;
  statedFoldersList: ICustomCategoryEntity[];
  subFolderList: ICustomCategoryEntity[];
  getChaptersList?: () => void;
  resetKnowledgeBasePageCount: () => void;
  setSelectedSubFolder: React.Dispatch<React.SetStateAction<string>>;
  setStatedFoldersList: React.Dispatch<
    React.SetStateAction<ICustomCategoryEntity[]>
  >;
}

const ListItem: FC<IProps> = (props) => {
  const {
    dashboardCtx,
    folder,
    history,
    index,
    selectedFolderId,
    selectedSubFolder,
    statedFoldersList,
    subFolderList,
    getChaptersList,
    resetKnowledgeBasePageCount,
    setSelectedSubFolder,
    setStatedFoldersList,
  } = props;
  // const addCategoryRef = useRef<AddCategoryRef>(null);
  const { getAllFolderSubFolderList } = useFolderSubFolder();
  const {
    hierarchyData,
    reset,
    updateHierarchyData,
    updateOperationType,
    updateVisibilityScope,
    updateVisibilitySelection,
  } = useCombinedStore();
  const categoryList = useReactiveVar(foldersListVar);
  const subFolderCreate = useSubFolderCreate();
  const onDeletePress = (folder: ICustomCategoryEntity) => {
    categoryDelete(folder);
  };
  const { folderId, subFolderId } = useRouteMatch<{
    folderId?: string;
    subFolderId?: string;
  }>().params;
  // const onDeleteSuccess = () => {
  //   if (folderId === folder?.eid) {
  //     history.push('/folders');
  //   }
  // };

  const onMoveSuccess = () => {
    getChaptersList?.();
  };

  const categoryDelete = useCategoryDelete({ onMoveSuccess });
  // const onEditPress = (folder: ICustomCategoryEntity) => {
  //   addCategoryRef.current?.initialise({
  //     category: folder.name,
  //     categoryId: folder.eid,
  //   });
  //   addCategoryRef.current?.mode('edit');
  //   addCategoryRef.current?.folderIconDetails({
  //     icon: folder.icon,
  //     color: folder.color,
  //   });
  //   setOpenNewCategory(true);
  // };
  // const onVisibilityPress = (folder: ICustomCategoryEntity) => { //
  //   addCategoryRef.current?.initialise({
  //     category: folder.name,
  //     categoryId: folder.eid,
  //   });
  //   addCategoryRef.current?.mode('visibility');
  //   setOpenNewCategory(true);
  // };
  // const closeHandler = () => {
  //   setOpenNewCategory(false);
  // };

  const onFolderCopyPressEmit = (folder: ICustomCategoryEntity) => {
    eventBus.emit(
      EventBusEmits.chapterContainer.LIST_ITEM_FOLDER_COPY_PRESS,
      folder
    ); // emit to ChapterContainer component
  };

  const onFolderEditPressEmit = (folder: ICustomCategoryEntity) => {
    eventBus.emit(
      EventBusEmits.chapterContainer.LIST_ITEM_FOLDER_EDIT_PRESS,
      folder
    ); // emit to ChapterContainer component
  };

  const onFolderVisibilityPressEmit = (folder: ICustomCategoryEntity) => {
    eventBus.emit(
      EventBusEmits.chapterContainer.LIST_ITEM_FOLDER_VISIBILITY_PRESS,
      {
        folder,
        allFoldersList: getAllFolderSubFolderList(),
      }
    ); // emit to ChapterContainer component
  };

  const onClickHandler = () => {
    dashboardCtx?.selectedFolderHandler({
      label: folder?.name,
      value: folder?.eid,
      eid: folder?.eid,
      name: folder?.name,
      visibility: folder?.visibility,
      visibleTo: {
        locations: folder?.visibleTo?.locations,
        roles: folder?.visibleTo?.roles,
        users: folder?.visibleTo?.roles,
        condition: folder?.visibleTo?.condition as IConditionType,
      },
    });
    resetKnowledgeBasePageCount();
    deployEvent(AmplitudeEventNames.NAVIGATE_FOLDER_FROM_FOLDER_LIST);
    history.push(`/folders/${folder?.eid}`);
    setSelectedSubFolder('');
    if (folder?.children?.length > 0) {
      let _list: ICustomCategoryEntity[] = cloneDeep(statedFoldersList || []);
      if (folder?.isExpanded) {
        _list.forEach((l) => (l.isExpanded = false));
      } else {
        for (let i = 0; i < _list.length; i++) {
          if (index !== i) {
            _list[i].isExpanded = false;
          } else {
            _list[i].isExpanded = true;
          }
        }
      }
      setStatedFoldersList(_list);
    }
  };

  useEffect(() => {
    let _categoriesList: CategoryEntity[] = categoryList || [];
    if (_categoriesList?.length > 0) {
      _categoriesList?.forEach((cat) => {
        if (selectedSubFolder && selectedFolderId) {
          cat?.children?.forEach((ch) => {
            if (ch?.eid === selectedSubFolder) {
              dashboardCtx?.selectedFolderHandler({
                label: ch?.name,
                value: ch?.eid,
                eid: ch?.eid,
                name: ch?.name,
                visibility: ch?.visibility,
                visibleTo: {
                  locations: ch?.visibleTo?.locations,
                  roles: ch?.visibleTo?.roles,
                  users: ch?.visibleTo?.users,
                  condition: ch?.visibleTo?.condition as IConditionType,
                },
              });
            }
          });
        } else if (cat?.eid === selectedFolderId) {
          dashboardCtx?.selectedFolderHandler({
            label: cat?.name,
            value: cat?.eid,
            eid: cat?.eid,
            name: cat?.name,
            visibility: cat?.visibility,
            visibleTo: {
              locations: cat?.visibleTo?.locations,
              roles: cat?.visibleTo?.roles,
              users: cat?.visibleTo?.users,
              condition: cat?.visibleTo?.condition as IConditionType,
            },
          });
        }
      });
    }
  }, [selectedFolderId, selectedSubFolder, categoryList]);

  return (
    <ChakraListItem>
      <Flex
        align='center'
        justify='space-between'
        p='10px 12px'
        cursor='pointer'
        w='full'
        fontWeight={selectedFolderId === folder?.eid ? 600 : 500}
        color={selectedFolderId === folder?.eid ? '272B30' : '#6F767E'}
        borderRadius={selectedFolderId === folder?.eid ? '5px' : ''}
        bg={selectedFolderId === folder?.eid ? '#EFEFEF' : ''}
        _hover={{
          bg: '#EFEFEF',
          borderRadius: '5px',
          '.sidebar-action': {
            visibility: 'visible',
          },
        }}
        onClick={onClickHandler}
      >
        <Tooltip label={folder?.name} hasArrow placement='top'>
          <Flex align='center' gap={2} w='85%'>
            <FontAwesomeIcon
              icon={
                (folder?.visibility === 'public'
                  ? faFolder
                  : faFolderUser) as IconProp
              }
            />
            <Text fontSize='14px' p={0} m={0} isTruncated maxW='150px'>
              {folder?.name}
            </Text>
          </Flex>
        </Tooltip>
        <Flex w='15%' gap={2} justify='flex-end' align='center'>
          {folder?.children?.length > 0 && (
            <FontAwesomeIcon
              icon={
                (folder?.isExpanded
                  ? faChevronDown
                  : faChevronRight) as IconProp
              }
            />
          )}
          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <SidebarAction
              onCopyClick={() => {
                deployEvent(AmplitudeEventNames.COPY_LINK_FOLDER);
                onFolderCopyPressEmit(folder);
              }}
              onDeleteClick={() => {
                deployEvent(AmplitudeEventNames.DELETE_FOLDER);
                onDeletePress(folder);
              }}
              onEditClick={() => {
                deployEvent(AmplitudeEventNames.EDIT_FOLDER);
                onFolderEditPressEmit(folder);
              }}
              onVisibilityClick={() => {
                deployEvent(AmplitudeEventNames.VISIBILITY_FOLDER);
                onFolderVisibilityPressEmit(folder);
              }}
            />
          </Authorize>
        </Flex>
      </Flex>
      {folder?.isExpanded && (
        <Flex ml='20px' flexDir='column'>
          {subFolderList?.map((sub) => (
            <ChakraListItem key={JSON.stringify(sub)}>
              <Flex
                align='center'
                justify='flex-start'
                gap={2}
                p='10px 7.5px'
                cursor='pointer'
                fontWeight={sub?.eid === selectedSubFolder ? 600 : 500}
                color={sub?.eid === selectedSubFolder ? '272B30' : '#6F767E'}
                borderRadius={sub?.eid === selectedSubFolder ? '5px' : ''}
                bg={sub?.eid === selectedSubFolder ? '#EFEFEF' : ''}
                _hover={{ bg: '#EFEFEF', borderRadius: '5px' }}
                onClick={() => {
                  setSelectedSubFolder(sub?.eid);
                  deployEvent(
                    AmplitudeEventNames.NAVIGATE_SUB_FOLDER_FROM_FOLDER_LIST
                  );
                  history.push(`/folders/${folder?.eid}/${sub?.eid}`);
                  dashboardCtx?.selectedFolderHandler({
                    label: sub?.name,
                    value: sub?.eid,
                    eid: sub?.eid,
                    name: sub?.name,
                    visibility: sub?.visibility,
                    visibleTo: {
                      locations: sub?.visibleTo?.locations,
                      roles: sub?.visibleTo?.roles,
                      users: sub?.visibleTo?.users,
                      condition: sub?.visibleTo?.condition as IConditionType,
                    },
                  });
                }}
              >
                <FontAwesomeIcon
                  icon={
                    (sub?.visibility === 'public'
                      ? faFolder
                      : faFolderUser) as IconProp
                  }
                />
                <Text fontSize='14px' p={0} m={0} isTruncated maxW='150px'>
                  {sub?.name}
                </Text>
              </Flex>
            </ChakraListItem>
          ))}
          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <Flex
              align='center'
              justify='flex-start'
              gap={2}
              p='10px 7.5px'
              cursor='pointer'
              fontWeight={600}
              color='#2A85FF'
              _hover={{ bg: '#EFEFEF', borderRadius: '5px' }}
              onClick={() => {
                //
                reset();
                updateVisibilityScope('subFolder');
                updateOperationType('add');
                deployEvent(
                  AmplitudeEventNames.CREATE_SUB_FOLDER_FROM_FOLDER_LIST
                );
                updateHierarchyData({
                  parentFolder: {
                    details: folder as CustomCategoryEntity,
                    visibilityData: {
                      locations: folder?.visibleTo?.locations,
                      roles: folder?.visibleTo?.roles,
                      users: folder?.visibleTo?.users,
                      ...(folder?.visibleTo?.condition
                        ? {
                            condition: folder?.visibleTo
                              ?.condition as IConditionType,
                          }
                        : {}),
                      visibility: folder?.visibility,
                    },
                  },
                });
                // initially when creating sub folder, its visibility will be same as that of parent folder
                updateVisibilitySelection({
                  locations: folder?.visibleTo?.locations,
                  roles: folder?.visibleTo?.roles,
                  users: folder?.visibleTo?.users,
                  condition: folder?.visibleTo?.condition as IConditionType,
                  visibility: folder?.visibility,
                });
                subFolderCreate({
                  folderDetails: {
                    eid: folder?.eid,
                    name: folder?.name,
                    visibility: folder?.visibility,
                    visibleTo: {
                      locations: folder?.visibleTo?.locations,
                      roles: folder?.visibleTo?.roles,
                      users: folder?.visibleTo?.users,
                      condition: folder?.visibleTo?.condition as IConditionType,
                    },
                  },
                  onSubFolderCreated: () => {
                    getChaptersList?.();
                  },
                });
              }}
            >
              <FontAwesomeIcon icon={faFolderPlus as IconProp} />
              <Text fontSize='14px' p={0} m={0}>
                Create subfolder
              </Text>
            </Flex>
          </Authorize>
        </Flex>
      )}
      {/* <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <AddCategory
          ref={addCategoryRef}
          open={openNewCategory}
          onClose={closeHandler}
          type='folder'
        />
      </Authorize> */}
    </ChakraListItem>
  );
};

export default ListItem;
