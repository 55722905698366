import { BoxProps, Center, ThemeTypings } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';
import {
  useTable,
  useBlockLayout,
  Column as NativeColumn,
  UseSortByColumnOptions,
  useSortBy,
  useRowSelect,
  UseTableOptions,
  Row,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import EmptyState from 'sub-components/EmptyState';
import Loader from 'ui-components/Loader';
import { Wrapper } from './location-table.styles';
import TableHeaderSort from '../../../../sub-components/ChakraTable/SortingTable/TableHeaderSort';

export type Column<D extends object = {}> = NativeColumn<D> &
  UseSortByColumnOptions<D>;

interface TableProps<T extends object = {}>
  extends Pick<BoxProps, 'background'> {
  columns: Column<T>[];
  data: T[];
  page?: number;
  totalRegisters?: number;
  onPageChange?: (page: number) => void;
  colorScheme?: ThemeTypings['colorSchemes'];
  emptyData?: {
    text?: string;
    content?: ReactNode;
  };
  isLoading?: boolean;
  isHidePagination?: boolean;
  stateReducer?: UseTableOptions<T>['stateReducer'];
  isResponsive?: boolean;
  tableFrom?: string;
  options?: Record<string, any>;
  onRowSelectChange?: (data: Row<T>[]) => void;
  registersPerPage?: number;
  tableLayout?: 'auto' | 'fixed';
}

function LocationTable<T extends object = {}>({
  page,
  onPageChange,
  totalRegisters,
  data,
  columns,
  colorScheme = 'teal',
  emptyData,
  isLoading,
  isHidePagination = false,
  stateReducer,
  isResponsive,
  tableFrom,
  options,
  onRowSelectChange,
  registersPerPage,
  tableLayout = 'fixed',
  background,
}: TableProps<T>): React.ReactElement {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      stateReducer,
      ...options,
    },
    useSortBy,
    useRowSelect,
    useBlockLayout,
    useSticky
  );

  if (isLoading) {
    return (
      <div className='sop-list-loader'>
        <Loader />
      </div>
    );
  }

  if (!isLoading && data.length === 0) {
    return (
      <div className='sop-list-loader'>
        {emptyData?.content || emptyData?.text}
      </div>
    );
  }

  return (
    <Wrapper id='location-table-wrapper'>
      <div
        {...getTableProps()}
        id='location-table-d-1'
        className='table sticky'
        style={{ overflow: 'auto', borderRadius: '5px' }}
      >
        <div className='header'>
          {headerGroups.map((headerGroup, indexI) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className='tr'
              key={indexI}
            >
              {headerGroup.headers.map((column, indexII) => (
                <div
                  {...column.getHeaderProps(
                    // @ts-ignore
                    column.getSortByToggleProps?.({ title: undefined })
                  )}
                  className='th'
                  key={indexII}
                >
                  {column.render('Header')}
                  <TableHeaderSort column={column as never} />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className='body'>
          {rows?.length > 0 ? (
            rows.map((row, indexIII) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className='tr' key={indexIII}>
                  {row.cells.map((cell, indexIV) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className='td'
                        key={indexIV}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <Center>
              <EmptyState image='Search' title='No data found' />
            </Center>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default LocationTable;
