import { FC } from 'react';
import { Route } from 'react-router-dom';
import { useUserEntity } from '../hooks';

import {
  LAUNCHER_ADD_LOCATION,
  LAUNCHER_CONFIG_EDITOR,
  LAUNCHER_DASHBOARD,
  LAUNCHER_DETAIL,
  LAUNCHER_OPENING_TASKS,
  LAUNCHER_SETUP,
  LAUNCHER_URL,
} from '../appRoutes';
import { LauncherNotPublished, LauncherPage } from '../pages/deprecated-page';
import {
  LauncherAddLocationWrapper,
  LauncherDashboardWrapper,
  LauncherDetailWrapper,
  LauncherEditorWrapper,
  LauncherSetupWrapper,
  LauncherStepsWrapper,
} from '../routeComponentDefinitions';
import { Authorize, AuthRole } from '../authorization';

const LauncherRoute: FC = () => {
  const isLauncherEnabled = useUserEntity(
    (entity) => entity?.features?.launcher
  );

  const launcherPublished = useUserEntity(
    (entity) => entity?.launcher?.published
  );

  if (!isLauncherEnabled) {
    return null;
  }

  if (!launcherPublished) {
    return (
      <>
        <Route
          exact
          path={LAUNCHER_URL}
          component={LauncherNotPublished} // redirect to launcher dashboard page
        />
        <Route
          exact
          path={LAUNCHER_DASHBOARD}
          component={LauncherNotPublished}
        />

        <Authorize
          permittedFor={['user']}
          permittedRoles={[AuthRole.SUPER_ADMIN]}
        >
          <>
            <Route path={LAUNCHER_SETUP} component={LauncherSetupWrapper} />

            <Route
              path={LAUNCHER_OPENING_TASKS}
              component={LauncherStepsWrapper}
            />

            <Route
              path={LAUNCHER_CONFIG_EDITOR}
              component={LauncherEditorWrapper}
            />
          </>
        </Authorize>
      </>
    );
  }

  return (
    <>
      <Route
        exact
        path={LAUNCHER_URL}
        component={LauncherPage} // redirect to launcher dashboard page
      />

      <Route
        exact
        path={LAUNCHER_DASHBOARD}
        component={LauncherDashboardWrapper}
      />

      <Authorize
        permittedFor={['user']}
        permittedRoles={[AuthRole.SUPER_ADMIN]}
      >
        <>
          <Route
            path={LAUNCHER_OPENING_TASKS}
            component={LauncherStepsWrapper}
          />
          <Route
            path={LAUNCHER_CONFIG_EDITOR}
            component={LauncherEditorWrapper}
          />

          <Route
            path={LAUNCHER_ADD_LOCATION}
            component={LauncherAddLocationWrapper}
          />
        </>
      </Authorize>

      <Route path={LAUNCHER_DETAIL} component={LauncherDetailWrapper} />
    </>
  );
};

export default LauncherRoute;
