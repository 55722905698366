/* eslint-disable react/no-children-prop */
// import scss
import './Input.scss';
import {
  forwardRef,
  Input,
  InputElementProps,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  useFormControlContext,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ReactNode } from 'react';

interface FormInputProps extends InputProps {
  leftIcon?: IconProp;
  rightIcon?: ReactNode;

  rightElementProps?: InputElementProps;

  /**
   * @deprecated this will be removed in upcoming refactoring
   * */
  formProps?: InputProps;

  /**
   * @deprecated please use isReadOnly
   * */
  readOnly?: boolean;

  /**
   * @deprecated please use formControl
   * */
  error?: boolean;
}

const FormInput = forwardRef<FormInputProps, 'div'>(
  (
    {
      formProps,
      leftIcon,
      size,
      error,
      readOnly,
      rightIcon,
      isDisabled,
      isReadOnly,
      rightElementProps,
      ...props
    },
    ref
  ) => {
    const field = useFormControlContext();

    const hasError = field?.isInvalid || error;

    return (
      <InputGroup size={size} hidden={props.hidden}>
        {leftIcon && (
          <InputLeftElement
            pointerEvents='none'
            children={
              <FontAwesomeIcon
                icon={leftIcon}
                color={hasError ? '#ff6a55' : '#6f767e'}
              />
            }
          />
        )}

        <Input
          isReadOnly={isReadOnly || readOnly}
          isDisabled={isDisabled}
          fontSize='15px'
          borderRadius={12}
          className='chakra-input'
          {...props}
          {...formProps}
          ref={ref}
          sx={{
            '&[readonly]': {
              cursor: 'initial',
              '&:focus': {
                border: '1px',
                borderColor: 'var(--chakra-colors-gray-200) !important',
                color: 'inherit',
              },
            },
            '&[aria-invalid=true]': {
              borderWidth: 0,
              borderColor: 'rgba(42, 133, 255, 0.35) !important',
              background: 'rgba(255, 188, 153, 0.3)',
              color: 'var(--chakra-colors-red-500)',
            },
            '&:focus': {
              border: '2px solid',
              borderColor: 'rgba(42, 133, 255, 0.35) !important',
              color: 'inherit',
            },
          }}
        />
        {rightIcon && (
          <InputRightElement width='3rem' {...rightElementProps}>
            {rightIcon}
          </InputRightElement>
        )}
        {/* {!error && isDirty && (
        <InputRightElement width='3rem'>
          <FontAwesomeIcon
            icon={faCheck}
            color={'#83BF6E'}
            style={{ cursor: 'pointer' }}
          ></FontAwesomeIcon>
        </InputRightElement>
      )}
      {isDirty && error && (
        <InputRightElement width='3rem'>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            color={'#ff6a55'}
            style={{ cursor: 'pointer' }}
          ></FontAwesomeIcon>
        </InputRightElement>
      )} */}
      </InputGroup>
    );
  }
);

FormInput.defaultProps = {
  variant: 'filled',
};

export default FormInput;
